import React, { useContext } from 'react'

const OrderOfBusinessManagerContext = React.createContext(null)

export const OrderOfBusinessManagerContextProvider = ({
  children,
  value
}) => (
  <OrderOfBusinessManagerContext.Provider value={value}>
    {children}
  </OrderOfBusinessManagerContext.Provider>
)

export const useOrderOfBusinessManagerContext = () => {
  const orderBusinessManager = useContext(
    OrderOfBusinessManagerContext
  )
  return orderBusinessManager
}

export default OrderOfBusinessManagerContext

import useSWR from 'swr'
import { buildUrl } from 'utils/transformers/apiUrl'
import {
  DRAFT_DOCUMENT_REPORT,
  DOCUMENT_REPORT,
  DOCUMENT_TYPE_ROUTES
} from './constants'

const UserDraftDocumentReport = (draftYear, draftType) => {
  const url = buildUrl({
    baseUrl: DRAFT_DOCUMENT_REPORT,
    year: draftYear?.value,
    type: draftType?.value
  })

  const {
    data: draftDocumentReport,
    error: draftDocumentReportError,
    isValidating: draftDocumentReportIsValidating,
    mutate: draftDocumentReportRevalidate
  } = useSWR(url)

  const draftDocumentReportIsLoading =
    !draftDocumentReport &&
    !draftDocumentReportError &&
    draftDocumentReportIsValidating

  return {
    draftDocumentReport,
    draftDocumentReportError,
    draftDocumentReportIsValidating,
    draftDocumentReportIsLoading,
    draftDocumentReportRevalidate
  }
}

const useDocumentTypes = () => {
  const url = buildUrl({
    baseUrl: DOCUMENT_TYPE_ROUTES
  })

  const {
    data: documentTypes,
    error: documentTypesError,
    isValidating: documentTypesIsValidating,
    mutate: documentTypesRevalidate
  } = useSWR(url)

  const documentTypesIsLoading =
    !documentTypes &&
    !documentTypesError &&
    documentTypesIsValidating

  return {
    documentTypes,
    documentTypesError,
    documentTypesIsValidating,
    documentTypesIsLoading,
    documentTypesRevalidate
  }
}

const UseDocumentReport = (draftYear, draftType) => {
  const url = buildUrl({
    baseUrl: DOCUMENT_REPORT,
    year: draftYear?.value,
    type: draftType?.value
  })

  const {
    data: documentReport,
    error: documentReportError,
    isValidating: documentReportIsValidating,
    mutate: documentReportRevalidate
  } = useSWR(url)

  const documentReportIsLoading =
    !documentReport &&
    !documentReportError &&
    documentReportIsValidating

  return {
    documentReport,
    documentReportError,
    documentReportIsValidating,
    documentReportIsLoading,
    documentReportRevalidate
  }
}

export {
  UserDraftDocumentReport,
  UseDocumentReport,
  useDocumentTypes
}

const SESSION_ROUTE = '/session'
const TERM_ROUTE = '/term/list'

const ORDER_BUSINESS_TYPES = [
  {
    label: 'Communication Received',
    value: 'COMMUNICATION_RECEIVED'
  },
  {
    label: 'First Reading',
    value: 'FIRST_READING'
  },
  {
    label: 'Committee Reports',
    value: 'COMMITTEE_REPORTS'
  },
  {
    label: 'Unfinished Business',
    value: 'UNFINISHED_BUSINESS'
  },
  {
    label: 'Business of the Day',
    value: 'BUSINESS_OF_THE_DAY'
  },
  {
    label: 'Unassigned Business',
    value: 'UNASSIGNED_BUSINESS'
  },
  {
    label: 'Other Matters',
    value: 'OTHER_MATTERS'
  },
  {
    label: 'Measure for Third Reading',
    value: 'MEASTURE_FOR_THIRD_READING'
  }
]

export { SESSION_ROUTE, ORDER_BUSINESS_TYPES, TERM_ROUTE }

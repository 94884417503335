const MINUTES_ROUTE = '/minutes'
const COMMITTEE_ROUTE = '/committee/list'
const DOCUMENT_TYPE_ROUTE = '/final-document-type/list'
const DRAFT_DOCUMENT_ROUTE = '/draft-document/list?status=true'
const SESSION_ROUTE = '/session/list'
const DOCUMENT_ROUTE = '/document'

const CATEGORY_ROUTES = [
  {
    label: 'Minutes of Session',
    value: 'Minutes of Session'
  },
  {
    label: 'Minutes of Public Hearing',
    value: 'Minutes of Public Hearing'
  },
  {
    label: 'Minutes of Committee  Meeting/Hearing',
    value: 'Minutes of Committee  Meeting/Hearing'
  }
]

const SESSION_TYPE_ROUTES = [
  {
    label: 'Regular Session',
    value: 'Regular Session'
  },
  {
    label: 'Special Session',
    value: 'Special Session'
  }
]

export {
  MINUTES_ROUTE,
  COMMITTEE_ROUTE,
  CATEGORY_ROUTES,
  SESSION_TYPE_ROUTES,
  SESSION_ROUTE,
  DRAFT_DOCUMENT_ROUTE,
  DOCUMENT_TYPE_ROUTE,
  DOCUMENT_ROUTE
}

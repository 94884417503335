import React from 'react'
import { useDisclosure } from '@chakra-ui/react'

import Modal from 'components/Modal'
import { ActionsRenderer } from 'components/CellRenderers'
import AlertDialog from 'components/AlertDialog'
import useRoleAccessContext from 'containers/RoleAccess/useRoleAccessContext'
import Edit from './Forms/Edit'
import { useMutateFinalDocumentType } from './api'
import { useFinalDocumentTypeContext } from './Context'

const TableActions = ({ row, value: id }) => {
  const { values } = row
  const { updateDocumentType, deleteDocumentType, isLoading } =
    useMutateFinalDocumentType()
  const { revalidate } = useFinalDocumentTypeContext()
  const { access = [] } = useRoleAccessContext()
  const {
    isOpen: editIsOpen,
    onOpen: editOnOpen,
    onClose: editOnClose
  } = useDisclosure()
  const {
    isOpen: deleteIsOpen,
    onOpen: deleteOnOpen,
    onClose: deleteOnClose
  } = useDisclosure()

  const handleEdit = async (newValue) => {
    await updateDocumentType(id, newValue)

    revalidate()
    editOnClose()
  }

  const handleDelete = async () => {
    await deleteDocumentType(id)

    revalidate()
    deleteOnClose()
  }

  return (
    <>
      <ActionsRenderer
        onEditClick={editOnOpen}
        onDeleteClick={deleteOnOpen}
        values={values}
        view={
          access['master-data'] &&
          access['master-data'].includes('view')
        }
        edit={
          access['master-data'] &&
          access['master-data'].includes('edit')
        }
        delete={
          access['master-data'] &&
          access['master-data'].includes('delete')
        }
      />

      <Modal
        title="Edit Document Type"
        isOpen={editIsOpen}
        onClose={editOnClose}
        withActionButtons={false}
        closeOnOverlayClick={false}
      >
        <Edit
          onClose={editOnClose}
          onSubmit={handleEdit}
          values={values}
        />
      </Modal>

      <AlertDialog
        isLoading={isLoading}
        isOpen={deleteIsOpen}
        onClose={deleteOnClose}
        onConfirm={handleDelete}
        title="Delete Document Type"
      />
    </>
  )
}

export default TableActions

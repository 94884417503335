import React, { useContext } from 'react'

const FinalDocumentContext = React.createContext(null)

export const FinalDocumentProvider = ({ children, value }) => (
  <FinalDocumentContext.Provider value={value}>
    {children}
  </FinalDocumentContext.Provider>
)

export const useFinalDocumentContext = () => {
  return useContext(FinalDocumentContext)
}

export default FinalDocumentContext

import React, { useMemo } from 'react'
import { Select } from 'chakra-react-select'
import { Controller } from 'react-hook-form'

import './styles.css'

const ReactSelect = React.forwardRef(
  (
    { options, control, defaultValues, name, isMulti, ...rest },
    forwardedRef
  ) => {
    const menuPortalTarget = useMemo(
      () => document.querySelector('#react-select-portal'),
      []
    )

    const commonProps = {
      focusBorderColor: 'teal.500',
      selectedOptionColor: 'teal',
      options,
      isMulti,
      menuPortalTarget,
      ...rest
    }
    if (control) {
      return (
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValues}
          render={({ field: { onChange, value, ref } }) => {
            const newOnChange = isMulti
              ? (s) => onChange(s.map((o) => o.value))
              : (o) => onChange(o.value)

            const newValue = isMulti
              ? options.filter((o) => value?.includes(o.value))
              : options.find((o) => o.value === value)

            return (
              <Select
                inputRef={ref}
                value={newValue}
                onChange={newOnChange}
                {...commonProps}
              />
            )
          }}
        />
      )
    }

    return (
      <Select
        name={name}
        inputRef={forwardedRef}
        {...commonProps}
      />
    )
  }
)

export default ReactSelect

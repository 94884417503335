import qs from 'query-string'
import { isObject } from './types'

const generateQueryString = (obj) => {
  if (!isObject) return obj

  const queryString = qs.stringify(obj)
  if (!queryString) return ''

  return `?${queryString}`
}

const parseQueryString = (data) => {
  return qs.parse(data)
}

export { generateQueryString, parseQueryString }

import Button from 'components/Button'
import { Box, Flex } from 'components/Layout'
import Text from 'components/Text'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { formatDate } from 'utils/dateTime'
import { FiPlus } from 'react-icons/fi'
import LoadingOverlay from 'components/Table/LoadingOverlay'
import {
  NoteListHeaderSt,
  NoteListItemSt,
  NoteListSt
} from './styles'
import DeleteButton from './DeleteButton'

const MAX_CHARS = 78

const NoteList = ({
  list,
  onCreate,
  onDelete,
  mutateLoading,
  isLoading,
  ...rest
}) => {
  const handleActiveLink = (route) =>
    route.isActive ? 'active' : ''

  if (isLoading)
    return (
      <Box w="full" position="relative">
        <LoadingOverlay isLoading={isLoading} />
      </Box>
    )

  return (
    <Box w="full">
      <NoteListHeaderSt
        p={4}
        px={30}
        borderBottomWidth={1}
        borderBottomColor="brand.200"
        fontSize="lg"
        justify="space-between"
        align="center"
        className=""
      >
        <Text fontSize="sm">({list.length}) Notes</Text>
        <Button
          size="sm"
          leftIcon={<FiPlus />}
          isLoading={mutateLoading}
          onClick={onCreate}
        >
          New Note
        </Button>
      </NoteListHeaderSt>
      <NoteListSt fontWeight={500} {...rest}>
        {list?.map(({ id, title, content, updatedAt }) => {
          const parsedContent = content
            ? JSON.parse(content)
            : { blocks: [] }
          const firstSentence = parsedContent.blocks[0]?.text
          const optimalNote =
            firstSentence?.length > MAX_CHARS
              ? `${firstSentence?.slice(0, MAX_CHARS)}...`
              : firstSentence

          const formattedDate = formatDate(
            updatedAt,
            'MMM DD, YYYY'
          )

          return (
            <NoteListItemSt key={id} className="List">
              <NavLink to={`${id}`} className={handleActiveLink}>
                <Flex
                  justify="space-between"
                  className="Note__details--primary"
                >
                  {title ? (
                    <Text as="h4" fontWeight={600}>
                      {title}
                    </Text>
                  ) : (
                    <Text as="h4" color="gray.300">
                      Add Title...
                    </Text>
                  )}
                  <Flex align="center">
                    <DeleteButton
                      isLoading={mutateLoading}
                      onDelete={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        onDelete(id)
                      }}
                    />
                    <Text
                      fontSize="xs"
                      color="gray.400"
                      className="Note__details--date"
                    >
                      {formattedDate}
                    </Text>
                  </Flex>
                </Flex>
                <Text fontSize="sm">{optimalNote}</Text>
              </NavLink>
            </NoteListItemSt>
          )
        })}
      </NoteListSt>
    </Box>
  )
}

export default NoteList

import useSWR from 'swr'
import {
  deleteRequest,
  postRequest,
  putRequest
} from 'utils/request'
import errorHandler from 'utils/errorHandler'
import { useCallback, useState } from 'react'
import { buildUrl } from 'utils/transformers/apiUrl'
import {
  MINUTES_ROUTE,
  COMMITTEE_ROUTE,
  SESSION_ROUTE
} from './constants'

const useMinutes = ({
  page,
  id,
  search,
  year,
  sessionTypeData,
  committeeData
} = {}) => {
  const url = buildUrl({
    baseUrl: MINUTES_ROUTE,
    search,
    page,
    id,
    year,
    sessionTypeData,
    committeeData
  })

  const {
    data: minutes,
    error: minutesError,
    isValidating: minutesIsValidating,
    mutate: minutesRevalidate
  } = useSWR(url)

  const minutesIsLoading =
    !minutes && !minutesError && minutesIsValidating

  return {
    minutes,
    minutesError,
    minutesIsValidating,
    minutesIsLoading,
    minutesRevalidate
  }
}

const useCommittee = () => {
  const url = buildUrl({
    baseUrl: COMMITTEE_ROUTE
  })

  const {
    data: committee,
    error: committeeError,
    isValidating: committeeIsValidating,
    mutate: committeeRevalidate
  } = useSWR(url)

  const committeeIsLoading =
    !committee && !committeeError && committeeIsValidating

  return {
    committee,
    committeeError,
    committeeIsValidating,
    committeeIsLoading,
    committeeRevalidate
  }
}

const useSession = () => {
  const url = buildUrl({
    baseUrl: SESSION_ROUTE
  })

  const {
    data: session,
    error: sessionError,
    isValidating: sessionIsValidating,
    mutate: sessionRevalidate
  } = useSWR(url)

  const sessionIsLoading =
    !session && !sessionError && sessionIsValidating

  return {
    session,
    sessionError,
    sessionIsValidating,
    sessionIsLoading,
    sessionRevalidate
  }
}

const useMinutesSingle = (id) => {
  const url = buildUrl({
    baseUrl: `${MINUTES_ROUTE}/${id}`
  })

  const {
    data: minutesSingle,
    error: minutesSingleError,
    isValidating: minutesSingleIsValidating,
    mutate: minutesSingleRevalidate
  } = useSWR(url)

  const minutesSingleIsLoading =
    !minutesSingle &&
    !minutesSingleError &&
    minutesSingleIsValidating

  return {
    minutesSingle,
    minutesSingleError,
    minutesSingleIsValidating,
    minutesSingleIsLoading,
    minutesSingleRevalidate
  }
}

const useMutateMinutes = () => {
  const [response, setResponse] = useState()
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const resetState = useCallback(() => {
    if (response) setResponse(undefined)
    if (isLoading) setError(undefined)
  }, [isLoading, response])

  const createMinutes = useCallback(
    async (payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await postRequest(
          `${MINUTES_ROUTE}?type=minutes`,
          payload
        )
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  const updateMinutes = useCallback(
    async (id, payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await putRequest(
          `${MINUTES_ROUTE}/${id}`,
          payload
        )
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  const deleteMinutes = useCallback(
    async (id) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await deleteRequest(
          `${MINUTES_ROUTE}/${id}`
        )
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  return {
    data: response,
    error,
    isLoading,
    createMinutes,
    updateMinutes,
    deleteMinutes
  }
}

export {
  useMinutes,
  useMutateMinutes,
  useCommittee,
  useSession,
  useMinutesSingle
}

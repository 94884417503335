import * as yup from 'yup'

const messages = {
  requiredGeneric: 'This field is required'
}

const model = yup.object({
  termId: yup.string().required(messages.requiredGeneric),
  documentType: yup.string().required(messages.requiredGeneric),
  title: yup.string().required(messages.requiredGeneric),
  description: yup.string().required(messages.requiredGeneric),
  committeeId: yup.array(),
  file: yup.mixed()
})

const initialValues = {
  termId: '',
  documentType: '',
  title: '',
  committeeId: '',
  description: '',
  file: ''
}

export { model, initialValues }

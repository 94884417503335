import axios from 'axios'

import { API_URL } from '@env'
import { getItem } from 'services/localForage'
import { USER_TOKEN_KEY } from 'containers/Auth/constants'
import errorHandler from '../errorHandler'

const request = axios.create({
  baseURL: API_URL
})

request.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
)

export const attachToken = (token) => {
  if (!token) return
  request.defaults.headers.common.Authorization = `Bearer ${token}`
}

export const detachToken = () => {
  delete request.defaults.headers.common.Authorization
}

const attachTokenFromIndexedDB = async () => {
  const token = await getItem(USER_TOKEN_KEY)
  await attachToken(token)
}

export const fetcher = async (...props) => {
  try {
    await attachTokenFromIndexedDB()
    const { data } = await request.get(...props)
    return data
  } catch (error) {
    throw errorHandler(error)
  }
}

export const postRequest = async (...props) => {
  await attachTokenFromIndexedDB()
  const { data } = await request.post(...props)

  return data
}

export const putRequest = async (...props) => {
  await attachTokenFromIndexedDB()
  const { data } = await request.put(...props)

  return data
}

export const deleteRequest = async (...props) => {
  await attachTokenFromIndexedDB()
  const { data } = await request.delete(...props)

  return data
}

export default request

import React from 'react'
import { useForm } from 'react-hook-form'
import Input from 'components/Input'
import Button from 'components/Button'
import Form from 'components/Form'
import FormField from 'components/FormField'
import { Flex, Stack } from 'components/Layout'
import ReactSelect from 'components/ReactSelect'
import FileUploader from 'components/FileUploader'

import { yupResolver } from '@hookform/resolvers/yup'
import { useCommitteeList } from 'containers/Admin/CommitteeReports/api'
import { model } from './schema'

const Create = ({ onClose, onSubmit }) => {
  const {
    handleSubmit,
    register,
    resetField,
    control,
    formState: { errors, isSubmitting, isDirty }
  } = useForm({
    resolver: yupResolver(model)
  })

  const { committeeList } = useCommitteeList()

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      disabled={isSubmitting}
    >
      <Stack spacing={6} pb={2}>
        <FormField
          id="description"
          isInvalid={errors.description}
          error={errors.description}
          label="Description"
        >
          <Input
            id="description"
            placeholder="Description"
            {...register('description', model.description)}
          />
        </FormField>
        <FormField
          id="committeeId"
          isInvalid={errors.committeeId}
          error={errors.committeeId}
          label="Committee"
        >
          <ReactSelect
            id="committeeId"
            name="committeeId"
            placeholder="Select Committee..."
            control={control}
            options={committeeList || []}
            isMulti
          />
        </FormField>
        <FormField
          id="file"
          isInvalid={errors.file}
          error={errors.file}
          label="Attachments (PDF only)"
        >
          <FileUploader
            placeholder="Select File"
            id="file"
            onReset={() => resetField('file')}
            {...register('file')}
          />
        </FormField>
        <Flex mt={5} justify="flex-end">
          <Button mr={2} variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            isLoading={isSubmitting}
            type="submit"
            disabled={!isDirty || isSubmitting}
          >
            Submit
          </Button>
        </Flex>
      </Stack>
    </Form>
  )
}

export default Create

import TableActions from './TableActions'

const columns = [
  {
    Header: 'Code',
    accessor: 'code'
  },
  {
    Header: 'Description',
    accessor: 'description'
  },
  {
    Header: 'Action',
    accessor: 'id',
    fixed: 'right',
    Cell: TableActions
  }
]

export { columns }

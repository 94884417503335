import * as yup from 'yup'

const model = yup.object({
  description: yup.string(),
  file: yup.mixed().nullable()
})

const initialValues = {
  description: '',
  file: ''
}

export { model, initialValues }

import { generateQueryString } from 'utils/queryString'

export const buildUrl = ({
  baseUrl,
  id,
  page,
  search,
  status,
  filter,
  year,
  type,
  termData: termId,
  committeeData,
  sessionTypeData
}) => {
  return `${baseUrl}${id ? `/${id}` : ''}${generateQueryString({
    page,
    search,
    status,
    filter,
    year,
    type,
    termId,
    committeeData,
    sessionTypeData
  })}`
}

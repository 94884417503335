import { EMAIL_REGEX_PATTERN } from 'utils/regex/constants'

const messages = {
  requiredGeneric: 'This field is required',
  email: 'Please enter a valid email address'
}

const model = {
  email: {
    required: messages.requiredGeneric,
    pattern: {
      value: EMAIL_REGEX_PATTERN,
      message: messages.email
    }
  },
  password: {
    required: messages.requiredGeneric
  }
}

const initialValues = {
  email: '',
  password: ''
}

export { model, initialValues }

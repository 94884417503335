import React from 'react'
import { useDisclosure } from '@chakra-ui/react'

import { Box } from 'components/Layout'
import Modal from 'components/Modal'
import Table from 'components/Table'
import { FiPlus } from 'react-icons/fi'
import useRoleAccessContext from 'containers/RoleAccess/useRoleAccessContext'
import Button from 'components/Button'
import PageWrapper from 'layout/PageWrapper'
import useRoutePagination from 'utils/hooks/useRoutePagination'
import useSearch from 'utils/hooks/useSearch'
import TableHeader from 'components/Table/TableHeader'
import Create from './Forms/Create'

import { columns } from './columns'
import {
  useOutgoingStatus,
  useMutateOutgoingStatus
} from './api'
import { OutgoingStatusProvider } from './Context'

const Dashboard = () => {
  const { page } = useRoutePagination()
  const [search, setSearch] = useSearch()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { access = [] } = useRoleAccessContext()
  const {
    outgoingStatus = {},
    outgoingStatusIsLoading,
    outgoingStatusRevalidate
  } = useOutgoingStatus({ page, search })

  const { data, totalPages } = outgoingStatus

  const { createOutgoingStatus } = useMutateOutgoingStatus()

  const handleCreateOutgoingStatus = async (value) => {
    await createOutgoingStatus(value)

    outgoingStatusRevalidate()
    onClose()
  }

  return (
    <OutgoingStatusProvider
      value={{ revalidate: outgoingStatusRevalidate }}
    >
      <Box>
        <PageWrapper
          headerRightComponent={
            access['master-data'] &&
            access['master-data'].includes('create') ? (
              <Button
                leftIcon={<FiPlus />}
                onClick={() => onOpen()}
              >
                New Outgoing Status
              </Button>
            ) : (
              false
            )
          }
        >
          <TableHeader
            totalPages={totalPages}
            onSearch={setSearch}
          />
          <Table
            isLoading={outgoingStatusIsLoading}
            data={data}
            columns={columns}
          />
        </PageWrapper>

        <Modal
          title="Create Outgoing Status"
          isOpen={isOpen}
          onClose={onClose}
          withActionButtons={false}
          closeOnOverlayClick={false}
        >
          <Create
            onClose={onClose}
            onSubmit={handleCreateOutgoingStatus}
          />
        </Modal>
      </Box>
    </OutgoingStatusProvider>
  )
}

export default React.memo(Dashboard)

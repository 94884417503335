import React, { useEffect } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'

import { Box, Flex, HStack } from 'components/Layout'
import { HEADER_HEIGHT } from 'layout/Header/styles'
import ChatThread from 'components/ChatThread'
import ChatBox from 'components/ChatBox'

import useSocketIo from 'utils/hooks/useSocketIO'
import EmptyOverlay from 'components/Table/EmptyOverlay'
import Text from 'components/Text'
import { isEmpty } from 'ramda'
import {
  useUsers,
  useChatMessages,
  useMutateChatRoom
} from './api'

import { ChatBoxWrapperSt } from './styles'

const Conversations = () => {
  const [socket] = useSocketIo()
  const { chatId: recipientId } = useParams()
  const { users } = useUsers()
  const navigate = useNavigate()

  const { response: chatroomId, createChatRoom } =
    useMutateChatRoom()

  const { chatMessages, chatMessagesRevalidate } =
    useChatMessages(chatroomId)

  const sendMessage = (message) => {
    if (!socket) return
    socket.emit('chatroom-chat', {
      chatroomId,
      message
    })
  }

  useEffect(() => {
    if (!recipientId) return

    const chatRoomChecker = async () => {
      /**
       * This API is anti-pattern. You can't fetch here without
       * ending up on an infinite loop. Never do read and create
       * on SWR again. Should be refactored when time allows it.
       *
       */
      await createChatRoom({
        memberSecond: recipientId
      })
    }

    chatRoomChecker()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipientId])

  useEffect(() => {
    if (!socket) return

    socket.on(chatroomId, () => {
      chatMessagesRevalidate()
    })
  }, [chatroomId, chatMessagesRevalidate, socket])

  useEffect(() => {
    if (recipientId || !users) return

    if (users.length) navigate(`${users[0].id}`)
  }, [navigate, recipientId, users])

  useEffect(() => {
    if (!socket) return

    socket.emit('join-room', { chatroomId })
  }, [chatroomId, socket])

  return (
    <HStack
      h={`calc(100vh - ${HEADER_HEIGHT})`}
      bgColor="white"
      position="relative"
    >
      {!isEmpty(users) ? (
        <>
          <Flex
            as="aside"
            h="full"
            maxW="sm"
            w="full"
            borderRightColor="brand.200"
            borderRightWidth={1}
          >
            <ChatThread thread={users} />
          </Flex>
          <Flex
            as="main"
            h="full"
            flex={1}
            w="full"
            style={{ margin: 0 }}
            borderRightWidth={1}
          >
            <Box position="relative" w="full">
              <Outlet
                context={{
                  chatMessages,
                  users
                }}
              />
              <ChatBoxWrapperSt>
                <ChatBox onSubmit={sendMessage} />
              </ChatBoxWrapperSt>
            </Box>
          </Flex>
        </>
      ) : (
        <Flex w="full" align="center" justify="center">
          <EmptyOverlay
            isEmpty
            labelComponent={
              <Text
                fontSize="lg"
                position="relative"
                left="-30px"
              >
                There&apos;s no active Personnel yet 💬
              </Text>
            }
          />
        </Flex>
      )}
    </HStack>
  )
}

export default Conversations

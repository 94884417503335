import * as yup from 'yup'

const model = yup.object({
  sessionId: yup.string(),
  draftId: yup.string(),
  title: yup.string(),
  shortTitle: yup.string(),
  documentCode: yup.string(),
  documentType: yup.string(),
  dateAdopted: yup.string(),
  userId: yup.string(),
  file: yup.mixed().nullable(),
  attachments: yup.mixed().nullable()
})

const initialValues = {
  sessionId: '',
  draftId: '',
  title: '',
  shortTitle: '',
  documentCode: '',
  documentType: '',
  dateAdopted: '',
  userId: '',
  file: '',
  attachments: ''
}

export { model, initialValues }

import React from 'react'

import { CAPITALIZE_CASE } from 'components/CellRenderers/constants'
import {
  CaseFormatterRenderer,
  MembersRenderer
} from 'components/CellRenderers'
import { formatDate } from '../../../utils/dateTime'
import TableActions from './TableActions'

const columns = [
  {
    Header: 'Code',
    accessor: 'draftCode'
  },
  {
    Header: 'Title',
    accessor: (val) => {
      return `${
        val.shortTitle
          ? val.shortTitle
          : val.draftTitle.substring(0, 50)
      }...`
    }
  },
  {
    Header: 'Authors',
    accessor: 'author',
    Cell: MembersRenderer
  },
  {
    Header: 'Co Authors',
    accessor: 'coAuthor',
    Cell: MembersRenderer
  },
  {
    Header: 'Status',
    accessor: (val) => {
      let data = ''
      if (val && val.approved) {
        data = 'APPROVED'
      } else {
        data = val.status
      }
      return data
    }
  },
  {
    Header: 'Term',
    accessor: (val) => {
      return val['term.description']
    }
  },
  {
    Header: 'Approved By',
    accessor: 'approve',
    Cell: MembersRenderer
  },
  {
    Header: 'Abstained By',
    accessor: 'abstain',
    Cell: MembersRenderer
  },
  {
    Header: 'Disapproved By',
    accessor: 'disapprove',
    Cell: MembersRenderer
  },
  {
    Header: 'Pending Vote',
    accessor: 'pending',
    Cell: MembersRenderer
  },
  {
    Header: 'Certified',
    accessor: 'certified'
  },
  {
    Header: 'Date Created',
    accessor: (val) => {
      return formatDate(val.createdAt)
    }
  },
  {
    Header: 'Type',
    accessor: (val) => {
      let data = ''
      if (val && val.documentType) {
        data = val.documentType.description
      }
      return data
    },
    Cell: (props) => (
      <CaseFormatterRenderer
        {...props}
        textCase={CAPITALIZE_CASE}
      />
    )
  }
]

const getColumns = ({ revalidate }) => [
  ...columns,
  {
    Header: 'Action',
    accessor: 'id',
    fixed: 'right',
    Cell: (props) => (
      <TableActions revalidate={revalidate} {...props} />
    )
  }
]

export { columns, getColumns }

import { Box } from 'components/Layout'
import React from 'react'

import { appendObjectIf } from 'utils/helpers'

import {
  FormControlSt,
  FormErrorMessageSt,
  FormLabelSt
} from './styles'

const FormField = React.forwardRef(
  (
    {
      children,
      label,
      error,
      name,
      isInvalid,
      direction,
      labelPosition,
      labelProps,
      ...rest
    },
    passedRef
  ) => {
    const isHorizontal = direction === 'horizontal'
    const isRightLabel = labelPosition === 'right'

    return (
      <FormControlSt
        isInvalid={isInvalid}
        ref={passedRef}
        {...rest}
      >
        <Box
          {...appendObjectIf(isHorizontal, {
            display: 'flex',
            alignItems: 'center'
          })}
        >
          {label && (
            <FormLabelSt
              htmlFor={name}
              {...appendObjectIf(isHorizontal, {
                mb: 1
              })}
              {...appendObjectIf(isRightLabel, {
                order: 2
              })}
              {...labelProps}
            >
              {label}
            </FormLabelSt>
          )}
          {children}
        </Box>
        {error && (
          <FormErrorMessageSt>
            {error.message}
          </FormErrorMessageSt>
        )}
      </FormControlSt>
    )
  }
)

FormField.defaultProps = {
  direction: 'vertical',
  labelPosition: 'left'
}

export default FormField

import React from 'react'
import { ImageSt } from './styles'

const Image = React.forwardRef(
  ({ children, ...rest }, forwardedRef) => {
    return (
      <ImageSt ref={forwardedRef} {...rest}>
        {children}
      </ImageSt>
    )
  }
)

export default Image

import React from 'react'

import { Box } from 'components/Layout'
import useAuth from 'containers/Auth/useAuth'
import LoadingOverlay from 'components/Table/LoadingOverlay'
import { formatToISOString } from 'utils/dateTime'
import { generateFormData } from 'utils/helpers'
import useToast from 'utils/hooks/useToast'
import Form from './Form'

import { useMutateUsers, useUsers } from '../UserManagement/api'

const Profile = () => {
  const { user } = useAuth()
  const toast = useToast()
  const { users, usersIsLoading, usersRevalidate } = useUsers({
    id: user.session.id
  })

  const { updateUser } = useMutateUsers()

  const handleEditProfile = async (newValues) => {
    const { id } = user.session

    const { birthdate, file = [], ...rest } = newValues

    const payload = {
      birthdate: formatToISOString(birthdate),
      file: file[0],
      ...rest
    }

    const response = updateUser(id, generateFormData(payload))
    if (!response) return

    usersRevalidate()
    toast({
      title: 'Profile edited.',
      status: 'success',
      description: 'Your Profile has been edited'
    })
  }

  return (
    <Box p={6}>
      <Box position="relative" maxW="820px">
        {users && (
          <Form onSubmit={handleEditProfile} values={users} />
        )}

        <LoadingOverlay isLoading={usersIsLoading} />
      </Box>
    </Box>
  )
}

export default React.memo(Profile)

import React from 'react'
import ReactDatePicker from 'react-datepicker'
import { Controller } from 'react-hook-form'
import { createPortal } from 'react-dom'

import 'react-datepicker/dist/react-datepicker.css'
import './styles.css'

const CalendarContainer = ({ children }) =>
  children
    ? createPortal(
        React.cloneElement(children, {
          className: 'react-datepicker-popper'
        }),
        document.body
      )
    : null

const DatePicker = ({
  name,
  selectedDate,
  onChange,
  isClearable = false,
  showPopperArrow = false,
  control,
  ...rest
}) => {
  if (control) {
    return (
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange: controlledOnChange, value }
        }) => (
          <ReactDatePicker
            selected={value}
            onChange={controlledOnChange}
            isClearable={isClearable}
            showYearDropdown
            showPopperArrow={showPopperArrow}
            popperContainer={CalendarContainer}
            {...rest}
          />
        )}
      />
    )
  }

  return (
    <ReactDatePicker
      selected={selectedDate}
      onChange={onChange}
      isClearable={isClearable}
      showPopperArrow={showPopperArrow}
      {...rest}
    />
  )
}

export default DatePicker

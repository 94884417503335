import React from 'react'
import { useForm } from 'react-hook-form'

import Button from 'components/Button'
import Form from 'components/Form'
import FormField from 'components/FormField'
import Input from 'components/Input'
import { Flex, Stack } from 'components/Layout'

import { Alert } from '@chakra-ui/react'
import Text from 'components/Text'
import { model } from './schema'

const LoginForm = ({ login, error, isLoading }) => {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting }
  } = useForm()

  const onSubmit = (values) => login(values)

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      disabled={isSubmitting || isLoading}
    >
      <Stack spacing={4}>
        <FormField
          id="email"
          isInvalid={errors.email}
          error={errors.email}
          label="Email"
        >
          <Input
            id="email"
            placeholder="Email"
            {...register('email', model.email)}
          />
        </FormField>
        <FormField
          id="password"
          isInvalid={errors.password}
          error={errors.password}
          label="Password"
        >
          <Input
            id="password"
            type="password"
            placeholder="Password"
            {...register('password', model.password)}
          />
        </FormField>
        {error && (
          <Alert status="error">
            <Flex justify="center">
              <Text fontSize="sm">{error.message}</Text>
            </Flex>
          </Alert>
        )}
        <Stack spacing={10}>
          <Button type="submit" isLoading={isLoading}>
            Sign in
          </Button>
        </Stack>
      </Stack>
    </Form>
  )
}

export default LoginForm

import React from 'react'
import { InputSt } from './styles'

const Input = React.forwardRef(({ bg, ...rest }, passedRef) => {
  return (
    <InputSt
      focusBorderColor="teal.500"
      bg={bg}
      ref={passedRef}
      {...rest}
    />
  )
})

Input.defaultProps = {
  bg: 'white'
}

export default Input

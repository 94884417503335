import useSWR from 'swr'
import { buildUrl } from 'utils/transformers/apiUrl'
import { CHAT_ROUTE } from './constants'

const useChat = ({ page, id } = {}) => {
  const url = buildUrl({
    baseUrl: CHAT_ROUTE,
    page,
    id
  })

  const {
    data: chats,
    error: chatsError,
    isValidating: chatsIsValidating,
    mutate: chatsRevalidate
  } = useSWR(url, {
    refreshInterval: 5000
  })

  const chatsIsLoading =
    !chats && !chatsError && chatsIsValidating

  return {
    chats,
    chatsError,
    chatsIsValidating,
    chatsIsLoading,
    chatsRevalidate
  }
}

export { useChat }

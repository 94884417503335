import React from 'react'
import { useDisclosure } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { mutate } from 'swr'

import Modal from 'components/Modal'
import { ActionsWithJoinRenderer } from 'components/CellRenderers'
import AlertDialog from 'components/AlertDialog'
import useRoleAccessContext from 'containers/RoleAccess/useRoleAccessContext'
import Edit from './Forms/Edit'
import { useMutateSession } from './api'
import { SESSION_ROUTE } from './constants'

const TableActions = ({ row, value: id }) => {
  const { values } = row
  const navigate = useNavigate()
  const { updateSession, deleteSession, isLoading } =
    useMutateSession()
  const { access = [] } = useRoleAccessContext()
  const {
    isOpen: editIsOpen,
    onOpen: editOnOpen,
    onClose: editOnClose
  } = useDisclosure()
  const {
    isOpen: deleteIsOpen,
    onOpen: deleteOnOpen,
    onClose: deleteOnClose
  } = useDisclosure()

  const handleEdit = async (newValue) => {
    await updateSession(id, newValue)

    mutate(SESSION_ROUTE)
    editOnClose()
  }

  const handleDelete = async () => {
    await deleteSession(id)
    deleteOnClose()
    window.location.reload()
    mutate(SESSION_ROUTE)
  }

  return (
    <>
      <ActionsWithJoinRenderer
        onJoinClick={() => navigate(`join/${id}`)}
        onEditClick={editOnOpen}
        onDeleteClick={deleteOnOpen}
        values={values}
        orOrderOfBusinessClick={() =>
          navigate(`order-of-business/${id}`)
        }
        create={
          access.session && access.session.includes('create')
        }
        view={access.session && access.session.includes('view')}
        update={
          access.session && access.session.includes('edit')
        }
        delete={
          access.session && access.session.includes('delete')
        }
      />
      <Modal
        title="Edit Session"
        isOpen={editIsOpen}
        onClose={editOnClose}
        withActionButtons={false}
        closeOnOverlayClick={false}
      >
        <Edit
          onClose={editOnClose}
          onSubmit={handleEdit}
          values={values}
        />
      </Modal>

      <AlertDialog
        isLoading={isLoading}
        isOpen={deleteIsOpen}
        onClose={deleteOnClose}
        onConfirm={handleDelete}
        title="Delete Session"
      />
    </>
  )
}

export default TableActions

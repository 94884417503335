import { InputGroup, IconButton } from '@chakra-ui/react'
import styled from '@emotion/styled'

const InputGroupSt = styled(InputGroup)`
  && {
    cursor: pointer;
    width: unset;
  }
`

const IconButtonSt = styled(IconButton)`
  position: absolute;
  right: 0;
`

export { InputGroupSt, IconButtonSt }

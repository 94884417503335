/* eslint-disable import/no-cycle */
import React from 'react'
import { useDisclosure } from '@chakra-ui/react'
import FileViewer from 'react-file-viewer-plugins-v3'

import Modal from 'components/Modal'
import { View } from 'components/CellRenderers'
import FormField from 'components/FormField'
import TextArea from 'components/TextArea'
import { Stack } from 'components/Layout'

const TableActions = ({ row }) => {
  const { original } = row
  const {
    isOpen: viewIsOpen,
    onOpen: viewOnOpen,
    onClose: viewOnClose
  } = useDisclosure()

  return (
    <>
      <View onView={viewOnOpen} values={original} />
      <Modal
        size="5xl"
        title="Committee Referrals"
        isOpen={viewIsOpen}
        onClose={viewOnClose}
        withActionButtons={false}
        closeOnOverlayClick={false}
      >
        <Stack spacing={6} pb={12}>
          <FormField id="draftTitle" label="Draft Title">
            <TextArea
              style={{ height: '15vh' }}
              type="text"
              id="draftTitle"
              value={
                original?.draftdocument?.draftTitle ||
                original?.committeedocument?.title
              }
              readOnly
            />
          </FormField>
          {original &&
            original.draftdocument &&
            original.draftdocument.documentFile && (
              <FileViewer
                fileType="pdf"
                filePath={original.draftdocument.documentFile}
              />
            )}
          {original &&
            original.committeedocument &&
            original.committeedocument.documentFile && (
              <FileViewer
                fileType="pdf"
                filePath={
                  original.committeedocument.documentFile
                }
              />
            )}
        </Stack>
      </Modal>
    </>
  )
}

export default TableActions

import React from 'react'
import {
  IconButton,
  Avatar,
  Box,
  Flex,
  HStack,
  VStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from '@chakra-ui/react'
import { FiMenu, FiChevronDown, FiBell } from 'react-icons/fi'
// import playAlert from 'alert-sound-notify'

import Logo from 'assets/images/logo-160px.png'

import useAuth from 'containers/Auth/useAuth'

import Text from 'components/Text'
import Image from 'components/Image'

import { useNavigate } from 'react-router-dom'
import { PATH } from 'routes'
import { HeaderSt, HEADER_HEIGHT, RedDotSt } from './styles'

import { useChat } from './api'
import Notifications from './Notifications'

const Header = ({ onOpen, ...rest }) => {
  const { chats } = useChat()
  const navigate = useNavigate()
  const auth = useAuth()
  const { session } = auth.user
  const [active, setActive] = React.useState(false)

  React.useEffect(() => {
    if (chats) {
      setActive(true)
      // playAlert('bottle')
    }
  }, [chats])

  return (
    <HeaderSt
      px={{ base: 4, md: 4 }}
      height={HEADER_HEIGHT}
      alignItems="center"
      bg="white"
      borderBottomWidth="1px"
      borderBottomColor="brand.200"
      justifyContent="space-between"
      pos="fixed"
      w="full"
      zIndex="10"
      {...rest}
    >
      <Flex
        py={2}
        alignItems="center"
        height="100%"
        pl={10}
        display={{ base: 'none', md: 'flex' }}
      >
        <Image
          objectFit="cover"
          src={Logo}
          alt="Candon Logo"
          height="100%"
          mr={1}
        />
        <Text>Legislative Tracking & Information System</Text>
      </Flex>

      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Image
        display={{ base: 'block', md: 'none' }}
        height="100%"
        p={2}
        objectFit="cover"
        src={Logo}
        alt="Candon Logo"
      />

      <HStack spacing={{ base: '0', md: '6' }}>
        <Flex alignItems="center">
          <Menu>
            <MenuButton
              py={2}
              mr={4}
              position="relative"
              onClick={() => setActive(false)}
            >
              <FiBell fontSize={30} color="teal" />
              {active && <RedDotSt />}
            </MenuButton>

            <MenuList>
              <Notifications notifications={chats} />
            </MenuList>
          </Menu>
          <Menu>
            <MenuButton py={2}>
              <HStack>
                <Avatar size="sm" src={session?.profile} />
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="sm">
                    {session?.firstName} {session?.lastName}
                  </Text>
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList>
              <MenuItem
                px={5}
                onClick={() => {
                  navigate(`/${PATH.PROFILE}`)
                }}
              >
                Profile
              </MenuItem>
              <MenuItem
                px={5}
                onClick={async () => {
                  await auth.logout()
                }}
              >
                Sign out
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </HeaderSt>
  )
}

export default Header

import useSWR from 'swr'
import {
  deleteRequest,
  postRequest,
  putRequest
} from 'utils/request'
import errorHandler from 'utils/errorHandler'
import { useCallback, useState } from 'react'
import { callSWRFetchOnce } from 'utils/request/constant'
import { buildUrl } from 'utils/transformers/apiUrl'
import { TERM_ROUTE, TERM_OPTIONS_ROUTE } from './constants'

const useTerm = ({ page, id, search } = {}) => {
  const url = buildUrl({
    baseUrl: TERM_ROUTE,
    page,
    id,
    search
  })

  const {
    data: term,
    error: termError,
    isValidating: termIsValidating,
    mutate: termRevalidate
  } = useSWR(url)

  const termIsLoading = !term && !termError && termIsValidating

  return {
    term,
    termError,
    termIsValidating,
    termIsLoading,
    termRevalidate
  }
}

const useTermOptions = () => {
  const {
    data,
    error: termOptionsError,
    isValidating: termOptionsIsValidating,
    mutate: termOptionsRevalidate
  } = useSWR(TERM_OPTIONS_ROUTE, callSWRFetchOnce)

  const termOptions = data?.data

  const termOptionsIsLoading =
    !termOptions && !termOptionsError && termOptionsIsValidating

  return {
    termOptions,
    termOptionsError,
    termOptionsIsValidating,
    termOptionsIsLoading,
    termOptionsRevalidate
  }
}

const useMutateTerm = () => {
  const [response, setResponse] = useState()
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const resetState = useCallback(() => {
    if (response) setResponse(undefined)
    if (isLoading) setError(undefined)
  }, [isLoading, response])

  const createTerm = useCallback(
    async (payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await postRequest(TERM_ROUTE, payload)
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  const updateTerm = useCallback(
    async (id, payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await putRequest(
          `${TERM_ROUTE}/${id}`,
          payload
        )
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  const deleteTerm = useCallback(
    async (id) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await deleteRequest(`${TERM_ROUTE}/${id}`)
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  return {
    data: response,
    error,
    isLoading,
    createTerm,
    updateTerm,
    deleteTerm
  }
}

export { useTerm, useTermOptions, useMutateTerm }

import styled from '@emotion/styled'
import { Box } from 'components/Layout'
import Messages from 'components/Messages'
import Text from 'components/Text'
import { CHAT_BOX_HEIGHT } from 'containers/Conversations/constants'
import { useOutletChatContext } from 'containers/Conversations/Context'
import { messages } from 'containers/Conversations/data'
import React, { useEffect, useState, useRef, memo } from 'react'
import { useParams } from 'react-router-dom'

const MessagesWrapperSt = styled(Box)`
  height: ${`calc(100% - ${CHAT_BOX_HEIGHT})`};
`

const Chat = () => {
  const { chatId: recepientId } = useParams()
  const lastMessageRef = useRef()
  const { chatMessages, users } = useOutletChatContext()
  const [scrolled, setScrolled] = useState(false)

  const [recipient] =
    users?.filter((user) => user.id === recepientId) || []

  useEffect(() => {
    if (!lastMessageRef.current) return

    lastMessageRef.current.scrollIntoView({ behavior: 'auto' })
    setScrolled(true)
  }, [chatMessages])

  const isEmpty = chatMessages?.length === 0

  return (
    <MessagesWrapperSt>
      <Box
        p={4}
        borderBottomWidth={1}
        borderBottomColor="brand.200"
        fontSize="lg"
        bg="tertiary"
        h="60px"
      >
        <Text>{recipient?.name}</Text>
      </Box>
      <Messages
        data={chatMessages}
        messages={messages}
        lastMessageRef={lastMessageRef}
        style={{
          opacity: scrolled || isEmpty ? 1 : 0
        }}
      />
    </MessagesWrapperSt>
  )
}

export default memo(Chat)

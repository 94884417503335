import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDisclosure } from '@chakra-ui/react'
import Modal from 'components/Modal'
import AlertDialog from 'components/AlertDialog'
import useRoleAccessContext from 'containers/RoleAccess/useRoleAccessContext'
import { SessionAction } from 'components/CellRenderers'
import useToast from 'utils/hooks/useToast'
import Create from './Forms/Create'
import View from './View/view'
import DocumentView from './View/document'
import {
  createCommitteeReports,
  approvalDraft,
  useSignatory
} from './api'

const TableActions = ({ row, value: id }) => {
  const { access = [] } = useRoleAccessContext()
  const navigate = useNavigate()
  const location = useLocation()
  const { signatory } = useSignatory(id)
  const { values, original } = row
  const toast = useToast()

  const {
    isOpen: submitIsOpen,
    onOpen: submitOnOpen,
    onClose: submitOnClose
  } = useDisclosure()

  const {
    isOpen: viewIsOpen,
    onOpen: viewOnOpen,
    onClose: viewOnClose
  } = useDisclosure()

  const {
    isOpen: documentIsOpen,
    onOpen: documentOnOpen,
    onClose: documentOnClose
  } = useDisclosure()

  const {
    isOpen: confirmIsOpen,
    onOpen: confirmOnOpen,
    onClose: confirmOnClose
  } = useDisclosure()

  const handleSubmit = async () => {
    navigate(`${location.pathname}?selected=${id}`)
  }

  const submit = async (payload) => {
    await createCommitteeReports(payload)
    submitOnClose()
  }
  const [initialId, setInitialId] = useState(null)
  const [initialVal, setInitialVal] = useState(null)
  const handleApproval = async (ids, val) => {
    setInitialId(ids)
    setInitialVal(val)
    confirmOnOpen()
  }

  const submitApproval = async () => {
    await approvalDraft(initialId, initialVal)
    toast({
      title: 'Approved!',
      status: 'success',
      description: 'Document has been approved.'
    })
    viewOnClose()
  }

  return (
    <>
      <SessionAction
        values={values}
        viewDocument={original?.draftdocument?.file}
        onAccept={handleSubmit}
        onRefer={submitOnOpen}
        onView={viewOnOpen}
        onDocument={documentOnOpen}
        hideRefer={
          original.type === 'UNFINISHED_BUSINESS' ||
          original.type === 'BUSINESS_OF_THE_DAY' ||
          original.type === 'UNASSIGNED_BUSINESS' ||
          original.type === 'MEASURES_FOR_THIRD_READING'
        }
        refer={
          access.session &&
          access.session.includes('create') &&
          access.session &&
          access.session.includes('edit')
        }
      />
      <Modal
        title="Refer to Committee"
        isOpen={submitIsOpen}
        onClose={submitOnClose}
        withActionButtons={false}
        closeOnOverlayClick={false}
      >
        <Create
          draftId={original?.draftdocument?.id}
          committeeReportId={original?.committeereport?.id}
          onSubmit={submit}
        />
      </Modal>
      <Modal
        title={`Draft Document ( ${values.Code} )`}
        isOpen={viewIsOpen}
        size="5xl"
        onClose={viewOnClose}
        withActionButtons={false}
        closeOnOverlayClick={false}
      >
        <View
          signatory={signatory}
          handleApproval={handleApproval}
          values={original}
        />
      </Modal>
      <Modal
        title={`Attached Files ( ${values.Code} )`}
        isOpen={documentIsOpen}
        size="5xl"
        onClose={documentOnClose}
        withActionButtons={false}
        closeOnOverlayClick={false}
      >
        <DocumentView values={original} />
      </Modal>
      <AlertDialog
        isOpen={confirmIsOpen}
        onClose={confirmOnClose}
        confirm
        onConfirm={submitApproval}
        title={`${initialVal?.status} this Document`}
      />
    </>
  )
}

export default TableActions

const ARCHIVED_ROUTE = '/archived'
const DOCUMENT_TYPE_ROUTE = '/document-type/list'
const TERM_ROUTE = '/term/list'
const COMMITTEE_ROUTE = '/committee/list'
const READING_STEPS_OPTION = [
  {
    label: 'COMMUNICATION RECEIVED',
    value: 'COMMUNICATION_RECEIVED'
  },
  {
    label: 'FIRST READING',
    value: 'FIRST_READING'
  },
  {
    label: 'UNFINISHED BUSINESS',
    value: 'UNFINISHED_BUSINESS'
  },
  {
    label: 'BUSINESS OF THE DAY',
    value: 'BUSINESS_OF_THE_DAY'
  },
  {
    label: 'UNASSIGNED BUSINESS',
    value: 'UNASSIGNED_BUSINESS'
  },
  {
    label: 'OTHER MATTERS',
    value: 'OTHER_MATTERS'
  },
  {
    label: 'MEASURES FOR THIRD READING',
    value: 'MEASURES_FOR_THIRD_READING'
  },
  {
    label: 'ANNOUNCEMENT',
    value: 'ANNOUNCEMENT'
  },
  {
    label: 'ADJOURNMENT',
    value: 'ADJOURNMENT'
  }
]
export {
  ARCHIVED_ROUTE,
  READING_STEPS_OPTION,
  DOCUMENT_TYPE_ROUTE,
  TERM_ROUTE,
  COMMITTEE_ROUTE
}

import React, { useContext } from 'react'

const TrackingContext = React.createContext(null)

export const TrackingProvider = ({ children, value }) => (
  <TrackingContext.Provider value={value}>
    {children}
  </TrackingContext.Provider>
)

export const useTrackingContext = () => {
  return useContext(TrackingContext)
}

export default TrackingContext

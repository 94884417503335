import {
  Box,
  Center,
  Container,
  Flex,
  Grid,
  SimpleGrid,
  HStack,
  VStack,
  Stack,
  Wrap,
  GridItem
} from '@chakra-ui/react'
import styled from '@emotion/styled'

const BoxSt = styled(Box)``
const CenterSt = styled(Center)``
const ContainerSt = styled(Container)``
const FlexSt = styled(Flex)``
const GridSt = styled(Grid)``
const GridItemSt = styled(GridItem)``
const StackSt = styled(Stack)``
const HStackSt = styled(HStack)``
const VStackSt = styled(VStack)``
const SimpleGridSt = styled(SimpleGrid)``
const WrapSt = styled(Wrap)``

export {
  BoxSt,
  CenterSt,
  ContainerSt,
  FlexSt,
  GridSt,
  GridItemSt,
  StackSt,
  SimpleGridSt,
  WrapSt,
  HStackSt,
  VStackSt
}

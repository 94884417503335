import useSWR from 'swr'
import {
  deleteRequest,
  postRequest,
  putRequest
} from 'utils/request'
import errorHandler from 'utils/errorHandler'
import { useCallback, useState } from 'react'
import { buildUrl } from 'utils/transformers/apiUrl'
import {
  ARCHIVED_ROUTE,
  DOCUMENT_TYPE_ROUTE,
  TERM_ROUTE,
  COMMITTEE_ROUTE
} from './constants'

const useCommittee = () => {
  const url = buildUrl({
    baseUrl: COMMITTEE_ROUTE
  })

  const {
    data: committee,
    error: committeeError,
    isValidating: committeeIsValidating,
    mutate: committeeRevalidate
  } = useSWR(url)

  const committeeIsLoading =
    !committee && !committeeError && committeeIsValidating

  return {
    committee,
    committeeError,
    committeeIsValidating,
    committeeIsLoading,
    committeeRevalidate
  }
}

const useTerm = () => {
  const url = buildUrl({
    baseUrl: `${TERM_ROUTE}`
  })

  const {
    data: term,
    error: termError,
    isValidating: termIsValidating,
    mutate: termRevalidate
  } = useSWR(url)

  const termIsLoading = !term && !termError && termIsValidating

  return {
    term,
    termError,
    termIsValidating,
    termIsLoading,
    termRevalidate
  }
}

const useArchived = ({
  page,
  id,
  search,
  filter,
  year,
  termData,
  committeeData
} = {}) => {
  const url = buildUrl({
    baseUrl: ARCHIVED_ROUTE,
    search,
    page,
    id,
    filter,
    year,
    termData,
    committeeData
  })
  const {
    data: archived,
    error: archivedError,
    isValidating: archivedIsValidating,
    mutate: archivedRevalidate
  } = useSWR(url)

  const archivedIsLoading =
    !archived && !archivedError && archivedIsValidating

  return {
    archived,
    archivedError,
    archivedIsValidating,
    archivedIsLoading,
    archivedRevalidate
  }
}

const useDocumentType = ({ page, id, search, filter } = {}) => {
  const url = buildUrl({
    baseUrl: DOCUMENT_TYPE_ROUTE,
    search,
    page,
    id,
    filter
  })

  const {
    data: documentType,
    error: documentTypeError,
    isValidating: documentTypeIsValidating,
    mutate: documentTypeRevalidate
  } = useSWR(url)

  const documentTypeIsLoading =
    !documentType &&
    !documentTypeError &&
    documentTypeIsValidating

  return {
    documentType,
    documentTypeError,
    documentTypeIsValidating,
    documentTypeIsLoading,
    documentTypeRevalidate
  }
}

const useMutateArchived = () => {
  const [response, setResponse] = useState()
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const resetState = useCallback(() => {
    if (response) setResponse(undefined)
    if (isLoading) setError(undefined)
  }, [isLoading, response])

  const createArchive = useCallback(
    async (payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await postRequest(ARCHIVED_ROUTE, payload)
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  const updateArchive = useCallback(
    async (id, payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await putRequest(
          `${ARCHIVED_ROUTE}/${id}`,
          payload
        )
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  const deleteArchive = useCallback(
    async (id) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await deleteRequest(
          `${ARCHIVED_ROUTE}/${id}`
        )
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  return {
    data: response,
    error,
    isLoading,
    createArchive,
    updateArchive,
    deleteArchive
  }
}

export {
  useArchived,
  useMutateArchived,
  useDocumentType,
  useTerm,
  useCommittee
}

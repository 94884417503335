import React, { useContext } from 'react'

const CommitteeReportsContext = React.createContext(null)

export const CommitteeReportsProvider = ({
  children,
  value
}) => (
  <CommitteeReportsContext.Provider value={value}>
    {children}
  </CommitteeReportsContext.Provider>
)

export const useCommitteeReportsContext = () => {
  return useContext(CommitteeReportsContext)
}

export default CommitteeReportsContext

import React, { useState } from 'react'

import { Box, Flex, Stack } from 'components/Layout'
import Button from 'components/Button'
import ReactSelect from 'components/ReactSelect'
import PageWrapper from 'layout/PageWrapper'
import { useDocumentType } from 'containers/Admin/Dashboard/api'
import {
  UserDraftDocumentReport,
  UseDocumentReport,
  useDocumentTypes
} from './api'
import generatePdf from './generate-ood'
import generateFinalPdf from './generate-final-ood'

const Reports = () => {
  const [draftYear, setDraftYear] = useState('')
  const [draftType, setDraftType] = useState('')
  const [documentYear, setDocumentYear] = useState('')
  const [documentTypeFilter, setDocumentTypeFilter] =
    useState('')
  const { documentType } = useDocumentType()
  const { draftDocumentReport, draftDocumentReportIsLoading } =
    UserDraftDocumentReport(draftYear, draftType)
  const { documentReport, documentReportIsLoading } =
    UseDocumentReport(documentYear, documentTypeFilter)
  const { documentTypes } = useDocumentTypes()

  return (
    <Box>
      <PageWrapper headerRightComponent={false}>
        <Stack spacing={6} pb={2}>
          <Flex>
            <h2
              style={{
                marginRight: '20px',
                marginTop: '8px'
              }}
            >
              Draft Document Report
            </h2>
          </Flex>
          <Flex>
            <div style={{ width: '300px', marginRight: '20px' }}>
              <ReactSelect
                id="userId"
                name="userId"
                placeholder="Select Year..."
                value={draftYear}
                onChange={(e) => {
                  setDraftYear(e)
                }}
                options={[
                  {
                    label: 2019,
                    value: 2019
                  },
                  {
                    label: 2020,
                    value: 2020
                  },
                  {
                    label: 2021,
                    value: 2021
                  },
                  {
                    label: 2022,
                    value: 2022
                  },
                  {
                    label: 2023,
                    value: 2023
                  },
                  {
                    label: 2024,
                    value: 2024
                  },
                  {
                    label: 2025,
                    value: 2025
                  },
                  {
                    label: 2026,
                    value: 2026
                  },
                  {
                    label: 2027,
                    value: 2027
                  },
                  {
                    label: 2028,
                    value: 2028
                  },
                  {
                    label: 2029,
                    value: 2029
                  },
                  {
                    label: 2030,
                    value: 2030
                  }
                ]}
              />
            </div>
          </Flex>
          <Flex>
            <div style={{ width: '300px', marginRight: '20px' }}>
              <ReactSelect
                id="userId"
                name="userId"
                value={draftType}
                onChange={(e) => {
                  setDraftType(e)
                }}
                placeholder="Select Document Type..."
                options={documentType?.data || []}
              />
            </div>
          </Flex>
          <Flex>
            <Button
              disabled={
                !draftDocumentReport ||
                draftDocumentReportIsLoading ||
                (!draftType && !draftYear)
              }
              onClick={() =>
                generatePdf(
                  draftDocumentReport,
                  draftType?.label
                )
              }
              type="submit"
            >
              {draftDocumentReportIsLoading
                ? 'Fetching . . .'
                : 'Preview'}
            </Button>
          </Flex>
          <Flex>
            <h2
              style={{
                marginRight: '20px',
                marginTop: '8px'
              }}
            >
              Adopted Document Report
            </h2>
          </Flex>
          <Flex>
            <div style={{ width: '300px', marginRight: '20px' }}>
              <ReactSelect
                id="userId"
                name="userId"
                placeholder="Select Year..."
                value={documentYear}
                onChange={(e) => {
                  setDocumentYear(e)
                }}
                options={[
                  {
                    label: 2019,
                    value: 2019
                  },
                  {
                    label: 2020,
                    value: 2020
                  },
                  {
                    label: 2021,
                    value: 2021
                  },
                  {
                    label: 2022,
                    value: 2022
                  },
                  {
                    label: 2023,
                    value: 2023
                  },
                  {
                    label: 2024,
                    value: 2024
                  },
                  {
                    label: 2025,
                    value: 2025
                  },
                  {
                    label: 2026,
                    value: 2026
                  },
                  {
                    label: 2027,
                    value: 2027
                  },
                  {
                    label: 2028,
                    value: 2028
                  },
                  {
                    label: 2029,
                    value: 2029
                  },
                  {
                    label: 2030,
                    value: 2030
                  }
                ]}
              />
            </div>
          </Flex>
          <Flex>
            <div style={{ width: '300px', marginRight: '20px' }}>
              <ReactSelect
                id="userId"
                name="userId"
                value={documentTypeFilter}
                onChange={(e) => {
                  setDocumentTypeFilter(e)
                }}
                placeholder="Select Document Type..."
                options={documentTypes?.data || []}
              />
            </div>
          </Flex>
          <Flex>
            <Button
              disabled={
                !documentReport ||
                documentReportIsLoading ||
                (!documentYear && !documentTypeFilter)
              }
              onClick={() =>
                generateFinalPdf(
                  documentReport,
                  documentTypeFilter?.label
                )
              }
              type="submit"
            >
              {documentReportIsLoading
                ? 'Fetching . . .'
                : 'Preview'}
            </Button>
          </Flex>
        </Stack>
      </PageWrapper>
    </Box>
  )
}

export default Reports

import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import Button from 'components/Button'
import Form from 'components/Form'
import FormField from 'components/FormField'
import Input from 'components/Input'
import DatePicker from 'components/DatePicker'
import ReactSelect from 'components/ReactSelect'
import { Flex, Stack } from 'components/Layout'

import { model } from './schema'

const Edit = ({ onClose, onSubmit, values, draft, office }) => {
  const { dateReceived, dateReleased, ...defaultValues } = values
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting, isDirty }
  } = useForm({
    resolver: yupResolver(model),
    defaultValues: {
      dateReceived: new Date(dateReceived),
      dateReleased: new Date(dateReleased),
      ...defaultValues
    }
  })

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      disabled={isSubmitting}
    >
      <Stack spacing={6} pb={2}>
        <FormField
          id="documentId"
          isInvalid={errors.documentId}
          error={errors.documentId}
          label="Document"
        >
          <ReactSelect
            id="documentId"
            name="documentId"
            placeholder="Select Document..."
            control={control}
            options={draft?.data || []}
          />
        </FormField>
        {/* <FormField
          id="responsible"
          isInvalid={errors.responsible}
          error={errors.responsible}
          label="Person Responsible"
        >
          <Input
            id="responsible"
            placeholder="John Doe"
            {...register('responsible')}
          />
        </FormField> */}
        <FormField
          id="dateReleased"
          isInvalid={errors.dateReleased}
          error={errors.dateReleased}
          label="Date Sent"
        >
          <DatePicker
            id="dateReleased"
            name="dateReleased"
            showYearDropdown
            control={control}
            placeholderText="Date Sent"
            yearDropdownItemNumber={100}
            showMonthDropdown
          />
        </FormField>
        <FormField
          id="office"
          isInvalid={errors.office}
          error={errors.office}
          label="Office"
        >
          <ReactSelect
            id="office"
            name="office"
            placeholder="Select Office..."
            isMulti
            control={control}
            options={office?.data || []}
          />
        </FormField>
        {/* <FormField
          id="receiver"
          isInvalid={errors.receiver}
          error={errors.receiver}
          label="Receiver"
        >
          <Input
            id="receiver"
            placeholder="John Doe"
            {...register('receiver')}
          />
        </FormField> */}
        <FormField
          id="dateReceived"
          isInvalid={errors.dateReceived}
          error={errors.dateReceived}
          label="Date Received"
        >
          <DatePicker
            id="dateReceived"
            name="dateReceived"
            showYearDropdown
            control={control}
            placeholderText="Date Released"
            yearDropdownItemNumber={100}
            showMonthDropdown
          />
        </FormField>
        <FormField
          id="remarks"
          isInvalid={errors.remarks}
          error={errors.remarks}
          label="Remarks"
        >
          <Input
            id="remarks"
            placeholder="Remarks"
            {...register('remarks')}
          />
        </FormField>
        <Flex mt={5} justify="flex-end">
          <Button mr={2} variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            isLoading={isSubmitting}
            type="submit"
            disabled={!isDirty || isSubmitting}
          >
            Submit
          </Button>
        </Flex>
      </Stack>
    </Form>
  )
}

export default Edit

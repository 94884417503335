import React from 'react'

import { useSessionJoinContext } from 'containers/Admin/SessionJoin/Context'
import styled from '@emotion/styled'

const TitleSt = styled.button`
  color: var(--chakra-colors-teal-500);
`

const DraftTitle = ({ row, value }) => {
  const { id } = row.original
  const { setDocId } = useSessionJoinContext()

  return (
    <TitleSt
      onClick={(e) => {
        e.preventDefault()
        setDocId(id)
      }}
    >
      {value}
    </TitleSt>
  )
}

export default React.memo(DraftTitle)

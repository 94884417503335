import React from 'react'

import { useOrderOfBusinessManagerContext } from 'containers/Admin/OrderOfBusinessManager/Context'
import styled from '@emotion/styled'

const TitleSt = styled.button`
  color: var(--chakra-colors-teal-500);
`

const OrderOfBusinessTitle = ({ row, value }) => {
  const { id } = row.original
  const { setDocId } = useOrderOfBusinessManagerContext()

  return (
    <TitleSt
      onClick={(e) => {
        e.preventDefault()
        setDocId(id)
      }}
    >
      {value}
    </TitleSt>
  )
}

export default React.memo(OrderOfBusinessTitle)

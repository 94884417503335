import React from 'react'

import { ContentSt } from './styles'

const Content = ({ children }) => {
  return (
    <ContentSt p={5} maxW="1248px" mx="auto">
      {children}
    </ContentSt>
  )
}

export default React.memo(Content)

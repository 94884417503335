import Input from 'components/Input'
import React, { useRef } from 'react'
import { FiXCircle } from 'react-icons/fi'
import { noop } from 'utils/helpers'
import { IconButtonSt, InputGroupSt } from './styles'

const FileUploader = React.forwardRef(
  (
    {
      multiple,
      onReset,
      onChange,
      wrapperProps,
      defaultValue,
      placeholder,
      ...rest
    },
    forwardedRef
  ) => {
    const fileInputRef = useRef()
    const [selectedFile, setSelectedFile] = React.useState(
      defaultValue || ''
    )

    const handleClick = () => {
      fileInputRef.current?.click()
    }

    const handleChange = (e) => {
      onChange(e)

      const [file] = e.target.files
      if (!file) return

      setSelectedFile(file.name)
    }

    const clearSelectedImage = (e) => {
      e.stopPropagation()

      setSelectedFile('')
      onReset()
    }

    const actionButtonsClasses = ['ActionButtons__Wrapper']
    if (selectedFile) {
      actionButtonsClasses.push('Show__Button--delete')
    }

    return (
      <InputGroupSt onClick={handleClick} {...wrapperProps}>
        <Input
          placeholder={placeholder}
          value={selectedFile}
          style={{ pointerEvents: 'none' }}
          onChange={() => {}}
        />
        {selectedFile && (
          <IconButtonSt
            onClick={clearSelectedImage}
            variant="ghost"
            colorScheme="teal"
            aria-label="Clear selected file"
            icon={<FiXCircle />}
          />
        )}
        <input
          onChange={handleChange}
          type="file"
          accept=".pdf"
          multiple
          hidden
          ref={(input) => {
            forwardedRef(input)
            fileInputRef.current = input
          }}
          {...rest}
        />
      </InputGroupSt>
    )
  }
)

FileUploader.defaultProps = {
  multiple: true,
  onChange: noop,
  onReset: noop
}

export default FileUploader

import React from 'react'
import { SwitchSt } from './styles'

const Switch = React.forwardRef(
  ({ colorScheme, ...rest }, forwardedRef) => {
    return (
      <SwitchSt
        ref={forwardedRef}
        colorScheme={colorScheme}
        {...rest}
      />
    )
  }
)

Switch.defaultProps = {
  colorScheme: 'teal'
}

export default Switch

import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useParams } from 'react-router-dom'

import { Box, Flex } from 'components/Layout'
import Editor from 'components/Editor'
import Button from 'components/Button'
import { FormFieldSetSt } from 'components/Form/styles'

import LoadingOverlay from 'components/Table/LoadingOverlay'
import useToast from 'utils/hooks/useToast'
import { mutate } from 'swr'
import { isNil } from 'ramda'
import EditableTitle from './EditableTitle'
import { useMutateNotes, useNote } from '../Notes/api'
import { NOTES_ROUTE_LIST } from '../Notes/constants'

const EditorWrapperSt = styled(Box)`
  && {
    .Toolbar {
      border-left: 0;
      border-top: 0;
      border-right: 0;
    }

    .Editor {
      border-left: 0;
      border-right: 0;
      border-bottom: 0;
    }
  }
`

const Conversations = () => {
  const { id } = useParams()
  const toast = useToast()

  const { updateNote, isLoading: mutateIsLoading } =
    useMutateNotes()
  const {
    note = {},
    noteIsLoading,
    noteRevalidate
  } = useNote({ id })

  const { data } = note
  const { content, title: noteTitle } = data || {}

  const [title, setTitle] = useState()
  const [contentState, setContentState] = useState()
  const [renderEditor, setRenderEditor] = useState(false)

  const handleSubmit = async () => {
    const res = await updateNote(id, {
      title,
      content: JSON.stringify(contentState)
    })

    if (!res) return

    toast({
      title: 'Note Edited',
      status: 'success'
    })
    noteRevalidate()
    mutate(NOTES_ROUTE_LIST)
  }

  useEffect(() => {
    if (isNil(noteTitle)) return

    setTitle(noteTitle)
  }, [noteTitle])

  useEffect(() => {
    if (isNil(content)) return
    setRenderEditor(false)

    const initEditor = async () => {
      const parsedContent = await JSON.parse(content)
      setContentState(parsedContent)
    }

    initEditor()
  }, [content])

  useEffect(() => {
    if (isNil(contentState)) return
    setRenderEditor(true)
  }, [contentState])

  return (
    <FormFieldSetSt aria-disabled={mutateIsLoading} w="full">
      {!isNil(content) && (
        <EditorWrapperSt position="relative" h="full">
          <Flex
            px={8}
            py={3}
            justify="space-between"
            align="center"
            borderBottomColor="brand.200"
            borderBottomWidth={1}
          >
            <Box w="full" mr={4}>
              <EditableTitle
                defaultValue={data?.title}
                value={title}
                onChange={setTitle}
              />
            </Box>
            <Button
              onClick={handleSubmit}
              isLoading={mutateIsLoading}
            >
              Save Note
            </Button>
          </Flex>
          {renderEditor && (
            <Editor
              contentState={contentState}
              onContentStateChange={setContentState}
            />
          )}
        </EditorWrapperSt>
      )}
      <LoadingOverlay isLoading={noteIsLoading} />
    </FormFieldSetSt>
  )
}

export default Conversations

import * as yup from 'yup'

const messages = {
  requiredGeneric: 'This field is required',
  codeMin: 'Code should be 4 characters and above'
}

const model = yup.object({
  code: yup.string().required(messages.requiredGeneric),
  title: yup.string().required(messages.requiredGeneric),
  shortTitle: yup.string().required(messages.requiredGeneric),
  draftId: yup.array().required(messages.requiredGeneric),
  committeeId: yup.array().required(messages.requiredGeneric),
  file: yup.mixed().nullable(),
  documentFile: yup.mixed().nullable()
})

const initialValues = {
  code: '',
  title: '',
  shortTitle: '',
  draftId: '',
  committeeId: '',
  content: '',
  file: '',
  documentFile: ''
}

export { model, initialValues }

import { Box, Text, Center } from '@chakra-ui/react'
import Button from 'components/Button'
import Heading from 'components/Heading'
import React from 'react'

const PageNotFound = () => {
  return (
    <Center minH="100vh">
      <Box textAlign="center" py={10} px={6}>
        <Heading
          size="2xl"
          bgGradient="linear(to-r, teal.400, teal.600)"
          backgroundClip="text"
        >
          404
        </Heading>
        <Text fontSize="18px" mt={3} mb={2}>
          Page Not Found
        </Text>
        <Text color="gray.500" mb={6}>
          The page you&apos;re looking for does not seem to exist
        </Text>

        <Button link to="/">
          Go to Home
        </Button>
      </Box>
    </Center>
  )
}

export default PageNotFound

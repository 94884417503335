import React, { useContext } from 'react'

const TranscriptContext = React.createContext(null)

export const TranscriptProvider = ({ children, value }) => (
  <TranscriptContext.Provider value={value}>
    {children}
  </TranscriptContext.Provider>
)

export const useTranscriptContext = () => {
  return useContext(TranscriptContext)
}

export default TranscriptContext

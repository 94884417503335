import React, { useContext } from 'react'

const CommitteeContext = React.createContext(null)

export const CommitteeProvider = ({ children, value }) => (
  <CommitteeContext.Provider value={value}>
    {children}
  </CommitteeContext.Provider>
)

export const useCommitteeContext = () => {
  return useContext(CommitteeContext)
}

export default CommitteeContext

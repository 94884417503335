import React from 'react'
import { IconSt } from './styles'

const Icon = ({ fontSize, as, ...rest }) => {
  if (!as) return null

  return <IconSt fontSize={fontSize} as={as} {...rest} />
}

Icon.defaultProps = {
  fontSize: 16
}

export default Icon

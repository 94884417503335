import React from 'react'
import { useForm } from 'react-hook-form'

import Button from 'components/Button'
import Form from 'components/Form'
import FormField from 'components/FormField'
import Input from 'components/Input'
import { Flex, Stack } from 'components/Layout'
import ReactSelect from 'components/ReactSelect'
import { useCommittee } from '../api'

const Create = ({
  onClose,
  onSubmit,
  draftId,
  committeeReportId
}) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting }
  } = useForm({
    defaultValues: {
      draftId,
      committeeDocumentId: committeeReportId
    }
  })

  const { committees } = useCommittee()

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      disabled={isSubmitting}
    >
      <Stack spacing={6} pb={12}>
        <FormField
          id="committeeId"
          isInvalid={errors.committeeId}
          error={errors.committeeId}
          label="Committees"
        >
          <ReactSelect
            id="committeeId"
            name="committeeId"
            placeholder="Select Committees..."
            control={control}
            isMulti
            options={committees || []}
          />
        </FormField>
        {/* <FormField
          id="ammendments"
          isInvalid={errors.ammendments}
          error={errors.ammendments}
          label="Ammendments"
        >
          <Input
            type="text"
            id="ammendments"
            placeholder="Ammendments"
            {...register('ammendments')}
          />
        </FormField>
        <FormField
          id="findings"
          isInvalid={errors.findings}
          error={errors.findings}
          label="Findings"
        >
          <Input
            type="text"
            id="findings"
            placeholder="Findings"
            {...register('findings')}
          />
        </FormField> */}
        <FormField
          id="recommendation"
          isInvalid={errors.recommendation}
          error={errors.recommendation}
          label="Recommendation"
        >
          <Input
            type="text"
            id="recommendation"
            placeholder="Recommendation"
            {...register('recommendation')}
          />
        </FormField>
        <Flex mt={5} justify="flex-end">
          <Button
            mr={2}
            variant="outline"
            isLoading={isSubmitting}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button isLoading={isSubmitting} type="submit">
            Submit
          </Button>
        </Flex>
      </Stack>
    </Form>
  )
}

export default Create

import { useState, useCallback } from 'react'
import useSWR from 'swr'
import { postRequest } from 'utils/request'
import errorHandler from 'utils/errorHandler'
import {
  USERS_ROUTE,
  CHAT_ROOM_ROUTE,
  CHAT_MESSAGES_ROUTE
} from './constants'

const useUsers = () => {
  const {
    data: users,
    error: usersError,
    isValidating: usersIsValidating,
    mutate: usersRevalidate
  } = useSWR(`${USERS_ROUTE}/active`)

  const usersIsLoading = !users && !usersError

  return {
    users,
    usersError,
    usersIsValidating,
    usersIsLoading,
    usersRevalidate
  }
}

const useChatMessages = (id) => {
  const {
    data: chatMessages,
    error: chatMessagesError,
    isValidating: chatMessagesIsValidating,
    mutate: chatMessagesRevalidate
  } = useSWR(id ? `${CHAT_MESSAGES_ROUTE}?chatroom=${id}` : null)

  const chatMessagesIsLoading =
    !chatMessages && !chatMessagesError

  return {
    chatMessages,
    chatMessagesError,
    chatMessagesIsValidating,
    chatMessagesIsLoading,
    chatMessagesRevalidate
  }
}

const useMutateChatRoom = () => {
  const [response, setResponse] = useState()
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const resetState = useCallback(() => {
    if (response) setResponse(undefined)
    if (isLoading) setError(undefined)
  }, [isLoading, response])

  const createChatRoom = useCallback(
    async (payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await postRequest(CHAT_ROOM_ROUTE, payload)
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  return {
    response,
    error,
    isLoading,
    createChatRoom
  }
}

export { useUsers, useMutateChatRoom, useChatMessages }

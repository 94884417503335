import useSWR from 'swr'
import {
  deleteRequest,
  postRequest,
  putRequest
} from 'utils/request'
import errorHandler from 'utils/errorHandler'
import { useCallback, useState } from 'react'
import { buildUrl } from 'utils/transformers/apiUrl'
import {
  COMMITTEE_MEETING_ROUTE,
  COMMITTEE_ROUTE,
  SESSION_ROUTE
} from './constants'

const useCommitteeMeetingMinutes = ({
  page,
  id,
  search,
  year,
  committeeData
} = {}) => {
  const url = buildUrl({
    baseUrl: COMMITTEE_MEETING_ROUTE,
    page,
    id,
    year,
    search,
    committeeData
  })

  const {
    data: committeeMeetingMinutes,
    error: committeeMeetingMinutesError,
    isValidating: committeeMeetingMinutesIsValidating,
    mutate: committeeMeetingMinutesRevalidate
  } = useSWR(url)

  const committeeMeetingMinutesIsLoading =
    !committeeMeetingMinutes &&
    !committeeMeetingMinutesError &&
    committeeMeetingMinutesIsValidating

  return {
    committeeMeetingMinutes,
    committeeMeetingMinutesError,
    committeeMeetingMinutesIsValidating,
    committeeMeetingMinutesIsLoading,
    committeeMeetingMinutesRevalidate
  }
}

const useCommittee = () => {
  const url = buildUrl({
    baseUrl: COMMITTEE_ROUTE
  })

  const {
    data: committee,
    error: committeeError,
    isValidating: committeeIsValidating,
    mutate: committeeRevalidate
  } = useSWR(url)

  const committeeIsLoading =
    !committee && !committeeError && committeeIsValidating

  return {
    committee,
    committeeError,
    committeeIsValidating,
    committeeIsLoading,
    committeeRevalidate
  }
}

const useSession = () => {
  const url = buildUrl({
    baseUrl: SESSION_ROUTE
  })

  const {
    data: session,
    error: sessionError,
    isValidating: sessionIsValidating,
    mutate: sessionRevalidate
  } = useSWR(url)

  const sessionIsLoading =
    !session && !sessionError && sessionIsValidating

  return {
    session,
    sessionError,
    sessionIsValidating,
    sessionIsLoading,
    sessionRevalidate
  }
}

const useCommitteeMeetingMinutesSingle = (id) => {
  const url = buildUrl({
    baseUrl: `${COMMITTEE_MEETING_ROUTE}/${id}`
  })

  const {
    data: committeeMeetingMinutesSingle,
    error: committeeMeetingMinutesSingleError,
    isValidating: committeeMeetingMinutesSingleIsValidating,
    mutate: committeeMeetingMinutesSingleRevalidate
  } = useSWR(url)

  const committeeMeetingMinutesSingleIsLoading =
    !committeeMeetingMinutesSingle &&
    !committeeMeetingMinutesSingleError &&
    committeeMeetingMinutesSingleIsValidating

  return {
    committeeMeetingMinutesSingle,
    committeeMeetingMinutesSingleError,
    committeeMeetingMinutesSingleIsValidating,
    committeeMeetingMinutesSingleIsLoading,
    committeeMeetingMinutesSingleRevalidate
  }
}

const useMutateCommitteeMeetingMinutes = () => {
  const [response, setResponse] = useState()
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const resetState = useCallback(() => {
    if (response) setResponse(undefined)
    if (isLoading) setError(undefined)
  }, [isLoading, response])

  const createCommitteeMeetingMinutes = useCallback(
    async (payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await postRequest(
          `${COMMITTEE_MEETING_ROUTE}?type=minutes`,
          payload
        )
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  const updateCommitteeMeetingMinutes = useCallback(
    async (id, payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await putRequest(
          `${COMMITTEE_MEETING_ROUTE}/${id}`,
          payload
        )
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  const deleteCommitteeMeetingMinutes = useCallback(
    async (id) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await deleteRequest(
          `${COMMITTEE_MEETING_ROUTE}/${id}`
        )
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  return {
    data: response,
    error,
    isLoading,
    createCommitteeMeetingMinutes,
    updateCommitteeMeetingMinutes,
    deleteCommitteeMeetingMinutes
  }
}

export {
  useCommitteeMeetingMinutes,
  useMutateCommitteeMeetingMinutes,
  useCommittee,
  useSession,
  useCommitteeMeetingMinutesSingle
}

import React, { useContext } from 'react'

const CommitteeReferralsContext = React.createContext(null)

export const CommitteeReferralsProvider = ({
  children,
  value
}) => (
  <CommitteeReferralsContext.Provider value={value}>
    {children}
  </CommitteeReferralsContext.Provider>
)

export const useCommitteeReferralsContext = () => {
  const CommitteeReferrals = useContext(
    CommitteeReferralsContext
  )
  return CommitteeReferrals
}

export default CommitteeReferralsContext

import { Button } from '@chakra-ui/react'
import styled from '@emotion/styled'

const ButtonSt = styled(Button)`
  .chakra-button__icon {
    font-size: 20px;
  }
`

export { ButtonSt }

import React from 'react'
import { useForm } from 'react-hook-form'

import Button from 'components/Button'
import Form from 'components/Form'
import FormField from 'components/FormField'
import Input from 'components/Input'
import { Flex, Stack } from 'components/Layout'

import DatePicker from 'components/DatePicker'
import Textarea from 'components/TextArea'
import ImageUploader from 'components/ImageUploader'

const Create = ({ onClose, onSubmit }) => {
  const {
    handleSubmit,
    register,
    control,
    getValues,
    resetField,
    formState: { errors, isSubmitting, isDirty }
  } = useForm()

  const hasImage = !!getValues('file')

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      disabled={isSubmitting}
    >
      <Stack spacing={6} pb={2}>
        <FormField
          id="file"
          mr={4}
          isInvalid={errors.file}
          error={errors.file}
          label={`${hasImage ? 'Change' : 'Add'} Profile Photo`}
          direction="horizontal"
          labelPosition="right"
          labelProps={{ cursor: 'pointer', color: 'teal' }}
        >
          <ImageUploader
            wrapperProps={{ mr: 4 }}
            id="file"
            onReset={() => resetField('file')}
            {...register('file')}
          />
        </FormField>
        <Flex>
          <FormField
            id="firstName"
            mr={4}
            isInvalid={errors.firstName}
            error={errors.firstName}
            label="First Name"
          >
            <Input
              id="firstName"
              placeholder="First Name"
              {...register('firstName')}
            />
          </FormField>

          <FormField
            id="middleName"
            mr={4}
            isInvalid={errors.middleName}
            error={errors.middleName}
            label="Middle Name"
          >
            <Input
              id="middleName"
              placeholder="Middle Name"
              {...register('middleName')}
            />
          </FormField>

          <FormField
            id="lastName"
            isInvalid={errors.lastName}
            error={errors.lastName}
            label="Last Name"
          >
            <Input
              id="lastName"
              placeholder="Last Name"
              {...register('lastName')}
            />
          </FormField>
        </Flex>
        <Flex>
          <FormField
            id="suffix"
            width={200}
            isInvalid={errors.suffix}
            error={errors.suffix}
            label="Suffix"
            mr={4}
          >
            <Input
              id="suffix"
              placeholder="Suffix"
              {...register('suffix')}
            />
          </FormField>

          <FormField
            id="birthdate"
            isInvalid={errors.birthdate}
            error={errors.birthdate}
            label="Birthday"
          >
            <DatePicker
              id="birthdate"
              name="birthdate"
              showYearDropdown
              control={control}
              dateFormatCalendar="MMMM"
              maxDate={new Date()}
              placeholderText="Birthday"
              yearDropdownItemNumber={100}
              scrollableYearDropdown
              showMonthDropdown
            />
          </FormField>
        </Flex>

        <Flex>
          <FormField
            id="email"
            isInvalid={errors.email}
            error={errors.email}
            label="Email"
            mr={4}
          >
            <Input
              id="email"
              placeholder="Email"
              {...register('email')}
            />
          </FormField>
        </Flex>

        <FormField
          id="password"
          isInvalid={errors.password}
          error={errors.password}
          label="Password"
        >
          <Input
            id="password"
            placeholder="Password"
            type="password"
            {...register('password')}
          />
        </FormField>

        <FormField
          id="barangay"
          isInvalid={errors.barangay}
          error={errors.barangay}
          label="Barangay"
        >
          <Input
            id="barangay"
            placeholder="Barangay"
            {...register('barangay')}
          />
        </FormField>

        <Flex>
          <FormField
            id="city"
            isInvalid={errors.city}
            error={errors.city}
            label="City"
            mr={4}
          >
            <Input
              id="city"
              placeholder="City"
              {...register('city')}
            />
          </FormField>
          <FormField
            id="province"
            isInvalid={errors.province}
            error={errors.province}
            label="Province"
          >
            <Input
              id="province"
              placeholder="Province"
              {...register('province')}
            />
          </FormField>
        </Flex>

        <FormField
          id="address1"
          isInvalid={errors.address1}
          error={errors.address1}
          label="Address 1"
          mr={4}
        >
          <Textarea
            id="address1"
            placeholder="Address 1"
            {...register('address1')}
          />
        </FormField>
        <FormField
          id="address2"
          isInvalid={errors.address2}
          error={errors.address2}
          label="Address 2"
          mr={4}
        >
          <Textarea
            id="address2"
            placeholder="Address 2"
            {...register('address2')}
          />
        </FormField>

        <Flex mt={5} justify="flex-end">
          <Button mr={2} variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            isLoading={isSubmitting}
            disabled={isSubmitting || !isDirty}
            type="submit"
          >
            Submit
          </Button>
        </Flex>
      </Stack>
    </Form>
  )
}

export default Create

import React from 'react'

import Button from 'components/Button'
import { HStack } from 'components/Layout'

const OrderOfBusinessAction = ({
  onReject,
  onAccept,
  original
}) => {
  return (
    <HStack direction="row">
      {original.status && (
        <Button
          onClick={onReject}
          colorScheme="red"
          variant="solid"
          size="sm"
          _focus={{
            boxShadow: '0 0 0 3px rgba(229, 62, 62, 0.5)'
          }}
        >
          Decline
        </Button>
      )}

      {!original.status && (
        <Button
          colorScheme="teal"
          variant="outline"
          size="sm"
          onClick={onAccept}
          _focus={{
            boxShadow: '0 0 0 3px rgba(49, 151, 149, 0.5)'
          }}
        >
          Submit
        </Button>
      )}
    </HStack>
  )
}

export default React.memo(OrderOfBusinessAction)

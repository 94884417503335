import * as yup from 'yup'

const messages = {
  requiredGeneric: 'This field is required',
  codeMin: 'Code should be 4 characters and above'
}

const model = yup.object({
  description: yup.string().required(messages.requiredGeneric),
  from: yup.string().required(messages.requiredGeneric),
  to: yup.string().required(messages.requiredGeneric)
})

const initialValues = {
  code: '',
  description: '',
  from: null,
  to: null
}

export { model, initialValues }

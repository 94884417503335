import useSWR from 'swr'
import {
  deleteRequest,
  postRequest,
  putRequest
} from 'utils/request'
import errorHandler from 'utils/errorHandler'
import { useCallback, useState } from 'react'
import { buildUrl } from 'utils/transformers/apiUrl'
import {
  DRAFT_DOCUMENT_ROUTE,
  SESSION_ROUTE,
  PROPOSAL_ROUTE,
  USERS_ROUTES,
  COMMITTEE_ROUTE
} from './constants'

const useSession = () => {
  const url = buildUrl({
    baseUrl: `${SESSION_ROUTE}/list`
  })

  const {
    data: session,
    error: sessionError,
    isValidating: sessionIsValidating,
    mutate: sessionRevalidate
  } = useSWR(url)

  const sessionIsLoading =
    !session && !sessionError && sessionIsValidating

  return {
    session,
    sessionError,
    sessionIsValidating,
    sessionIsLoading,
    sessionRevalidate
  }
}

const useHistory = (id) => {
  const url = buildUrl({
    baseUrl: `${PROPOSAL_ROUTE}/history`,
    id
  })

  const {
    data: history,
    error: historyError,
    isValidating: historyIsValidating,
    mutate: historyRevalidate
  } = useSWR(url)

  const historyIsLoading =
    !history && !historyError && historyIsValidating

  return {
    history,
    historyError,
    historyIsValidating,
    historyIsLoading,
    historyRevalidate
  }
}

const useCommittee = () => {
  const url = buildUrl({
    baseUrl: `${COMMITTEE_ROUTE}/list`
  })

  const {
    data: committee,
    error: committeeError,
    isValidating: committeeIsValidating,
    mutate: committeeRevalidate
  } = useSWR(url)

  const committeeIsLoading =
    !committee && !committeeError && committeeIsValidating

  return {
    committee,
    committeeError,
    committeeIsValidating,
    committeeIsLoading,
    committeeRevalidate
  }
}

const useUsers = () => {
  const url = buildUrl({
    baseUrl: `${USERS_ROUTES}`
  })

  const {
    data: users,
    error: usersError,
    isValidating: usersIsValidating,
    mutate: usersRevalidate
  } = useSWR(url)

  const usersIsLoading =
    !users && !usersError && usersIsValidating

  return {
    users,
    usersError,
    usersIsValidating,
    usersIsLoading,
    usersRevalidate
  }
}

const useMutateSession = () => {
  const [response, setResponse] = useState()
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const resetState = useCallback(() => {
    if (response) setResponse(undefined)
    if (isLoading) setError(undefined)
  }, [isLoading, response])

  const createSession = useCallback(
    async (payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await postRequest(PROPOSAL_ROUTE, payload)
        setResponse(data)
        setIsLoading(false)
        return data
      } catch (err) {
        setError(errorHandler(err))
        setIsLoading(false)
      }
      return null
    },
    [resetState]
  )
  return {
    data: response,
    error,
    isLoading,
    createSession
  }
}

const useDraftDocument = ({ page, id, search, filter } = {}) => {
  const url = buildUrl({
    baseUrl: DRAFT_DOCUMENT_ROUTE,
    search,
    page,
    id,
    filter
  })

  const {
    data: draftDocument,
    error: draftDocumentError,
    isValidating: draftDocumentIsValidating,
    mutate: draftDocumentRevalidate
  } = useSWR(url)

  const draftDocumentIsLoading =
    !draftDocument &&
    !draftDocumentError &&
    draftDocumentIsValidating

  return {
    draftDocument,
    draftDocumentError,
    draftDocumentIsValidating,
    draftDocumentIsLoading,
    draftDocumentRevalidate
  }
}

const useMutateDraftDocument = () => {
  const [response, setResponse] = useState()
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const resetState = useCallback(() => {
    if (response) setResponse(undefined)
    if (isLoading) setError(undefined)
  }, [isLoading, response])

  const createDraftDocument = useCallback(
    async (payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await postRequest(
          DRAFT_DOCUMENT_ROUTE,
          payload
        )
        setResponse(data)
        setIsLoading(false)
        return data
      } catch (err) {
        setError(errorHandler(err))
        setIsLoading(false)
      }
      return null
    },
    [resetState]
  )

  const updateDraftDocument = useCallback(
    async (id, payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await putRequest(
          `${DRAFT_DOCUMENT_ROUTE}/${id}`,
          payload
        )
        setResponse(data)
        setIsLoading(false)
        return data
      } catch (err) {
        setError(errorHandler(err))
        setIsLoading(false)
      }
      return null
    },
    [resetState]
  )

  const approvedDraftDocument = useCallback(
    async (id) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await putRequest(
          `${DRAFT_DOCUMENT_ROUTE}/approved/${id}`
        )
        setResponse(data)
        setIsLoading(false)
        return data
      } catch (err) {
        setError(errorHandler(err))
        setIsLoading(false)
      }
      return null
    },
    [resetState]
  )

  const deleteDraftDocument = useCallback(
    async (id) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await deleteRequest(
          `${DRAFT_DOCUMENT_ROUTE}/${id}`
        )
        setResponse(data)
        setIsLoading(false)
        return data
      } catch (err) {
        setError(errorHandler(err))
        setIsLoading(false)
      }
      return null
    },
    [resetState]
  )

  return {
    data: response,
    error,
    isLoading,
    createDraftDocument,
    updateDraftDocument,
    deleteDraftDocument,
    approvedDraftDocument
  }
}

export {
  useDraftDocument,
  useMutateDraftDocument,
  useSession,
  useMutateSession,
  useUsers,
  useCommittee,
  useHistory
}

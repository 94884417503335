import styled from '@emotion/styled'
import customScrollbar from 'theme/styles/customScrollbar'
import { Flex } from 'components/Layout'

const HEADER_HEIGHT = '65px'

const NoteListHeaderSt = styled(Flex)`
  height: ${HEADER_HEIGHT};
`

const NoteListSt = styled.ul`
  width: 100%;
  overflow-y: overlay;
  height: ${`calc(100% - ${HEADER_HEIGHT})`};
  ${customScrollbar}

  .Button__Delete {
    opacity: 0;
  }

  li a {
    height: 107px;
    display: inline-block;
    width: 100%;
    padding: 20px 30px;
    border-bottom: 1px solid var(--chakra-colors-brand-200);

    &.active {
      background-color: #f0f1f2;
    }

    &:hover {
      background-color: #f9f9fa;
      .Button__Delete {
        opacity: 1;
      }
    }
  }
`

const NoteListItemSt = styled.li`
  font-weight: 400;
`

export { NoteListSt, NoteListItemSt, NoteListHeaderSt }

/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react'
import {
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton
} from '@chakra-ui/react'
import moment from 'moment'
import { Chrono } from 'react-chrono'
import Modal from 'components/Modal'
import { DraftDocumentActionRenderer } from 'components/CellRenderers'
import AlertDialog from 'components/AlertDialog'
import useRoleAccessContext from 'containers/RoleAccess/useRoleAccessContext'
import { useNavigate } from 'react-router-dom'
import {
  useMutateDraftDocument,
  useMutateSession,
  useDraftDocument,
  useHistory
} from './api'

import Create from './Forms/Create'

const TableActions = ({ row, value: docId, revalidate }) => {
  const navigate = useNavigate()
  const {
    deleteDraftDocument,
    approvedDraftDocument,
    isLoading
  } = useMutateDraftDocument()
  const { draftDocumentRevalidate } = useDraftDocument()
  const { history } = useHistory(row.original.id)
  const { createSession } = useMutateSession()
  const { access = [] } = useRoleAccessContext()
  const { values, original } = row

  const {
    isOpen: deleteIsOpen,
    onOpen: deleteOnOpen,
    onClose: deleteOnClose
  } = useDisclosure()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const {
    isOpen: approvedIsOpen,
    onOpen: approvedOnOpen,
    onClose: approvedOnClose
  } = useDisclosure()

  const handleDelete = async () => {
    await deleteDraftDocument(docId)
    revalidate()
    deleteOnClose()
  }

  const {
    isOpen: submitIsOpen,
    onOpen: submitOnOpen,
    onClose: submitOnClose
  } = useDisclosure()

  const handleSubmit = async (val) => {
    await createSession(val)
    draftDocumentRevalidate()
    submitOnClose()
  }

  const onApproved = () => {
    approvedOnOpen()
  }

  const handleApproved = () => {
    approvedDraftDocument(original.id)
    approvedOnClose()
  }

  const [chrono, setChrono] = useState([])

  useEffect(() => {
    if (history && history.length > 0) {
      const arr = []
      for (let i = 0; i < history.length; i++) {
        arr.push({
          title: history[i].createdAt
            ? moment(new Date(history[i].createdAt)).format(
                'MM/DD/YYYY'
              )
            : '',
          cardTitle: history[i].draftdocument.draftNumber,
          url: 'http://www.history.com',
          cardSubtitle: history[i].draftdocument.shortTitle,
          cardDetailedText: history[i].draftdocument.draftTitle
        })
      }
      setChrono(arr)
    }
  }, [history])
  return (
    <>
      <DraftDocumentActionRenderer
        onSubmitClick={submitOnOpen}
        onEditClick={() =>
          navigate(`/draft-document/edit/${docId}`)
        }
        onDownloadClick={original.file}
        create={
          access['draft-document'] &&
          access['draft-document'].includes('create')
        }
        view={
          access['draft-document'] &&
          access['draft-document'].includes('view')
        }
        update={
          access['draft-document'] &&
          access['draft-document'].includes('edit')
        }
        delete={
          access['draft-document'] &&
          access['draft-document'].includes('delete')
        }
        onDeleteClick={deleteOnOpen}
        values={original}
        onApproved={onApproved}
        onViewClick={onOpen}
      />
      <Drawer
        isOpen={isOpen}
        placement="right"
        size="xl"
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Chronological of Document</DrawerHeader>

          <DrawerBody>
            <Chrono items={chrono} mode="VERTICAL" />
          </DrawerBody>
          <DrawerFooter>
            Footer
            {/* <Button variant='outline' mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme='blue'>Save</Button> */}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <Modal
        title="Submit to Sanggunian"
        isOpen={submitIsOpen}
        onClose={submitOnClose}
        withActionButtons={false}
        closeOnOverlayClick={false}
      >
        <Create
          id={docId}
          onClose={submitOnClose}
          onSubmit={handleSubmit}
          values={values}
        />
      </Modal>

      <AlertDialog
        isLoading={isLoading}
        isOpen={approvedIsOpen}
        onClose={approvedOnClose}
        onConfirm={handleApproved}
        title="Approve Document"
        confirm
      />

      <AlertDialog
        isLoading={isLoading}
        isOpen={deleteIsOpen}
        onClose={deleteOnClose}
        onConfirm={handleDelete}
        title="Delete Draft"
      />
    </>
  )
}

export default TableActions

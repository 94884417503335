import { Avatar } from '@chakra-ui/react'
import React, { useMemo, useRef } from 'react'
import { FiDelete, FiPlusCircle } from 'react-icons/fi'
import { noop } from 'utils/helpers'
import {
  ActionButtonsWrapperSt,
  IconWrapperSt,
  InputGroupSt
} from './styles'

const ImageUploader = React.forwardRef(
  (
    {
      children,
      multiple,
      onReset,
      onChange,
      wrapperProps,
      defaultValue,
      fileTypes,
      ...rest
    },
    forwardedRef
  ) => {
    const fileInputRef = useRef()
    const [selectedImageUrl, setSelectedImageUrl] =
      React.useState(defaultValue)

    const acceptedFileTypes = useMemo(() => {
      return fileTypes.join(', ')
    }, [fileTypes])

    const handleClick = () => {
      fileInputRef.current?.click()
    }

    const handleChange = (e) => {
      onChange(e)

      const [file] = e.target.files
      if (!file) return

      if (!fileTypes.includes(file.type)) {
        const msg = `File type should be one of the ff: ${acceptedFileTypes}`
        console.error(msg)
        return
      }

      const fileReader = new FileReader()

      fileReader.readAsDataURL(file)
      fileReader.onloadend = () => {
        setSelectedImageUrl(fileReader.result)
      }
    }

    const clearSelectedImage = (e) => {
      e.stopPropagation()

      setSelectedImageUrl('')
      onReset()
    }

    const actionButtonsClasses = ['ActionButtons__Wrapper']
    if (selectedImageUrl) {
      actionButtonsClasses.push('Show__Button--delete')
    }

    return (
      <InputGroupSt onClick={handleClick} {...wrapperProps}>
        <Avatar size="lg" src={selectedImageUrl} />
        <input
          onChange={handleChange}
          type="file"
          multiple={multiple}
          hidden
          accept={fileTypes.join(', ')}
          ref={(input) => {
            forwardedRef(input)
            fileInputRef.current = input
          }}
          {...rest}
        />
        {children}
        <ActionButtonsWrapperSt
          position="absolute"
          bottom={0}
          right="-25px"
          className={actionButtonsClasses.join(' ')}
        >
          <IconWrapperSt>
            <FiPlusCircle />
          </IconWrapperSt>
          <IconWrapperSt
            onClick={clearSelectedImage}
            className="Button__Delete"
          >
            <FiDelete />
          </IconWrapperSt>
        </ActionButtonsWrapperSt>
      </InputGroupSt>
    )
  }
)

ImageUploader.defaultProps = {
  multiple: false,
  fileTypes: ['image/png', 'image/gif', 'image/jpeg'],
  onChange: noop,
  onReset: noop
}

export default ImageUploader

/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
/* eslint-disable camelcase */
import React, { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  SortableContainer,
  SortableElement
} from 'react-sortable-hoc'

import FromComponent from 'components/Form'
import FormField from 'components/FormField'
import Input from 'components/Input'
import TextArea from 'components/TextArea'
import { Flex, Stack } from 'components/Layout'
import ReactSelect from 'components/ReactSelect'

import DatePicker from 'components/DatePicker'
import FileUploader from 'components/FileUploader'
import { model, initialValues } from './schema'

const Form = ({
  formRef,
  onSubmit,
  term,
  documentTypeOptions,
  setFormState,
  usersOptions,
  committee,
  author,
  setAuthor,
  coAuthor,
  setCoAuthor
}) => {
  const {
    handleSubmit,
    register,
    control,
    reset,
    resetField,
    formState: { errors, isSubmitting, isDirty }
  } = useForm({
    resolver: yupResolver(model)
  })

  const SortableItem = SortableElement(({ value }) => (
    <div className="sortableItem">
      <div className="sortableItem-handle" />
      {value.label}
    </div>
  ))

  const SortableList = SortableContainer(({ items }) => {
    return (
      <ul>
        {items.map((value, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            value={value}
          />
        ))}
      </ul>
    )
  })

  const resetForm = useCallback(() => {
    reset(initialValues)
  }, [reset])

  useEffect(() => {
    setFormState({
      isSubmitting,
      isDirty
    })
  }, [isDirty, isSubmitting, setFormState])

  const move = (collection, fromIndex, toIndex) => {
    const result = []
    const shift = toIndex - fromIndex > 0 ? 1 : -1
    const start = toIndex < fromIndex ? toIndex : fromIndex
    const end = start < toIndex ? toIndex : fromIndex

    for (let index = 0; index < collection.length; index++) {
      const offset = index >= start && index <= end ? shift : 0
      result[index] = collection[index + offset]
    }

    result[toIndex] = collection[fromIndex]

    return result
  }

  const handleSortEndAuthor = ({ oldIndex, newIndex }) => {
    setAuthor(move(author, oldIndex, newIndex))
  }

  const handleSortEndCoAuthor = ({ oldIndex, newIndex }) => {
    setCoAuthor(move(coAuthor, oldIndex, newIndex))
  }

  return (
    <FromComponent
      ref={formRef}
      onSubmit={handleSubmit((e) => onSubmit(e, resetForm))}
      disabled={isSubmitting}
    >
      <Stack spacing={6} pb={2}>
        <Flex>
          <FormField
            id="draftNumber"
            isInvalid={errors.draftNumber}
            error={errors.draftNumber}
            label="Draft Number"
          >
            <Input
              id="draftNumber"
              placeholder="Draft Number"
              {...register('draftNumber')}
            />
          </FormField>
        </Flex>
        <Flex>
          <FormField
            id="author"
            isInvalid={errors.author}
            error={errors.author}
            label="Main Author/s"
            mr={4}
          >
            <ReactSelect
              id="author"
              name="author"
              placeholder="Select Author..."
              onChange={(val) => {
                setAuthor(val)
              }}
              isMulti
              options={usersOptions || []}
            />
          </FormField>
          <FormField
            id="author"
            isInvalid={errors.author}
            error={errors.author}
            label="Co Author/s"
            mr={4}
          >
            <ReactSelect
              id="author"
              name="author"
              placeholder="Select Author..."
              onChange={(val) => {
                setCoAuthor(val)
              }}
              isMulti
              options={usersOptions || []}
            />
          </FormField>
        </Flex>
        <Flex>
          <FormField>
            <SortableList
              items={author}
              lockAxis="y"
              onSortEnd={handleSortEndAuthor}
            />
          </FormField>
          <FormField ml={5}>
            <SortableList
              items={coAuthor}
              lockAxis="y"
              onSortEnd={handleSortEndCoAuthor}
            />
          </FormField>
        </Flex>
        <Flex>
          <FormField
            id="termId"
            isInvalid={errors.termId}
            error={errors.termId}
            label="Term"
            mr={4}
          >
            <ReactSelect
              id="termId"
              name="termId"
              placeholder="Select Term..."
              control={control}
              options={term?.data || []}
            />
          </FormField>
          <FormField
            id="committee"
            isInvalid={errors.committee}
            error={errors.committee}
            label="Committee"
          >
            <ReactSelect
              id="committee"
              name="committee"
              placeholder="Select Committee..."
              control={control}
              isMulti
              options={committee || []}
            />
          </FormField>
        </Flex>
        <Flex>
          <FormField
            id="typeId"
            isInvalid={errors.typeId}
            error={errors.typeId}
            label="Document Type"
            mr={4}
          >
            <ReactSelect
              id="typeId"
              name="typeId"
              placeholder="Select Document Type..."
              control={control}
              options={documentTypeOptions || []}
            />
          </FormField>
          <FormField
            id="effectiveDate"
            isInvalid={errors.effectiveDate}
            error={errors.effectiveDate}
            label="Date Submitted"
          >
            <DatePicker
              id="effectiveDate"
              name="effectiveDate"
              showYearDropdown
              control={control}
              placeholderText="Date Submitted"
              yearDropdownItemNumber={100}
              showMonthDropdown
            />
          </FormField>
        </Flex>
        <Flex>
          <FormField
            id="shortTitle"
            isInvalid={errors.shortTitle}
            error={errors.shortTitle}
            label="Short Title"
          >
            <Input
              id="shortTitle"
              placeholder="Short Title"
              {...register('shortTitle')}
            />
          </FormField>
        </Flex>
        <FormField
          id="draftTitle"
          isInvalid={errors.draftTitle}
          error={errors.draftTitle}
          label="Draft Title"
        >
          <TextArea
            id="draftTitle"
            placeholder="Draft Title"
            {...register('draftTitle')}
          />
        </FormField>
        <FormField
          id="file"
          isInvalid={errors.file}
          error={errors.file}
          label="Attachments  (PDF only)"
        >
          <FileUploader
            placeholder="Select File"
            id="file"
            multiple
            onReset={() => resetField('file')}
            {...register('file')}
          />
        </FormField>
        <FormField
          id="documentFile"
          isInvalid={errors.documentFile}
          error={errors.documentFile}
          label="Draft Document File (PDF only)"
        >
          <FileUploader
            placeholder="Select File"
            id="documentFile"
            multiple
            onReset={() => resetField('documentFile')}
            {...register('documentFile')}
          />
        </FormField>
      </Stack>
    </FromComponent>
  )
}

export default Form

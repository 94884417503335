import React from 'react'
import { always, cond, equals, T } from 'ramda'

import Tag from 'components/Tag'

import {
  STATUS_PENDING,
  STATUS_APPROVED,
  STATUS_REJECTED
} from './constants'

const getTagColor = cond([
  [equals(STATUS_PENDING), always('orange')],
  [equals(STATUS_APPROVED), always('blue')],
  [equals(STATUS_REJECTED), always('red')],
  [T, always('teal')]
])

const Status = ({ value, cell }) => {
  if (!value) return null

  return (
    <Tag
      size="md"
      key="md"
      colorScheme={getTagColor(value)}
      borderRadius="full"
      {...cell.getCellProps()}
    >
      {value}
    </Tag>
  )
}

export default React.memo(Status)

import { DateRenderer } from 'components/CellRenderers'
import TableActions from './TableActions'

const columns = [
  {
    Header: 'Session No.',
    accessor: 'sessionNo'
  },
  {
    Header: 'Term',
    accessor: (val) => {
      return val.term && val.term.description
    }
  },
  {
    Header: 'Date',
    accessor: 'date',
    Cell: DateRenderer
  },
  {
    Header: 'Action',
    accessor: 'id',
    fixed: 'right',
    Cell: TableActions
  }
]

const data = [
  {
    id: 1,
    sessionNo: 'XYT123',
    description: 'Loremn Ipsum'
  },
  {
    id: 2,
    sessionNo: 'XYT123',
    description: 'Loremn Ipsum'
  },
  {
    id: 3,
    sessionNo: 'XYT123',
    description: 'Loremn Ipsum'
  }
]

export { columns, data }

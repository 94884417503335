import React, { useContext } from 'react'

const OutgoingStatusContext = React.createContext(null)

export const OutgoingStatusProvider = ({ children, value }) => (
  <OutgoingStatusContext.Provider value={value}>
    {children}
  </OutgoingStatusContext.Provider>
)

export const useOutgoingStatusContext = () => {
  return useContext(OutgoingStatusContext)
}

export default OutgoingStatusContext

import React from 'react'
import { useDisclosure } from '@chakra-ui/react'

import { Box } from 'components/Layout'
import Modal from 'components/Modal'
import Table from 'components/Table'
import PageWrapper from 'layout/PageWrapper'
import { generateFormData } from 'utils/helpers'
import useRoutePagination from 'utils/hooks/useRoutePagination'
import useToast from 'utils/hooks/useToast'
import TableHeader from 'components/Table/TableHeader'
import useSearch from 'utils/hooks/useSearch'
import Create from './Forms/Create'

import { columns } from './columns'
import {
  useFinalDocument,
  useMutateFinalDocument,
  useDraftDocument,
  useDocumentType
} from './api'
import { FinalDocumentProvider } from './Context'

const FinalDocument = () => {
  const toast = useToast()
  const [filter, setFilter] = React.useState('')
  const { page } = useRoutePagination()
  const [search, setSearch] = useSearch()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [contentState, setContentState] = React.useState()
  const {
    finalDocument = {},
    finalDocumentIsLoading,
    finalDocumentRevalidate
  } = useFinalDocument({ page, search, filter })

  const { draftDocument } = useDraftDocument()
  const { documentType } = useDocumentType()

  const { data, totalPages } = finalDocument

  const { createFinalDocument } = useMutateFinalDocument()

  const handleCreateFinalDocument = async (formValues) => {
    const { file = [], attachments = [], ...rest } = formValues

    const payload = {
      file: file && file.length > 0 ? file[0] : null,
      attachments:
        attachments && attachments.length > 0
          ? attachments[0]
          : null,
      ...rest
    }
    await createFinalDocument(generateFormData(payload))

    toast({
      title: 'Draft document created.',
      status: 'success',
      description: "We've created your draft document."
    })
    finalDocumentRevalidate()
    onClose()
    setContentState(undefined)
  }

  return (
    <FinalDocumentProvider
      value={{ revalidate: finalDocumentRevalidate }}
    >
      <Box>
        <PageWrapper onCreate={onOpen}>
          <TableHeader
            totalPages={totalPages}
            onSearch={setSearch}
            setFilter={setFilter}
            filterData={documentType?.data}
          />
          <Table
            isLoading={finalDocumentIsLoading}
            data={data}
            columns={columns}
          />
        </PageWrapper>

        <Modal
          title="Create Document"
          isOpen={isOpen}
          size="5xl"
          onClose={onClose}
          withActionButtons={false}
          closeOnOverlayClick={false}
        >
          <Create
            contentState={contentState}
            setContentState={setContentState}
            onClose={onClose}
            onSubmit={handleCreateFinalDocument}
            draftDocument={draftDocument}
            documentType={documentType}
          />
        </Modal>
      </Box>
    </FinalDocumentProvider>
  )
}

export default React.memo(FinalDocument)

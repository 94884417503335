import useSWR from 'swr'
import {
  deleteRequest,
  postRequest,
  putRequest
} from 'utils/request'
import errorHandler from 'utils/errorHandler'
import { useCallback, useState } from 'react'
import { buildUrl } from 'utils/transformers/apiUrl'
import { OFFICE_ROUTE } from './constants'

const useOffice = ({ page, id, search } = {}) => {
  const url = buildUrl({
    baseUrl: OFFICE_ROUTE,
    search,
    page,
    id
  })

  const {
    data: office,
    error: officeError,
    isValidating: officeIsValidating,
    mutate: officeRevalidate
  } = useSWR(url)

  const officeIsLoading =
    !office && !officeError && officeIsValidating

  return {
    office,
    officeError,
    officeIsValidating,
    officeIsLoading,
    officeRevalidate
  }
}

const useMutateOffice = () => {
  const [response, setResponse] = useState()
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const resetState = useCallback(() => {
    if (response) setResponse(undefined)
    if (isLoading) setError(undefined)
  }, [isLoading, response])

  const createOffice = useCallback(
    async (payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await postRequest(OFFICE_ROUTE, payload)
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  const updateOffice = useCallback(
    async (id, payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await putRequest(
          `${OFFICE_ROUTE}/${id}`,
          payload
        )
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  const deleteOffice = useCallback(
    async (id) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await deleteRequest(`${OFFICE_ROUTE}/${id}`)
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  return {
    data: response,
    error,
    isLoading,
    createOffice,
    updateOffice,
    deleteOffice
  }
}

export { useOffice, useMutateOffice }

import React, { useEffect, useRef, useState } from 'react'
import {
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  chakra
} from '@chakra-ui/react'
import { FiArrowDown, FiArrowUp } from 'react-icons/fi'
import { useTable, useSortBy } from 'react-table'

import { Flex } from 'components/Layout'

import { appendObjectIf } from 'utils/helpers'
import { TableSt, TableWrapperSt } from './styles'
import LoadingOverlay from './LoadingOverlay'
import EmptyOverlay from './EmptyOverlay'

const TableSortIcons = ({ isSorted, isSortedDesc }) => {
  if (!isSorted) return null

  return isSortedDesc ? (
    <FiArrowDown aria-label="sorted descending" />
  ) : (
    <FiArrowUp aria-label="sorted ascending" />
  )
}

const Table = ({ data, columns, isLoading, ...rest }) => {
  const wrapperRef = useRef(null)
  const classes = ['Table__wrapper']
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data }, useSortBy)
  const isEmpty = data?.length === 0 && !isLoading
  const [isScrollVisible, setIsScrollVisible] = useState()

  if (isLoading) classes.push('isLoading')

  if (isEmpty) classes.push('isEmpty')

  useEffect(() => {
    if (!wrapperRef?.current) return undefined

    function handleResize() {
      const { scrollWidth, clientWidth } = wrapperRef.current

      if (scrollWidth > clientWidth) {
        if (isScrollVisible) return
        setIsScrollVisible(true)
      }

      if (scrollWidth === clientWidth) {
        if (!isScrollVisible) return
        setIsScrollVisible(false)
      }
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return (
    <TableWrapperSt
      p={0}
      className={classes.join(' ')}
      ref={wrapperRef}
    >
      <TableSt {...getTableProps()} {...rest} variant="striped">
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                const fixedDir = column?.fixed
                const fixedClasses = [`Fixed--${fixedDir}`]
                if (isScrollVisible) fixedClasses.push('float')

                return (
                  <Th
                    {...column.getHeaderProps(
                      column.getSortByToggleProps()
                    )}
                    {...appendObjectIf(fixedDir, {
                      className: fixedClasses.join(' ')
                    })}
                    isNumeric={column.isNumeric}
                  >
                    <Flex alignItems="center">
                      {column.render('Header')}
                      <chakra.span pl="4">
                        <TableSortIcons
                          isSorted={column.isSorted}
                          isSortedDesc={column.isSortedDesc}
                        />
                      </chakra.span>
                    </Flex>
                  </Th>
                )
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows?.map((row) => {
            prepareRow(row)

            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  const fixedDir = cell.column?.fixed
                  const fixedClasses = [`Fixed--${fixedDir}`]
                  if (isScrollVisible) fixedClasses.push('float')

                  return (
                    <Td
                      {...cell.getCellProps()}
                      isNumeric={cell.column.isNumeric}
                      {...appendObjectIf(fixedDir, {
                        className: fixedClasses.join(' ')
                      })}
                    >
                      {cell.render('Cell')}
                    </Td>
                  )
                })}
              </Tr>
            )
          })}
        </Tbody>
      </TableSt>
      <LoadingOverlay isLoading={isLoading} />
      <EmptyOverlay isEmpty={isEmpty} />
    </TableWrapperSt>
  )
}

Table.defaultProps = {
  data: [],
  columns: []
}

export default Table

import React, { useEffect } from 'react'
import { useDisclosure } from '@chakra-ui/react'

import { generateFormData } from 'utils/helpers'

import Modal from 'components/Modal'
import { CommitteeDocument } from 'components/CellRenderers'
import AlertDialog from 'components/AlertDialog'
import useRoleAccessContext from 'containers/RoleAccess/useRoleAccessContext'
import Edit from './Forms/Edit'
import Submit from './Forms/Submit'
import {
  useUsers,
  useStatus,
  useDraft,
  useMutateCommitteeReports,
  useCommitteeList,
  useMutateSession,
  useMutateUpdateCommitteeReports
} from './api'
import { useCommitteeReportsContext } from './Context'

const TableActions = ({ row, value: id }) => {
  const { values, original } = row
  const { content } = original
  const {
    updateCommitteeReports,
    deleteCommitteeReports,
    isLoading
  } = useMutateCommitteeReports()
  const { updateCommitteeReportsFile } =
    useMutateUpdateCommitteeReports()
  const { revalidate } = useCommitteeReportsContext()
  const { access = [] } = useRoleAccessContext()

  const { users } = useUsers()
  const { status } = useStatus()
  const { draft } = useDraft()
  const { committeeList } = useCommitteeList()

  const [contentState, setContentState] = React.useState()

  const {
    isOpen: editIsOpen,
    onOpen: editOnOpen,
    onClose: editOnClose
  } = useDisclosure()
  const {
    isOpen: deleteIsOpen,
    onOpen: deleteOnOpen,
    onClose: deleteOnClose
  } = useDisclosure()

  const {
    isOpen: submitIsOpen,
    onOpen: submitOnOpen,
    onClose: submitOnClose
  } = useDisclosure()

  const { createSession } = useMutateSession()

  const handleEdit = async (formValues) => {
    const { file = [], documentFile = [], ...rest } = formValues

    const payload = {
      file: file && file.length > 0 ? file[0] : null,
      documentFile:
        documentFile && documentFile.length > 0
          ? documentFile[0]
          : null,
      ...rest
    }
    await updateCommitteeReports(id, generateFormData(payload))

    revalidate()
    editOnClose()
  }

  const handleRemoveFile = async (value) => {
    const payload = {
      field: value
    }
    await updateCommitteeReportsFile(id, payload)
    revalidate()
    editOnClose()
  }

  const handleDelete = async () => {
    await deleteCommitteeReports(id)

    revalidate()
    deleteOnClose()
  }

  useEffect(() => {
    if (!content) return
    const initContentState = async () => {
      if (content && content.length > 0) {
        setContentState(await JSON.parse(content))
      }
    }

    initContentState()
  }, [content])

  const handleSubmit = async (val) => {
    await createSession(val)
    revalidate()
    submitOnClose()
  }

  return (
    <>
      <CommitteeDocument
        onEditClick={editOnOpen}
        onDeleteClick={deleteOnOpen}
        onSubmitClick={submitOnOpen}
        values={values}
        view={
          access['committee-reports'] &&
          access['committee-reports'].includes('view')
        }
        edit={
          access['committee-reports'] &&
          access['committee-reports'].includes('edit')
        }
        delete={
          access['committee-reports'] &&
          access['committee-reports'].includes('delete')
        }
      />
      <Modal
        title="Submit to Sanggunian"
        isOpen={submitIsOpen}
        onClose={submitOnClose}
        withActionButtons={false}
        closeOnOverlayClick={false}
      >
        <Submit
          id={id}
          onClose={submitOnClose}
          onSubmit={handleSubmit}
          values={values}
        />
      </Modal>

      <Modal
        title="Edit Committee Reports"
        isOpen={editIsOpen}
        onClose={editOnClose}
        withActionButtons={false}
        closeOnOverlayClick={false}
        size="xxxl"
      >
        <Edit
          onClose={editOnClose}
          onSubmit={handleEdit}
          onRemove={handleRemoveFile}
          values={original}
          users={users}
          status={status}
          draft={draft}
          committeeList={committeeList}
          contentState={contentState}
          setContentState={setContentState}
        />
      </Modal>

      <AlertDialog
        isLoading={isLoading}
        isOpen={deleteIsOpen}
        onClose={deleteOnClose}
        onConfirm={handleDelete}
        title="Delete Committee Reports"
      />
    </>
  )
}

export default TableActions

import React from 'react'
import { useForm } from 'react-hook-form'

import Button from 'components/Button'
import Form from 'components/Form'
import FormField from 'components/FormField'
import Input from 'components/Input'
import { Flex, Stack } from 'components/Layout'

import { yupResolver } from '@hookform/resolvers/yup'
import { model } from './schema'

const Create = ({ onClose, onSubmit }) => {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty }
  } = useForm({
    resolver: yupResolver(model)
  })

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      disabled={isSubmitting}
    >
      <Stack spacing={6} pb={2}>
        <FormField
          id="code"
          isInvalid={errors.code}
          error={errors.code}
          label="Code"
        >
          <Input
            id="code"
            placeholder="Code"
            {...register('code')}
          />
        </FormField>
        <FormField
          id="description"
          isInvalid={errors.description}
          error={errors.description}
          label="Description"
        >
          <Input
            id="description"
            placeholder="Description"
            {...register('description')}
          />
        </FormField>
        <Flex mt={5} justify="flex-end">
          <Button mr={2} variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            isLoading={isSubmitting}
            type="submit"
            disabled={!isDirty || isSubmitting}
          >
            Submit
          </Button>
        </Flex>
      </Stack>
    </Form>
  )
}

export default Create

const TRANSCRIPT_ROUTE = '/transcript'
const COMMITTEE_ROUTE = '/committee/list'
const SESSION_ROUTE = '/session/list'

const CATEGORY_ROUTES = [
  {
    label: 'Minutes of Session',
    value: 'Minutes of Session'
  },
  {
    label: 'Minutes of Public Hearing',
    value: 'Minutes of Public Hearing'
  },
  {
    label: 'Minutes of Committee  Meeting/Hearing',
    value: 'Minutes of Committee  Meeting/Hearing'
  }
]

const SESSION_TYPE_ROUTES = [
  {
    label: 'Regular Session',
    value: 'Regular Session'
  },
  {
    label: 'Special Session',
    value: 'Special Session'
  }
]

export {
  TRANSCRIPT_ROUTE,
  COMMITTEE_ROUTE,
  CATEGORY_ROUTES,
  SESSION_TYPE_ROUTES,
  SESSION_ROUTE
}

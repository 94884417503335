import * as yup from 'yup'

const messages = {
  requiredGeneric: 'This field is required',
  codeMin: 'Code should be 4 characters and above'
}

const model = yup.object({
  documentId: yup.string().required(messages.requiredGeneric),
  dateReleased: yup.string().required(messages.requiredGeneric),
  office: yup.array().nullable(),
  dateReceived: yup.string().required(messages.requiredGeneric),
  remarks: yup.string()
})

const initialValues = {
  documentId: '',
  dateReleased: '',
  office: '',
  dateReceived: '',
  remarks: ''
}

export { model, initialValues }

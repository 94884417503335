import React from 'react'
import { useDisclosure } from '@chakra-ui/react'

import Modal from 'components/Modal'
import { CommitteeReportActions } from 'components/CellRenderers'

import Edit from './Forms/Edit'
import Submit from './Forms/Submit'
import { useMutateCommitteeReferrals } from './api'
import { useMutateSession } from '../DraftDocument/api'
import { useCommitteeReferralsContext } from './Context'

const TableActions = ({ row, value: id }) => {
  const { values, original } = row
  const { updateCommitteeReferrals } =
    useMutateCommitteeReferrals()
  const { revalidate, usersOptions } =
    useCommitteeReferralsContext()

  const { createSession } = useMutateSession()

  const {
    isOpen: editIsOpen,
    onOpen: editOnOpen,
    onClose: editOnClose
  } = useDisclosure()

  const {
    isOpen: submitIsOpen,
    onOpen: submitOnOpen,
    onClose: submitOnClose
  } = useDisclosure()

  const handleEdit = async (newValue) => {
    const payload = {
      recommendation: newValue.recommendation,
      ammendments: newValue.ammendments,
      findings: newValue.findings
    }
    await updateCommitteeReferrals(id, payload)

    revalidate()
    editOnClose()
  }

  const handleSubmit = async (val) => {
    await createSession(val)
    submitOnClose()
  }

  return (
    <>
      <CommitteeReportActions
        onEditClick={editOnOpen}
        values={values}
        onSubmitClick={submitOnOpen}
      />
      <Modal
        title="Edit Committee Report"
        isOpen={editIsOpen}
        onClose={editOnClose}
        withActionButtons={false}
        closeOnOverlayClick={false}
        size="2xl"
      >
        <Edit
          onClose={editOnClose}
          onSubmit={handleEdit}
          values={original}
          usersOptions={usersOptions}
        />
      </Modal>

      <Modal
        title="Submit to Sanggunian"
        isOpen={submitIsOpen}
        onClose={submitOnClose}
        withActionButtons={false}
        closeOnOverlayClick={false}
      >
        <Submit
          id={original?.draftId}
          onClose={submitOnClose}
          onSubmit={handleSubmit}
        />
      </Modal>
    </>
  )
}

export default TableActions

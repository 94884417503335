import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import Button from 'components/Button'
import Form from 'components/Form'
import FormField from 'components/FormField'
import Input from 'components/Input'
import ReactSelect from 'components/ReactSelect'
import { Flex, Stack } from 'components/Layout'
import Switch from 'components/Switch'
import { model } from './schema'

const Edit = ({
  onClose,
  onSubmit,
  values,
  users,
  office,
  designations
}) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting, isDirty }
  } = useForm({
    resolver: yupResolver(model),
    defaultValues: {
      userId: values.userId,
      code: values.code,
      designationId: values.designationId,
      officeId: values.officeId,
      status: values.status,
      secretary: values.secretary,
      viceMayor: values.viceMayor,
      councilors: values.councilors
    }
  })

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      disabled={isSubmitting}
      // values={defaultValues}
    >
      <Stack spacing={6} pb={2}>
        <Flex>
          <FormField
            w="300px"
            id="code"
            mr={4}
            isInvalid={errors.code}
            error={errors.code}
            label="Code"
          >
            <Input
              id="code"
              placeholder="Code"
              {...register('code', model.code)}
            />
          </FormField>

          <FormField
            id="userId"
            isInvalid={errors.userId}
            error={errors.userId}
            label="User"
          >
            <ReactSelect
              id="userId"
              name="userId"
              placeholder="Select User..."
              control={control}
              options={users}
            />
          </FormField>
        </Flex>

        <FormField
          id="designationId"
          isInvalid={errors.designationId}
          error={errors.designationId}
          label="Designation"
        >
          <ReactSelect
            id="designationId"
            name="designationId"
            placeholder="Select Designation..."
            control={control}
            options={designations}
          />
        </FormField>

        <FormField
          id="officeId"
          isInvalid={errors.officeId}
          error={errors.officeId}
          label="Office"
        >
          <ReactSelect
            id="officeId"
            name="officeId"
            placeholder="Select Office..."
            control={control}
            options={office}
          />
        </FormField>
        <FormField
          id="viceMayor"
          isInvalid={errors.viceMayor}
          error={errors.viceMayor}
          direction="horizontal"
          label="Current City Vice Mayor"
        >
          <Switch
            id="viceMayor"
            name="viceMayor"
            {...register('viceMayor')}
          />
        </FormField>
        <FormField
          id="councilors"
          isInvalid={errors.councilors}
          error={errors.councilors}
          direction="horizontal"
          label="Current City Councilors"
        >
          <Switch
            id="councilors"
            name="councilors"
            {...register('councilors')}
          />
        </FormField>
        <FormField
          id="secretary"
          isInvalid={errors.secretary}
          error={errors.secretary}
          direction="horizontal"
          label="Current SP Secretary"
        >
          <Switch
            id="secretary"
            name="secretary"
            {...register('secretary')}
          />
        </FormField>
        <FormField
          id="status"
          isInvalid={errors.status}
          error={errors.status}
          direction="horizontal"
          label="Active"
        >
          <Switch
            id="status"
            name="status"
            {...register('status')}
          />
        </FormField>

        <Flex mt={5} justify="flex-end">
          <Button
            mr={2}
            variant="outline"
            isLoading={isSubmitting}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            isLoading={isSubmitting}
            disabled={isSubmitting || !isDirty}
            type="submit"
          >
            Submit
          </Button>
        </Flex>
      </Stack>
    </Form>
  )
}

export default Edit

import TableActions from './TableActions'

const columns = [
  {
    Header: 'Code',
    accessor: 'code'
  },
  {
    Header: 'User',
    accessor: (val) => {
      return `${val.user?.firstName} ${val.user?.lastName}`
    }
  },
  {
    Header: 'Designation',
    accessor: 'designations.description'
  },
  {
    Header: 'Office',
    accessor: 'office.description'
  },
  {
    Header: 'Action',
    accessor: (val) => {
      return val
    },
    fixed: 'right',
    Cell: TableActions
  }
]

export { columns }

const USERS_ROUTE = '/api/users'
const PROPOSAL_ROUTE = '/proposal'
const SIGNATORIES_ROUTE = '/signatories'
const COMMITTEE_ROUTE = '/committee/list'
const COMMITTEE_REPORTS_ROUTE = '/committee-reports'

const READING_STEPS_OPTION = [
  {
    label: 'COMMUNICATION RECEIVED',
    value: 'COMMUNICATION_RECEIVED'
  },
  {
    label: 'FIRST READING',
    value: 'FIRST_READING'
  },
  {
    label: 'COMMITTEE REPORTS',
    value: 'COMMITTEE_REPORTS'
  },
  {
    label: 'UNFINISHED BUSINESS',
    value: 'UNFINISHED_BUSINESS'
  },
  {
    label: 'BUSINESS OF THE DAY',
    value: 'BUSINESS_OF_THE_DAY'
  },
  {
    label: 'UNASSIGNED BUSINESS',
    value: 'UNASSIGNED_BUSINESS'
  },
  {
    label: 'OTHER MATTERS',
    value: 'OTHER_MATTERS'
  },
  {
    label: 'MEASURES FOR THIRD READING',
    value: 'MEASURES_FOR_THIRD_READING'
  },
  {
    label: 'ANNOUNCEMENT',
    value: 'ANNOUNCEMENT'
  },
  {
    label: 'ADJOURNMENT',
    value: 'ADJOURNMENT'
  }
]

export {
  USERS_ROUTE,
  READING_STEPS_OPTION,
  PROPOSAL_ROUTE,
  COMMITTEE_ROUTE,
  COMMITTEE_REPORTS_ROUTE,
  SIGNATORIES_ROUTE
}

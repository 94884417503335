import useSWR from 'swr'
import {
  deleteRequest,
  postRequest,
  putRequest
} from 'utils/request'
import errorHandler from 'utils/errorHandler'
import { useCallback, useState } from 'react'
import { buildUrl } from 'utils/transformers/apiUrl'
import {
  TRANSCRIPT_ROUTE,
  COMMITTEE_ROUTE,
  SESSION_ROUTE
} from './constants'

const useTranscript = ({ page, id, search } = {}) => {
  const url = buildUrl({
    baseUrl: TRANSCRIPT_ROUTE,
    search,
    page,
    id
  })

  const {
    data: transcript,
    error: transcriptError,
    isValidating: transcriptIsValidating,
    mutate: transcriptRevalidate
  } = useSWR(url)

  const transcriptIsLoading =
    !transcript && !transcriptError && transcriptIsValidating

  return {
    transcript,
    transcriptError,
    transcriptIsValidating,
    transcriptIsLoading,
    transcriptRevalidate
  }
}

const useCommittee = () => {
  const url = buildUrl({
    baseUrl: COMMITTEE_ROUTE
  })

  const {
    data: committee,
    error: committeeError,
    isValidating: committeeIsValidating,
    mutate: committeeRevalidate
  } = useSWR(url)

  const committeeIsLoading =
    !committee && !committeeError && committeeIsValidating

  return {
    committee,
    committeeError,
    committeeIsValidating,
    committeeIsLoading,
    committeeRevalidate
  }
}

const useSession = () => {
  const url = buildUrl({
    baseUrl: SESSION_ROUTE
  })

  const {
    data: session,
    error: sessionError,
    isValidating: sessionIsValidating,
    mutate: sessionRevalidate
  } = useSWR(url)

  const sessionIsLoading =
    !session && !sessionError && sessionIsValidating

  return {
    session,
    sessionError,
    sessionIsValidating,
    sessionIsLoading,
    sessionRevalidate
  }
}

const useTranscriptSingle = (id) => {
  const url = buildUrl({
    baseUrl: `${TRANSCRIPT_ROUTE}/${id}`
  })

  const {
    data: transcriptSingle,
    error: transcriptSingleError,
    isValidating: transcriptSingleIsValidating,
    mutate: transcriptSingleRevalidate
  } = useSWR(url)

  const transcriptSingleIsLoading =
    !transcriptSingle &&
    !transcriptSingleError &&
    transcriptSingleIsValidating

  return {
    transcriptSingle,
    transcriptSingleError,
    transcriptSingleIsValidating,
    transcriptSingleIsLoading,
    transcriptSingleRevalidate
  }
}

const useMutateTranscript = () => {
  const [response, setResponse] = useState()
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const resetState = useCallback(() => {
    if (response) setResponse(undefined)
    if (isLoading) setError(undefined)
  }, [isLoading, response])

  const createTranscript = useCallback(
    async (payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await postRequest(TRANSCRIPT_ROUTE, payload)
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  const updateTranscript = useCallback(
    async (id, payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await putRequest(
          `${TRANSCRIPT_ROUTE}/${id}`,
          payload
        )
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  const deleteTranscript = useCallback(
    async (id) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await deleteRequest(
          `${TRANSCRIPT_ROUTE}/${id}`
        )
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  return {
    data: response,
    error,
    isLoading,
    createTranscript,
    updateTranscript,
    deleteTranscript
  }
}

export {
  useTranscript,
  useMutateTranscript,
  useCommittee,
  useSession,
  useTranscriptSingle
}

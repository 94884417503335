import React, { useContext } from 'react'

const DesignationContext = React.createContext(null)

export const DesignationProvider = ({ children, value }) => (
  <DesignationContext.Provider value={value}>
    {children}
  </DesignationContext.Provider>
)

export const useDesignationContext = () => {
  return useContext(DesignationContext)
}

export default DesignationContext

import React from 'react'

import Text from 'components/Text'
import { switchFn } from 'utils/helpers'
import {
  CAPITALIZE_CASE,
  LOWER_CASE,
  UPPER_CASE
} from './constants'

const formatString = (str, textCase) => {
  if (!str || typeof str !== 'string') return undefined
  const [firstChar, ...rest] = str

  const newString = switchFn({
    [UPPER_CASE]: str.toUpperCase(),
    [CAPITALIZE_CASE]:
      firstChar.toUpperCase() + rest.join('').toLowerCase(),
    [LOWER_CASE]: str.toLowerCase()
  })

  return newString(textCase)
}

const CaseFormatter = ({ value, textCase }) => {
  return <Text>{formatString(value, textCase)}</Text>
}

export default React.memo(CaseFormatter)

import styled from '@emotion/styled'
import { VStack } from 'components/Layout'
import { CHAT_BOX_HEIGHT } from 'containers/Conversations/constants'
import customScrollbar from 'theme/styles/customScrollbar'

const MessagesSt = styled(VStack)`
  overflow: overlay;
  height: ${`calc(100% - ${CHAT_BOX_HEIGHT})`};

  ${customScrollbar};
`

const MessagesItemSt = styled.div`
  display: inline-flex;
  color: white;
  font-weight: 400;
  padding: 8px 12px;
  border-radius: 10px;
  background-color: var(--chakra-colors-gray-600);
  align-self: flex-start;

  &.own {
    background-color: var(--chakra-colors-teal-500);
    align-self: flex-end;
  }
`

export { MessagesSt, MessagesItemSt }

import styled from '@emotion/styled'
import customScrollbar from 'theme/styles/customScrollbar'

const ChatThreadSt = styled.ul`
  width: 100%;
  overflow-y: overlay;
  ${customScrollbar}

  li a {
    display: inline-block;
    width: 100%;
    padding: 20px 30px;
    border-bottom: 1px solid var(--chakra-colors-brand-200);

    &.active {
      background-color: #f0f1f2;
    }
  }
`

const ChatThreadItemSt = styled.li`
  font-weight: 400;

  &.Status {
    &--read {
      .Message__details {
        &--primary {
          * {
            color: var(--chakra-colors-gray-400);
          }
        }
      }
    }

    &--unread {
      .Message__details {
        &--primary {
          h4 {
            font-weight: 600;
          }

          p {
            color: var(--chakra-colors-teal-300);
          }
        }
      }
    }
  }
`

export { ChatThreadSt, ChatThreadItemSt }

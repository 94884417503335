import useSWR from 'swr'
import {
  deleteRequest,
  postRequest,
  putRequest
} from 'utils/request'
import { useCallback, useState } from 'react'
import errorHandler from 'utils/errorHandler'
import { buildUrl } from 'utils/transformers/apiUrl'
import {
  USERS_ROUTE,
  DESIGNATION_ROUTE,
  OFFICE_ROUTE,
  CREATE_PERSONNEL_ROUTE,
  PERSONNEL_ROUTE
} from './constants'

const usePersonnel = ({ page, search }) => {
  const url = buildUrl({
    baseUrl: PERSONNEL_ROUTE,
    search,
    page
  })
  const {
    data: personnel,
    error: personnelError,
    isValidating: personnelIsValidating,
    mutate: personnelRevalidate
  } = useSWR(url)

  const personnelIsLoading =
    !personnel && !personnelError && personnelIsValidating

  return {
    personnel,
    personnelError,
    personnelIsValidating,
    personnelIsLoading,
    personnelRevalidate
  }
}

const useUsers = () => {
  const {
    data: users,
    error: usersError,
    isValidating: usersIsValidating,
    mutate: usersRevalidate
  } = useSWR(USERS_ROUTE)

  const usersIsLoading = !users && !usersError

  return {
    users,
    usersError,
    usersIsValidating,
    usersIsLoading,
    usersRevalidate
  }
}

const useOffice = () => {
  const {
    data: office,
    error: officeError,
    isValidating: officeIsValidating,
    mutate: officeRevalidate
  } = useSWR(OFFICE_ROUTE)

  const officeIsLoading = !office && !officeError

  return {
    office,
    officeError,
    officeIsValidating,
    officeIsLoading,
    officeRevalidate
  }
}

const useDesignations = () => {
  const {
    data: designations,
    error: designationsError,
    isValidating: designationsIsValidating,
    mutate: designationsRevalidate
  } = useSWR(DESIGNATION_ROUTE)

  const designationsIsLoading =
    !designations && !designationsError

  return {
    designations,
    designationsError,
    designationsIsValidating,
    designationsIsLoading,
    designationsRevalidate
  }
}

const useMutatePersonnel = () => {
  const [response, setResponse] = useState()
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const resetState = useCallback(() => {
    if (response) setResponse(undefined)
    if (isLoading) setError(undefined)
  }, [isLoading, response])

  const createPersonnel = useCallback(
    async (payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await postRequest(
          CREATE_PERSONNEL_ROUTE,
          payload
        )
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  const updatePersonnel = useCallback(
    async (id, payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await putRequest(
          `${CREATE_PERSONNEL_ROUTE}/${id}`,
          payload
        )
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  const deletePersonnel = useCallback(
    async (id) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await deleteRequest(
          `${CREATE_PERSONNEL_ROUTE}/${id}`
        )
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  return {
    data: response,
    error,
    isLoading,
    createPersonnel,
    updatePersonnel,
    deletePersonnel
  }
}

export {
  useUsers,
  useOffice,
  useDesignations,
  useMutatePersonnel,
  usePersonnel
}

import useSWR from 'swr'
import {
  deleteRequest,
  postRequest,
  putRequest
} from 'utils/request'
import errorHandler from 'utils/errorHandler'
import { useCallback, useState } from 'react'
import { buildUrl } from 'utils/transformers/apiUrl'
import {
  COMMITTEE_HEARING_MINUTES_ROUTE,
  COMMITTEE_ROUTE,
  SESSION_ROUTE
} from './constants'

const useCommitteeHearingMinutes = ({
  page,
  id,
  year,
  search,
  committeeData
} = {}) => {
  const url = buildUrl({
    baseUrl: COMMITTEE_HEARING_MINUTES_ROUTE,
    page,
    id,
    year,
    search,
    committeeData
  })

  const {
    data: committeeHearingMinutes,
    error: committeeHearingMinutesError,
    isValidating: committeeHearingMinutesIsValidating,
    mutate: committeeHearingMinutesRevalidate
  } = useSWR(url)

  const committeeHearingMinutesIsLoading =
    !committeeHearingMinutes &&
    !committeeHearingMinutesError &&
    committeeHearingMinutesIsValidating

  return {
    committeeHearingMinutes,
    committeeHearingMinutesError,
    committeeHearingMinutesIsValidating,
    committeeHearingMinutesIsLoading,
    committeeHearingMinutesRevalidate
  }
}

const useCommittee = () => {
  const url = buildUrl({
    baseUrl: COMMITTEE_ROUTE
  })

  const {
    data: committee,
    error: committeeError,
    isValidating: committeeIsValidating,
    mutate: committeeRevalidate
  } = useSWR(url)

  const committeeIsLoading =
    !committee && !committeeError && committeeIsValidating

  return {
    committee,
    committeeError,
    committeeIsValidating,
    committeeIsLoading,
    committeeRevalidate
  }
}

const useSession = () => {
  const url = buildUrl({
    baseUrl: SESSION_ROUTE
  })

  const {
    data: session,
    error: sessionError,
    isValidating: sessionIsValidating,
    mutate: sessionRevalidate
  } = useSWR(url)

  const sessionIsLoading =
    !session && !sessionError && sessionIsValidating

  return {
    session,
    sessionError,
    sessionIsValidating,
    sessionIsLoading,
    sessionRevalidate
  }
}

const useCommitteeHearingMinutesSingle = (id) => {
  const url = buildUrl({
    baseUrl: `${COMMITTEE_HEARING_MINUTES_ROUTE}/${id}`
  })

  const {
    data: committeeHearingMinutesSingle,
    error: committeeHearingMinutesSingleError,
    isValidating: committeeHearingMinutesSingleIsValidating,
    mutate: committeeHearingMinutesSingleRevalidate
  } = useSWR(url)

  const committeeHearingMinutesSingleIsLoading =
    !committeeHearingMinutesSingle &&
    !committeeHearingMinutesSingleError &&
    committeeHearingMinutesSingleIsValidating

  return {
    committeeHearingMinutesSingle,
    committeeHearingMinutesSingleError,
    committeeHearingMinutesSingleIsValidating,
    committeeHearingMinutesSingleIsLoading,
    committeeHearingMinutesSingleRevalidate
  }
}

const useMutateCommitteeHearingMinutes = () => {
  const [response, setResponse] = useState()
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const resetState = useCallback(() => {
    if (response) setResponse(undefined)
    if (isLoading) setError(undefined)
  }, [isLoading, response])

  const createCommitteeHearingMinutes = useCallback(
    async (payload = {}) => {
      console.log(payload)
      resetState()
      setIsLoading(true)
      try {
        const data = await postRequest(
          `${COMMITTEE_HEARING_MINUTES_ROUTE}?type=minutes`,
          payload
        )
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  const updateCommitteeHearingMinutes = useCallback(
    async (id, payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await putRequest(
          `${COMMITTEE_HEARING_MINUTES_ROUTE}/${id}`,
          payload
        )
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  const deleteCommitteeHearingMinutes = useCallback(
    async (id) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await deleteRequest(
          `${COMMITTEE_HEARING_MINUTES_ROUTE}/${id}`
        )
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  return {
    data: response,
    error,
    isLoading,
    createCommitteeHearingMinutes,
    updateCommitteeHearingMinutes,
    deleteCommitteeHearingMinutes
  }
}

export {
  useCommitteeHearingMinutes,
  useMutateCommitteeHearingMinutes,
  useCommittee,
  useSession,
  useCommitteeHearingMinutesSingle
}

import React, { useContext } from 'react'

const PersonnelCodeContext = React.createContext(null)

export const PersonnelCodeProvider = ({ children, value }) => (
  <PersonnelCodeContext.Provider value={value}>
    {children}
  </PersonnelCodeContext.Provider>
)

export const usePersonnelCodeContext = () => {
  return useContext(PersonnelCodeContext)
}

export default PersonnelCodeContext

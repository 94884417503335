import useAuth from 'containers/Auth/useAuth'
import React from 'react'

const LogoutPage = () => {
  const auth = useAuth()

  React.useEffect(() => {
    auth.logout()
  }, [auth])

  return 'Logging out...'
}

export default LogoutPage

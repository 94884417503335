import useSWR from 'swr'
import {
  deleteRequest,
  postRequest,
  putRequest
} from 'utils/request'
import errorHandler from 'utils/errorHandler'
import { useCallback, useState } from 'react'
import { buildUrl } from 'utils/transformers/apiUrl'
import {
  DESIGNATIONS_ROUTE,
  COMMITTEE_ROUTE,
  MEASURES_PENDING_ROUTE,
  MEASURES_APPROVED_ROUTE,
  DOCUMENT_TYPE_ROUTE
} from './constants'

const usePendingMeasures = (pendingFilter, filter) => {
  const url = buildUrl({
    baseUrl: `${MEASURES_PENDING_ROUTE}&type=${pendingFilter}&filter=${filter}`
  })

  const {
    data: pending,
    error: pendingError,
    isValidating: pendingIsValidating,
    mutate: pendingRevalidate
  } = useSWR(url)

  const pendingIsLoading =
    !pending && !pendingError && pendingIsValidating

  return {
    pending,
    pendingError,
    pendingIsValidating,
    pendingIsLoading,
    pendingRevalidate
  }
}

const useDocumentType = () => {
  const url = buildUrl({
    baseUrl: DOCUMENT_TYPE_ROUTE
  })

  const {
    data: documentType,
    error: documentTypeError,
    isValidating: documentTypeIsValidating,
    mutate: documentTypeRevalidate
  } = useSWR(url)

  const documentTypeIsLoading =
    !documentType &&
    !documentTypeError &&
    documentTypeIsValidating

  return {
    documentType,
    documentTypeError,
    documentTypeIsValidating,
    documentTypeIsLoading,
    documentTypeRevalidate
  }
}

const useApprovedMeasures = (approvedFilter) => {
  const url = buildUrl({
    baseUrl: `${MEASURES_APPROVED_ROUTE}&filter=${approvedFilter}`
  })

  const {
    data: approved,
    error: approvedError,
    isValidating: approvedIsValidating,
    mutate: approvedRevalidate
  } = useSWR(url)

  const approvedIsLoading =
    !approved && !approvedError && approvedIsValidating

  return {
    approved,
    approvedError,
    approvedIsValidating,
    approvedIsLoading,
    approvedRevalidate
  }
}

const useCommittee = () => {
  const url = buildUrl({
    baseUrl: COMMITTEE_ROUTE
  })

  const {
    data: committee,
    error: committeeError,
    isValidating: committeeIsValidating,
    mutate: committeeRevalidate
  } = useSWR(url)

  const committeeIsLoading =
    !committee && !committeeError && committeeIsValidating

  return {
    committee,
    committeeError,
    committeeIsValidating,
    committeeIsLoading,
    committeeRevalidate
  }
}

const useDesignation = ({ page, id } = {}) => {
  const url = buildUrl({
    baseUrl: DESIGNATIONS_ROUTE,
    page,
    id
  })

  const {
    data: designations,
    error: designationsError,
    isValidating: designationsIsValidating,
    mutate: designationsRevalidate
  } = useSWR(url)

  const designationsIsLoading =
    !designations &&
    !designationsError &&
    designationsIsValidating

  return {
    designations,
    designationsError,
    designationsIsValidating,
    designationsIsLoading,
    designationsRevalidate
  }
}

const useMutateDesignation = () => {
  const [response, setResponse] = useState()
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const resetState = useCallback(() => {
    if (response) setResponse(undefined)
    if (isLoading) setError(undefined)
  }, [isLoading, response])

  const createDesignation = useCallback(
    async (payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await postRequest(
          DESIGNATIONS_ROUTE,
          payload
        )
        setResponse(data)
        setIsLoading(false)
        return data
      } catch (err) {
        setError(errorHandler(err))
        setIsLoading(false)
      }
      return null
    },
    [resetState]
  )

  const updateDesignation = useCallback(
    async (id, payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await putRequest(
          `${DESIGNATIONS_ROUTE}/${id}`,
          payload
        )
        setResponse(data)
        setIsLoading(false)
        return data
      } catch (err) {
        setError(errorHandler(err))
        setIsLoading(false)
      }
      return null
    },
    [resetState]
  )

  const deleteDesignation = useCallback(
    async (id) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await deleteRequest(
          `${DESIGNATIONS_ROUTE}/${id}`
        )
        setResponse(data)
        setIsLoading(false)
        return data
      } catch (err) {
        setError(errorHandler(err))
        setIsLoading(false)
      }
      return null
    },
    [resetState]
  )

  return {
    data: response,
    error,
    isLoading,
    createDesignation,
    updateDesignation,
    deleteDesignation
  }
}

export {
  useDesignation,
  useMutateDesignation,
  useCommittee,
  usePendingMeasures,
  useApprovedMeasures,
  useDocumentType
}

import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import Table from 'components/Table'
import TableHeader from 'components/Table/TableHeader'
import useRoutePagination from 'utils/hooks/useRoutePagination'
import Button from 'components/Button'
import PageWrapper from 'layout/PageWrapper'
import useToast from 'utils/hooks/useToast'
import { SessionJoinContextProvider } from './Context'
import { useProposal, useProposalReport } from './api'
import { columns } from './columns'
import { READING_STEPS_OPTION } from './constants'
import generatePdf from './generate-ood'

const SessionJoin = () => {
  const toast = useToast()
  const { page } = useRoutePagination()
  const { id } = useParams()

  const [filter, setFilter] = useState()

  const { proposals } = useProposal(id, filter, page)
  const { report } = useProposalReport(id)

  const handleDownload = () => {
    if (report) {
      generatePdf(report)
    } else {
      toast({
        title: 'Please wait!',
        status: 'warning',
        description: 'Compiling Order of Business.'
      })
    }
  }

  return (
    <SessionJoinContextProvider>
      <PageWrapper
        label="Order of Business"
        headerRightComponent={
          <Button onClick={() => handleDownload(proposals)}>
            Download / Print
          </Button>
        }
      >
        <br />
        <TableHeader
          totalPages={proposals?.totalPages}
          filterData={READING_STEPS_OPTION}
          setFilter={setFilter}
          defaultValue={filter}
        />
        <Table data={proposals?.data || []} columns={columns} />
      </PageWrapper>
    </SessionJoinContextProvider>
  )
}

export default SessionJoin

import React, { useContext } from 'react'

const FinalDocumentTypeContext = React.createContext(null)

export const FinalDocumentTypeProvider = ({
  children,
  value
}) => (
  <FinalDocumentTypeContext.Provider value={value}>
    {children}
  </FinalDocumentTypeContext.Provider>
)

export const useFinalDocumentTypeContext = () => {
  return useContext(FinalDocumentTypeContext)
}

export default FinalDocumentTypeContext

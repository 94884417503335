import useSWR from 'swr'
import { callSWRFetchOnce } from 'utils/request/constant'
import { buildUrl } from 'utils/transformers/apiUrl'

import { ACCESS_ROUTE } from './constants'

const useAccess = ({ cancelCall }) => {
  const url = buildUrl({
    baseUrl: `${ACCESS_ROUTE}`
  })

  const {
    data: access,
    error: accessError,
    isValidating: accessIsValidating,
    mutate: accessRevalidate
  } = useSWR(cancelCall ? null : url, callSWRFetchOnce)

  const accessIsLoading =
    !access && !accessError && accessIsValidating

  return {
    access,
    accessError,
    accessIsValidating,
    accessIsLoading,
    accessRevalidate
  }
}

export { useAccess }

import React, { useContext } from 'react'

const SessionJoinContext = React.createContext(null)

export const SessionJoinContextProvider = ({
  children,
  value
}) => (
  <SessionJoinContext.Provider value={value}>
    {children}
  </SessionJoinContext.Provider>
)

export const useSessionJoinContext = () => {
  const orderBusinessManager = useContext(SessionJoinContext)
  return orderBusinessManager
}

export default SessionJoinContext

const TRACKING_ROUTE = '/tracking'
const DRAFT_DOCUMENT_ROUTE = '/document'
const FINAL_DOCUMENT_TYPE_ROUTE = '/final-document-type/list'
const OFFICE_ROUTE = '/office'
const USERS_ROUTE = '/users'

export {
  TRACKING_ROUTE,
  DRAFT_DOCUMENT_ROUTE,
  OFFICE_ROUTE,
  USERS_ROUTE,
  FINAL_DOCUMENT_TYPE_ROUTE
}

import React, { useMemo, useState } from 'react'
import { FiPlus } from 'react-icons/fi'

import useRoutePagination from 'utils/hooks/useRoutePagination'
import useRoleAccessContext from 'containers/RoleAccess/useRoleAccessContext'
import Table from 'components/Table'
import PageWrapper from 'layout/PageWrapper'
import Button from 'components/Button'
import { Box } from 'components/Layout'

import TableHeader from 'components/Table/TableHeader'
import useSearch from 'utils/hooks/useSearch'
import { getColumns } from './columns'
import { READING_STEPS_OPTION } from './constants'
import { useDraftDocument } from './api'

const DraftDocument = () => {
  const [filter, setFilter] = useState('')
  const { page } = useRoutePagination()
  const [search, setSearch] = useSearch()
  const { access = [] } = useRoleAccessContext()
  const {
    draftDocument = {},
    draftDocumentIsLoading,
    draftDocumentRevalidate
  } = useDraftDocument({ page, search, filter })

  const { data, totalPages } = draftDocument

  const newColumns = useMemo(() => {
    return getColumns({
      revalidate: draftDocumentRevalidate
    })
  }, [draftDocumentRevalidate])

  return (
    <Box>
      <PageWrapper
        headerRightComponent={
          access['draft-document'] &&
          access['draft-document'].includes('create') ? (
            <Button leftIcon={<FiPlus />} link to="create">
              New Draft
            </Button>
          ) : (
            false
          )
        }
      >
        <TableHeader
          totalPages={totalPages}
          onSearch={setSearch}
          filterData={READING_STEPS_OPTION}
          setFilter={setFilter}
        />
        <Table
          isLoading={draftDocumentIsLoading}
          data={data}
          columns={newColumns}
        />
      </PageWrapper>
    </Box>
  )
}

export default DraftDocument

const DRAFT_DOCUMENT_ROUTE = '/draft-document'
const USERS_ROUTES = '/users/list/officials'
const SESSION_ROUTE = '/session'
const PROPOSAL_ROUTE = '/proposal'
const COMMITTEE_ROUTE = '/committee'

const READING_STEPS_OPTION = [
  {
    label: 'COMMUNICATION RECEIVED',
    value: 'COMMUNICATION_RECEIVED'
  },
  {
    label: 'FIRST READING',
    value: 'FIRST_READING'
  },
  {
    label: 'UNFINISHED BUSINESS',
    value: 'UNFINISHED_BUSINESS'
  },
  {
    label: 'BUSINESS OF THE DAY',
    value: 'BUSINESS_OF_THE_DAY'
  },
  {
    label: 'UNASSIGNED BUSINESS',
    value: 'UNASSIGNED_BUSINESS'
  },
  {
    label: 'OTHER MATTERS',
    value: 'OTHER_MATTERS'
  },
  {
    label: 'MEASURES FOR THIRD READING',
    value: 'MEASURES_FOR_THIRD_READING'
  },
  {
    label: 'ANNOUNCEMENT',
    value: 'ANNOUNCEMENT'
  },
  {
    label: 'ADJOURNMENT',
    value: 'ADJOURNMENT'
  }
]

const CERTIFIED_ROUTES = [
  {
    label: 'Certified as urgent by the LCE',
    value: 'Certified as urgent by the LCE'
  },
  {
    label: 'Certified as urgent by majority of the members',
    value: 'Certified as urgent by majority of the members'
  }
]

export {
  DRAFT_DOCUMENT_ROUTE,
  READING_STEPS_OPTION,
  SESSION_ROUTE,
  PROPOSAL_ROUTE,
  USERS_ROUTES,
  COMMITTEE_ROUTE,
  CERTIFIED_ROUTES
}

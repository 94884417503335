import { MenuItem } from '@chakra-ui/react'
import Avatar from 'components/Avatar'
import { Box } from 'components/Layout'
import Text from 'components/Text'
import { isEmpty } from 'ramda'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { PATH } from 'routes'
import { fromNow } from 'utils/dateTime'
import { NotificationWrapperSt } from './styles'

const Notifications = ({ notifications }) => {
  const navigate = useNavigate()

  return (
    <NotificationWrapperSt>
      {isEmpty(notifications?.data) && (
        <MenuItem minH="48px" px={5} py={3}>
          <Box maxW="230px">
            <Text fontSize={14}>No Notifications yet</Text>
          </Box>
        </MenuItem>
      )}
      {notifications?.data?.map((val) => (
        <MenuItem
          minH="48px"
          px={5}
          py={3}
          key={val.id}
          onClick={() => {
            navigate(`/${PATH.CHAT}/${val.user}`)
          }}
        >
          <Avatar
            mr="12px"
            size="sm"
            src={val.sender?.profile}
          />
          <Box maxW="230px">
            <Text color="inherit">
              <span style={{ fontWeight: 600 }}>
                {`${val?.sender?.firstName} ${val?.sender?.lastName}`}
              </span>{' '}
              sent a message.
            </Text>
            <Text fontSize={14} color="teal">
              {fromNow(val?.createdAt)}
            </Text>
          </Box>
        </MenuItem>
      ))}
    </NotificationWrapperSt>
  )
}

export default Notifications

import useSWR from 'swr'
import {
  deleteRequest,
  postRequest,
  putRequest
} from 'utils/request'
import errorHandler from 'utils/errorHandler'
import { useCallback, useState } from 'react'
import { buildUrl } from 'utils/transformers/apiUrl'
import {
  TRACKING_ROUTE,
  DRAFT_DOCUMENT_ROUTE,
  OFFICE_ROUTE,
  USERS_ROUTE,
  FINAL_DOCUMENT_TYPE_ROUTE
} from './constants'

const useDraft = ({ page, id } = {}) => {
  const url = buildUrl({
    baseUrl: `${DRAFT_DOCUMENT_ROUTE}/list`,
    page,
    id
  })

  const {
    data: draft,
    error: draftError,
    isValidating: draftIsValidating,
    mutate: draftRevalidate
  } = useSWR(url)

  const draftIsLoading =
    !draft && !draftError && draftIsValidating

  return {
    draft,
    draftError,
    draftIsValidating,
    draftIsLoading,
    draftRevalidate
  }
}

const useFinalDocumentType = ({ page, id } = {}) => {
  const url = buildUrl({
    baseUrl: `${FINAL_DOCUMENT_TYPE_ROUTE}`,
    page,
    id
  })

  const {
    data: finalDocument,
    error: finalDocumentError,
    isValidating: finalDocumentIsValidating,
    mutate: finalDocumentRevalidate
  } = useSWR(url)

  const finalDocumentIsLoading =
    !finalDocument &&
    !finalDocumentError &&
    finalDocumentIsValidating

  return {
    finalDocument,
    finalDocumentError,
    finalDocumentIsValidating,
    finalDocumentIsLoading,
    finalDocumentRevalidate
  }
}

const useOffice = ({ page, id } = {}) => {
  const url = buildUrl({
    baseUrl: `${OFFICE_ROUTE}/list`,
    page,
    id
  })

  const {
    data: office,
    error: officeError,
    isValidating: officeIsValidating,
    mutate: officeRevalidate
  } = useSWR(url)

  const officeIsLoading =
    !office && !officeError && officeIsValidating

  return {
    office,
    officeError,
    officeIsValidating,
    officeIsLoading,
    officeRevalidate
  }
}

const useUsers = ({ page, id } = {}) => {
  const url = buildUrl({
    baseUrl: `${USERS_ROUTE}/list`,
    page,
    id
  })

  const {
    data: users,
    error: usersError,
    isValidating: usersIsValidating,
    mutate: usersRevalidate
  } = useSWR(url)

  const usersIsLoading =
    !users && !usersError && usersIsValidating

  return {
    users,
    usersError,
    usersIsValidating,
    usersIsLoading,
    usersRevalidate
  }
}

const useTracking = ({ page, id, search, filter } = {}) => {
  const url = buildUrl({
    baseUrl: TRACKING_ROUTE,
    search,
    page,
    id,
    filter
  })

  const {
    data: tracking,
    error: trackingError,
    isValidating: trackingIsValidating,
    mutate: trackingRevalidate
  } = useSWR(url)

  const trackingIsLoading =
    !tracking && !trackingError && trackingIsValidating

  return {
    tracking,
    trackingError,
    trackingIsValidating,
    trackingIsLoading,
    trackingRevalidate
  }
}

const useMutateTracking = () => {
  const [response, setResponse] = useState()
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const resetState = useCallback(() => {
    if (response) setResponse(undefined)
    if (isLoading) setError(undefined)
  }, [isLoading, response])

  const createTracking = useCallback(
    async (payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await postRequest(TRACKING_ROUTE, payload)
        setResponse(data)
        setIsLoading(false)
        return data
      } catch (err) {
        setError(errorHandler(err))
        setIsLoading(false)
      }
      return null
    },
    [resetState]
  )

  const updateTracking = useCallback(
    async (id, payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await putRequest(
          `${TRACKING_ROUTE}/${id}`,
          payload
        )
        setResponse(data)
        setIsLoading(false)
        return data
      } catch (err) {
        setError(errorHandler(err))
        setIsLoading(false)
      }
      return null
    },
    [resetState]
  )

  const deleteTracking = useCallback(
    async (id) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await deleteRequest(
          `${TRACKING_ROUTE}/${id}`
        )
        setResponse(data)
        setIsLoading(false)
        return data
      } catch (err) {
        setError(errorHandler(err))
        setIsLoading(false)
      }
      return null
    },
    [resetState]
  )

  return {
    data: response,
    error,
    isLoading,
    createTracking,
    updateTracking,
    deleteTracking
  }
}

export {
  useTracking,
  useMutateTracking,
  useUsers,
  useOffice,
  useDraft,
  useFinalDocumentType
}

import useSWR from 'swr'
import {
  deleteRequest,
  postRequest,
  putRequest
} from 'utils/request'
import errorHandler from 'utils/errorHandler'
import { useCallback, useState } from 'react'
import { callSWRFetchOnce } from 'utils/request/constant'
import { buildUrl } from 'utils/transformers/apiUrl'
import {
  DOCUMENT_TYPE_ROUTE,
  DOCUMENT_TYPE_OPTIONS_ROUTE
} from './constants'

const useDocumentType = ({ page, id, search } = {}) => {
  const url = buildUrl({
    baseUrl: DOCUMENT_TYPE_ROUTE,
    page,
    id,
    search
  })

  const {
    data: documentType,
    error: documentTypeError,
    isValidating: documentTypeIsValidating,
    mutate: documentTypeRevalidate
  } = useSWR(url)

  const documentTypeIsLoading =
    !documentType &&
    !documentTypeError &&
    documentTypeIsValidating

  return {
    documentType,
    documentTypeError,
    documentTypeIsValidating,
    documentTypeIsLoading,
    documentTypeRevalidate
  }
}

const useDocumentTypeOptions = () => {
  const {
    data,
    error: documentTypeOptionsError,
    isValidating: documentTypeOptionsIsValidating,
    mutate: documentTypeOptionsRevalidate
  } = useSWR(DOCUMENT_TYPE_OPTIONS_ROUTE, callSWRFetchOnce)

  const documentTypeOptions = data?.data

  const documentTypeOptionsIsLoading =
    !documentTypeOptions &&
    !documentTypeOptionsError &&
    documentTypeOptionsIsValidating

  return {
    documentTypeOptions,
    documentTypeOptionsError,
    documentTypeOptionsIsValidating,
    documentTypeOptionsIsLoading,
    documentTypeOptionsRevalidate
  }
}

const useMutateDocumentType = () => {
  const [response, setResponse] = useState()
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const resetState = useCallback(() => {
    if (response) setResponse(undefined)
    if (isLoading) setError(undefined)
  }, [isLoading, response])

  const createDocumentType = useCallback(
    async (payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await postRequest(
          DOCUMENT_TYPE_ROUTE,
          payload
        )
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  const updateDocumentType = useCallback(
    async (id, payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await putRequest(
          `${DOCUMENT_TYPE_ROUTE}/${id}`,
          payload
        )
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  const deleteDocumentType = useCallback(
    async (id) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await deleteRequest(
          `${DOCUMENT_TYPE_ROUTE}/${id}`
        )
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  return {
    data: response,
    error,
    isLoading,
    createDocumentType,
    updateDocumentType,
    deleteDocumentType
  }
}

export {
  useDocumentType,
  useDocumentTypeOptions,
  useMutateDocumentType
}

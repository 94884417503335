import React from 'react'
import { useDisclosure } from '@chakra-ui/react'

import useRoutePagination from 'utils/hooks/useRoutePagination'

import PageWrapper from 'layout/PageWrapper'
import { Box } from 'components/Layout'
import Modal from 'components/Modal'
import Table from 'components/Table'
import { FiPlus } from 'react-icons/fi'
import useRoleAccessContext from 'containers/RoleAccess/useRoleAccessContext'
import Button from 'components/Button'
import useSearch from 'utils/hooks/useSearch'
import TableHeader from 'components/Table/TableHeader'
import { useUsersOptions } from '../UserManagement/api'
import CreateUserForm from './Forms/Create'
import { columns } from './columns'
import { CommitteeProvider } from './Context'
import { useCommittee, useMutateCommittee, useTerm } from './api'

const Committee = () => {
  const { page } = useRoutePagination()
  const [search, setSearch] = useSearch()
  const { usersOptions } = useUsersOptions()
  const { access = [] } = useRoleAccessContext()
  const {
    committee = {},
    committeeIsLoading,
    committeeRevalidate
  } = useCommittee({ page, search })

  const { term } = useTerm()

  const { data, totalPages } = committee

  const { createCommittee } = useMutateCommittee()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleCreateDesignation = async (value) => {
    await createCommittee(value)

    committeeRevalidate()
    onClose()
  }

  return (
    <CommitteeProvider
      value={{ usersOptions, revalidate: committeeRevalidate }}
    >
      <Box>
        <PageWrapper
          headerRightComponent={
            access['master-data'] &&
            access['master-data'].includes('create') ? (
              <Button
                leftIcon={<FiPlus />}
                onClick={() => onOpen()}
              >
                New Committee
              </Button>
            ) : (
              false
            )
          }
        >
          <TableHeader
            totalPages={totalPages}
            onSearch={setSearch}
          />
          <Table
            data={data}
            term={term}
            columns={columns}
            isLoading={committeeIsLoading}
          />
        </PageWrapper>

        <Modal
          title="Create Committee"
          isOpen={isOpen}
          onClose={onClose}
          withActionButtons={false}
          closeOnOverlayClick={false}
          size="2xl"
        >
          <CreateUserForm
            term={term}
            onSubmit={handleCreateDesignation}
            onClose={onClose}
            usersOptions={usersOptions}
          />
        </Modal>
      </Box>
    </CommitteeProvider>
  )
}

export default Committee

import React from 'react'
import { useForm } from 'react-hook-form'

import Button from 'components/Button'
import Form from 'components/Form'
import FormField from 'components/FormField'
import { Flex, Stack } from 'components/Layout'

import { yupResolver } from '@hookform/resolvers/yup'
import ReactSelect from 'components/ReactSelect'
import { model } from './schema'
import { useSession, useUsers } from '../api'
import {
  READING_STEPS_OPTION,
  CERTIFIED_ROUTES
} from '../constants'

const Create = ({ onClose, onSubmit, id }) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isDirty }
  } = useForm({
    resolver: yupResolver(model),
    defaultValues: { draftId: id }
  })
  const { users } = useUsers()
  const { session } = useSession()

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      disabled={isSubmitting}
    >
      <Stack spacing={6} pb={2}>
        <FormField
          id="sessionId"
          isInvalid={errors.sessionId}
          error={errors.sessionId}
          label="Session"
        >
          <ReactSelect
            id="sessionId"
            name="sessionId"
            placeholder="Select Session..."
            control={control}
            options={session?.data || []}
          />
        </FormField>
        <FormField
          id="signatories"
          isInvalid={errors.signatories}
          error={errors.signatories}
          label="Voters"
        >
          <ReactSelect
            id="signatories"
            name="signatories"
            placeholder="Select Users..."
            control={control}
            isMulti
            options={users || []}
          />
        </FormField>
        <FormField
          id="type"
          isInvalid={errors.type}
          error={errors.type}
          label="Business Type"
        >
          <ReactSelect
            id="type"
            name="type"
            placeholder="Select type..."
            control={control}
            options={READING_STEPS_OPTION || []}
          />
        </FormField>
        <FormField
          id="certified"
          isInvalid={errors.certified}
          error={errors.certified}
          label="Certified"
        >
          <ReactSelect
            id="certified"
            name="certified"
            placeholder="Select Certified..."
            control={control}
            options={CERTIFIED_ROUTES || []}
          />
        </FormField>
        <Flex mt={5} justify="flex-end">
          <Button mr={2} variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            isLoading={isSubmitting}
            type="submit"
            disabled={!isDirty || isSubmitting}
          >
            Submit
          </Button>
        </Flex>
      </Stack>
    </Form>
  )
}

export default Create

import React from 'react'

import { PageWrapperSt } from './styles'
import ContentHeader from '../ContentHeader'
import Content from '../Content'

const PageWrapper = ({
  headerComponent,
  children,
  onCreate,
  label,
  headerRightComponent
}) => {
  return (
    <PageWrapperSt>
      {headerComponent ?? (
        <ContentHeader
          onCreate={onCreate}
          label={label}
          rightComponent={headerRightComponent}
        />
      )}
      <Content>{children}</Content>
    </PageWrapperSt>
  )
}

export default React.memo(PageWrapper)

import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import FileViewer from 'react-file-viewer-plugins-v3'

import Button from 'components/Button'
import Form from 'components/Form'
import FormField from 'components/FormField'
import ReactSelect from 'components/ReactSelect'
import { Flex, Stack } from 'components/Layout'
import FileUploader from 'components/FileUploader'
import { useSession } from '../api'

import { SESSION_TYPE_ROUTES } from '../constants'

import { model } from './schema'

const Edit = ({ onClose, onSubmit, values }) => {
  const { session } = useSession()

  const {
    handleSubmit,
    control,
    resetField,
    register,
    formState: { errors, isSubmitting, isDirty }
  } = useForm({
    resolver: yupResolver(model),
    defaultValues: {
      ...values
    }
  })

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      disabled={isSubmitting}
    >
      <Stack spacing={6} pb={2}>
        <FormField
          id="sessionId"
          isInvalid={errors.sessionId}
          error={errors.sessionId}
          label="Session"
          mr={4}
        >
          <ReactSelect
            id="sessionId"
            name="sessionId"
            placeholder="Select Session..."
            control={control}
            options={session?.data || []}
          />
        </FormField>
        <FormField
          id="sessionType"
          isInvalid={errors.sessionType}
          error={errors.sessionType}
          label="Session Type"
          mr={4}
        >
          <ReactSelect
            id="sessionType"
            name="sessionType"
            placeholder="Select Session Type..."
            control={control}
            options={SESSION_TYPE_ROUTES || []}
          />
        </FormField>
        <FormField
          id="file"
          isInvalid={errors.file}
          error={errors.file}
          label="Attachments (PDF only)"
        >
          <FileUploader
            placeholder="Select File"
            id="file"
            onReset={() => resetField('file')}
            {...register('file')}
          />
        </FormField>
        {values && values.file && (
          <FileViewer fileType="pdf" filePath={values?.file} />
        )}
        <Flex mt={5} justify="flex-end">
          <Button mr={2} variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            isLoading={isSubmitting}
            type="submit"
            disabled={!isDirty || isSubmitting}
          >
            Submit
          </Button>
        </Flex>
      </Stack>
    </Form>
  )
}

export default Edit

import React, { memo, useRef } from 'react'
import { FiSend } from 'react-icons/fi'

import Button from 'components/Button'
import { Box, Flex } from 'components/Layout'

import { GrowingTextAreaSt } from './styles'

const ChatBox = ({ onSubmit }) => {
  const inputRef = useRef()

  const handleKeyDown = (event) => {
    if (event.shiftKey) return

    // 13 is Enter key
    if (event.keyCode === 13) {
      event.preventDefault()
      onSubmit(inputRef.current.value)
      inputRef.current.value = ''
    }
  }

  return (
    <Box p={4} borderBottomWidth={1} fontSize="lg" bg="tertiary">
      <Flex alignItems="center">
        <GrowingTextAreaSt
          ref={inputRef}
          bgColor="white"
          placeholder="Aa"
          onKeyDown={handleKeyDown}
        />

        <Button
          size="md"
          borderRadius="50%"
          ml={2}
          variant="ghost"
          transform="rotate(45deg)"
          onClick={() => {
            onSubmit(inputRef.current.value)
            inputRef.current.value = ''
          }}
          p={1}
          fontSize={20}
        >
          <Flex left="-1px" top="1px" position="relative">
            <FiSend />
          </Flex>
        </Button>
      </Flex>
    </Box>
  )
}

export default memo(ChatBox)

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import customScrollbar from 'theme/styles/customScrollbar'

const ModalSt = styled(Modal)``
const ModalOverlaySt = styled(ModalOverlay)``
const ModalContentSt = styled(ModalContent)``
const ModalHeaderSt = styled(ModalHeader)``
const ModalCloseButtonSt = styled(ModalCloseButton)``
const ModalBodySt = styled(ModalBody)`
  overflow: overlay;

  ${customScrollbar};
  &::-webkit-scrollbar {
    display: block;
  }
`
const ModalFooterSt = styled(ModalFooter)``

export {
  ModalSt,
  ModalOverlaySt,
  ModalContentSt,
  ModalHeaderSt,
  ModalCloseButtonSt,
  ModalBodySt,
  ModalFooterSt
}

import useSWR from 'swr'
import {
  deleteRequest,
  postRequest,
  putRequest
} from 'utils/request'
import errorHandler from 'utils/errorHandler'
import { useCallback, useState } from 'react'
import { buildUrl } from 'utils/transformers/apiUrl'
import {
  DOCUMENT_ROUTE,
  COMMITTEE_ROUTE,
  SESSION_ROUTE,
  DRAFT_DOCUMENT_ROUTE,
  DOCUMENT_TYPE_ROUTE
} from './constants'

const useFinalDocument = ({ page, id, search, filter } = {}) => {
  const url = buildUrl({
    baseUrl: DOCUMENT_ROUTE,
    search,
    page,
    filter,
    id
  })

  const {
    data: finalDocument,
    error: finalDocumentError,
    isValidating: finalDocumentIsValidating,
    mutate: finalDocumentRevalidate
  } = useSWR(url)

  const finalDocumentIsLoading =
    !finalDocument &&
    !finalDocumentError &&
    finalDocumentIsValidating

  return {
    finalDocument,
    finalDocumentError,
    finalDocumentIsValidating,
    finalDocumentIsLoading,
    finalDocumentRevalidate
  }
}

const useDraftDocument = ({ page, id, search } = {}) => {
  const url = buildUrl({
    baseUrl: DRAFT_DOCUMENT_ROUTE,
    search,
    page,
    id
  })

  const {
    data: draftDocument,
    error: draftDocumentError,
    isValidating: draftDocumentIsValidating,
    mutate: draftDocumentRevalidate
  } = useSWR(url)

  const draftDocumentIsLoading =
    !draftDocument &&
    !draftDocumentError &&
    draftDocumentIsValidating

  return {
    draftDocument,
    draftDocumentError,
    draftDocumentIsValidating,
    draftDocumentIsLoading,
    draftDocumentRevalidate
  }
}

const useDocumentType = ({ page, id, search } = {}) => {
  const url = buildUrl({
    baseUrl: DOCUMENT_TYPE_ROUTE,
    search,
    page,
    id
  })

  const {
    data: documentType,
    error: documentTypeError,
    isValidating: documentTypeIsValidating,
    mutate: documentTypeRevalidate
  } = useSWR(url)

  const documentTypeIsLoading =
    !documentType &&
    !documentTypeError &&
    documentTypeIsValidating

  return {
    documentType,
    documentTypeError,
    documentTypeIsValidating,
    documentTypeIsLoading,
    documentTypeRevalidate
  }
}

const useCommittee = () => {
  const url = buildUrl({
    baseUrl: COMMITTEE_ROUTE
  })

  const {
    data: committee,
    error: committeeError,
    isValidating: committeeIsValidating,
    mutate: committeeRevalidate
  } = useSWR(url)

  const committeeIsLoading =
    !committee && !committeeError && committeeIsValidating

  return {
    committee,
    committeeError,
    committeeIsValidating,
    committeeIsLoading,
    committeeRevalidate
  }
}

const useSession = () => {
  const url = buildUrl({
    baseUrl: SESSION_ROUTE
  })

  const {
    data: session,
    error: sessionError,
    isValidating: sessionIsValidating,
    mutate: sessionRevalidate
  } = useSWR(url)

  const sessionIsLoading =
    !session && !sessionError && sessionIsValidating

  return {
    session,
    sessionError,
    sessionIsValidating,
    sessionIsLoading,
    sessionRevalidate
  }
}

const useFinalDocumentSingle = (id) => {
  const url = buildUrl({
    baseUrl: `${DOCUMENT_ROUTE}/${id}`
  })

  const {
    data: finalDocumentSingle,
    error: finalDocumentSingleError,
    isValidating: finalDocumentSingleIsValidating,
    mutate: finalDocumentSingleRevalidate
  } = useSWR(url)

  const finalDocumentSingleIsLoading =
    !finalDocumentSingle &&
    !finalDocumentSingleError &&
    finalDocumentSingleIsValidating

  return {
    finalDocumentSingle,
    finalDocumentSingleError,
    finalDocumentSingleIsValidating,
    finalDocumentSingleIsLoading,
    finalDocumentSingleRevalidate
  }
}

const useMutateFinalDocument = () => {
  const [response, setResponse] = useState()
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const resetState = useCallback(() => {
    if (response) setResponse(undefined)
    if (isLoading) setError(undefined)
  }, [isLoading, response])

  const createFinalDocument = useCallback(
    async (payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await postRequest(DOCUMENT_ROUTE, payload)
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  const updateFinalDocument = useCallback(
    async (id, payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await putRequest(
          `${DOCUMENT_ROUTE}/${id}`,
          payload
        )
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  const deleteFinalDocument = useCallback(
    async (id) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await deleteRequest(
          `${DOCUMENT_ROUTE}/${id}`
        )
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  return {
    data: response,
    error,
    isLoading,
    createFinalDocument,
    updateFinalDocument,
    deleteFinalDocument
  }
}

export {
  useFinalDocument,
  useMutateFinalDocument,
  useCommittee,
  useSession,
  useFinalDocumentSingle,
  useDraftDocument,
  useDocumentType
}

import React from 'react'

import { formatDate } from 'utils/dateTime'
import { PRETTY_FORMAT } from 'utils/dateTime/constants'

import Text from 'components/Text'

const Date = ({ value }) => {
  return <Text>{formatDate(value, PRETTY_FORMAT)}</Text>
}

export default React.memo(Date)

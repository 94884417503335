const COMMITTEE_LIST_ROUTE = '/committee'
const COMMITTEE_ROUTE = '/committee-document'
const DRAFT_DOCUMENT_ROUTE = '/draft-document'
const OUTGOING_STATUS_ROUTE = '/outgoing-status'
const USERS_ROUTE = '/users'
const SESSION_ROUTE = '/session'
const PROPOSAL_ROUTE = '/proposal'

const READING_STEPS_OPTION = [
  {
    label: 'COMMITTEE REPORTS',
    value: 'COMMITTEE_REPORTS'
  }
]

const CERTIFIED_ROUTES = [
  {
    label: 'Certified as urgent by the LCE',
    value: 'Certified as urgent by the LCE'
  },
  {
    label: 'Certified as urgent by majority of the members',
    value: 'Certified as urgent by majority of the members'
  }
]

export {
  COMMITTEE_LIST_ROUTE,
  DRAFT_DOCUMENT_ROUTE,
  OUTGOING_STATUS_ROUTE,
  USERS_ROUTE,
  COMMITTEE_ROUTE,
  READING_STEPS_OPTION,
  CERTIFIED_ROUTES,
  SESSION_ROUTE,
  PROPOSAL_ROUTE
}

/* eslint-disable no-plusplus */
import { isNil } from 'ramda'
import { isArray, isObject } from './types'

// Curry instead of using *ugly switch statement
const switchFn = (cases) => (key) => cases[key] || cases.default

const appendObjectIf = (cond, obj) => (cond ? obj : {})

const noop = () => {}

const debounce = (callback, wait) => {
  let timeoutId = null
  return (...args) => {
    window.clearTimeout(timeoutId)
    timeoutId = window.setTimeout(() => {
      callback(...args)
    }, wait)
  }
}

const generateFormData = (payloadObj) => {
  const formData = new FormData()

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(payloadObj)) {
    if (key === 'file') {
      if (value && value.length > 0) {
        for (let i = 0; i < value.length; i++) {
          formData.append(key, value[i])
        }
      }
    }
    if (key === 'documentFile') {
      if (value && value.length > 0) {
        for (let i = 0; i < value.length; i++) {
          formData.append(key, value[i])
        }
      }
    }
    if (!isNil(value)) {
      formData.append(key, value)
    }
  }

  return formData
}

const filterUnmatchedKeys = (keys, objectToFilter) => {
  if (!isArray(keys) || !isObject(objectToFilter)) {
    return undefined
  }

  return keys.reduce((acc, curr) => {
    return {
      ...acc,
      [curr]: objectToFilter[curr]
    }
  }, {})
}

export {
  appendObjectIf,
  noop,
  debounce,
  generateFormData,
  switchFn,
  filterUnmatchedKeys
}

import React, { useEffect } from 'react'
import {
  Outlet,
  useLocation,
  useNavigate
} from 'react-router-dom'
import {
  Drawer,
  DrawerContent,
  useDisclosure
} from '@chakra-ui/react'

import Header from 'layout/Header'
import Sidebar from 'layout/Sidebar'
import { HEADER_HEIGHT } from 'layout/Header/styles'

import { Box } from 'components/Layout'

const Admin = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    if (pathname === '/') navigate('dashboard')
  }, [navigate, pathname])

  return (
    <Box minH="100vh" bg="#FDFDFE">
      <Sidebar
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <Sidebar onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <Header onOpen={onOpen} />
      <Box ml={{ base: 0, md: 80 }} pt={HEADER_HEIGHT} h="full">
        <Outlet />
      </Box>
    </Box>
  )
}

export default Admin

import React from 'react'
import { useDisclosure } from '@chakra-ui/react'

import Modal from 'components/Modal'
import { UserManagerAction } from 'components/CellRenderers'
import AlertDialog from 'components/AlertDialog'
import useRoleAccessContext from 'containers/RoleAccess/useRoleAccessContext'
import { formatToISOString } from 'utils/dateTime'
import { generateFormData } from 'utils/helpers'
import useToast from 'utils/hooks/useToast'
import Edit from './Forms/Edit'
import AssignRole from './Forms/AssignRole'
import {
  useMutateUsers,
  useMutateAccess,
  useAccess
} from './api'
import { useUsersManagementContext } from './Context'

const TableActions = ({ row, value: id }) => {
  const { original } = row
  const { firstName, lastName } = original
  const toast = useToast()
  const { revalidate } = useUsersManagementContext()
  const { updateUser, deleteUser, isLoading } = useMutateUsers()
  const { createAccess } = useMutateAccess()
  const { access } = useAccess(id)
  const { access: authorization = [] } = useRoleAccessContext()
  const {
    isOpen: editIsOpen,
    onOpen: editOnOpen,
    onClose: editOnClose
  } = useDisclosure()
  const {
    isOpen: deleteIsOpen,
    onOpen: deleteOnOpen,
    onClose: deleteOnClose
  } = useDisclosure()

  const {
    isOpen: accessIsOpen,
    onOpen: accessOnOpen,
    onClose: accessOnClose
  } = useDisclosure()

  const handleEdit = async (newValues) => {
    const { birthdate, file = [], ...rest } = newValues

    const payload = {
      birthdate: formatToISOString(birthdate),
      file: file[0],
      ...rest
    }

    await updateUser(id, generateFormData(payload))

    revalidate()
    editOnClose()
  }

  const handleDelete = async () => {
    await deleteUser(id)

    revalidate()
    deleteOnClose()
  }

  const handleAccess = async (val) => {
    const temp = val
    temp.userId = id
    const create = createAccess(temp)
    if (create) {
      toast({
        title: 'User access updated.',
        status: 'success',
        description: ''
      })
    }
    accessOnClose()
  }

  return (
    <>
      <UserManagerAction
        onEditClick={editOnOpen}
        onDeleteClick={deleteOnOpen}
        deleteTooltipLabel={`Delete ${firstName} ${lastName}`}
        onAccessClick={accessOnOpen}
        view={
          authorization['master-data'] &&
          authorization['master-data'].includes('view')
        }
        edit={
          authorization['master-data'] &&
          authorization['master-data'].includes('edit')
        }
        // delete={
        //   authorization['master-data'] &&
        //   authorization['master-data'].includes('delete')
        // }
      />
      <Modal
        title="Edit Users"
        isOpen={editIsOpen}
        onClose={editOnClose}
        onDeleteClick={deleteOnOpen}
        withActionButtons={false}
        closeOnOverlayClick={false}
        size="3xl"
      >
        <Edit
          onClose={editOnClose}
          onSubmit={handleEdit}
          values={original}
        />
      </Modal>
      <Modal
        title="Manage Access"
        isOpen={accessIsOpen}
        onClose={accessOnClose}
        withActionButtons={false}
        closeOnOverlayClick={false}
        size="3xl"
      >
        <AssignRole
          onClose={accessOnClose}
          onSubmit={handleAccess}
          values={access}
        />
      </Modal>

      <AlertDialog
        isLoading={isLoading}
        isOpen={deleteIsOpen}
        onClose={deleteOnClose}
        onConfirm={handleDelete}
        title="Delete Users"
      />
    </>
  )
}

export default TableActions

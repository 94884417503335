import React, { useContext } from 'react'

const OfficeContext = React.createContext(null)

export const OfficeProvider = ({ children, value }) => (
  <OfficeContext.Provider value={value}>
    {children}
  </OfficeContext.Provider>
)

export const useOfficeContext = () => {
  return useContext(OfficeContext)
}

export default OfficeContext

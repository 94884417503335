import styled from '@emotion/styled'
import EmptyOverlay from 'components/Table/EmptyOverlay'
import { CHAT_BOX_HEIGHT } from './constants'

const ChatBoxWrapperSt = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  height: ${CHAT_BOX_HEIGHT};
`
const EmptyOverlaySt = styled(EmptyOverlay)`
  && {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: #fff;
    left: 0;
    top: 0;
    margin: 0;
  }
`

export { ChatBoxWrapperSt, EmptyOverlaySt }

import * as yup from 'yup'

const messages = {
  requiredGeneric: 'This field is required',
  codeMin: 'Code should be 4 characters and above'
}

const model = yup.object({
  code: yup
    .string()
    .min(4, messages.codeMin)
    .required(messages.requiredGeneric),
  description: yup.string().required(messages.requiredGeneric)
})

const initialValues = {
  code: '',
  description: ''
}

export { model, initialValues }

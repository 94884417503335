import { useCallback, useState } from 'react'
import useSWR from 'swr'
import {
  deleteRequest,
  postRequest,
  putRequest
} from 'utils/request'
import errorHandler from 'utils/errorHandler'
import { buildUrl } from 'utils/transformers/apiUrl'
import { LEGISLATION_ROUTE } from './constants'

const useLegislation = ({ page, id, search } = {}) => {
  const url = buildUrl({
    baseUrl: LEGISLATION_ROUTE,
    search,
    page,
    id
  })

  const {
    data: legislation,
    error: legislationError,
    isValidating: legislationIsValidating,
    mutate: legislationRevalidate
  } = useSWR(url)

  const legislationIsLoading =
    !legislation && !legislationError && legislationIsValidating

  return {
    legislation,
    legislationError,
    legislationIsValidating,
    legislationIsLoading,
    legislationRevalidate
  }
}

const useMutateLegislation = () => {
  const [response, setResponse] = useState()
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const resetState = useCallback(() => {
    if (response) setResponse(undefined)
    if (isLoading) setError(undefined)
  }, [isLoading, response])

  const createLegislation = useCallback(
    async (payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await postRequest(
          LEGISLATION_ROUTE,
          payload
        )
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  const updateLegislation = useCallback(
    async (id, payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await putRequest(
          `${LEGISLATION_ROUTE}/${id}`,
          payload
        )
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  const deleteLegislation = useCallback(
    async (id) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await deleteRequest(
          `${LEGISLATION_ROUTE}/${id}`
        )
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  return {
    data: response,
    error,
    isLoading,
    createLegislation,
    updateLegislation,
    deleteLegislation
  }
}

export { useLegislation, useMutateLegislation }

import styled from '@emotion/styled'

const FormFieldSetSt = styled.fieldset`
  position: relative;
  width: 100%;

  &[aria-disabled='true'] {
    pointer-events: none;
  }
`

export { FormFieldSetSt }

import {
  Editable,
  EditableInput,
  EditablePreview
} from '@chakra-ui/react'
import React from 'react'

const EditableTitle = ({ value, onChange }) => {
  return (
    <Editable
      colorScheme="teal"
      fontSize="2xl"
      onChange={onChange}
      value={value}
      placeholder="Enter Note Title..."
    >
      <EditablePreview p={2} />
      <EditableInput p={2} />
    </Editable>
  )
}

export default EditableTitle

import React, { useMemo } from 'react'
import useAuth from 'containers/Auth/useAuth'
import RoleAccessContext from './Context'
import { useAccess } from './api'

export const RoleAccessProvider = ({ children }) => {
  const auth = useAuth()
  const { access } = useAccess({ cancelCall: !auth.user })
  const value = useMemo(() => ({ access }), [access])

  return (
    <RoleAccessContext.Provider value={value}>
      {children}
    </RoleAccessContext.Provider>
  )
}

export default RoleAccessProvider

import React from 'react'
import { useDisclosure } from '@chakra-ui/react'
import { generateFormData } from 'utils/helpers'
import { Box } from 'components/Layout'
import Modal from 'components/Modal'
import Table from 'components/Table'

import PageWrapper from 'layout/PageWrapper'
import useRoutePagination from 'utils/hooks/useRoutePagination'
import TableHeader from 'components/Table/TableHeader'
import useSearch from 'utils/hooks/useSearch'
import Create from './Forms/Create'

import { CommitteeReportsProvider } from './Context'
import { columns } from './columns'
import {
  useCommitteeReports,
  useMutateCommitteeReports,
  useUsers,
  useStatus,
  useDraft,
  useCommitteeList
} from './api'

const CommitteeReports = () => {
  const { page } = useRoutePagination()
  const [search, setSearch] = useSearch()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [contentState, setContentState] = React.useState()
  const {
    committeeReports = {},
    committeeReportsIsLoading,
    committeeReportsRevalidate
  } = useCommitteeReports({ page, search })
  const { users } = useUsers()
  const { draft } = useDraft()
  const { status } = useStatus()
  const { committeeList } = useCommitteeList()

  const { data, totalPages } = committeeReports

  const { createCommitteeReports } = useMutateCommitteeReports()

  const handleCreateCommitteeReports = async (formValues) => {
    const { file = [], documentFile = [], ...rest } = formValues

    const payload = {
      file: file && file.length > 0 ? file[0] : null,
      documentFile:
        documentFile && documentFile.length > 0
          ? documentFile[0]
          : null,
      ...rest
    }
    await createCommitteeReports(generateFormData(payload))

    committeeReportsRevalidate()
    onClose()
  }

  return (
    <CommitteeReportsProvider
      value={{ revalidate: committeeReportsRevalidate }}
    >
      <Box>
        <PageWrapper onCreate={onOpen}>
          <TableHeader
            totalPages={totalPages}
            onSearch={setSearch}
          />
          <Table
            isLoading={committeeReportsIsLoading}
            data={data}
            columns={columns}
          />
        </PageWrapper>

        <Modal
          size="xxxl"
          title="Create Committee Reports"
          isOpen={isOpen}
          onClose={onClose}
          withActionButtons={false}
          closeOnOverlayClick={false}
        >
          <Create
            contentState={contentState}
            setContentState={setContentState}
            users={users}
            draft={draft}
            status={status}
            onClose={onClose}
            onSubmit={handleCreateCommitteeReports}
            committeeList={committeeList}
          />
        </Modal>
      </Box>
    </CommitteeReportsProvider>
  )
}

export default React.memo(CommitteeReports)

import React from 'react'
import { useDisclosure } from '@chakra-ui/react'
import useToast from 'utils/hooks/useToast'
import { OrderOfBusinessAction } from 'components/CellRenderers'
import { createProposal, rejectProposal } from './api'
import { useOrderOfBusinessManagerContext } from './Context'

const TableActions = ({ row, value: id }) => {
  const { values, original } = row
  const toast = useToast()
  const { revalidate } = useOrderOfBusinessManagerContext()
  const {
    isOpen: submitIsOpen,
    onOpen: submitOnOpen,
    onClose: submitOnClose
  } = useDisclosure()

  const handleSubmit = async () => {
    await createProposal(id)
    toast({
      title: 'Draft document approved.',
      status: 'success',
      description: 'Document has been registered!.'
    })
    revalidate()
  }

  const handleReject = async () => {
    await rejectProposal(id)
    toast({
      title: 'Draft document rejected.',
      status: 'success',
      description: 'Document has been rejected for next session.'
    })
    revalidate()
  }

  return (
    <OrderOfBusinessAction
      onEditClick={submitOnOpen}
      values={values}
      original={original}
      onReject={handleReject}
      onAccept={handleSubmit}
      submitOnClose={submitOnClose}
      submitIsOpen={submitIsOpen}
    />
  )
}

export default TableActions

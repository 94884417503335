import React from 'react'
import { useDisclosure } from '@chakra-ui/react'

import { Box } from 'components/Layout'
import Modal from 'components/Modal'
import Table from 'components/Table'
import PageWrapper from 'layout/PageWrapper'
import useRoutePagination from 'utils/hooks/useRoutePagination'
import useToast from 'utils/hooks/useToast'
import TableHeader from 'components/Table/TableHeader'
import { generateFormData } from 'utils/helpers'
import useSearch from 'utils/hooks/useSearch'
import Create from './Forms/Create'

import { columns } from './columns'
import { useTranscript, useMutateTranscript } from './api'
import { TranscriptProvider } from './Context'

const Transcript = () => {
  const toast = useToast()

  const { page } = useRoutePagination()
  const [search, setSearch] = useSearch()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [contentState, setContentState] = React.useState()
  const {
    transcript = {},
    transcriptIsLoading,
    transcriptRevalidate
  } = useTranscript({ page, search })

  const { data, totalPages } = transcript

  const { createTranscript } = useMutateTranscript()

  const handleCreateTranscript = async (formValues) => {
    const { file = [], ...rest } = formValues

    const payload = {
      file: file && file.length > 0 ? file[0] : null,
      ...rest
    }
    await createTranscript(generateFormData(payload))

    toast({
      title: 'Draft document created.',
      status: 'success',
      description: "We've created your draft document."
    })
    transcriptRevalidate()
    onClose()
    setContentState(undefined)
  }

  return (
    <TranscriptProvider
      value={{ revalidate: transcriptRevalidate }}
    >
      <Box>
        <PageWrapper onCreate={onOpen}>
          <TableHeader
            totalPages={totalPages}
            onSearch={setSearch}
          />
          <Table
            isLoading={transcriptIsLoading}
            data={data}
            columns={columns}
          />
        </PageWrapper>

        <Modal
          title="Create Transcript"
          isOpen={isOpen}
          size="xxl"
          onClose={onClose}
          withActionButtons={false}
          closeOnOverlayClick={false}
        >
          <Create
            contentState={contentState}
            setContentState={setContentState}
            onClose={onClose}
            onSubmit={handleCreateTranscript}
          />
        </Modal>
      </Box>
    </TranscriptProvider>
  )
}

export default React.memo(Transcript)

const DESIGNATIONS_ROUTE = '/designations'
const COMMITTEE_ROUTE = '/committee/report-dashboard'
const DOCUMENT_TYPE_ROUTE = '/document-type/list'
const MEASURES_PENDING_ROUTE =
  '/draft-document/pending-measures?status=false'
const MEASURES_APPROVED_ROUTE =
  '/draft-document/pending-measures?status=true'

export {
  DESIGNATIONS_ROUTE,
  COMMITTEE_ROUTE,
  MEASURES_PENDING_ROUTE,
  MEASURES_APPROVED_ROUTE,
  DOCUMENT_TYPE_ROUTE
}

import useSWR from 'swr'
import {
  deleteRequest,
  postRequest,
  putRequest
} from 'utils/request'
import errorHandler from 'utils/errorHandler'
import { useCallback, useState } from 'react'
import { buildUrl } from 'utils/transformers/apiUrl'
import { DESIGNATIONS_ROUTE } from './constants'

const useDesignation = ({ page, id, search } = {}) => {
  const url = buildUrl({
    baseUrl: DESIGNATIONS_ROUTE,
    page,
    search,
    id
  })

  const {
    data: designations,
    error: designationsError,
    isValidating: designationsIsValidating,
    mutate: designationsRevalidate
  } = useSWR(url)

  const designationsIsLoading =
    !designations &&
    !designationsError &&
    designationsIsValidating

  return {
    designations,
    designationsError,
    designationsIsValidating,
    designationsIsLoading,
    designationsRevalidate
  }
}

const useMutateDesignation = () => {
  const [response, setResponse] = useState()
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const resetState = useCallback(() => {
    if (response) setResponse(undefined)
    if (isLoading) setError(undefined)
  }, [isLoading, response])

  const createDesignation = useCallback(
    async (payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await postRequest(
          DESIGNATIONS_ROUTE,
          payload
        )
        setResponse(data)
        setIsLoading(false)
        return data
      } catch (err) {
        setError(errorHandler(err))
        setIsLoading(false)
      }
      return null
    },
    [resetState]
  )

  const updateDesignation = useCallback(
    async (id, payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await putRequest(
          `${DESIGNATIONS_ROUTE}/${id}`,
          payload
        )
        setResponse(data)
        setIsLoading(false)
        return data
      } catch (err) {
        setError(errorHandler(err))
        setIsLoading(false)
      }
      return null
    },
    [resetState]
  )

  const deleteDesignation = useCallback(
    async (id) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await deleteRequest(
          `${DESIGNATIONS_ROUTE}/${id}`
        )
        setResponse(data)
        setIsLoading(false)
        return data
      } catch (err) {
        setError(errorHandler(err))
        setIsLoading(false)
      }
      return null
    },
    [resetState]
  )

  return {
    data: response,
    error,
    isLoading,
    createDesignation,
    updateDesignation,
    deleteDesignation
  }
}

export { useDesignation, useMutateDesignation }

import React, { useState } from 'react'

import {
  Box,
  Flex,
  Grid,
  GridItem,
  SimpleGrid
  // SimpleGrid
} from 'components/Layout'
import Table from 'components/Table'

import PageWrapper from 'layout/PageWrapper'
import Select from 'components/Select'
// import Text from 'components/Text'
import {
  // committeeReferralColumn,
  measuresColumn
} from './columns'
import {
  // useCommittee,
  usePendingMeasures
  // useApprovedMeasures,
  // useDocumentType
} from './api'
import MiniStatistics from 'components/Dashboard/MiniStats'
import { FiCheck, FiX } from 'react-icons/fi'
import Card from 'components/Card'
import useRoutePagination from 'utils/hooks/useRoutePagination'
import useSearch from 'utils/hooks/useSearch'
import TableHeader from 'components/Table/TableHeader'
// import StatsCard from './StatsCard'

const Dashboard = () => {
  // const [active, setActive] = React.useState(1)
  // const [approvedFilter, setApprovedFilter] = React.useState('')
  const [pendingFilter] = useState('')
  const [filter, setFilter] = useState('')
  const { page } = useRoutePagination()
  const [search, setSearch] = useSearch()
  const [authorFilter, setAuthorFilter] =
    React.useState('author')
  // const { documentType } = useDocumentType()
  // const { committee } = useCommittee()
  // const { approved } = useApprovedMeasures(approvedFilter)
  const { pending } = usePendingMeasures(
    pendingFilter,
    authorFilter
  )

  return (
    <Box>
      <PageWrapper headerComponent={<span />}>
        <Flex
          flexDirection="column"
          pt={{ base: '20px', md: '20px' }}
          paddingRight="70px"
        >
          <SimpleGrid
            mt="5"
            columns={{ sm: 1, md: 2, xl: 2 }}
            spacing="24px"
          >
            <Card>
              <MiniStatistics
                title="PENDING"
                amount={pending?.notApproved}
                percentage={55}
                icon={
                  <FiCheck h="24px" w="24px" color="green" />
                }
              />
            </Card>
            <Card>
              <MiniStatistics
                title="APPROVED"
                amount={pending?.approved}
                percentage={55}
                icon={<FiX h="24px" w="24px" color="green" />}
              />
            </Card>
          </SimpleGrid>
        </Flex>
        <Grid pt={5} templateColumns="repeat(4, 1fr)" gap={10}>
          <GridItem colSpan={4}>
            <Box mb={14}>
              <TableHeader
                totalPages={1}
                onSearch={setSearch}
                setFilter={setFilter}
              />
              <Table data={[]} columns={measuresColumn} />
            </Box>
          </GridItem>
        </Grid>
      </PageWrapper>
    </Box>
  )
}

export default React.memo(Dashboard)

import { useCallback, useState } from 'react'
import useSWR from 'swr'

import {
  deleteRequest,
  postRequest,
  putRequest
} from 'utils/request'
import errorHandler from 'utils/errorHandler'
import { buildUrl } from 'utils/transformers/apiUrl'

import { COMMITTEE_REFERRALS_ROUTE } from './constants'

const useCommitteeReferrals = ({ page, id, search } = {}) => {
  const url = buildUrl({
    baseUrl: COMMITTEE_REFERRALS_ROUTE,
    search,
    page,
    id
  })

  const {
    data: committeeReferrals,
    error: committeeReferralsError,
    isValidating: committeeReferralsIsValidating,
    mutate: committeeReferralsRevalidate
  } = useSWR(url)

  const committeeReferralsIsLoading =
    !committeeReferrals &&
    !committeeReferralsError &&
    committeeReferralsIsValidating

  return {
    committeeReferrals,
    committeeReferralsError,
    committeeReferralsIsValidating,
    committeeReferralsIsLoading,
    committeeReferralsRevalidate
  }
}

const useMutateCommitteeReferrals = () => {
  const [response, setResponse] = useState()
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const resetState = useCallback(() => {
    if (response) setResponse(undefined)
    if (isLoading) setError(undefined)
  }, [isLoading, response])

  const createCommitteeReferrals = useCallback(
    async (payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await postRequest(
          COMMITTEE_REFERRALS_ROUTE,
          payload
        )
        setResponse(data)
        setIsLoading(false)
        return data
      } catch (err) {
        setError(errorHandler(err))
        setIsLoading(false)
      }
      return null
    },
    [resetState]
  )

  const updateCommitteeReferrals = useCallback(
    async (id, payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await putRequest(
          `${COMMITTEE_REFERRALS_ROUTE}/${id}`,
          payload
        )
        setResponse(data)
        setIsLoading(false)
        return data
      } catch (err) {
        setError(errorHandler(err))
        setIsLoading(false)
      }
      return null
    },
    [resetState]
  )

  const deleteCommitteeReferrals = useCallback(
    async (id) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await deleteRequest(
          `${COMMITTEE_REFERRALS_ROUTE}/${id}`
        )
        setResponse(data)
        setIsLoading(false)
        return data
      } catch (err) {
        setError(errorHandler(err))
        setIsLoading(false)
      }
      return null
    },
    [resetState]
  )

  return {
    data: response,
    error,
    isLoading,
    createCommitteeReferrals,
    updateCommitteeReferrals,
    deleteCommitteeReferrals
  }
}

export { useCommitteeReferrals, useMutateCommitteeReferrals }

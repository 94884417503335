import React from 'react'
import { Editor as DraftJSEditor } from 'react-draft-wysiwyg'
import { debounce } from 'utils/helpers'
import { EditorWrapperSt } from './styles'
import toolbar from './toolbar'

// eslint-disable-next-line import/no-relative-packages
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const Editor = ({
  contentState,
  onContentStateChange,
  minH,
  readOnly
}) => {
  const debouncedOnChange = debounce(
    (e) => onContentStateChange(e),
    150
  )

  return (
    <EditorWrapperSt minH={minH}>
      <DraftJSEditor
        initialContentState={contentState}
        wrapperClassName="Wrapper"
        editorClassName="Editor"
        toolbarClassName="Toolbar"
        onContentStateChange={debouncedOnChange}
        toolbar={toolbar}
        readOnly={readOnly}
      />
    </EditorWrapperSt>
  )
}

export default Editor

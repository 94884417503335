import { useUsers } from 'containers/Admin/UserManagement/api'
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useNavigate } from 'react-router-dom'
import {
  getItem,
  setItem,
  removeItem
} from 'services/localForage'
import { callSWRFetchOnce } from 'utils/request/constant'
import { USER_SESSION_KEY, USER_TOKEN_KEY } from './constants'
import AuthContext from './Context'

const AuthProvider = ({ children }) => {
  const navigate = useNavigate()
  const [session, setSession] = useState()

  const { session: { id } = {} } = session || {}

  const { users } = useUsers({
    id,
    config: callSWRFetchOnce,
    cancel: !id
  })

  useEffect(() => {
    if (!users) return

    setSession((currSession) => ({
      ...currSession,
      session: users
    }))
  }, [users])

  useEffect(() => {
    const getUserSessionOnIndexedDB = async () => {
      const userSession = await getItem(USER_SESSION_KEY)
      const userToken = await getItem(USER_TOKEN_KEY)

      // Setting to "null" instead of "undefined" to
      // differentiate initial value with the actual result
      if (!userToken) return setSession(null)

      return setSession({
        ...userSession,
        token: userToken
      })
    }

    getUserSessionOnIndexedDB()
  }, [])

  const handleLogin = async (user, callback = () => {}) => {
    if (!user) return

    const { token, ...userDetails } = user

    const newSession = await setItem(USER_SESSION_KEY, {
      session: { ...userDetails },
      token
    })
    await setItem(USER_TOKEN_KEY, token)

    setSession(newSession)
    callback()
  }

  const handleLogout = useCallback(async () => {
    await removeItem(USER_SESSION_KEY)
    await removeItem(USER_TOKEN_KEY)

    setSession(null)
    navigate('/')
  }, [navigate])

  const value = useMemo(
    () => ({
      user: session,
      login: handleLogin,
      logout: handleLogout
    }),
    [handleLogout, session]
  )

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider

import React, { useContext } from 'react'

const DocumentTrackingContext = React.createContext(null)

export const DocumentTrackingProvider = ({
  children,
  value
}) => (
  <DocumentTrackingContext.Provider value={value}>
    {children}
  </DocumentTrackingContext.Provider>
)

export const useDocumentTrackingContext = () => {
  return useContext(DocumentTrackingContext)
}

export default DocumentTrackingContext

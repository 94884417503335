import { IconButton } from '@chakra-ui/react'
import React from 'react'
import { FiTrash2 } from 'react-icons/fi'

const DeleteButton = ({ onDelete }) => {
  return (
    <IconButton
      onClick={onDelete}
      className="Button__Delete"
      variant="ghost"
      colorScheme="red"
      aria-label="Delete Note"
      icon={<FiTrash2 />}
      p={1}
      minW="unset"
      minH="unset"
      h="unset"
      mr={2}
      _focus={{
        boxShadow: 0
      }}
    />
  )
}

export default DeleteButton

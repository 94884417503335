/* eslint-disable no-console */
import React from 'react'
import { useDisclosure } from '@chakra-ui/react'

import Modal from 'components/Modal'
import { ActionsRenderer } from 'components/CellRenderers'
import AlertDialog from 'components/AlertDialog'
import useRoleAccessContext from 'containers/RoleAccess/useRoleAccessContext'
import { generateFormData } from 'utils/helpers'
import Edit from './Forms/Edit'
import { useMutateArchived } from './api'
import { useDocumentTypeOptions } from '../DocumentType/api'
import { useArchivedContext } from './Context'

const TableActions = ({ row, value: id }) => {
  const { original } = row
  const { updateArchive, deleteArchive, isLoading } =
    useMutateArchived()
  const { revalidate } = useArchivedContext()
  const { documentTypeOptions } = useDocumentTypeOptions()
  const { access = [] } = useRoleAccessContext()
  const {
    isOpen: editIsOpen,
    onOpen: editOnOpen,
    onClose: editOnClose
  } = useDisclosure()
  const {
    isOpen: deleteIsOpen,
    onOpen: deleteOnOpen,
    onClose: deleteOnClose
  } = useDisclosure()

  const handleEdit = async (newValue) => {
    const { file = [], ...rest } = newValue

    const payload = {
      file,
      ...rest
    }
    await updateArchive(id, generateFormData(payload))

    revalidate()
    editOnClose()
  }

  const handleDelete = async () => {
    await deleteArchive(id)

    revalidate()
    deleteOnClose()
  }

  return (
    <>
      <ActionsRenderer
        onEditClick={editOnOpen}
        onDeleteClick={deleteOnOpen}
        values={original}
        create={
          access.archive && access.archive.includes('create')
        }
        view={access.archive && access.archive.includes('view')}
        edit={access.archive && access.archive.includes('edit')}
        delete={
          access.archive && access.archive.includes('delete')
        }
      />

      <Modal
        title="Edit Archive"
        isOpen={editIsOpen}
        onClose={editOnClose}
        withActionButtons={false}
        closeOnOverlayClick={false}
        size="3xl"
      >
        <Edit
          onClose={editOnClose}
          onSubmit={handleEdit}
          values={original}
          documentTypeOptions={documentTypeOptions}
        />
      </Modal>

      <AlertDialog
        isLoading={isLoading}
        isOpen={deleteIsOpen}
        onClose={deleteOnClose}
        onConfirm={handleDelete}
        title="Delete Archive"
      />
    </>
  )
}

export default TableActions

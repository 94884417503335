import * as yup from 'yup'

const messages = {
  requiredGeneric: 'This field is required',
  codeMin: 'Code should be 4 characters and above',
  membersMin: 'You should select at least (1) member'
}

const model = yup.object({
  recommendation: yup
    .string()
    .required(messages.requiredGeneric),
  findings: yup.string().nullable(),
  ammendments: yup.string().nullable()
})

const initialValues = {
  recommendation: '',
  findings: '',
  ammendments: ''
}

export { model, initialValues }

import React, { useContext } from 'react'

const UsersManagementContext = React.createContext(null)

export const UsersManagementProvider = ({ children, value }) => (
  <UsersManagementContext.Provider value={value}>
    {children}
  </UsersManagementContext.Provider>
)

export const useUsersManagementContext = () => {
  return useContext(UsersManagementContext)
}

export default UsersManagementContext

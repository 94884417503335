import React from 'react'

import {
  BoxSt,
  CenterSt,
  ContainerSt,
  FlexSt,
  GridSt,
  StackSt,
  SimpleGridSt,
  WrapSt,
  HStackSt,
  VStackSt,
  GridItemSt
} from './styles'

const Box = ({ children, ...rest }) => (
  <BoxSt {...rest}>{children}</BoxSt>
)

const Center = ({ children, ...rest }) => (
  <CenterSt {...rest}>{children}</CenterSt>
)

const Container = ({ children, ...rest }) => (
  <ContainerSt {...rest}>{children}</ContainerSt>
)

const Flex = ({ children, ...rest }) => (
  <FlexSt {...rest}>{children}</FlexSt>
)

const Grid = ({ children, ...rest }) => (
  <GridSt {...rest}>{children}</GridSt>
)

const GridItem = ({ children, ...rest }) => (
  <GridItemSt {...rest}>{children}</GridItemSt>
)

const Stack = ({ children, ...rest }) => (
  <StackSt {...rest}>{children}</StackSt>
)

const SimpleGrid = ({ children, ...rest }) => (
  <SimpleGridSt {...rest}>{children}</SimpleGridSt>
)

const Wrap = ({ children, ...rest }) => (
  <WrapSt {...rest}>{children}</WrapSt>
)

const HStack = ({ children, ...rest }) => (
  <HStackSt {...rest}>{children}</HStackSt>
)

const VStack = ({ children, ...rest }) => (
  <VStackSt {...rest}>{children}</VStackSt>
)

export {
  Box,
  Center,
  Container,
  Flex,
  Grid,
  GridItem,
  Stack,
  HStack,
  VStack,
  SimpleGrid,
  Wrap
}

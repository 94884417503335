import React from 'react'
import { FiEdit2, FiTrash } from 'react-icons/fi'
import { Tooltip } from '@chakra-ui/react'

import Button from 'components/Button'
import { HStack } from 'components/Layout'

const UserManagerAction = ({
  deleteTooltipLabel,
  onEditClick,
  onAccessClick,
  onDeleteClick,
  edit,
  delete: remove
}) => {
  return (
    <HStack direction="row">
      {edit && (
        <Button
          onClick={onEditClick}
          leftIcon={<FiEdit2 />}
          variant="outline"
          size="sm"
        >
          Edit
        </Button>
      )}
      {edit && (
        <Button
          onClick={onAccessClick}
          leftIcon={<FiEdit2 />}
          variant="outline"
          size="sm"
        >
          Manage Access
        </Button>
      )}
      {remove && (
        <Tooltip
          label={deleteTooltipLabel ?? `Delete User`}
          fontSize="xs"
        >
          <Button
            colorScheme="red"
            variant="solid"
            size="sm"
            onClick={onDeleteClick}
            _focus={{
              boxShadow: '0 0 0 3px rgba(229, 62, 62, 0.5)'
            }}
          >
            <FiTrash />
          </Button>
        </Tooltip>
      )}
    </HStack>
  )
}

export default React.memo(UserManagerAction)

const USERS_ROUTE = '/users'
const CHAT_ROOM_ROUTE = '/chat-room'
const CHAT_MESSAGES_ROUTE = '/chat-messages'

const CHAT_BOX_HEIGHT = '73px'

export {
  USERS_ROUTE,
  CHAT_ROOM_ROUTE,
  CHAT_MESSAGES_ROUTE,
  CHAT_BOX_HEIGHT
}

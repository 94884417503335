import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import FileViewer from 'react-file-viewer-plugins-v3'
import Button from 'components/Button'
import Form from 'components/Form'
import FormField from 'components/FormField'
import TextArea from 'components/TextArea'
import Input from 'components/Input'
import ReactSelect from 'components/ReactSelect'
import DatePicker from 'components/DatePicker'
import FileUploader from 'components/FileUploader'
import { Flex, Stack } from 'components/Layout'

import { model } from './schema'

const Edit = ({
  onClose,
  onSubmit,
  values,
  draftDocument,
  documentType
}) => {
  const { dateAdopted, draftId, ...defaultValues } = values
  const {
    handleSubmit,
    control,
    resetField,
    register,
    formState: { errors, isSubmitting, isDirty }
  } = useForm({
    resolver: yupResolver(model),
    defaultValues: {
      draftId,
      dateAdopted: new Date(dateAdopted),

      ...defaultValues
    }
  })

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      disabled={isSubmitting}
    >
      <Stack spacing={6} pb={2}>
        <Flex>
          <FormField
            id="documentCode"
            isInvalid={errors.documentCode}
            error={errors.documentCode}
            label="Document Code"
            mr={4}
          >
            <Input
              id="documentCode"
              placeholder="Document Code"
              {...register('documentCode')}
            />
          </FormField>
          <FormField
            id="draftId"
            isInvalid={errors.draftId}
            error={errors.draftId}
            label="Draft Document"
            mr={4}
          >
            <ReactSelect
              id="draftId"
              name="draftId"
              placeholder="Select Draft Document..."
              control={control}
              options={draftDocument?.data || []}
            />
          </FormField>
        </Flex>
        <Flex>
          <FormField
            id="documentType"
            isInvalid={errors.documentType}
            error={errors.documentType}
            label="Document Type"
            mr={4}
          >
            <ReactSelect
              id="documentType"
              name="documentType"
              placeholder="Select Document Type..."
              control={control}
              options={documentType?.data || []}
            />
          </FormField>
          <FormField
            id="dateAdopted"
            isInvalid={errors.dateAdopted}
            error={errors.dateAdopted}
            label="Date Adopted"
          >
            <DatePicker
              id="dateAdopted"
              name="dateAdopted"
              showYearDropdown
              control={control}
              placeholderText="Date Adopted"
              yearDropdownItemNumber={100}
              showMonthDropdown
            />
          </FormField>
        </Flex>
        <Flex>
          <FormField
            id="shortTitle"
            isInvalid={errors.shortTitle}
            error={errors.shortTitle}
            label="Short Title"
          >
            <Input
              id="shortTitle"
              placeholder="Short Title"
              {...register('shortTitle')}
            />
          </FormField>
        </Flex>
        <FormField
          id="title"
          isInvalid={errors.title}
          error={errors.title}
          label="Title"
        >
          <TextArea
            id="title"
            placeholder="Title"
            {...register('title')}
          />
        </FormField>
        <FormField
          id="file"
          isInvalid={errors.file}
          error={errors.file}
          label="File  (PDF only)"
        >
          <FileUploader
            placeholder="Select File"
            id="file"
            onReset={() => resetField('file')}
            {...register('file')}
          />
        </FormField>
        {values && values.file && (
          <div style={{ width: '80%', textAlign: 'center' }}>
            <FileViewer fileType="pdf" filePath={values?.file} />
          </div>
        )}
        <FormField
          id="attachments"
          isInvalid={errors.attachments}
          error={errors.attachments}
          label="Attachments File (PDF only)"
        >
          <FileUploader
            placeholder="Select File"
            id="attachments"
            onReset={() => resetField('attachments')}
            {...register('attachments')}
          />
        </FormField>
        {values && values.attachments && (
          <div style={{ width: '80%', textAlign: 'center' }}>
            <FileViewer
              fileType="pdf"
              filePath={values?.attachments}
            />
          </div>
        )}
        <Flex mt={5} justify="flex-end">
          <Button mr={2} variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            isLoading={isSubmitting}
            type="submit"
            disabled={!isDirty || isSubmitting}
          >
            Submit
          </Button>
        </Flex>
      </Stack>
    </Form>
  )
}

export default Edit

const messages = {
  requiredGeneric: 'This field is required'
}

const model = {
  sessionNo: {
    required: messages.requiredGeneric
  },
  termId: {
    required: messages.requiredGeneric
  },
  date: {
    required: messages.requiredGeneric
  }
}

const initialValues = {
  termId: '',
  sessionNo: '',
  date: ''
}

export { model, initialValues }

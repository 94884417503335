import * as yup from 'yup'

const messages = {
  requiredGeneric: 'This field is required',
  codeMin: 'Code should be 4 characters and above'
}

const model = yup.object({
  sessionId: yup.string().required(messages.requiredGeneric),
  type: yup.string().required(messages.requiredGeneric),
  signatories: yup.array().nullable(),
  certified: yup.string().nullable()
})

const initialValues = {
  sessionId: '',
  type: '',
  signatories: '',
  certified: ''
}

export { model, initialValues }

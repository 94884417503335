/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-plusplus */
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import FileViewer from 'react-file-viewer-plugins-v3'

import Button from 'components/Button'
import Form from 'components/Form'
import FormField from 'components/FormField'
import Input from 'components/Input'
import { Flex, Stack } from 'components/Layout'
import DatePicker from 'components/DatePicker'
import ReactSelect from 'components/ReactSelect'
import FileUploader from 'components/FileUploader'
import { model } from './schema'
import { useTerm, useCommittee } from '../api'

const Edit = ({
  onClose,
  onSubmit,
  documentTypeOptions,
  values
}) => {
  const { date, ...rest } = values
  const {
    handleSubmit,
    register,
    control,
    resetField,
    setValue,
    formState: { errors, isSubmitting, isDirty }
  } = useForm({
    resolver: yupResolver(model),
    defaultValues: {
      date: new Date(date),
      ...rest
    }
  })
  const { term } = useTerm()
  const { committee } = useCommittee()

  useEffect(() => {
    const temp = []
    if (values && values.committees) {
      for (let i = 0; i < values.committees.length; i++) {
        temp.push(values.committees[i].committee.id)
      }
    }
    setValue('committeeId', temp)
  }, [values])

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      disabled={isSubmitting}
    >
      <Stack spacing={6} pb={2}>
        <FormField
          id="title"
          isInvalid={errors.title}
          error={errors.title}
          label="Title"
        >
          <Input
            id="title"
            placeholder="Title"
            {...register('title')}
          />
        </FormField>

        <FormField
          id="description"
          isInvalid={errors.description}
          error={errors.description}
          label="Description"
        >
          <Input
            id="description"
            placeholder="Description"
            {...register('description')}
          />
        </FormField>
        <FormField
          id="termId"
          isInvalid={errors.termId}
          error={errors.termId}
          label="Term"
        >
          <ReactSelect
            id="termId"
            name="termId"
            placeholder="Select Term..."
            control={control}
            options={term?.data || []}
          />
        </FormField>
        <FormField
          id="committeeId"
          isInvalid={errors.committeeId}
          error={errors.committeeId}
          label="Committee"
        >
          <ReactSelect
            id="committeeId"
            name="committeeId"
            placeholder="Select Committee..."
            control={control}
            isMulti
            options={committee || []}
          />
        </FormField>
        <FormField
          id="date"
          isInvalid={errors.date}
          error={errors.date}
          label="Date Adopted"
        >
          <DatePicker
            id="date"
            name="date"
            showYearDropdown
            control={control}
            dateFormatCalendar="MMMM"
            maxDate={new Date()}
            placeholderText="Date"
            yearDropdownItemNumber={100}
            scrollableYearDropdown
            showMonthDropdown
          />
        </FormField>
        <FormField
          id="documentType"
          isInvalid={errors.documentType}
          error={errors.documentType}
          label="Document Type"
        >
          <ReactSelect
            id="documentType"
            name="documentType"
            placeholder="Select Document Type..."
            control={control}
            options={documentTypeOptions}
          />
        </FormField>

        <FormField
          id="file"
          isInvalid={errors.file}
          error={errors.file}
          label="File (PDF only)"
        >
          <FileUploader
            placeholder="Select File"
            id="file"
            onReset={() => resetField('file')}
            {...register('file')}
          />
        </FormField>
        {values &&
          values.file &&
          values.file.map((file) => {
            return <FileViewer fileType="pdf" filePath={file} />
          })}
        <Flex mt={5} justify="flex-end">
          <Button mr={2} variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            isLoading={isSubmitting}
            disabled={isSubmitting || !isDirty}
            type="submit"
          >
            Submit
          </Button>
        </Flex>
      </Stack>
    </Form>
  )
}

export default Edit

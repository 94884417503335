import React from 'react'
import { Tooltip } from '@chakra-ui/react'
import { Flex } from 'components/Layout'
import Text from 'components/Text'
import { fromNow } from 'utils/dateTime'
import useAuth from 'containers/Auth/useAuth'
import EmptyOverlay from 'components/Table/EmptyOverlay'
import { MessagesItemSt, MessagesSt } from './styles'

const Messages = ({
  data,
  messages,
  lastMessageRef,
  ...rest
}) => {
  const auth = useAuth()
  const { session } = auth.user

  return (
    <MessagesSt
      align="stretch"
      spacing="0.12rem"
      p={4}
      h="full"
      w="full"
      {...rest}
    >
      {data?.map(({ id, message, createdAt, user }) => {
        const messageClasses = ['Message']
        if (user === session.id) messageClasses.push('own')

        return (
          <Tooltip
            label={fromNow(createdAt)}
            placement="left"
            openDelay={1000}
            key={id}
          >
            <MessagesItemSt
              className={messageClasses.join(' ')}
              maxW="70%"
              ref={lastMessageRef}
            >
              <Flex justify="start">
                <Text fontSize="sm">{message}</Text>
              </Flex>
            </MessagesItemSt>
          </Tooltip>
        )
      })}
      <Flex h="full" w="full" align="center" justify="center">
        <EmptyOverlay
          isEmpty={data?.length === 0}
          labelComponent={
            <Text fontSize="lg" left="-30px" position="relative">
              No messages yet. Feel free to say Hello! 👋
            </Text>
          }
        />
      </Flex>
    </MessagesSt>
  )
}

export default Messages

import React from 'react'
import { useForm } from 'react-hook-form'

import Button from 'components/Button'
import Form from 'components/Form'
import Input from 'components/Input'
import TextArea from 'components/TextArea'
import FormField from 'components/FormField'
import FileUploader from 'components/FileUploader'
import ReactSelect from 'components/ReactSelect'
import { Flex, Stack } from 'components/Layout'

import { yupResolver } from '@hookform/resolvers/yup'
import { model } from './schema'

const Create = ({ onClose, onSubmit, draft, committeeList }) => {
  const {
    handleSubmit,
    control,
    register,
    resetField,
    formState: { errors, isSubmitting, isDirty }
  } = useForm({
    resolver: yupResolver(model)
  })

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      disabled={isSubmitting}
    >
      <Stack spacing={6} pb={2}>
        <FormField
          id="code"
          isInvalid={errors.code}
          error={errors.code}
          label="Code"
        >
          <Input
            id="code"
            placeholder="Code"
            {...register('code')}
          />
        </FormField>
        <FormField
          id="shortTitle"
          isInvalid={errors.shortTitle}
          error={errors.shortTitle}
          label="Short Title"
        >
          <TextArea
            id="shortTitle"
            placeholder="Short Title"
            {...register('shortTitle')}
          />
        </FormField>
        <FormField
          id="title"
          isInvalid={errors.title}
          error={errors.title}
          label="Title"
        >
          <TextArea
            id="title"
            placeholder="Title"
            {...register('title')}
          />
        </FormField>
        <FormField
          id="draftId"
          isInvalid={errors.draftId}
          error={errors.draftId}
          label="Draft Document"
        >
          <ReactSelect
            id="draftId"
            name="draftId"
            placeholder="Select Docoument..."
            control={control}
            options={draft?.data || []}
            isMulti
          />
        </FormField>
        <FormField
          id="committeeId"
          isInvalid={errors.committeeId}
          error={errors.committeeId}
          label="Committee"
        >
          <ReactSelect
            id="committeeId"
            name="committeeId"
            placeholder="Select Draft Docoument..."
            control={control}
            options={committeeList || []}
            isMulti
          />
        </FormField>
        <FormField
          id="file"
          isInvalid={errors.file}
          error={errors.file}
          label="Attachments  (PDF only)"
        >
          <FileUploader
            placeholder="Select File"
            id="file"
            onReset={() => resetField('file')}
            {...register('file')}
          />
        </FormField>
        <FormField
          id="documentFile"
          isInvalid={errors.documentFile}
          error={errors.documentFile}
          label="Committee Report File (PDF only)"
        >
          <FileUploader
            placeholder="Select File"
            id="documentFile"
            onReset={() => resetField('documentFile')}
            {...register('documentFile')}
          />
        </FormField>
        <Flex mt={5} justify="flex-end">
          <Button mr={2} variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            isLoading={isSubmitting}
            type="submit"
            disabled={!isDirty || isSubmitting}
          >
            Submit
          </Button>
        </Flex>
      </Stack>
    </Form>
  )
}

export default Create

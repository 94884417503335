const errorHandler = (error) => {
  if (!error) return undefined

  const newError = new Error(error)

  if (!error.response) {
    newError.status = 401
    newError.message = error.message
    newError.stack = error.stack

    return newError
  }

  // Unauthorized
  if (error.response.status === 401) {
    window.location.replace('/logout')
  }

  newError.status = error.response.status
  newError.message = error.response.data.message
  newError.stack = error.stack

  return newError
}

export default errorHandler

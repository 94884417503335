import React from 'react'
import { CloseButton } from '@chakra-ui/react'

import { HEADER_HEIGHT } from 'layout/Header/styles'
import { Box, Flex } from 'components/Layout'

import useRoleAccessContext from 'containers/RoleAccess/useRoleAccessContext'

import { LINK_ITEMS, ANY_ROLE_ACCESSIBLE_ROUTES } from 'routes'
import NavItem from './NavItem'
import { NavWrapperSt } from './styles'

const Sidebar = ({ onClose, ...rest }) => {
  const { access = [] } = useRoleAccessContext()

  const accessibleLinks = [
    ...Object.keys(access),
    ...ANY_ROLE_ACCESSIBLE_ROUTES
  ]

  const menu = LINK_ITEMS.filter(({ to }) =>
    accessibleLinks.includes(to)
  )

  return (
    <Box
      transition=".3s ease"
      bg="white"
      borderRight="1px"
      borderRightColor="brand.200"
      w={{ base: 'full', md: 80 }}
      pos="fixed"
      h="full"
      mt={{ base: 0, md: HEADER_HEIGHT }}
      {...rest}
    >
      <Flex
        py={{ base: 5, md: 2 }}
        alignItems="center"
        mx={14}
        justifyContent="flex-end"
      >
        <CloseButton
          display={{ base: 'flex', md: 'none' }}
          onClick={onClose}
          mr="-8px"
        />
      </Flex>
      <NavWrapperSt>
        {menu.map((link) => {
          return (
            <NavItem
              key={link.name}
              icon={link.icon}
              to={link.to}
              subMenu={link.subMenu}
            >
              {link.name}
            </NavItem>
          )
        })}
      </NavWrapperSt>
    </Box>
  )
}

export default Sidebar

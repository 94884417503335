import { CommitteeActions } from 'components/CellRenderers'
import TableActions from './TableActions'

const columns = [
  {
    Header: 'Draft Code',
    accessor: (val) => {
      let data = ''
      if (val.committeedocument && val.committeedocument.code) {
        data = val.committeedocument.code
      }
      if (val.draftdocument && val.draftdocument.draftCode) {
        data = val.draftdocument.draftCode
      }
      return data
    }
  },
  {
    Header: 'Short Title',
    accessor: (val) => {
      let data = ''
      if (val.committeedocument) {
        data = val.committeedocument.shortTitle
      }
      if (val.draftdocument) {
        data = val.draftdocument.shortTitle
      }
      return data
    }
  },
  {
    Header: 'Recommendation',
    accessor: 'recommendation'
  },
  {
    Header: 'Findings',
    accessor: 'findings'
  },
  {
    Header: 'Remarks',
    accessor: 'ammendments'
  },
  {
    Header: 'Committee List',
    accessor: 'committee',
    Cell: CommitteeActions
  },
  {
    Header: 'Action',
    accessor: 'id',
    fixed: 'right',
    Cell: TableActions
  }
]

export { columns }

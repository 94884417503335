import React from 'react'
import { useLocation } from 'react-router-dom'
import { LINK_ITEMS } from 'routes'

const useBreadCrumbs = () => {
  const { pathname } = useLocation()
  const [, ...paths] = pathname.split('/')

  const locationArr = React.useMemo(
    () =>
      paths.map((path) => {
        return LINK_ITEMS.reduce((acc, curr) => {
          if (curr.to === path) {
            return curr.name
          }

          if (curr.subMenu && paths.includes(curr.to)) {
            return curr.subMenu.reduce(
              (subMenuAcc, subMenuCurr) => {
                if (subMenuCurr.to === `${curr.to}/${path}`) {
                  return subMenuCurr.name
                }

                return subMenuAcc
              },
              ''
            )
          }

          return acc
        }, '')
      }),
    [paths]
  )

  const breadCrumbsLabel = React.useMemo(
    () => locationArr.join(' - '),
    [locationArr]
  )

  const defaultLabel = React.useMemo(
    () => locationArr[locationArr.length - 1],
    [locationArr]
  )

  return [breadCrumbsLabel, defaultLabel, locationArr]
}

export default useBreadCrumbs

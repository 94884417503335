import React from 'react'
import { useParams } from 'react-router-dom'

import Table from 'components/Table'
import Pagination from 'components/Pagination'
import useRoutePagination from 'utils/hooks/useRoutePagination'
import PageWrapper from 'layout/PageWrapper'
import { columns } from './columns'
import { useProposal } from './api'
import { OrderOfBusinessManagerContextProvider } from './Context'

const OrderOfBusinessManager = () => {
  const { page, ...pageProps } = useRoutePagination()
  const { id } = useParams()
  const { proposals, proposalsRevalidate } = useProposal({
    page,
    id
  })

  return (
    <OrderOfBusinessManagerContextProvider
      value={{ revalidate: proposalsRevalidate }}
    >
      <PageWrapper
        label="Session - Manage Order of Business"
        headerRightComponent={false}
      >
        <Table data={proposals?.data || []} columns={columns} />
        {proposals?.data && (
          <Pagination
            {...pageProps}
            totalPages={proposals?.totalPages}
            page={page}
            nextDisabled={proposals?.totalPages === page}
          />
        )}
      </PageWrapper>
    </OrderOfBusinessManagerContextProvider>
  )
}

export default OrderOfBusinessManager

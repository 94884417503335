import React from 'react'
import {
  FiEdit2,
  FiSave,
  FiDownload,
  FiTrash,
  FiCheck,
  FiEye
} from 'react-icons/fi'
import { Tooltip } from '@chakra-ui/react'

import Button from 'components/Button'
import { HStack } from 'components/Layout'

const DraftDocumentAction = ({
  values,
  deleteTooltipLabel,
  onEditClick,
  onDeleteClick,
  onSubmitClick,
  onViewClick,
  onApproved,
  create,
  update,
  delete: remove,
  onDownloadClick
}) => {
  return (
    <HStack direction="row">
      <Tooltip label="Submit" fontSize="xs">
        <Button
          colorScheme="blue"
          onClick={onViewClick}
          // variant="outline"
          size="sm"
        >
          <FiEye />
        </Button>
      </Tooltip>
      {create && (
        <Tooltip label="Submit" fontSize="xs">
          <Button
            colorScheme="green"
            onClick={onSubmitClick}
            variant="outline"
            size="sm"
          >
            <FiSave />
          </Button>
        </Tooltip>
      )}
      {update && (
        <Tooltip label="Edit" fontSize="xs">
          <Button
            colorScheme="blue"
            onClick={onEditClick}
            variant="outline"
            size="sm"
          >
            <FiEdit2 />
          </Button>
        </Tooltip>
      )}
      {(create || update) && values.file && (
        <Tooltip label="Download" fontSize="xs">
          <Button
            variant="solid"
            size="sm"
            onClick={() => {
              window.location.href = onDownloadClick
            }}
            _focus={{
              boxShadow: '0 0 0 3px rgba(229, 62, 62, 0.5)'
            }}
          >
            <FiDownload />
          </Button>
        </Tooltip>
      )}
      {remove && (
        <Tooltip
          label={
            deleteTooltipLabel ??
            `Delete ${values?.code ?? values?.draftCode}`
          }
          fontSize="xs"
        >
          <Button
            colorScheme="red"
            variant="solid"
            size="sm"
            onClick={onDeleteClick}
            _focus={{
              boxShadow: '0 0 0 3px rgba(229, 62, 62, 0.5)'
            }}
          >
            <FiTrash />
          </Button>
        </Tooltip>
      )}
      {values && !values.approved && (
        <Tooltip label="Approved" fontSize="xs">
          <Button
            colorScheme="green"
            variant="solid"
            size="sm"
            onClick={onApproved}
            _focus={{
              boxShadow: '0 0 0 3px rgba(229, 62, 62, 0.5)'
            }}
          >
            <FiCheck />
          </Button>
        </Tooltip>
      )}
    </HStack>
  )
}

export default React.memo(DraftDocumentAction)

import React from 'react'
import { useForm } from 'react-hook-form'
import { FiTrash, FiSave } from 'react-icons/fi'
import { yupResolver } from '@hookform/resolvers/yup'
import FileViewer from 'react-file-viewer-plugins-v3'

import Button from 'components/Button'
import Form from 'components/Form'
import FileUploader from 'components/FileUploader'
import ReactSelect from 'components/ReactSelect'
import FormField from 'components/FormField'
import Input from 'components/Input'
import TextArea from 'components/TextArea'
import { Flex, Stack } from 'components/Layout'

import { model } from './schema'

const Edit = ({
  onClose,
  onSubmit,
  values,
  draft,
  committeeList,
  onRemove
}) => {
  const { committeeId, draftId, ...rest } = values
  const {
    handleSubmit,
    register,
    resetField,
    control,
    formState: { errors, isSubmitting, isDirty }
  } = useForm({
    resolver: yupResolver(model),
    defaultValues: {
      committeeId: committeeId.split(','),
      draftId: draftId.split(','),
      ...rest
    }
  })

  const downloadFile = (file) => {
    window.location.href = file
  }

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      disabled={isSubmitting}
    >
      <Stack spacing={6} pb={2}>
        <FormField
          id="code"
          isInvalid={errors.code}
          error={errors.code}
          label="Code"
        >
          <Input
            id="code"
            placeholder="Code"
            {...register('code')}
          />
        </FormField>
        <FormField
          id="shortTitle"
          isInvalid={errors.shortTitle}
          error={errors.shortTitle}
          label="Short Title"
        >
          <TextArea
            id="shortTitle"
            placeholder="Short Title"
            {...register('shortTitle')}
          />
        </FormField>
        <FormField
          id="title"
          isInvalid={errors.title}
          error={errors.title}
          label="Title"
        >
          <TextArea
            id="title"
            placeholder="Title"
            {...register('title')}
          />
        </FormField>
        <FormField
          id="draftId"
          isInvalid={errors.draftId}
          error={errors.draftId}
          label="Draft Document"
        >
          <ReactSelect
            id="draftId"
            name="draftId"
            placeholder="Select Draft Docoument..."
            control={control}
            options={draft?.data || []}
            isMulti
          />
        </FormField>
        <FormField
          id="committeeId"
          isInvalid={errors.committeeId}
          error={errors.committeeId}
          label="Committee"
        >
          <ReactSelect
            id="committeeId"
            name="committeeId"
            placeholder="Select Draft Docoument..."
            control={control}
            options={committeeList || []}
            isMulti
          />
        </FormField>
        <FormField
          id="file"
          isInvalid={errors.file}
          error={errors.file}
          label="Attachments (PDF only)"
        >
          <FileUploader
            placeholder="Select File"
            id="file"
            onReset={() => resetField('file')}
            {...register('file')}
          />
        </FormField>
        <FormField>
          {values.file && (
            <Button
              colorScheme="red"
              mr={4}
              onClick={() => onRemove('file')}
              variant="solid"
              size="sm"
            >
              <FiTrash /> Remove
            </Button>
          )}
          {values.file && (
            <Button
              colorScheme="blue"
              onClick={() => downloadFile(values.file)}
              variant="solid"
              size="sm"
            >
              <FiSave /> Download
            </Button>
          )}
        </FormField>
        {values && values.file && (
          <FileViewer fileType="pdf" filePath={values?.file} />
        )}
        <FormField
          id="documentFile"
          isInvalid={errors.documentFile}
          error={errors.documentFile}
          label="Committee Report File (PDF only)"
        >
          <FileUploader
            placeholder="Select File"
            id="documentFile"
            onReset={() => resetField('documentFile')}
            {...register('documentFile')}
          />
        </FormField>
        <FormField>
          {values.documentFile && (
            <Button
              colorScheme="red"
              mr={4}
              onClick={() => onRemove('documentFile')}
              variant="solid"
              size="sm"
            >
              <FiTrash /> Remove
            </Button>
          )}
          {values.documentFile && (
            <Button
              colorScheme="blue"
              onClick={() => downloadFile(values.documentFile)}
              variant="solid"
              size="sm"
            >
              <FiSave /> Download
            </Button>
          )}
        </FormField>
        <Flex mt={5} justify="flex-end">
          <Button mr={2} variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            isLoading={isSubmitting}
            type="submit"
            disabled={!isDirty || isSubmitting}
          >
            Submit
          </Button>
        </Flex>
      </Stack>
    </Form>
  )
}

export default Edit

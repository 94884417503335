import React from 'react'
import { useDisclosure } from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'

import useRoleAccessContext from 'containers/RoleAccess/useRoleAccessContext'
import { Box } from 'components/Layout'
import Modal from 'components/Modal'
import Table from 'components/Table'
import PageWrapper from 'layout/PageWrapper'
import useRoutePagination from 'utils/hooks/useRoutePagination'
import Button from 'components/Button'
import TableHeader from 'components/Table/TableHeader'
import useSearch from 'utils/hooks/useSearch'
import Create from './Forms/Create'

import { columns } from './columns'
import { useSession, useMutateSession, useTerm } from './api'

const Session = () => {
  const { page } = useRoutePagination()
  const [search, setSearch] = useSearch()
  const { term } = useTerm()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { access = [] } = useRoleAccessContext()
  const {
    session = {},
    sessionIsLoading,
    sessionRevalidate
  } = useSession({ page, search })

  const { data, totalPages } = session

  const { createSession } = useMutateSession()

  const handleCreateSession = async (value) => {
    await createSession(value)

    sessionRevalidate()
    onClose()
  }

  return (
    <Box>
      <PageWrapper
        headerRightComponent={
          access.session && access.session.includes('create') ? (
            <Button
              leftIcon={<FiPlus />}
              onClick={() => onOpen()}
            >
              New Session
            </Button>
          ) : (
            false
          )
        }
      >
        <TableHeader
          totalPages={totalPages}
          onSearch={setSearch}
        />
        <Table
          isLoading={sessionIsLoading}
          data={data}
          columns={columns}
          term={term}
        />
      </PageWrapper>
      <Modal
        title="Create Session"
        isOpen={isOpen}
        onClose={onClose}
        withActionButtons={false}
        closeOnOverlayClick={false}
      >
        <Create
          term={term}
          onClose={onClose}
          onSubmit={handleCreateSession}
        />
      </Modal>
    </Box>
  )
}

export default Session

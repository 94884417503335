import TableActions from './TableActions'

const columns = [
  {
    Header: 'Session',
    accessor: 'session.sessionNo'
  },
  {
    Header: 'Type',
    accessor: 'sessionType'
  },
  {
    Header: 'Action',
    accessor: 'id',
    fixed: 'right',
    Cell: TableActions
  }
]

export { columns }

import React from 'react'
import { useForm } from 'react-hook-form'

import Button from 'components/Button'
import Form from 'components/Form'
import FormField from 'components/FormField'
import { Flex, Stack } from 'components/Layout'

import ReactSelect from 'components/ReactSelect'
import { useSession } from '../api'
import { READING_STEPS_OPTION } from '../constants'

const Submit = ({ onClose, onSubmit, id }) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isDirty }
  } = useForm({
    defaultValues: { committeeReportId: id }
  })
  const { session } = useSession()

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      disabled={isSubmitting}
    >
      <Stack spacing={6} pb={2}>
        <FormField
          id="sessionId"
          isInvalid={errors.sessionId}
          error={errors.sessionId}
          label="Session"
        >
          <ReactSelect
            id="sessionId"
            name="sessionId"
            placeholder="Select Session..."
            control={control}
            options={session?.data || []}
          />
        </FormField>
        <FormField
          id="type"
          isInvalid={errors.type}
          error={errors.type}
          label="Business Type"
        >
          <ReactSelect
            id="type"
            name="type"
            placeholder="Select type..."
            control={control}
            options={READING_STEPS_OPTION || []}
          />
        </FormField>
        <Flex mt={5} justify="flex-end">
          <Button mr={2} variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            isLoading={isSubmitting}
            type="submit"
            disabled={!isDirty || isSubmitting}
          >
            Submit
          </Button>
        </Flex>
      </Stack>
    </Form>
  )
}

export default Submit

import React from 'react'
import { Routes, Route } from 'react-router-dom'

import { PATH } from 'routes'

import AccessGuard from 'components/AccessGuard'

import Dashboard from 'containers/Admin/Dashboard'
import Designation from 'containers/Admin/Designation'
import Office from 'containers/Admin/Office'
import PersonnelCode from 'containers/Admin/PersonnelCode'
import HomePage from 'containers/HomePage'
import AuthProvider from 'containers/Auth'
import RoleAccessProvider from 'containers/RoleAccess'
import PageNotFound from 'containers/PageNotFound'
import DocumentTracking from 'containers/Admin/DocumentTracking'
import Committee from 'containers/Admin/Committee'
import Legislation from 'containers/Admin/Legislation'
import UserManagement from 'containers/Admin/UserManagement'
import DraftDocument from 'containers/Admin/DraftDocument'
import DraftDocumentCreate from 'containers/Admin/DraftDocumentCreate'
import DraftDocumentEdit from 'containers/Admin/DraftDocumentEdit'
import Session from 'containers/Admin/Session'
import Reports from 'containers/Admin/Reports'
import SessionJoin from 'containers/Admin/SessionJoin'
import OrderOfBusinessManager from 'containers/Admin/OrderOfBusinessManager'
import Notes from 'containers/Admin/Notes'
import OutgoingStatus from 'containers/Admin/OutgoingStatus'
import DocumentType from 'containers/Admin/DocumentType'
import FinalDocumentType from 'containers/Admin/FinalDocumentType'
import LogoutPage from 'containers/LogoutPage'
import Conversations from 'containers/Conversations'
import Chat from 'containers/Chat'
import Note from 'containers/Admin/Note'
import Tracking from 'containers/Admin/Tracking'
import CommitteeReferrals from 'containers/Admin/CommitteeReferrals'
import CommitteeReports from 'containers/Admin/CommitteeReports'
import Profile from 'containers/Admin/Profile'
import Archived from 'containers/Admin/Archived'
import Minutes from 'containers/Admin/Minutes'
import CommitteeHearingMinutes from 'containers/Admin/CommitteeHearingMinutes'
import PublicHearingMinutes from 'containers/Admin/PublicHearingMinutes'
import CommitteeMeetingMinutes from 'containers/Admin/CommitteeMeetingMinutes'
import FinalDocument from 'containers/Admin/FinalDocument'
import Transcript from 'containers/Admin/Transcript'
import Term from 'containers/Admin/Term'

const EntryContainer = () => (
  <AuthProvider>
    <RoleAccessProvider>
      <Routes>
        <Route path="/" element={<HomePage />}>
          <Route
            name="Dashboard"
            path={PATH.DASHBOARD}
            element={<Dashboard />}
          />
          <Route
            element={<AccessGuard path={PATH.MASTER_DATA} />}
          >
            <Route
              path={PATH.DESIGNATION}
              element={<Designation />}
            />
            <Route
              path={PATH.DOCUMENT_TYPE}
              element={<DocumentType />}
            />
            <Route
              path={PATH.FINAL_DOCUMENT_TYPE}
              element={<FinalDocumentType />}
            />

            <Route path={PATH.OFFICE} element={<Office />} />
            <Route
              path={PATH.DOCUMENT_TRACKING}
              element={<DocumentTracking />}
            />
            <Route
              path={PATH.COMMITTEE}
              element={<Committee />}
            />
            <Route
              path={PATH.LEGISLATION}
              element={<Legislation />}
            />
            <Route
              path={PATH.OUTGOING_STATUS}
              element={<OutgoingStatus />}
            />
            <Route path={PATH.TERM} element={<Term />} />
            <Route
              path={PATH.PERSONNEL_CODE}
              element={<PersonnelCode />}
            />
            <Route
              path={PATH.USER_MANAGEMENT}
              element={<UserManagement />}
            />
          </Route>

          <Route
            element={<AccessGuard path={PATH.DRAFT_DOCUMENT} />}
          >
            <Route
              path={PATH.DRAFT_DOCUMENT}
              element={<DraftDocument />}
            />
            <Route
              path={PATH.FINAL_DOCUMENT}
              element={<FinalDocument />}
            />
            <Route
              path={PATH.DRAFT_DOCUMENT_CREATE}
              element={<DraftDocumentCreate />}
            />
            <Route
              path={PATH.DRAFT_DOCUMENT_EDIT}
              element={<DraftDocumentEdit />}
            />
          </Route>

          <Route element={<AccessGuard path={PATH.TRACKING} />}>
            <Route path={PATH.TRACKING} element={<Tracking />} />
          </Route>

          <Route
            element={
              <AccessGuard path={PATH.COMMITTEE_REFERRALS} />
            }
          >
            <Route
              path={PATH.COMMITTEE_REFERRALS}
              element={<CommitteeReferrals />}
            />
          </Route>
          <Route element={<AccessGuard path={PATH.REPORTS} />}>
            <Route path={PATH.REPORTS} element={<Reports />} />
          </Route>
          <Route
            element={
              <AccessGuard path={PATH.COMMITTEE_REPORTS} />
            }
          >
            <Route
              path={PATH.COMMITTEE_REPORTS}
              element={<CommitteeReports />}
            />
          </Route>

          <Route element={<AccessGuard path={PATH.SESSION} />}>
            <Route path={PATH.MINUTES} element={<Minutes />} />
          </Route>
          <Route
            element={
              <AccessGuard
                path={PATH.COMMITTEE_HEARING_MINUTES}
              />
            }
          >
            <Route
              path={PATH.COMMITTEE_HEARING_MINUTES}
              element={<CommitteeHearingMinutes />}
            />
          </Route>
          <Route
            element={<AccessGuard path={PATH.PUBLIC_HEARING} />}
          >
            <Route
              path={PATH.PUBLIC_HEARING}
              element={<PublicHearingMinutes />}
            />
          </Route>
          <Route
            element={
              <AccessGuard
                path={PATH.COMMITTEE_MEETING_MINUTES}
              />
            }
          >
            <Route
              path={PATH.COMMITTEE_MEETING_MINUTES}
              element={<CommitteeMeetingMinutes />}
            />
          </Route>
          <Route
            element={<AccessGuard path={PATH.TRANSCRIPT} />}
          >
            <Route
              path={PATH.TRANSCRIPT}
              element={<Transcript />}
            />
          </Route>
          <Route element={<AccessGuard path={PATH.SESSION} />}>
            <Route path={PATH.SESSION} element={<Session />} />
            <Route
              path={PATH.SESSION_JOIN}
              element={<SessionJoin />}
            />
          </Route>

          <Route
            path={PATH.ORDER_OF_BUSINESS_MANAGER}
            element={<OrderOfBusinessManager />}
          />

          <Route element={<AccessGuard path={PATH.CHAT} />}>
            <Route path={PATH.CHAT} element={<Conversations />}>
              <Route path=":chatId" element={<Chat />} />
            </Route>
          </Route>

          <Route element={<AccessGuard path={PATH.NOTES} />}>
            <Route path={PATH.NOTES} element={<Notes />}>
              <Route path=":id" element={<Note />} />
            </Route>
          </Route>

          <Route path={PATH.PROFILE} element={<Profile />} />

          <Route element={<AccessGuard path={PATH.ARCHIVE} />}>
            <Route path={PATH.ARCHIVE} element={<Archived />} />
          </Route>
        </Route>
        <Route path="logout" element={<LogoutPage />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </RoleAccessProvider>
  </AuthProvider>
)
export default EntryContainer

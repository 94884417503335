const DRAFT_DOCUMENT_ROUTE = '/draft-document'
const TERM_ROUTE = '/term/list'

const DOCUMENT_TYPE_OPTIONS = [
  {
    label: 'Resolution',
    value: 'RESOLUTION'
  },
  {
    label: 'Ordinance',
    value: 'ORDINANCE'
  }
]

export {
  DRAFT_DOCUMENT_ROUTE,
  DOCUMENT_TYPE_OPTIONS,
  TERM_ROUTE
}

import { formatDate } from 'utils/dateTime'
import { PRETTY_FORMAT } from 'utils/dateTime/constants'
import TableActions from './TableActions'

const columns = [
  {
    Header: 'Code',
    accessor: (val) => {
      let data = ''
      if (val && val.draftdocument) {
        data = val.draftdocument.draftCode
      }
      if (val && val.committeereport) {
        data = val.committeereport.code
      }
      return data
    }
  },
  {
    Header: 'Title',
    accessor: (val) => {
      let data = ''
      if (
        val &&
        val.draftdocument &&
        val.draftdocument.shortTitle
      ) {
        data = val.draftdocument.shortTitle
      }
      if (
        val &&
        val.draftdocument &&
        val.draftdocument.draftTitle &&
        !val.draftdocument.shortTitle
      ) {
        data = `${val.draftdocument.draftTitle.substring(
          0,
          50
        )}...`
      }

      if (
        val &&
        val.committeereport &&
        val.committeereport.shortTitle
      ) {
        data = val.committeereport.shortTitle
      }
      return data
    }
  },
  {
    Header: 'Date Submitted',
    accessor: (val) => {
      let data = ''
      if (val && val.draftdocument) {
        data = formatDate(
          val?.draftdocument?.effectiveDate,
          PRETTY_FORMAT
        )
      }
      if (val && val.committeereport) {
        data = formatDate(
          val?.committeereport?.createdAt,
          PRETTY_FORMAT
        )
      }
      return data
    }
  },
  {
    Header: 'Status',
    accessor: (val) => {
      let data = ''
      if (val && val.draftdocument) {
        data = val?.draftdocument.approved && 'Approved'
      }
      return data
    }
  },
  {
    Header: 'Action',
    accessor: 'id',
    fixed: 'right',
    Cell: TableActions
  }
]

export { columns }

import {
  FiHome,
  FiDatabase,
  FiFileText,
  FiCheckSquare,
  FiBookOpen,
  FiMessageSquare,
  FiArchive,
  FiUsers,
  FiBook,
  FiClock,
  FiFile
} from 'react-icons/fi'

export const PATH = {
  DASHBOARD: 'dashboard',
  MASTER_DATA: 'master-data',
  DESIGNATION: 'master-data/designation',
  DOCUMENT_TYPE: 'master-data/document-type',
  FINAL_DOCUMENT_TYPE: 'master-data/final-document-type',
  OFFICE: 'master-data/office',
  DOCUMENT_TRACKING: 'master-data/document-tracking',
  COMMITTEE: 'master-data/committee',
  COMMITTEE_REFERRALS: 'committee-referrals',
  COMMITTEE_REPORTS: 'committee-reports',
  LEGISLATION: 'master-data/legislation',
  OUTGOING_STATUS: 'master-data/outgoing-status',
  PERSONNEL_CODE: 'master-data/personnel-code',
  USER_MANAGEMENT: 'master-data/user-management',
  TERM: 'master-data/term',
  DRAFT_DOCUMENT: 'draft-document',
  DRAFT_DOCUMENT_CREATE: 'draft-document/create',
  DRAFT_DOCUMENT_EDIT: 'draft-document/edit/:id',
  SESSION: 'session',
  MINUTES: 'minutes',
  TRANSCRIPT: 'transcript',
  COMMITTEE_HEARING_MINUTES: 'committee-hearing-minutes',
  PUBLIC_HEARING: 'public-hearing-minutes',
  COMMITTEE_MEETING_MINUTES: 'committee-meeting-minutes',
  TRACKING: 'document-tracking',
  SESSION_JOIN: 'session/join/:id',
  FINAL_DOCUMENT: 'final-document',
  ORDER_OF_BUSINESS_MANAGER: 'session/order-of-business/:id',
  CHAT: 'chat',
  DOCUMENT: 'document',
  REPORTS: 'reports',
  NOTES: 'notes',
  PROFILE: 'profile',
  ARCHIVE: 'archive'
}

export const LINK_ITEMS = [
  { name: 'Dashboard', icon: FiHome, to: PATH.DASHBOARD },
  {
    name: 'Master Data',
    icon: FiDatabase,
    to: PATH.MASTER_DATA,
    subMenu: [
      {
        name: 'Designation',
        to: PATH.DESIGNATION
      },
      {
        name: 'Draft Document Type',
        to: PATH.DOCUMENT_TYPE
      },
      {
        name: 'Final Document Type',
        to: PATH.FINAL_DOCUMENT_TYPE
      },
      {
        name: 'Office',
        to: PATH.OFFICE
      },
      {
        name: 'Document Tracking',
        to: PATH.DOCUMENT_TRACKING
      },
      {
        name: 'Term',
        to: PATH.TERM
      },
      {
        name: 'Committee',
        to: PATH.COMMITTEE
      },
      {
        name: 'Legislation',
        to: PATH.LEGISLATION
      },
      {
        name: 'Outgoing Status',
        to: PATH.OUTGOING_STATUS
      },
      {
        name: 'Personnel Code',
        to: PATH.PERSONNEL_CODE
      },
      {
        name: 'User Management',
        to: PATH.USER_MANAGEMENT
      }
    ]
  },
  {
    name: 'Draft Document',
    icon: FiBookOpen,
    to: PATH.DRAFT_DOCUMENT
  },
  { name: 'Sessions', icon: FiUsers, to: PATH.SESSION },
  // {
  //   name: 'Final Document',
  //   icon: FiBookOpen,
  //   to: PATH.DOCUMENT
  // },
  {
    name: 'Committee Reports',
    icon: FiBook,
    to: PATH.COMMITTEE_REPORTS
  },
  {
    name: 'Committee Referrals',
    icon: FiBook,
    to: PATH.COMMITTEE_REFERRALS
  },
  { name: 'Session Minutes', icon: FiClock, to: PATH.MINUTES },
  {
    name: 'Public Hearing Minutes',
    icon: FiClock,
    to: PATH.PUBLIC_HEARING
  },
  {
    name: 'Committee Hearing Minutes',
    icon: FiClock,
    to: PATH.COMMITTEE_HEARING_MINUTES
  },
  {
    name: 'Committee Meeting Minutes',
    icon: FiClock,
    to: PATH.COMMITTEE_MEETING_MINUTES
  },
  {
    name: 'Transcript',
    icon: FiClock,
    to: PATH.TRANSCRIPT
  },
  {
    name: 'Legislative Tracking',
    icon: FiCheckSquare,
    to: PATH.TRACKING
  },
  {
    name: 'Final Document',
    icon: FiFile,
    to: PATH.FINAL_DOCUMENT
  },
  { name: 'Chat', icon: FiMessageSquare, to: PATH.CHAT },
  { name: 'Archive', icon: FiArchive, to: PATH.ARCHIVE },
  { name: 'Notes', icon: FiFileText, to: PATH.NOTES },
  {
    name: 'Reports',
    icon: FiDatabase,
    to: PATH.REPORTS
  }
]

export const ANY_ROLE_ACCESSIBLE_ROUTES = [
  PATH.DASHBOARD,
  PATH.PROFILE
]

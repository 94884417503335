import React from 'react'
import { TagLabelSt, TagSt } from './styles'

const Tag = ({ children, ...rest }) => {
  return (
    <TagSt {...rest} px="5">
      <TagLabelSt fontSize="xs">{children}</TagLabelSt>
    </TagSt>
  )
}

export default Tag

import useSWR from 'swr'
import { deleteRequest, postRequest } from 'utils/request'
import errorHandler from 'utils/errorHandler'
import { buildUrl } from 'utils/transformers/apiUrl'
import { useCallback, useState } from 'react'
import {
  USERS_ROUTE,
  DELETE_FILE_ROUTE,
  DELETE_DOCFILE_ROUTE
} from './constants'
import { TERM_ROUTE } from '../DraftDocumentCreate/constants'

const useUsers = () => {
  const {
    data: users,
    error: usersError,
    isValidating: usersIsValidating,
    mutate: usersRevalidate
  } = useSWR(USERS_ROUTE)

  const usersIsLoading = !users && !usersError

  return {
    users,
    usersError,
    usersIsValidating,
    usersIsLoading,
    usersRevalidate
  }
}
const useTerm = () => {
  const url = buildUrl({
    baseUrl: `${TERM_ROUTE}`
  })

  const {
    data: term,
    error: termError,
    isValidating: termIsValidating,
    mutate: termRevalidate
  } = useSWR(url)

  const termIsLoading = !term && !termError && termIsValidating

  return {
    term,
    termError,
    termIsValidating,
    termIsLoading,
    termRevalidate
  }
}

const useMutateDeleteFile = () => {
  const [response, setResponse] = useState()
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const resetState = useCallback(() => {
    if (response) setResponse(undefined)
    if (isLoading) setError(undefined)
  }, [isLoading, response])

  const deleteFile = useCallback(
    async (id, file) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await deleteRequest(
          `${DELETE_FILE_ROUTE}/${id}/${file}`
        )
        setResponse(data)
        setIsLoading(false)
        window.location.reload()
        return data
      } catch (err) {
        setError(errorHandler(err))
        setIsLoading(false)
      }
      return null
    },
    [resetState]
  )

  const deleteDocumentFile = useCallback(
    async (id, file) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await deleteRequest(
          `${DELETE_DOCFILE_ROUTE}/${id}/${file}`
        )
        setResponse(data)
        setIsLoading(false)
        window.location.reload()
        return data
      } catch (err) {
        setError(errorHandler(err))
        setIsLoading(false)
      }
      return null
    },
    [resetState]
  )

  return {
    data: response,
    error,
    isLoading,
    deleteFile,
    deleteDocumentFile
  }
}

const createUser = async (payload) => {
  try {
    const user = await postRequest(USERS_ROUTE, payload)
    return user
  } catch (error) {
    return errorHandler(error)
  }
}

export { useUsers, createUser, useTerm, useMutateDeleteFile }

import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
  colors: {
    brand: {
      50: 'rgba(0,0,0,0.1)', // outline hover
      100: '#F2F3F4',
      200: '#E1E4E8',
      300: 'rgba(0,0,0,0.5)', // focus ring
      500: '#1b1f23', // default bg
      600: '#3D4044' // default hover
    },

    tertiary: '#F8F8Fa'
  },
  fonts: {
    heading: 'Inter',
    body: 'Inter'
  }
})

export default theme

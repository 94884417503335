import useSWR from 'swr'
import {
  deleteRequest,
  postRequest,
  putRequest
} from 'utils/request'
import errorHandler from 'utils/errorHandler'
import { useCallback, useState } from 'react'
import { buildUrl } from 'utils/transformers/apiUrl'
import { DOCUMENT_TRACKING_ROUTE } from './constants'

const useDocumentTracking = ({ page, id, search } = {}) => {
  const url = buildUrl({
    baseUrl: DOCUMENT_TRACKING_ROUTE,
    search,
    page,
    id
  })

  const {
    data: documentTracking,
    error: documentTrackingError,
    isValidating: documentTrackingIsValidating,
    mutate: documentTrackingRevalidate
  } = useSWR(url)

  const documentTrackingIsLoading =
    !documentTracking &&
    !documentTrackingError &&
    documentTrackingIsValidating

  return {
    documentTracking,
    documentTrackingError,
    documentTrackingIsValidating,
    documentTrackingIsLoading,
    documentTrackingRevalidate
  }
}

const useMutateDocumentTracking = () => {
  const [response, setResponse] = useState()
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const resetState = useCallback(() => {
    if (response) setResponse(undefined)
    if (isLoading) setError(undefined)
  }, [isLoading, response])

  const createDocumentTracking = useCallback(
    async (payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await postRequest(
          DOCUMENT_TRACKING_ROUTE,
          payload
        )
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  const updateDocumentTracking = useCallback(
    async (id, payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await putRequest(
          `${DOCUMENT_TRACKING_ROUTE}/${id}`,
          payload
        )
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  const deleteDocumentTracking = useCallback(
    async (id) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await deleteRequest(
          `${DOCUMENT_TRACKING_ROUTE}/${id}`
        )
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  return {
    data: response,
    error,
    isLoading,
    createDocumentTracking,
    updateDocumentTracking,
    deleteDocumentTracking
  }
}

export { useDocumentTracking, useMutateDocumentTracking }

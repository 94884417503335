import { useCallback, useState } from 'react'
import errorHandler from 'utils/errorHandler'

import { postRequest } from 'utils/request'

import { LOGIN_ROUTE } from './constants'

const useLogin = () => {
  const [response, setResponse] = useState()
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const login = useCallback(async (payload = {}) => {
    setError(undefined)
    setIsLoading(true)
    try {
      const data = await postRequest(LOGIN_ROUTE, payload)
      setResponse(data)
    } catch (err) {
      setError(errorHandler(err))
    }
    setIsLoading(false)
  }, [])

  return {
    data: response,
    error,
    login,
    isLoading
  }
}

export { useLogin }

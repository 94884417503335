// Chakra imports
import React from 'react'
import {
  Flex,
  IconButton,
  Spacer,
  Stat,
  StatLabel,
  StatNumber
} from '@chakra-ui/react'
// Custom components
import { FiCheck } from 'react-icons/fi'
import { FaList } from 'react-icons/fa'
import Card from '../Card/Card'
import CardBody from '../Card/CardBody'

const MiniStatistics = ({ title, amount }) => {
  return (
    <Card minH="70px" maxW="350px" variant="outline">
      <CardBody>
        <Flex minWidth="max-content" alignItems="center" gap="2">
          {title === 'PENDING' ? (
            <IconButton
              variant="outline"
              colorScheme="teal"
              icon={<FaList />}
            />
          ) : (
            <IconButton
              variant="outline"
              colorScheme="teal"
              icon={<FiCheck />}
            />
          )}
          <Spacer />
          <Stat>
            <StatLabel wordBreak={false}>{title}</StatLabel>
            <StatNumber>{amount}</StatNumber>
          </Stat>
        </Flex>
      </CardBody>
    </Card>
  )
}

export default MiniStatistics

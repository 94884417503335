import TableActions from './TableActions'

const columns = [
  {
    Header: 'Description',
    accessor: 'description'
  },
  {
    Header: 'From',
    accessor: 'from'
  },
  {
    Header: 'To',
    accessor: 'to'
  },
  {
    Header: 'Action',
    accessor: 'id',
    fixed: 'right',
    Cell: TableActions
  }
]

export { columns }

import { useToast as useChakraToast } from '@chakra-ui/react'

const useToast = () => {
  const chakraToast = useChakraToast()

  const toast = (config) =>
    chakraToast({
      duration: 5000,
      isClosable: true,
      position: 'top-right',
      ...config
    })

  return toast
}

export default useToast

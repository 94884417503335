/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-useless-fragment */
import { Tooltip } from '@chakra-ui/react'
import styled from '@emotion/styled'
import Avatar from 'components/Avatar'
import React from 'react'

const AvatarWrapperSt = styled.div`
  .chakra-avatar__excess {
    justify-content: flex-end;
    font-size: 12px;
    padding: 4px;
  }
`

const Members = ({ value }) => {
  if (!value) return null

  const avatars = value.map(({ label, profile }) => ({
    name: label,
    src: profile
  }))
  // console.log(value)
  const val = value
    .map(
      (data) =>
        (data && data.label) ||
        (data &&
          data.user &&
          data.user.firstName &&
          data.user.firstName + ' ' + data.user.lastName) ||
        (data && data.committee && data.committee.description)
    )
    .join(', ')

  return (
    <>
      {avatars.length > 0 && (
        <Tooltip label={val} fontSize="xs">
          <AvatarWrapperSt>
            <Avatar avatars={avatars} />
          </AvatarWrapperSt>
        </Tooltip>
      )}
    </>
  )
}

export default React.memo(Members)

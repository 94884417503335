import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { Flex, Stack } from 'components/Layout'
import Button from 'components/Button'
import Form from 'components/Form'
import FormField from 'components/FormField'
import Input from 'components/Input'
import ReactSelect from 'components/ReactSelect'
import Switch from 'components/Switch'

import { model } from './schema'

const Create = ({ onClose, usersOptions, onSubmit, term }) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting, isDirty }
  } = useForm({
    resolver: yupResolver(model)
  })

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      disabled={isSubmitting}
    >
      <Stack spacing={6} pb={2}>
        <FormField
          id="term"
          isInvalid={errors.term}
          error={errors.term}
          label="Term"
        >
          <ReactSelect
            id="term"
            name="term"
            placeholder="Select term..."
            control={control}
            options={term?.data || []}
          />
        </FormField>
        <FormField
          id="code"
          isInvalid={errors.code}
          error={errors.code}
          label="Code"
        >
          <Input
            id="code"
            placeholder="Code"
            {...register('code')}
          />
        </FormField>
        <FormField
          id="description"
          isInvalid={errors.description}
          error={errors.description}
          label="Description"
        >
          <Input
            id="description"
            placeholder="Description"
            {...register('description')}
          />
        </FormField>
        <Flex>
          <FormField
            id="chairman"
            mr={4}
            isInvalid={errors.chairman}
            error={errors.chairman}
            label="Chairperson"
          >
            <ReactSelect
              id="chairman"
              name="chairman"
              placeholder="Select Chairperson..."
              control={control}
              options={usersOptions}
            />
          </FormField>

          <FormField
            id="viceChairman"
            isInvalid={errors.viceChairman}
            error={errors.viceChairman}
            label="Vice Chairperson"
          >
            <ReactSelect
              id="viceChairman"
              name="viceChairman"
              placeholder="Select Vice Chairperson..."
              control={control}
              options={usersOptions}
            />
          </FormField>
        </Flex>

        <FormField
          id="member"
          isInvalid={errors.member}
          error={errors.member}
          label="Members"
        >
          <ReactSelect
            id="member"
            name="member"
            placeholder="Select Members..."
            control={control}
            isMulti
            options={usersOptions}
          />
        </FormField>
        <FormField
          id="status"
          isInvalid={errors.status}
          error={errors.status}
          direction="horizontal"
          label="Active"
        >
          <Switch
            id="status"
            name="status"
            {...register('status')}
          />
        </FormField>

        <Flex mt={5} justify="flex-end">
          <Button mr={2} variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            isLoading={isSubmitting}
            disabled={isSubmitting || !isDirty}
            type="submit"
          >
            Submit
          </Button>
        </Flex>
      </Stack>
    </Form>
  )
}

export default Create

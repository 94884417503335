const USERS_ROUTE = '/users'
const USERS_OPTIONS_ROUTE = '/users/list'
const ACCESS_ROUTE = '/users/access'

const ROLE_OPTIONS = [
  {
    label: 'Master Data',
    value: 'Master Data'
  },
  {
    label: 'Draft Document',
    value: 'Draft Document'
  },
  {
    label: 'Sessions',
    value: 'Sessions'
  },
  {
    label: 'Minutes',
    value: 'Minutes'
  },
  {
    label: 'Document Tracking',
    value: 'Document Tracking'
  },
  {
    label: 'Committee Reports',
    value: 'Committee Reports'
  },
  {
    label: 'Chat',
    value: 'Chat'
  },
  {
    label: 'Archive',
    value: 'Archive'
  },
  {
    label: 'Notes',
    value: 'Notes'
  }
]

const ACCESS_OPTIONS = [
  {
    label: 'create',
    value: 'create'
  },
  {
    label: 'view',
    value: 'view'
  },
  {
    label: 'update',
    value: 'update'
  },
  {
    label: 'delete',
    value: 'delete'
  }
]

export {
  USERS_ROUTE,
  USERS_OPTIONS_ROUTE,
  ROLE_OPTIONS,
  ACCESS_OPTIONS,
  ACCESS_ROUTE
}

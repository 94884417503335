import { IconButton } from '@chakra-ui/react'
import Text from 'components/Text'
import React from 'react'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { noop } from 'utils/helpers'
import { PaginationSt } from './styles'

const Pagination = ({
  onPrevClick,
  onNextClick,
  page,
  totalPages,
  nextDisabled,
  prevDisabled
}) => {
  return (
    <PaginationSt alignItems="center" justify="flex-end">
      <IconButton
        disabled={prevDisabled || page === 1}
        onClick={onPrevClick}
        variant="ghost"
        colorScheme="teal"
        aria-label="Send email"
        isRound
        size="sm"
        icon={<FiChevronLeft />}
      />
      <IconButton
        disabled={nextDisabled || !totalPages}
        onClick={onNextClick}
        variant="ghost"
        colorScheme="teal"
        aria-label="Send email"
        isRound
        size="sm"
        icon={<FiChevronRight />}
      />
      <Text fontSize="sm">
        Page {page} of {totalPages || 1}
      </Text>
    </PaginationSt>
  )
}

Pagination.defaultProps = {
  page: 1,
  totalPages: 1,
  onPrevClick: noop,
  onNextClick: noop
}

export default Pagination

import useSWR from 'swr'
import {
  deleteRequest,
  postRequest,
  putRequest
} from 'utils/request'
import errorHandler from 'utils/errorHandler'
import { useCallback, useState } from 'react'
import { buildUrl } from 'utils/transformers/apiUrl'
import { NOTES_ROUTE, NOTES_ROUTE_LIST } from './constants'

const useNotes = ({ page, id } = {}) => {
  const url = buildUrl({
    baseUrl: NOTES_ROUTE_LIST,
    page,
    id
  })

  const {
    data: notes,
    error: notesError,
    isValidating: notesIsValidating,
    mutate: notesRevalidate
  } = useSWR(url)

  const notesIsLoading =
    !notes && !notesError && notesIsValidating

  return {
    notes,
    notesError,
    notesIsValidating,
    notesIsLoading,
    notesRevalidate
  }
}

const useNote = ({ id } = {}) => {
  const url = buildUrl({
    baseUrl: NOTES_ROUTE,
    id
  })

  const {
    data: note,
    error: noteError,
    isValidating: noteIsValidating,
    mutate: noteRevalidate
  } = useSWR(url)

  const noteIsLoading = !note && !noteError && noteIsValidating

  return {
    note,
    noteError,
    noteIsValidating,
    noteIsLoading,
    noteRevalidate
  }
}

const useMutateNotes = () => {
  const [response, setResponse] = useState()
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const resetState = useCallback(() => {
    if (response) setResponse(undefined)
    if (isLoading) setError(undefined)
  }, [isLoading, response])

  const createNote = useCallback(
    async (payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await postRequest(NOTES_ROUTE, payload)
        setResponse(data)
        setIsLoading(false)
        return data
      } catch (err) {
        setError(errorHandler(err))
        setIsLoading(false)
      }
      return null
    },
    [resetState]
  )

  const updateNote = useCallback(
    async (id, payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await putRequest(
          `${NOTES_ROUTE}/${id}`,
          payload
        )
        setResponse(data)
        setIsLoading(false)
        return data
      } catch (err) {
        setError(errorHandler(err))
        setIsLoading(false)
      }
      return null
    },
    [resetState]
  )

  const deleteNote = useCallback(
    async (id) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await deleteRequest(`${NOTES_ROUTE}/${id}`)
        setResponse(data)
        setIsLoading(false)
        return data
      } catch (err) {
        setError(errorHandler(err))
        setIsLoading(false)
      }
      return null
    },
    [resetState]
  )

  return {
    data: response,
    error,
    isLoading,
    createNote,
    updateNote,
    deleteNote
  }
}

export { useNotes, useMutateNotes, useNote }

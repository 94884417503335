import React from 'react'

import Button from 'components/Button'
import { HStack } from 'components/Layout'

const SessionAction = ({
  onDocument,
  onRefer,
  refer,
  onView,
  viewDocument,
  hideRefer
}) => {
  return (
    <HStack direction="row">
      <Button
        colorScheme="teal"
        variant="outline"
        size="sm"
        onClick={onView}
        _focus={{
          boxShadow: '0 0 0 3px rgba(49, 151, 149, 0.5)'
        }}
      >
        View
      </Button>
      {viewDocument && (
        <Button
          colorScheme="teal"
          variant="outline"
          size="sm"
          onClick={onDocument}
          _focus={{
            boxShadow: '0 0 0 3px rgba(49, 151, 149, 0.5)'
          }}
        >
          Attachments
        </Button>
      )}
      {refer && !hideRefer && (
        <Button
          size="sm"
          onClick={onRefer}
          _focus={{
            boxShadow: '0 0 0 3px rgba(49, 151, 149, 0.5)'
          }}
        >
          Refer to Committee
        </Button>
      )}
    </HStack>
  )
}

export default SessionAction

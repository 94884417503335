import React from 'react'
import { TextSt } from './styles'

const Text = React.forwardRef(
  ({ children, ...rest }, forwardedRef) => {
    return (
      <TextSt ref={forwardedRef} {...rest}>
        {children}
      </TextSt>
    )
  }
)

export default Text

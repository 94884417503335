import useSWR from 'swr'
import {
  deleteRequest,
  postRequest,
  putRequest
} from 'utils/request'
import errorHandler from 'utils/errorHandler'
import { useCallback, useState } from 'react'
import { buildUrl } from 'utils/transformers/apiUrl'
import {
  DRAFT_DOCUMENT_ROUTE,
  OUTGOING_STATUS_ROUTE,
  USERS_ROUTE,
  COMMITTEE_ROUTE,
  COMMITTEE_LIST_ROUTE,
  SESSION_ROUTE,
  PROPOSAL_ROUTE
} from './constants'

const useSession = () => {
  const url = buildUrl({
    baseUrl: `${SESSION_ROUTE}/list`
  })

  const {
    data: session,
    error: sessionError,
    isValidating: sessionIsValidating,
    mutate: sessionRevalidate
  } = useSWR(url)

  const sessionIsLoading =
    !session && !sessionError && sessionIsValidating

  return {
    session,
    sessionError,
    sessionIsValidating,
    sessionIsLoading,
    sessionRevalidate
  }
}

const useDraft = ({ page, id } = {}) => {
  const url = buildUrl({
    baseUrl: `${DRAFT_DOCUMENT_ROUTE}/list`,
    page,
    id
  })

  const {
    data: draft,
    error: draftError,
    isValidating: draftIsValidating,
    mutate: draftRevalidate
  } = useSWR(url)

  const draftIsLoading =
    !draft && !draftError && draftIsValidating

  return {
    draft,
    draftError,
    draftIsValidating,
    draftIsLoading,
    draftRevalidate
  }
}

const useCommitteeList = () => {
  const url = buildUrl({
    baseUrl: `${COMMITTEE_LIST_ROUTE}/list`
  })

  const {
    data: committeeList,
    error: committeeListError,
    isValidating: committeeListIsValidating,
    mutate: committeeListRevalidate
  } = useSWR(url)

  const committeeListIsLoading =
    !committeeList &&
    !committeeListError &&
    committeeListIsValidating

  return {
    committeeList,
    committeeListError,
    committeeListIsValidating,
    committeeListIsLoading,
    committeeListRevalidate
  }
}

const useStatus = ({ page, id } = {}) => {
  const url = buildUrl({
    baseUrl: `${OUTGOING_STATUS_ROUTE}/list`,
    page,
    id
  })

  const {
    data: status,
    error: statusError,
    isValidating: statusIsValidating,
    mutate: statusRevalidate
  } = useSWR(url)

  const statusIsLoading =
    !status && !statusError && statusIsValidating

  return {
    status,
    statusError,
    statusIsValidating,
    statusIsLoading,
    statusRevalidate
  }
}

const useUsers = ({ page, id } = {}) => {
  const url = buildUrl({
    baseUrl: `${USERS_ROUTE}/list`,
    page,
    id
  })

  const {
    data: users,
    error: usersError,
    isValidating: usersIsValidating,
    mutate: usersRevalidate
  } = useSWR(url)

  const usersIsLoading =
    !users && !usersError && usersIsValidating

  return {
    users,
    usersError,
    usersIsValidating,
    usersIsLoading,
    usersRevalidate
  }
}

const useMutateSession = () => {
  const [response, setResponse] = useState()
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const resetState = useCallback(() => {
    if (response) setResponse(undefined)
    if (isLoading) setError(undefined)
  }, [isLoading, response])

  const createSession = useCallback(
    async (payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await postRequest(PROPOSAL_ROUTE, payload)
        setResponse(data)
        setIsLoading(false)
        return data
      } catch (err) {
        setError(errorHandler(err))
        setIsLoading(false)
      }
      return null
    },
    [resetState]
  )
  return {
    data: response,
    error,
    isLoading,
    createSession
  }
}

const useCommitteeReports = ({ page, id, search } = {}) => {
  const url = buildUrl({
    baseUrl: COMMITTEE_ROUTE,
    search,
    page,
    id
  })

  const {
    data: committeeReports,
    error: committeeReportsError,
    isValidating: committeeReportsIsValidating,
    mutate: committeeReportsRevalidate
  } = useSWR(url)

  const committeeReportsIsLoading =
    !committeeReports &&
    !committeeReportsError &&
    committeeReportsIsValidating

  return {
    committeeReports,
    committeeReportsError,
    committeeReportsIsValidating,
    committeeReportsIsLoading,
    committeeReportsRevalidate
  }
}

const useMutateCommitteeReports = () => {
  const [response, setResponse] = useState()
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const resetState = useCallback(() => {
    if (response) setResponse(undefined)
    if (isLoading) setError(undefined)
  }, [isLoading, response])

  const createCommitteeReports = useCallback(
    async (payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await postRequest(COMMITTEE_ROUTE, payload)
        setResponse(data)
        setIsLoading(false)
        return data
      } catch (err) {
        setError(errorHandler(err))
        setIsLoading(false)
      }
      return null
    },
    [resetState]
  )

  const updateCommitteeReports = useCallback(
    async (id, payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await putRequest(
          `${COMMITTEE_ROUTE}/${id}`,
          payload
        )
        setResponse(data)
        setIsLoading(false)
        return data
      } catch (err) {
        setError(errorHandler(err))
        setIsLoading(false)
      }
      return null
    },
    [resetState]
  )

  const deleteCommitteeReports = useCallback(
    async (id) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await deleteRequest(
          `${COMMITTEE_ROUTE}/${id}`
        )
        setResponse(data)
        setIsLoading(false)
        return data
      } catch (err) {
        setError(errorHandler(err))
        setIsLoading(false)
      }
      return null
    },
    [resetState]
  )

  return {
    data: response,
    error,
    isLoading,
    createCommitteeReports,
    updateCommitteeReports,
    deleteCommitteeReports
  }
}

const useMutateUpdateCommitteeReports = () => {
  const [response, setResponse] = useState()
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const resetState = useCallback(() => {
    if (response) setResponse(undefined)
    if (isLoading) setError(undefined)
  }, [isLoading, response])

  const updateCommitteeReportsFile = useCallback(
    async (id, payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await putRequest(
          `${COMMITTEE_ROUTE}/file/${id}`,
          payload
        )
        setResponse(data)
        setIsLoading(false)
        return data
      } catch (err) {
        setError(errorHandler(err))
        setIsLoading(false)
      }
      return null
    },
    [resetState]
  )

  return {
    data: response,
    error,
    isLoading,
    updateCommitteeReportsFile
  }
}

export {
  useCommitteeReports,
  useMutateCommitteeReports,
  useUsers,
  useStatus,
  useDraft,
  useCommitteeList,
  useSession,
  useMutateSession,
  useMutateUpdateCommitteeReports
}

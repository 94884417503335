import TableActions from './TableActions'

const columns = [
  {
    Header: 'Email Address',
    accessor: 'email'
  },
  {
    Header: 'Active',
    accessor: (val) => {
      let data = 'Inactive'
      if (val.personnel && val.personnel.status) {
        data = 'Active'
      }
      return data
    }
  },
  {
    Header: 'Action',
    accessor: 'id',
    fixed: 'right',
    Cell: TableActions
  }
]

const data = [
  {
    code: 'XYT123',
    description: 'Loremn Ipsum'
  },
  {
    code: 'XYT123',
    description: 'Loremn Ipsum'
  },
  {
    code: 'XYT123',
    description: 'Loremn Ipsum'
  }
]

export { columns, data }

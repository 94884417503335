import React from 'react'
import { useDisclosure } from '@chakra-ui/react'

import Modal from 'components/Modal'
import { ActionsRenderer } from 'components/CellRenderers'
import useRoleAccessContext from 'containers/RoleAccess/useRoleAccessContext'
import AlertDialog from 'components/AlertDialog'

import Edit from './Forms/Edit'
import {
  useUsers,
  useOffice,
  useDraft,
  useMutateTracking
} from './api'
import { useTrackingContext } from './Context'

const TableActions = ({ row, value: id }) => {
  const { access = [] } = useRoleAccessContext()
  const { original } = row
  const { updateTracking, deleteTracking, isLoading } =
    useMutateTracking()
  const { revalidate } = useTrackingContext()

  const { users } = useUsers()
  const { office } = useOffice()
  const { draft } = useDraft()

  const {
    isOpen: editIsOpen,
    onOpen: editOnOpen,
    onClose: editOnClose
  } = useDisclosure()
  const {
    isOpen: deleteIsOpen,
    onOpen: deleteOnOpen,
    onClose: deleteOnClose
  } = useDisclosure()

  const handleEdit = async (newValue) => {
    await updateTracking(id, newValue)

    revalidate()
    editOnClose()
  }

  const handleDelete = async () => {
    await deleteTracking(id)

    revalidate()
    deleteOnClose()
  }

  return (
    <>
      <ActionsRenderer
        onEditClick={editOnOpen}
        onDeleteClick={deleteOnOpen}
        values={original}
        edit={
          access['document-tracking'] &&
          access['document-tracking'].includes('edit')
        }
        delete={
          access['document-tracking'] &&
          access['document-tracking'].includes('delete')
        }
      />

      <Modal
        title="Edit Tracking"
        isOpen={editIsOpen}
        onClose={editOnClose}
        withActionButtons={false}
        closeOnOverlayClick={false}
        size="4xl"
      >
        <Edit
          onClose={editOnClose}
          onSubmit={handleEdit}
          values={original}
          users={users}
          office={office}
          draft={draft}
        />
      </Modal>

      <AlertDialog
        isLoading={isLoading}
        isOpen={deleteIsOpen}
        onClose={deleteOnClose}
        onConfirm={handleDelete}
        title="Delete Tracking"
      />
    </>
  )
}

export default TableActions

import styled from '@emotion/styled'

const EditorWrapperSt = styled.div`
  .Toolbar {
    border-color: var(--chakra-colors-brand-200);
    margin-bottom: 10px;

    .rdw-option-wrapper {
      padding: 10.5px;
      height: 35px;
      border: 0;
      border-radius: 5px;

      &:hover {
        box-shadow: none;
        background-color: var(--chakra-colors-gray-100);
      }
    }

    .rdw-option-active {
      box-shadow: none;
      background-color: var(--chakra-colors-gray-200);
    }

    .rdw-dropdown-wrapper {
      height: 35px;
      border-color: var(--chakra-colors-brand-200);

      &:hover {
        box-shadow: none;
        background-color: var(--chakra-colors-gray-100);
      }
    }

    .rdw-dropdown-selectedtext {
      padding: 0 10px;
    }

    .rdw-block-dropdown {
      min-width: 135px;
    }

    .rdw-fontsize-dropdown {
      min-width: 55px;
    }
  }

  .Editor {
    border: 1px solid var(--chakra-colors-brand-200);
    min-height: ${({ minH }) => (minH ? `${minH}px` : 'unset')};
  }
`

export { EditorWrapperSt }

import React from 'react'
import { FiEdit2 } from 'react-icons/fi'
import { Tooltip } from '@chakra-ui/react'
import Button from 'components/Button'
import { HStack } from 'components/Layout'

const CommitteeReportActions = ({ onEditClick }) => {
  return (
    <HStack direction="row">
      <Tooltip label="Submit" fontSize="xs">
        <Button
          colorScheme="green"
          onClick={onEditClick}
          variant="outline"
          size="sm"
        >
          <FiEdit2 />
        </Button>
      </Tooltip>
    </HStack>
  )
}

export default React.memo(CommitteeReportActions)

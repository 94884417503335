import React, {
  useMemo,
  useRef,
  useState,
  useEffect
} from 'react'
import { useParams } from 'react-router-dom'

import useToast from 'utils/hooks/useToast'
import { formatToISOString } from 'utils/dateTime'
import {
  filterUnmatchedKeys,
  generateFormData
} from 'utils/helpers'

import PageWrapper from 'layout/PageWrapper'

import { Box, Flex } from 'components/Layout'
import Button from 'components/Button'
import Loader from 'components/Loader'

import {
  useDraftDocument,
  useMutateDraftDocument,
  useCommittee
} from '../DraftDocument/api'
import { useTerm } from './api'
import { useDocumentTypeOptions } from '../DocumentType/api'
import { useUsersOptions } from '../UserManagement/api'
import Form from './Form'
import { initialValues } from './schema'

const DraftDocumentEdit = () => {
  const { id } = useParams()
  const { draftDocument } = useDraftDocument({ id })
  const { term } = useTerm()
  const { documentTypeOptions } = useDocumentTypeOptions()
  const { updateDraftDocument } = useMutateDraftDocument()
  const { usersOptions } = useUsersOptions()
  const { committee } = useCommittee()
  const toast = useToast()

  const {
    author: authors,
    coAuthor: coAuthors,
    ...values
  } = draftDocument?.data || {}

  const formRef = useRef(null)

  const [formState, setFormState] = useState({})
  const [author, setAuthor] = useState([])
  const [coAuthor, setCoAuthor] = useState([])
  const { isSubmitting } = formState

  const defaultValues = useMemo(() => {
    return filterUnmatchedKeys(
      Object.keys(initialValues),
      values
    )
  }, [values])

  const defaultValuesLoaded = useMemo(
    () => draftDocument?.data,
    [draftDocument?.data]
  )

  const handleSubmitClick = () => {
    formRef.current.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true })
    )
  }

  const onSubmit = async (formValues) => {
    const { file = [], documentFile = [], ...rest } = formValues
    const finalAuthor = author.map(({ value }) => value)
    const finalCoAuthor = coAuthor.map(({ value }) => value)

    const payload = {
      dateFiled: formatToISOString(new Date()),
      file: file && file.length > 0 ? file[0] : null,
      documentFile:
        documentFile && documentFile.length > 0
          ? documentFile[0]
          : null,
      author: finalAuthor,
      coAuthor: finalCoAuthor,
      ...rest
    }

    const data = await updateDraftDocument(
      id,
      generateFormData(payload)
    )
    if (!data) return

    toast({
      title: 'Draft document edited.',
      status: 'success',
      description: "We've edited your draft document."
    })
    window.location.reload()
  }

  useEffect(() => {
    if (authors && authors.length > 0) {
      setAuthor(authors)
    }
    if (coAuthors && coAuthors.length > 0) {
      setCoAuthor(coAuthors)
    }
  }, [authors, coAuthors])

  return (
    <Box>
      <PageWrapper
        label="Edit Draft Document"
        headerRightComponent={
          <Button
            onClick={handleSubmitClick}
            isLoading={isSubmitting}
            disabled={isSubmitting}
          >
            Confirm Edit
          </Button>
        }
      >
        {defaultValuesLoaded ? (
          <>
            <Form
              formRef={formRef}
              onSubmit={onSubmit}
              values={defaultValues}
              documentTypeOptions={documentTypeOptions}
              setFormState={setFormState}
              usersOptions={usersOptions}
              committee={committee}
              author={author}
              setAuthor={setAuthor}
              term={term}
              id={id}
              coAuthor={coAuthor}
              setCoAuthor={setCoAuthor}
            />
            <Flex mt={5} justify="flex-end">
              <Button
                onClick={handleSubmitClick}
                isLoading={isSubmitting}
                disabled={isSubmitting}
              >
                Confirm Edit
              </Button>
            </Flex>
          </>
        ) : (
          <Loader />
        )}
      </PageWrapper>
    </Box>
  )
}

export default DraftDocumentEdit

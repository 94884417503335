import {
  FormControl,
  FormErrorMessage,
  FormLabel
} from '@chakra-ui/react'
import styled from '@emotion/styled'

const FormControlSt = styled(FormControl)``

const FormLabelSt = styled(FormLabel)`
  &[data-invalid] + .react-datepicker-wrapper {
    .react-datepicker__input-container {
      border-color: #e53e3e;
      box-shadow: 0 0 0 1px #e53e3e;
    }
  }
`

const FormErrorMessageSt = styled(FormErrorMessage)``

export { FormControlSt, FormLabelSt, FormErrorMessageSt }

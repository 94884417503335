import useSWR from 'swr'
import {
  deleteRequest,
  postRequest,
  putRequest
} from 'utils/request'
import errorHandler from 'utils/errorHandler'
import { useCallback, useState } from 'react'
import { buildUrl } from 'utils/transformers/apiUrl'
import { OUTGOING_STATUS_ROUTE } from './constants'

const useOutgoingStatus = ({ page, id, search } = {}) => {
  const url = buildUrl({
    baseUrl: OUTGOING_STATUS_ROUTE,
    search,
    page,
    id
  })

  const {
    data: outgoingStatus,
    error: outgoingStatusError,
    isValidating: outgoingStatusIsValidating,
    mutate: outgoingStatusRevalidate
  } = useSWR(url)

  const outgoingStatusIsLoading =
    !outgoingStatus &&
    !outgoingStatusError &&
    outgoingStatusIsValidating

  return {
    outgoingStatus,
    outgoingStatusError,
    outgoingStatusIsValidating,
    outgoingStatusIsLoading,
    outgoingStatusRevalidate
  }
}

const useMutateOutgoingStatus = () => {
  const [response, setResponse] = useState()
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const resetState = useCallback(() => {
    if (response) setResponse(undefined)
    if (isLoading) setError(undefined)
  }, [isLoading, response])

  const createOutgoingStatus = useCallback(
    async (payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await postRequest(
          OUTGOING_STATUS_ROUTE,
          payload
        )
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  const updateOutgoingStatus = useCallback(
    async (id, payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await putRequest(
          `${OUTGOING_STATUS_ROUTE}/${id}`,
          payload
        )
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  const deleteOutgoingStatus = useCallback(
    async (id) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await deleteRequest(
          `${OUTGOING_STATUS_ROUTE}/${id}`
        )
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  return {
    data: response,
    error,
    isLoading,
    createOutgoingStatus,
    updateOutgoingStatus,
    deleteOutgoingStatus
  }
}

export { useOutgoingStatus, useMutateOutgoingStatus }

import React, { useState } from 'react'
import { useDisclosure } from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'

import { Box } from 'components/Layout'
import Modal from 'components/Modal'
import Table from 'components/Table'
import Button from 'components/Button'
import useRoleAccessContext from 'containers/RoleAccess/useRoleAccessContext'
import PageWrapper from 'layout/PageWrapper'
import useRoutePagination from 'utils/hooks/useRoutePagination'
import TableHeader from 'components/Table/TableHeader'
import useSearch from 'utils/hooks/useSearch'
import Create from './Forms/Create'
import { TrackingProvider } from './Context'
import { columns } from './columns'
import {
  useTracking,
  useMutateTracking,
  useUsers,
  useOffice,
  useDraft,
  useFinalDocumentType
} from './api'

const Tracking = () => {
  const [filter, setFilter] = useState('')
  const { page } = useRoutePagination()
  const [search, setSearch] = useSearch()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { access = [] } = useRoleAccessContext()
  const {
    tracking = {},
    trackingIsLoading,
    trackingRevalidate
  } = useTracking({ page, search, filter })
  const { users } = useUsers()
  const { draft } = useDraft()
  const { office } = useOffice()
  const { finalDocument } = useFinalDocumentType()

  const { data, totalPages } = tracking

  const { createTracking } = useMutateTracking()

  const handleCreateTracking = async (value) => {
    await createTracking(value)

    trackingRevalidate()
    onClose()
  }

  return (
    <TrackingProvider value={{ revalidate: trackingRevalidate }}>
      <Box>
        <PageWrapper
          headerRightComponent={
            access['document-tracking'] &&
            access['document-tracking'].includes('create') ? (
              <Button
                leftIcon={<FiPlus />}
                onClick={() => onOpen()}
              >
                New Document Tracking
              </Button>
            ) : (
              false
            )
          }
        >
          <TableHeader
            totalPages={totalPages}
            onSearch={setSearch}
            filterData={finalDocument?.data || []}
            setFilter={setFilter}
            documentType={finalDocument?.data || []}
          />
          <Table
            isLoading={trackingIsLoading}
            data={data}
            columns={columns}
          />
        </PageWrapper>

        <Modal
          title="Create Tracking"
          size="4xl"
          isOpen={isOpen}
          onClose={onClose}
          withActionButtons={false}
          closeOnOverlayClick={false}
        >
          <Create
            users={users}
            draft={draft}
            office={office}
            onClose={onClose}
            onSubmit={handleCreateTracking}
          />
        </Modal>
      </Box>
    </TrackingProvider>
  )
}

export default React.memo(Tracking)

import Button from 'components/Button'
import React from 'react'

import { noop } from 'utils/helpers'

import {
  ModalBodySt,
  ModalCloseButtonSt,
  ModalContentSt,
  ModalFooterSt,
  ModalHeaderSt,
  ModalOverlaySt,
  ModalSt
} from './styles'

const Modal = ({
  children,
  title,
  onClose,
  isOpen,
  withCloseButton,
  isCentered,
  withActionButtons,
  cancelLabel,
  confirmLabel,
  onConfirm,
  scrollBehavior,
  ...rest
}) => {
  return (
    <ModalSt
      onClose={onClose}
      isOpen={isOpen}
      isCentered={isCentered}
      scrollBehavior={scrollBehavior}
      {...rest}
    >
      <ModalOverlaySt />
      <ModalContentSt p={2}>
        {title && (
          <ModalHeaderSt mb="4" fontSize="2xl">
            {title}
          </ModalHeaderSt>
        )}
        {withCloseButton && (
          <ModalCloseButtonSt
            _focus={{
              boxShadow: '0 0 0 3px hsl(0deg 0% 0% / 50%)'
            }}
          />
        )}
        <ModalBodySt>{children}</ModalBodySt>
        {withActionButtons && (
          <ModalFooterSt>
            <Button mr={3} onClick={onClose}>
              {cancelLabel}
            </Button>
            <Button variant="ghost" onClick={onConfirm}>
              {confirmLabel}
            </Button>
          </ModalFooterSt>
        )}
      </ModalContentSt>
    </ModalSt>
  )
}

Modal.defaultProps = {
  onConfirm: noop,
  onClose: noop,
  isCentered: true,
  withCloseButton: true,
  cancelLabel: 'Cancel',
  confirmLabel: 'Ok',
  scrollBehavior: 'inside'
}

export default Modal

import React from 'react'

import { ReactComponent as EmptyIcon } from 'assets/icons/empty-icon.svg'

import { Flex } from 'components/Layout'
import Text from 'components/Text'

import { EmptyOverlaySt } from './styles'

const EmptyOverlay = ({
  isEmpty,
  label,
  labelComponent,
  ...rest
}) => {
  if (!isEmpty) return null

  return (
    <EmptyOverlaySt {...rest}>
      <Flex alignItems="center" p={5}>
        <EmptyIcon />
        {labelComponent || (
          <Text ml={-5} fontSize="12">
            {label}
          </Text>
        )}
      </Flex>
    </EmptyOverlaySt>
  )
}

EmptyOverlay.defaultProps = {
  label: 'No Data'
}

export default EmptyOverlay

import { Box } from '@chakra-ui/react'
import React, { forwardRef } from 'react'

const Card = forwardRef(
  (
    { p, bg, borderWidth, rounded, position, children, ...rest },
    forwardedRef
  ) => {
    return (
      <Box
        ref={forwardedRef}
        p={p}
        bg={bg}
        borderWidth={borderWidth}
        borderColor="brand.200"
        rounded={rounded}
        position={position}
        {...rest}
      >
        {children}
      </Box>
    )
  }
)

Card.defaultProps = {
  p: 6,
  bg: 'white',
  borderWidth: '1px',
  rounded: 'lg',
  position: 'relative'
}

export default Card

const DRAFT_EMPTY_CONTENT = {
  entityMap: {},
  blocks: [
    {
      key: '637gr',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    }
  ]
}

export { DRAFT_EMPTY_CONTENT }

import React, { useState } from 'react'
import { useDisclosure } from '@chakra-ui/react'

import { Box } from 'components/Layout'
import Modal from 'components/Modal'
import Table from 'components/Table'
import PageWrapper from 'layout/PageWrapper'
import { generateFormData } from 'utils/helpers'
import useRoutePagination from 'utils/hooks/useRoutePagination'
import useToast from 'utils/hooks/useToast'
import TableHeader from 'components/Table/TableHeader'
import useSearch from 'utils/hooks/useSearch'
import Create from './Forms/Create'

import { columns } from './columns'
import { useMinutes, useMutateMinutes } from './api'
import { MinutesProvider } from './Context'
import { useCommittee } from '../Archived/api'

const Minutes = () => {
  const toast = useToast()
  const [year, setYear] = useState(null)
  const [sessionTypeData, setSessionTypeData] = useState('')
  const { page } = useRoutePagination()
  const [search, setSearch] = useSearch()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [contentState, setContentState] = React.useState()
  const [committeeData, setCommitteeData] = React.useState('')
  const {
    minutes = {},
    minutesIsLoading,
    minutesRevalidate
  } = useMinutes({
    page,
    search,
    year,
    sessionTypeData,
    committeeData
  })

  const { committee } = useCommittee()

  const { data, totalPages } = minutes

  const { createMinutes } = useMutateMinutes()

  const handleCreateMinutes = async (formValues) => {
    const { file = [], committeeId, ...rest } = formValues

    const payload = {
      file: file && file.length > 0 ? file[0] : null,
      committeeId: JSON.stringify(committeeId),
      ...rest
    }
    await createMinutes(generateFormData(payload))

    toast({
      title: 'Draft document created.',
      status: 'success',
      description: "We've created your draft document."
    })
    minutesRevalidate()
    onClose()
    setContentState(undefined)
  }

  return (
    <MinutesProvider value={{ revalidate: minutesRevalidate }}>
      <Box>
        <PageWrapper onCreate={onOpen}>
          <TableHeader
            totalPages={totalPages}
            onSearch={setSearch}
            setYear={setYear}
            setSessionTypeData={setSessionTypeData}
            setCommitteeData={setCommitteeData}
            sessionTypeData={sessionTypeData}
            committeeData={committee}
            year={[
              {
                label: 1999,
                value: 1999
              },
              {
                label: 2000,
                value: 2000
              },
              {
                label: 2001,
                value: 2001
              },
              {
                label: 2002,
                value: 2002
              },
              {
                label: 2003,
                value: 2003
              },
              {
                label: 2004,
                value: 2004
              },
              {
                label: 2005,
                value: 2005
              },
              {
                label: 2006,
                value: 2006
              },
              {
                label: 2007,
                value: 2007
              },
              {
                label: 2008,
                value: 2008
              },
              {
                label: 2009,
                value: 2009
              },
              {
                label: 2010,
                value: 2010
              },
              {
                label: 2011,
                value: 2011
              },
              {
                label: 2012,
                value: 2012
              },
              {
                label: 2013,
                value: 2013
              },
              {
                label: 2014,
                value: 2014
              },
              {
                label: 2015,
                value: 2015
              },
              {
                label: 2016,
                value: 2016
              },
              {
                label: 2017,
                value: 2017
              },
              {
                label: 2018,
                value: 2018
              },
              {
                label: 2019,
                value: 2019
              },
              {
                label: 2020,
                value: 2020
              },
              {
                label: 2021,
                value: 2021
              },
              {
                label: 2022,
                value: 2022
              },
              {
                label: 2023,
                value: 2023
              },
              {
                label: 2024,
                value: 2024
              },
              {
                label: 2025,
                value: 2025
              },
              {
                label: 2026,
                value: 2026
              },
              {
                label: 2027,
                value: 2027
              },
              {
                label: 2028,
                value: 2028
              },
              {
                label: 2029,
                value: 2029
              },
              {
                label: 2030,
                value: 2030
              }
            ]}
            sessionType={[
              {
                label: 'Regular Session',
                value: 'Regular Session'
              },
              {
                label: 'Special Session',
                value: 'Special Session'
              }
            ]}
          />
          <Table
            isLoading={minutesIsLoading}
            data={data}
            columns={columns}
          />
        </PageWrapper>

        <Modal
          title="Create Minutes"
          isOpen={isOpen}
          size="5xl"
          onClose={onClose}
          withActionButtons={false}
          closeOnOverlayClick={false}
        >
          <Create
            contentState={contentState}
            setContentState={setContentState}
            onClose={onClose}
            onSubmit={handleCreateMinutes}
          />
        </Modal>
      </Box>
    </MinutesProvider>
  )
}

export default React.memo(Minutes)

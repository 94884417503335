import useSWR from 'swr'
import { postRequest, putRequest } from 'utils/request'
import errorHandler from 'utils/errorHandler'
import { buildUrl } from 'utils/transformers/apiUrl'
import { USERS_ROUTE, PROPOSAL_ROUTE } from './constants'

const useUsers = () => {
  const {
    data: users,
    error: usersError,
    isValidating: usersIsValidating,
    mutate: usersRevalidate
  } = useSWR(USERS_ROUTE)

  const usersIsLoading = !users && !usersError

  return {
    users,
    usersError,
    usersIsValidating,
    usersIsLoading,
    usersRevalidate
  }
}

const useProposal = ({ page, id } = {}) => {
  const url = buildUrl({
    baseUrl: `${PROPOSAL_ROUTE}/filter/${id}`,
    page,
    status: false
  })

  const {
    data: proposals,
    error: proposalsError,
    isValidating: proposalsIsValidating,
    mutate: proposalsRevalidate
  } = useSWR(url)

  const proposalsIsLoading =
    !proposals && !proposalsError && proposalsIsValidating

  return {
    proposals,
    proposalsError,
    proposalsIsValidating,
    proposalsIsLoading,
    proposalsRevalidate
  }
}

const createUser = async (payload) => {
  try {
    const user = await postRequest(USERS_ROUTE, payload)
    return user
  } catch (error) {
    return errorHandler(error)
  }
}

const rejectProposal = async (id) => {
  try {
    const user = await putRequest(
      `${PROPOSAL_ROUTE}/manage/${id}`,
      {
        status: false
      }
    )
    return user
  } catch (error) {
    return errorHandler(error)
  }
}

const createProposal = async (id) => {
  try {
    const user = await putRequest(
      `${PROPOSAL_ROUTE}/manage/${id}`,
      {
        status: true
      }
    )
    return user
  } catch (error) {
    return errorHandler(error)
  }
}

export {
  useUsers,
  createUser,
  useProposal,
  createProposal,
  rejectProposal
}

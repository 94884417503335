import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import Button from 'components/Button'
import Form from 'components/Form'
import FormField from 'components/FormField'
import Input from 'components/Input'
import { Flex, Stack } from 'components/Layout'

import ReactSelect from 'components/ReactSelect'
import { model } from './schema'

const Edit = ({ onClose, onSubmit, values }) => {
  const { description, code } = values

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting, isDirty }
  } = useForm({
    resolver: yupResolver(model),
    defaultValues: { description, code }
  })

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      disabled={isSubmitting}
    >
      <Stack spacing={6} pb={2}>
        <FormField
          id="description"
          isInvalid={errors.description}
          error={errors.description}
          label="Description"
        >
          <Input
            id="description"
            placeholder="Description"
            {...register('description')}
          />
        </FormField>
        <FormField
          id="from"
          isInvalid={errors.from}
          error={errors.from}
          label="From"
        >
          <ReactSelect
            id="from"
            name="from"
            placeholder="Select Date..."
            control={control}
            options={[
              {
                label: 1999,
                value: 1999
              },
              {
                label: 2000,
                value: 2000
              },
              {
                label: 2001,
                value: 2001
              },
              {
                label: 2002,
                value: 2002
              },
              {
                label: 2003,
                value: 2003
              },
              {
                label: 2004,
                value: 2004
              },
              {
                label: 2005,
                value: 2005
              },
              {
                label: 2006,
                value: 2006
              },
              {
                label: 2007,
                value: 2007
              },
              {
                label: 2008,
                value: 2008
              },
              {
                label: 2009,
                value: 2009
              },
              {
                label: 2010,
                value: 2010
              },
              {
                label: 2011,
                value: 2011
              },
              {
                label: 2012,
                value: 2012
              },
              {
                label: 2013,
                value: 2013
              },
              {
                label: 2014,
                value: 2014
              },
              {
                label: 2015,
                value: 2015
              },
              {
                label: 2016,
                value: 2016
              },
              {
                label: 2017,
                value: 2017
              },
              {
                label: 2018,
                value: 2018
              },
              {
                label: 2019,
                value: 2019
              },
              {
                label: 2020,
                value: 2020
              },
              {
                label: 2021,
                value: 2021
              },
              {
                label: 2022,
                value: 2022
              },
              {
                label: 2023,
                value: 2023
              },
              {
                label: 2024,
                value: 2024
              },
              {
                label: 2025,
                value: 2025
              },
              {
                label: 2026,
                value: 2026
              },
              {
                label: 2027,
                value: 2027
              },
              {
                label: 2028,
                value: 2028
              },
              {
                label: 2029,
                value: 2029
              },
              {
                label: 2030,
                value: 2030
              },
              {
                label: 2031,
                value: 2031
              },
              {
                label: 2032,
                value: 2032
              },
              {
                label: 2033,
                value: 2033
              },
              {
                label: 2034,
                value: 2034
              },
              {
                label: 2035,
                value: 2035
              },
              {
                label: 2036,
                value: 2036
              },
              {
                label: 2037,
                value: 2037
              },
              {
                label: 2038,
                value: 2038
              },
              {
                label: 2039,
                value: 2039
              },
              {
                label: 2040,
                value: 2040
              }
            ]}
          />
        </FormField>
        <FormField
          id="to"
          isInvalid={errors.to}
          error={errors.to}
          label="To"
        >
          <ReactSelect
            id="to"
            name="to"
            placeholder="Select Date..."
            control={control}
            options={[
              {
                label: 1999,
                value: 1999
              },
              {
                label: 2000,
                value: 2000
              },
              {
                label: 2001,
                value: 2001
              },
              {
                label: 2002,
                value: 2002
              },
              {
                label: 2003,
                value: 2003
              },
              {
                label: 2004,
                value: 2004
              },
              {
                label: 2005,
                value: 2005
              },
              {
                label: 2006,
                value: 2006
              },
              {
                label: 2007,
                value: 2007
              },
              {
                label: 2008,
                value: 2008
              },
              {
                label: 2009,
                value: 2009
              },
              {
                label: 2010,
                value: 2010
              },
              {
                label: 2011,
                value: 2011
              },
              {
                label: 2012,
                value: 2012
              },
              {
                label: 2013,
                value: 2013
              },
              {
                label: 2014,
                value: 2014
              },
              {
                label: 2015,
                value: 2015
              },
              {
                label: 2016,
                value: 2016
              },
              {
                label: 2017,
                value: 2017
              },
              {
                label: 2018,
                value: 2018
              },
              {
                label: 2019,
                value: 2019
              },
              {
                label: 2020,
                value: 2020
              },
              {
                label: 2021,
                value: 2021
              },
              {
                label: 2022,
                value: 2022
              },
              {
                label: 2023,
                value: 2023
              },
              {
                label: 2024,
                value: 2024
              },
              {
                label: 2025,
                value: 2025
              },
              {
                label: 2026,
                value: 2026
              },
              {
                label: 2027,
                value: 2027
              },
              {
                label: 2028,
                value: 2028
              },
              {
                label: 2029,
                value: 2029
              },
              {
                label: 2030,
                value: 2030
              },
              {
                label: 2031,
                value: 2031
              },
              {
                label: 2032,
                value: 2032
              },
              {
                label: 2033,
                value: 2033
              },
              {
                label: 2034,
                value: 2034
              },
              {
                label: 2035,
                value: 2035
              },
              {
                label: 2036,
                value: 2036
              },
              {
                label: 2037,
                value: 2037
              },
              {
                label: 2038,
                value: 2038
              },
              {
                label: 2039,
                value: 2039
              },
              {
                label: 2040,
                value: 2040
              }
            ]}
          />
        </FormField>
        <Flex mt={5} justify="flex-end">
          <Button mr={2} variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            isLoading={isSubmitting}
            type="submit"
            disabled={!isDirty || isSubmitting}
          >
            Submit
          </Button>
        </Flex>
      </Stack>
    </Form>
  )
}

export default Edit

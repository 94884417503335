import React, { useContext } from 'react'

const LegislationContext = React.createContext(null)

export const LegislationProvider = ({ children, value }) => (
  <LegislationContext.Provider value={value}>
    {children}
  </LegislationContext.Provider>
)

export const useLegislationContext = () => {
  return useContext(LegislationContext)
}

export default LegislationContext

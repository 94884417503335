import { Table } from '@chakra-ui/react'
import styled from '@emotion/styled'
import Card from 'components/Card'
import customScrollbar from 'theme/styles/customScrollbar'

const TableSt = styled(Table)`
  && {
    th {
      background: var(--chakra-colors-tertiary);
      border-bottom: 1px solid var(--chakra-colors-brand-200);
      padding-block-start: 19px;
      padding-block-end: 19px;
      font-size: 16px;
      font-weight: 800;
      text-transform: none;
      letter-spacing: normal;
      line-height: 1.6;
      color: var(--chakra-colors-gray-800);
      white-space: nowrap;

      &.Fixed {
        &--right {
          position: sticky;
          right: 0;

          &.float {
            display: block;
            border-left: 1px solid var(--chakra-colors-brand-200);
          }
        }
      }
    }

    td {
      border: 0;
      border-bottom: 1px solid var(--chakra-colors-brand-200);
      white-space: nowrap;

      &.Fixed {
        &--right {
          position: sticky;
          right: 0;
          background: #fff;

          &.float {
            display: block;
            border-left: 1px solid var(--chakra-colors-brand-200);
          }
        }
      }
    }

    tr {
      &:last-child td {
        border: 0;
      }
    }
  }
`

const TableWrapperSt = styled(Card)`
  min-height: 110px;
  overflow-x: overlay;

  &.isLoading {
    pointer-events: none;
  }

  ${customScrollbar}
`

const LoadingOverlaySt = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: block;
  border-radius: 6px;
  background: ${({ solid }) =>
    solid
      ? 'rgba(255, 255, 255, 1)'
      : 'rgba(255, 255, 255, 0.8)'};
  padding: 20px;
`

const EmptyOverlaySt = styled.div`
  font-size: 16px;

  svg {
    width: 100px;
  }
`

export {
  TableSt,
  TableWrapperSt,
  LoadingOverlaySt,
  EmptyOverlaySt
}

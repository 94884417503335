import React from 'react'
import { useDisclosure } from '@chakra-ui/react'

import useRoutePagination from 'utils/hooks/useRoutePagination'

import PageWrapper from 'layout/PageWrapper'
import { Box } from 'components/Layout'
import Modal from 'components/Modal'
import Table from 'components/Table'

import TableHeader from 'components/Table/TableHeader'
import useSearch from 'utils/hooks/useSearch'
import { useUsersOptions } from '../UserManagement/api'
import CreateUserForm from './Forms/Create'
import { columns } from './columns'
import { CommitteeReferralsProvider } from './Context'
import {
  useCommitteeReferrals,
  useMutateCommitteeReferrals
} from './api'

const CommitteeReferrals = () => {
  const { page } = useRoutePagination()
  const [search, setSearch] = useSearch()
  const { usersOptions } = useUsersOptions()
  const {
    committeeReferrals = {},
    committeeReferralsIsLoading,
    committeeReferralsRevalidate
  } = useCommitteeReferrals({ page, search })

  const { data, totalPages } = committeeReferrals

  const { createCommitteeReferrals } =
    useMutateCommitteeReferrals()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleCreateDesignation = async (value) => {
    await createCommitteeReferrals(value)

    committeeReferralsRevalidate()
    onClose()
  }

  return (
    <CommitteeReferralsProvider
      value={{
        usersOptions,
        revalidate: committeeReferralsRevalidate
      }}
    >
      <Box>
        <PageWrapper
          onCreate={onOpen}
          headerRightComponent={false}
        >
          <TableHeader
            totalPages={totalPages}
            onSearch={setSearch}
          />
          <Table
            data={data}
            columns={columns}
            isLoading={committeeReferralsIsLoading}
          />
        </PageWrapper>

        <Modal
          title="Create Committee Referrals"
          isOpen={isOpen}
          onClose={onClose}
          withActionButtons={false}
          closeOnOverlayClick={false}
          size="2xl"
        >
          <CreateUserForm
            onSubmit={handleCreateDesignation}
            onClose={onClose}
            usersOptions={usersOptions}
          />
        </Modal>
      </Box>
    </CommitteeReferralsProvider>
  )
}

export default CommitteeReferrals

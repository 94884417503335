import React from 'react'
import { Flex } from '@chakra-ui/react'

import Text from 'components/Text'

import useBreadCrumbs from 'utils/hooks/useBreadCrumbs'
import Button from 'components/Button'
import { FiPlus } from 'react-icons/fi'
import { ContentHeaderSt } from './styles'

const ContentHeader = ({
  label,
  leftComponent,
  rightComponent,
  onCreate
}) => {
  const [breadCrumbsLabel, defaultLabel] = useBreadCrumbs()

  return (
    <ContentHeaderSt
      borderBottomColor="gray.200"
      borderBottomWidth={1}
      bg="#FFF"
    >
      <Flex
        justify="space-between"
        align="center"
        px={6}
        py={{ base: 3, md: 8 }}
        maxW="1248px"
        margin="auto"
        flexDirection={{ base: 'column', md: 'row' }}
      >
        {leftComponent ?? (
          <Text
            lineHeight="40px"
            fontSize={{ base: 'xl', md: '2xl' }}
            mb={{ base: 2, md: 0 }}
          >
            {label ?? breadCrumbsLabel}
          </Text>
        )}
        {rightComponent ?? (
          <Button leftIcon={<FiPlus />} onClick={onCreate}>
            New {defaultLabel}
          </Button>
        )}
      </Flex>
    </ContentHeaderSt>
  )
}

export default React.memo(ContentHeader)

import React from 'react'

import Button from 'components/Button'
import FormField from 'components/FormField'
import TextArea from 'components/TextArea'
import Input from 'components/Input'
import { Flex, Stack } from 'components/Layout'

const View = ({ values, handleApproval, signatory }) => {
  return (
    <Stack spacing={6} pb={12}>
      {signatory && (
        <FormField id="statusdisplay" label="My Vote">
          <Input
            type="text"
            value={
              signatory && signatory.status
                ? signatory.status
                : 'Pending'
            }
            readOnly
          />
        </FormField>
      )}
      <FormField id="draftTitle" label="Draft Title">
        <TextArea
          style={{ height: '15vh' }}
          type="text"
          id="draftTitle"
          value={
            values?.draftdocument?.draftTitle ||
            values?.committeereport?.title
          }
          readOnly
        />
      </FormField>
      {signatory && !signatory.status && (
        <Flex mt={5} justify="flex-end">
          <Button
            onClick={() =>
              handleApproval(values?.id, {
                status: 'Disapprove'
              })
            }
            colorScheme="red"
            type="submit"
            mr={4}
          >
            Disapprove
          </Button>
          <Button
            onClick={() =>
              handleApproval(values?.id, { status: 'Abstain' })
            }
            colorScheme="gray"
            type="submit"
            mr={4}
          >
            Abstain
          </Button>
          <Button
            onClick={() =>
              handleApproval(values?.id, {
                status: 'Approved'
              })
            }
            colorScheme="green"
            type="submit"
            mr={4}
          >
            Approve
          </Button>
        </Flex>
      )}
    </Stack>
  )
}

export default View

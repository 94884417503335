import React from 'react'
import FileViewer from 'react-file-viewer-plugins-v3'
import { Stack } from 'components/Layout'

const DocumentView = ({ values }) => {
  return (
    <Stack spacing={6} pb={12}>
      <a
        href={`${
          /(http(s?)):\/\//i.test(values?.draftdocument?.file)
            ? values?.draftdocument?.file
            : `https://${values?.draftdocument?.file}`
        }`}
        style={{ color: 'blue' }}
      >
        Download File
      </a>
      {values &&
        values.draftdocument &&
        values.draftdocument.file &&
        values.draftdocument.file.map((a) => {
          return (
            <FileViewer
              fileType="pdf"
              filePath={`${
                /(http(s?)):\/\//i.test(a) ? a : `https://${a}`
              }`}
            />
          )
        })}
    </Stack>
  )
}

export default DocumentView

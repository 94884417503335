import * as yup from 'yup'

const createModel = yup.object({
  masterData: yup.boolean(),
  masterDataView: yup.boolean(),
  masterDataUpdate: yup.boolean(),
  masterDataCreate: yup.boolean(),
  masterDataDelete: yup.boolean(),
  draftDocument: yup.boolean(),
  draftDocumentView: yup.boolean(),
  draftDocumentUpdate: yup.boolean(),
  draftDocumentCreate: yup.boolean(),
  draftDocumentDelete: yup.boolean(),
  session: yup.boolean(),
  sessionView: yup.boolean(),
  sessionUpdate: yup.boolean(),
  sessionCreate: yup.boolean(),
  sessionDelete: yup.boolean(),
  minutes: yup.boolean(),
  minutesView: yup.boolean(),
  minutesUpdate: yup.boolean(),
  minutesCreate: yup.boolean(),
  minutesDelete: yup.boolean(),
  documentTracking: yup.boolean(),
  documentTrackingView: yup.boolean(),
  documentTrackingUpdate: yup.boolean(),
  documentTrackingCreate: yup.boolean(),
  documentTrackingDelete: yup.boolean(),
  committeeReports: yup.boolean(),
  committeeReportsView: yup.boolean(),
  committeeReportsUpdate: yup.boolean(),
  committeeReportsCreate: yup.boolean(),
  committeeReportsDelete: yup.boolean(),
  chat: yup.boolean(),
  chatView: yup.boolean(),
  chatUpdate: yup.boolean(),
  chatCreate: yup.boolean(),
  chatDelete: yup.boolean(),
  archive: yup.boolean(),
  archiveView: yup.boolean(),
  archiveUpdate: yup.boolean(),
  archiveCreate: yup.boolean(),
  archiveDelete: yup.boolean(),
  notes: yup.boolean(),
  notesView: yup.boolean(),
  notesUpdate: yup.boolean(),
  notesCreate: yup.boolean(),
  notesDelete: yup.boolean(),
  committeeHearingMinutes: yup.boolean(),
  committeeHearingMinutesView: yup.boolean(),
  committeeHearingMinutesUpdate: yup.boolean(),
  committeeHearingMinutesCreate: yup.boolean(),
  committeeHearingMinutesDelete: yup.boolean(),
  committeeMeetingMinutes: yup.boolean(),
  committeeMeetingMinutesView: yup.boolean(),
  committeeMeetingMinutesUpdate: yup.boolean(),
  committeeMeetingMinutesCreate: yup.boolean(),
  committeeMeetingMinutesDelete: yup.boolean(),
  publicHearingMinutes: yup.boolean(),
  publicHearingMinutesView: yup.boolean(),
  publicHearingMinutesUpdate: yup.boolean(),
  publicHearingMinutesCreate: yup.boolean(),
  publicHearingMinutesDelete: yup.boolean(),
  document: yup.boolean(),
  documentView: yup.boolean(),
  documentUpdate: yup.boolean(),
  documentCreate: yup.boolean(),
  documentDelete: yup.boolean(),
  transcript: yup.boolean(),
  transcriptView: yup.boolean(),
  transcriptUpdate: yup.boolean(),
  transcriptCreate: yup.boolean(),
  transcriptDelete: yup.boolean(),
  committeeReferrals: yup.boolean(),
  committeeReferralsView: yup.boolean(),
  committeeReferralsUpdate: yup.boolean(),
  committeeReferralsCreate: yup.boolean(),
  committeeReferralsDelete: yup.boolean(),
  finalDocument: yup.boolean(),
  finalDocumentView: yup.boolean(),
  finalDocumentUpdate: yup.boolean(),
  finalDocumentCreate: yup.boolean(),
  finalDocumentDelete: yup.boolean(),
  reports: yup.boolean()
})

const editModel = yup.object({})

const initialValues = {
  masterData: false,
  masterDataView: false,
  masterDataUpdate: false,
  masterDataCreate: false,
  masterDataDelete: false,
  draftDocument: false,
  draftDocumentView: false,
  draftDocumentUpdate: false,
  draftDocumentCreate: false,
  draftDocumentDelete: false,
  session: false,
  sessionView: false,
  sessionUpdate: false,
  sessionCreate: false,
  sessionDelete: false,
  minutes: false,
  minutesView: false,
  minutesUpdate: false,
  minutesCreate: false,
  minutesDelete: false,
  documentTracking: false,
  documentTrackingView: false,
  documentTrackingUpdate: false,
  documentTrackingCreate: false,
  documentTrackingDelete: false,
  committeeReports: false,
  committeeReportsView: false,
  committeeReportsUpdate: false,
  committeeReportsCreate: false,
  committeeReportsDelete: false,
  chat: false,
  chatView: false,
  chatUpdate: false,
  chatCreate: false,
  chatDelete: false,
  archive: false,
  archiveView: false,
  archiveUpdate: false,
  archiveCreate: false,
  archiveDelete: false,
  notes: false,
  notesView: false,
  notesUpdate: false,
  notesCreate: false,
  notesDelete: false,
  committeeHearingMinutes: false,
  committeeHearingMinutesView: false,
  committeeHearingMinutesUpdate: false,
  committeeHearingMinutesCreate: false,
  committeeHearingMinutesDelete: false,
  committeeMeetingMinutes: false,
  committeeMeetingMinutesView: false,
  committeeMeetingMinutesUpdate: false,
  committeeMeetingMinutesCreate: false,
  committeeMeetingMinutesDelete: false,
  publicHearingMinutes: false,
  publicHearingMinutesView: false,
  publicHearingMinutesUpdate: false,
  publicHearingMinutesCreate: false,
  publicHearingMinutesDelete: false,
  document: false,
  documentView: false,
  documentUpdate: false,
  documentCreate: false,
  documentDelete: false,
  transcript: false,
  transcriptView: false,
  transcriptUpdate: false,
  transcriptCreate: false,
  transcriptDelete: false,
  committeeReferrals: false,
  committeeReferralsView: false,
  committeeReferralsUpdate: false,
  committeeReferralsCreate: false,
  committeeReferralsDelete: false,
  reports: false
}

export { createModel, initialValues, editModel }

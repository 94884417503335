import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Flex, Box, Stack } from '@chakra-ui/react'
import Text from 'components/Text'
import Logo from 'assets/images/logo-160px.png'

import useAuth from 'containers/Auth/useAuth'

import Image from 'components/Image'

import { useLogin } from './api'
import LoginForm from './Form'

const LoginPage = () => {
  const auth = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  // Redirect to where they want to go for better UX
  // location.pathname <- Not sure if fool proof
  const from =
    location.state?.from?.pathname || location.pathname || '/'

  const { data, login, error, isLoading } = useLogin()

  useEffect(() => {
    if (!data) return

    const setUserDetailsOnIndexedDB = async () => {
      await auth.login(data, () => {
        navigate(from, { replace: true })
      })
    }

    setUserDetailsOnIndexedDB()
  }, [auth, data, from, navigate])

  return (
    <Flex
      minH="100vh"
      align="center"
      justify="center"
      bg="tertiary"
    >
      <Stack spacing={8} mx="auto" maxW="lg" py={12} px={6}>
        <Stack align="center">
          <Image
            boxSize="150px"
            objectFit="cover"
            src={Logo}
            alt="Candon Logo"
          />
        </Stack>
        <Stack align="center">
          <Text>Legislative Tracking & Information System</Text>
        </Stack>
        <Box
          rounded="lg"
          bg="white"
          p={8}
          borderColor="brand.200"
          borderWidth={1}
        >
          <LoginForm
            login={login}
            error={error}
            isLoading={isLoading}
          />
        </Box>
      </Stack>
    </Flex>
  )
}

export default LoginPage

import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  generateQueryString,
  parseQueryString
} from 'utils/queryString'

const INIT_PAGE_NUMBER = 1

const useRoutePagination = ({ totalPages } = {}) => {
  const { pathname, search } = useLocation()
  const navigate = useNavigate()
  const { page } = parseQueryString(search)
  const [maxPage, setMaxPage] = useState()

  useEffect(() => {
    if (!totalPages) return

    setMaxPage(totalPages)
  }, [totalPages])

  const onNextClick = useCallback(() => {
    const currPage = +page || INIT_PAGE_NUMBER
    if (currPage === maxPage) return

    const nextPage = { page: currPage + 1 }
    const newUrl = pathname + generateQueryString(nextPage)

    navigate(newUrl)
  }, [maxPage, navigate, page, pathname])

  const onPrevClick = useCallback(() => {
    const currPage = +page
    if (!currPage || currPage === INIT_PAGE_NUMBER) return

    const nextPage = { page: currPage - 1 }
    const newUrl = pathname + generateQueryString(nextPage)

    navigate(newUrl)
  }, [navigate, page, pathname])

  const nextDisabled = useMemo(() => {
    const currPage = +page || INIT_PAGE_NUMBER
    return currPage === maxPage
  }, [maxPage, page])

  const prevDisabled = useMemo(() => {
    const currPage = +page
    return currPage === INIT_PAGE_NUMBER
  }, [page])

  return {
    page: +page || INIT_PAGE_NUMBER,
    onNextClick,
    onPrevClick,
    nextDisabled,
    prevDisabled
  }
}

export default useRoutePagination

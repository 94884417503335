/* eslint-disable no-console */
import React from 'react'
import { useForm } from 'react-hook-form'

import Button from 'components/Button'
import Form from 'components/Form'
import FormField from 'components/FormField'
import Input from 'components/Input'
import { Flex, Stack } from 'components/Layout'

import DatePicker from 'components/DatePicker'
import ReactSelect from 'components/ReactSelect'
import { model } from './schema'
import { useTerm } from '../api'

const Edit = ({ onClose, values }) => {
  const defaultValues = {
    ...values,
    date: new Date(values.date)
  }

  const { term } = useTerm()

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting }
  } = useForm({ defaultValues })

  const onSubmit = (payload) => {
    console.log({ payload })
  }

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      disabled={isSubmitting}
    >
      <Stack spacing={6} pb={2}>
        <FormField
          id="termId"
          isInvalid={errors.termId}
          error={errors.termId}
          label="Term"
        >
          <ReactSelect
            id="termId"
            name="termId"
            placeholder="Select Term..."
            control={control}
            options={term?.data || []}
          />
        </FormField>
        <FormField
          id="sessionNo"
          isInvalid={errors.sessionNo}
          error={errors.sessionNo}
          label="Session No."
        >
          <Input
            id="sessionNo"
            placeholder="Session No."
            {...register('sessionNo', model.sessionNo)}
          />
        </FormField>
        <FormField
          id="date"
          isInvalid={errors.date}
          error={errors.date}
          label="Date"
        >
          <DatePicker
            id="date"
            name="date"
            control={control}
            dateFormatCalendar="MMMM"
            placeholderText="Date"
            showMonthDropdown
            dropdownMode="select"
          />
        </FormField>
        <Flex mt={5} justify="flex-end">
          <Button
            mr={2}
            variant="outline"
            isLoading={isSubmitting}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button isLoading={isSubmitting} type="submit">
            Submit
          </Button>
        </Flex>
      </Stack>
    </Form>
  )
}

export default Edit

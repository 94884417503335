import useSWR from 'swr'
import { buildUrl } from 'utils/transformers/apiUrl'
import { TERM_ROUTE } from './constants'

const useTerm = () => {
  const url = buildUrl({
    baseUrl: `${TERM_ROUTE}`
  })

  const {
    data: term,
    error: termError,
    isValidating: termIsValidating,
    mutate: termRevalidate
  } = useSWR(url)

  const termIsLoading = !term && !termError && termIsValidating

  return {
    term,
    termError,
    termIsValidating,
    termIsLoading,
    termRevalidate
  }
}

export { useTerm }

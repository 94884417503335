import React from 'react'

import Button from 'components/Button'
import { HStack } from 'components/Layout'

const View = ({ onView }) => {
  return (
    <HStack direction="row">
      <Button
        onClick={onView}
        size="sm"
        _focus={{
          boxShadow: '0 0 0 3px rgba(229, 62, 62, 0.5)'
        }}
      >
        View
      </Button>
    </HStack>
  )
}

export default React.memo(View)

import useSWR from 'swr'
import {
  deleteRequest,
  postRequest,
  putRequest
} from 'utils/request'
import errorHandler from 'utils/errorHandler'
import { useCallback, useState } from 'react'
import { callSWRFetchOnce } from 'utils/request/constant'
import { buildUrl } from 'utils/transformers/apiUrl'
import {
  ACCESS_ROUTE,
  USERS_OPTIONS_ROUTE,
  USERS_ROUTE
} from './constants'

const useUsers = ({
  page,
  id,
  search,
  cancel,
  config = {}
} = {}) => {
  const url = buildUrl({
    baseUrl: USERS_ROUTE,
    search,
    page,
    id
  })

  const {
    data: users,
    error: usersError,
    isValidating: usersIsValidating,
    mutate: usersRevalidate
  } = useSWR(cancel ? null : url, config)

  const usersIsLoading =
    !users && !usersError && usersIsValidating

  return {
    users,
    usersError,
    usersIsValidating,
    usersIsLoading,
    usersRevalidate
  }
}

const useAccess = (id) => {
  const url = buildUrl({
    baseUrl: `${ACCESS_ROUTE}`,
    id
  })

  const {
    data: access,
    error: accessError,
    isValidating: accessIsValidating,
    mutate: accessRevalidate
  } = useSWR(url)

  const accessIsLoading =
    !access && !accessError && accessIsValidating

  return {
    access,
    accessError,
    accessIsValidating,
    accessIsLoading,
    accessRevalidate
  }
}

const useUsersOptions = () => {
  const {
    data: usersOptions,
    error: usersOptionsError,
    isValidating: usersOptionsIsValidating,
    mutate: usersOptionsRevalidate
  } = useSWR(USERS_OPTIONS_ROUTE, callSWRFetchOnce)

  const usersOptionsIsLoading =
    !usersOptions &&
    !usersOptionsError &&
    usersOptionsIsValidating

  return {
    usersOptions,
    usersOptionsError,
    usersOptionsIsValidating,
    usersOptionsIsLoading,
    usersOptionsRevalidate
  }
}

const useMutateUsers = () => {
  const [response, setResponse] = useState()
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const resetState = useCallback(() => {
    if (response) setResponse(undefined)
    if (isLoading) setError(undefined)
  }, [isLoading, response])

  const createUser = useCallback(
    async (payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await postRequest(
          `${USERS_ROUTE}/signup`,
          payload
        )
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  const updateUser = useCallback(
    async (id, payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await putRequest(
          `${USERS_ROUTE}/${id}`,
          payload
        )
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  const deleteUser = useCallback(
    async (id) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await deleteRequest(`${USERS_ROUTE}/${id}`)
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  return {
    data: response,
    error,
    isLoading,
    createUser,
    updateUser,
    deleteUser
  }
}

const useMutateAccess = () => {
  const [response, setResponse] = useState()
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const resetState = useCallback(() => {
    if (response) setResponse(undefined)
    if (isLoading) setError(undefined)
  }, [isLoading, response])

  const createAccess = useCallback(
    async (payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await postRequest(
          `${ACCESS_ROUTE}`,
          payload
        )
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  return {
    data: response,
    error,
    isLoading,
    createAccess
  }
}

export {
  useUsers,
  useMutateUsers,
  useUsersOptions,
  useMutateAccess,
  useAccess
}

import * as yup from 'yup'

const model = yup.object({
  sessionId: yup.string(),
  file: yup.mixed().nullable(),
  minutesCategory: yup.string(),
  sessionType: yup.string(),
  committee: yup.array()
})

const initialValues = {
  sessionId: '',
  file: '',
  minutesCategory: '',
  sessionType: '',
  committee: []
}

export { model, initialValues }

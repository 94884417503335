import React from 'react'
import TableActions from './TableActions'

const columns = [
  {
    Header: 'Code',
    accessor: 'documentCode'
  },
  {
    Header: 'Title',
    accessor: 'shortTitle'
  },
  {
    Header: 'Type',
    accessor: 'type.description'
  },
  {
    Header: 'File',
    accessor: (val) => {
      return (
        <a href={val.file} style={{ color: 'blue' }}>
          Download
        </a>
      )
    }
  },
  {
    Header: 'Attachment',
    accessor: (val) => {
      return (
        <a href={val.attachments} style={{ color: 'blue' }}>
          Download
        </a>
      )
    }
  },
  {
    Header: 'Action',
    accessor: 'id',
    fixed: 'right',
    Cell: TableActions
  }
]

export { columns }

import React from 'react'
import { useDisclosure } from '@chakra-ui/react'

import { Box } from 'components/Layout'
import Modal from 'components/Modal'
import Table from 'components/Table'
import { FiPlus } from 'react-icons/fi'
import useRoleAccessContext from 'containers/RoleAccess/useRoleAccessContext'
import Button from 'components/Button'
import PageWrapper from 'layout/PageWrapper'
import useRoutePagination from 'utils/hooks/useRoutePagination'
import useSearch from 'utils/hooks/useSearch'
import TableHeader from 'components/Table/TableHeader'
import CreateUserForm from './Forms/Create'

import { columns } from './columns'
import {
  useDocumentTracking,
  useMutateDocumentTracking
} from './api'
import { DocumentTrackingProvider } from './Context'

const DocumentTracking = () => {
  const { page } = useRoutePagination()
  const [search, setSearch] = useSearch()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const {
    documentTracking = {},
    documentTrackingIsLoading,
    documentTrackingRevalidate
  } = useDocumentTracking({ page, search })
  const { access = [] } = useRoleAccessContext()
  const { data, totalPages } = documentTracking

  const { createDocumentTracking } = useMutateDocumentTracking()

  const handleCreateDocumentTracking = async (value) => {
    await createDocumentTracking(value)

    documentTrackingRevalidate()
    onClose()
  }

  return (
    <DocumentTrackingProvider
      value={{ revalidate: documentTrackingRevalidate }}
    >
      <Box>
        <PageWrapper
          headerRightComponent={
            access['master-data'] &&
            access['master-data'].includes('create') ? (
              <Button
                leftIcon={<FiPlus />}
                onClick={() => onOpen()}
              >
                New Document Tracking
              </Button>
            ) : (
              false
            )
          }
        >
          <TableHeader
            totalPages={totalPages}
            onSearch={setSearch}
          />
          <Table
            isLoading={documentTrackingIsLoading}
            data={data}
            columns={columns}
          />
        </PageWrapper>

        <Modal
          title="Create Document Tracking"
          isOpen={isOpen}
          onClose={onClose}
          withActionButtons={false}
          closeOnOverlayClick={false}
        >
          <CreateUserForm
            onClose={onClose}
            onSubmit={handleCreateDocumentTracking}
          />
        </Modal>
      </Box>
    </DocumentTrackingProvider>
  )
}

export default DocumentTracking

import { CommitteeMinutes } from 'components/CellRenderers'
import TableActions from './TableActions'

const columns = [
  {
    Header: 'Title',
    accessor: 'description'
  },
  {
    Header: 'Committees',
    accessor: 'publicHearingCommittee',
    Cell: CommitteeMinutes
  },
  {
    Header: 'Action',
    accessor: 'id',
    fixed: 'right',
    Cell: TableActions
  }
]

export { columns }

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import { DEFAULT_FORMAT } from './constants'

dayjs.extend(relativeTime)

const formatDate = (date, format = DEFAULT_FORMAT) => {
  if (!date) return undefined

  return dayjs(date).format(format)
}

const formatToISOString = (date) => {
  if (!date) return undefined

  return new Date(date).toISOString()
}

const fromNow = (date) => {
  if (!date) return undefined

  return dayjs(date).fromNow()
}
export { formatDate, formatToISOString, fromNow }

import React from 'react'
import { AvatarSt, AvatarGroupSt } from './styles'

const Avatar = React.forwardRef(
  ({ children, avatars, groupProps, ...rest }, forwardedRef) => {
    if (avatars?.length) {
      return (
        <AvatarGroupSt
          size={groupProps.size}
          max={groupProps.max}
          ref={forwardedRef}
          {...groupProps}
        >
          {avatars.map((avatar, index) => (
            <AvatarSt
              key={`${avatar.src} ${index + 1}`}
              name={avatar.name}
              src={avatar.src}
              {...rest}
            />
          ))}
        </AvatarGroupSt>
      )
    }

    return (
      <AvatarSt ref={forwardedRef} {...rest}>
        {children}
      </AvatarSt>
    )
  }
)

Avatar.defaultProps = {
  groupProps: {
    size: 'sm',
    max: 3
  }
}

export default Avatar

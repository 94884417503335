import { Box, Flex } from 'components/Layout'
import Text from 'components/Text'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { ChatThreadItemSt, ChatThreadSt } from './styles'

const ChatThread = ({ thread, ...rest }) => {
  const handleActiveLink = (route) =>
    route.isActive ? 'active' : ''

  return (
    <ChatThreadSt fontWeight={500} {...rest}>
      {thread?.map(({ id, name }) => {
        const threadClasses = ['Thread']
        return (
          <ChatThreadItemSt
            key={id}
            className={threadClasses.join(' ')}
          >
            <NavLink to={`${id}`} className={handleActiveLink}>
              <Flex justify="space-between">
                <Box className="Message__details--primary">
                  <Text as="h4">{name}</Text>
                </Box>
              </Flex>
            </NavLink>
          </ChatThreadItemSt>
        )
      })}
    </ChatThreadSt>
  )
}

export default ChatThread

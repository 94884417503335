import { useState, useEffect } from 'react'
import { API_BASE_URL } from '@env'
import io from 'socket.io-client'

import useAuth from 'containers/Auth/useAuth'

const useSocketIo = () => {
  const auth = useAuth()
  const { token } = auth.user
  const [socket, setSocket] = useState()

  useEffect(() => {
    if (!token) return undefined

    const newSocket = io(API_BASE_URL, {
      query: { token }
    })

    setSocket(newSocket)

    return () => {
      newSocket.close()
      setSocket(undefined)
    }
  }, [token])

  return [socket, setSocket]
}

export default useSocketIo

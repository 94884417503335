import React, { useCallback, useEffect, useState } from 'react'
import { Flex, HStack } from 'components/Layout'
import { HEADER_HEIGHT } from 'layout/Header/styles'
import NoteList from 'components/NoteList'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import EmptyOverlay from 'components/Table/EmptyOverlay'
import Text from 'components/Text'
import useToast from 'utils/hooks/useToast'
import { useDisclosure } from '@chakra-ui/react'
import AlertDialog from 'components/AlertDialog'
import { DRAFT_EMPTY_CONTENT } from 'components/Editor/constants'
import { PATH } from 'routes'
import { isEmpty } from 'ramda'
import { useMutateNotes, useNotes } from './api'

const Conversations = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [idToDelete, setIdToDelete] = useState()

  const {
    notes = {},
    notesIsLoading,
    notesRevalidate
  } = useNotes()
  const { createNote, isLoading, deleteNote } = useMutateNotes()
  const { data } = notes

  const handleCreateNote = useCallback(async () => {
    const res = await createNote({
      title: '',
      content: JSON.stringify(DRAFT_EMPTY_CONTENT)
    })

    if (!res) return

    notesRevalidate()
    toast({
      title: 'Note Created',
      status: 'success'
    })

    navigate(`/${PATH.NOTES}/${res.id}`)
  }, [createNote, navigate, notesRevalidate, toast])

  const handleDeleteNote = useCallback(async () => {
    const res = await deleteNote(idToDelete)

    if (!res) return

    notesRevalidate()
    setIdToDelete(undefined)
    onClose()
    toast({
      title: 'Note Deleted',
      status: 'success'
    })
  }, [deleteNote, idToDelete, notesRevalidate, onClose, toast])

  useEffect(() => {
    if (!data || id) return

    if (data.length) navigate(`${data[0].id}`)
  }, [data, id, navigate])

  return (
    <HStack h={`calc(100vh - ${HEADER_HEIGHT})`} bgColor="white">
      <Flex
        as="aside"
        h="full"
        maxW="sm"
        w="full"
        borderRightColor="brand.200"
        borderRightWidth={1}
      >
        <NoteList
          list={data}
          onCreate={handleCreateNote}
          mutateLoading={isLoading}
          isLoading={notesIsLoading}
          onDelete={(currId) => {
            setIdToDelete(currId)
            onOpen()
          }}
        />
      </Flex>
      <Flex
        as="main"
        h="full"
        flex={1}
        w="full"
        style={{ margin: 0 }}
        borderRightWidth={1}
      >
        {isEmpty(data) ? (
          <Flex w="full" align="center" justify="center">
            <EmptyOverlay
              isEmpty
              labelComponent={
                <Text
                  fontSize="lg"
                  position="relative"
                  left="-30px"
                >
                  You don&apos;t have any notes yet 😢
                </Text>
              }
            />
          </Flex>
        ) : (
          <Outlet />
        )}
      </Flex>
      <AlertDialog
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleDeleteNote}
        title="Delete Note"
      />
    </HStack>
  )
}

export default Conversations

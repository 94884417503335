import React, { useMemo } from 'react'
import { useDisclosure } from '@chakra-ui/react'

import { filterUnmatchedKeys } from 'utils/helpers'

import Modal from 'components/Modal'
import AlertDialog from 'components/AlertDialog'
import { ActionsRenderer } from 'components/CellRenderers'
import useRoleAccessContext from 'containers/RoleAccess/useRoleAccessContext'
import Edit from './Forms/Edit'
import { useMutateCommittee } from './api'
import { useCommitteeContext } from './Context'
import { initialValues } from './Forms/schema'

const TableActions = ({ row, value: id }) => {
  const { values, original } = row
  const { updateCommittee, deleteCommittee, isLoading } =
    useMutateCommittee()
  const { revalidate, usersOptions } = useCommitteeContext()
  const { access = [] } = useRoleAccessContext()
  const {
    isOpen: editIsOpen,
    onOpen: editOnOpen,
    onClose: editOnClose
  } = useDisclosure()
  const {
    isOpen: deleteIsOpen,
    onOpen: deleteOnOpen,
    onClose: deleteOnClose
  } = useDisclosure()

  const handleEdit = async (newValue) => {
    await updateCommittee(id, newValue)

    revalidate()
    editOnClose()
  }

  const handleDelete = async () => {
    await deleteCommittee(id)

    revalidate()
    deleteOnClose()
  }

  const defaultValues = useMemo(() => {
    const filteredValues = filterUnmatchedKeys(
      Object.keys(initialValues),
      original
    )
    return {
      ...filteredValues,
      member: original.member.map((member) => {
        return {
          label: `${member.user.firstName} ${member.user.lastName}`,
          value: member.user.id
        }
      }),
      chairman:
        original.chairman &&
        original.chairman.user &&
        original.chairman.user.id,
      viceChairman:
        original.viceChairman &&
        original.viceChairman.user &&
        original.viceChairman.user.id
    }
  }, [original])

  return (
    <>
      <ActionsRenderer
        onEditClick={editOnOpen}
        onDeleteClick={deleteOnOpen}
        values={values}
        view={
          access['master-data'] &&
          access['master-data'].includes('view')
        }
        edit={
          access['master-data'] &&
          access['master-data'].includes('edit')
        }
        delete={
          access['master-data'] &&
          access['master-data'].includes('delete')
        }
      />
      <Modal
        title="Edit Committee"
        isOpen={editIsOpen}
        onClose={editOnClose}
        withActionButtons={false}
        closeOnOverlayClick={false}
        size="2xl"
      >
        {/* {console.log(defaultValues)} */}
        <Edit
          onClose={editOnClose}
          onSubmit={handleEdit}
          values={defaultValues}
          usersOptions={usersOptions}
        />
      </Modal>

      <AlertDialog
        isLoading={isLoading}
        isOpen={deleteIsOpen}
        onClose={deleteOnClose}
        onConfirm={handleDelete}
        title="Delete Committee"
      />
    </>
  )
}

export default TableActions

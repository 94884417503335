import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import useRoleAccessContext from 'containers/RoleAccess/useRoleAccessContext'
import { hasAccess } from 'utils/access'

const AccessGuard = ({ path }) => {
  const location = useLocation()
  const { access } = useRoleAccessContext()

  if (!access) return null

  const couldAccess = hasAccess(access, path)
  const from =
    location.state?.from?.pathname || location.pathname || '/'

  return couldAccess ? (
    <Outlet />
  ) : (
    <Navigate to="/unauthorized" state={{ from }} replace />
  )
}

export default AccessGuard

import useSWR from 'swr'
import { postRequest, putRequest } from 'utils/request'
import errorHandler from 'utils/errorHandler'
import {
  USERS_ROUTE,
  PROPOSAL_ROUTE,
  COMMITTEE_ROUTE,
  COMMITTEE_REPORTS_ROUTE
} from './constants'

const useUsers = () => {
  const {
    data: users,
    error: usersError,
    isValidating: usersIsValidating,
    mutate: usersRevalidate
  } = useSWR(USERS_ROUTE)

  const usersIsLoading = !users && !usersError

  return {
    users,
    usersError,
    usersIsValidating,
    usersIsLoading,
    usersRevalidate
  }
}

const useSignatory = (id) => {
  const {
    data: signatory,
    error: signatoryError,
    isValidating: signatoryIsValidating,
    mutate: signatoryRevalidate
  } = useSWR(`${PROPOSAL_ROUTE}/signatory/${id}`)

  const signatoryIsLoading = !signatory && !signatoryError

  return {
    signatory,
    signatoryError,
    signatoryIsValidating,
    signatoryIsLoading,
    signatoryRevalidate
  }
}

const useProposalReport = (id) => {
  const {
    data: report,
    error: reportError,
    isValidating: reportIsValidating,
    mutate: reportRevalidate
  } = useSWR(`${PROPOSAL_ROUTE}/order-of-business/${id}`)

  const reportIsLoading = !report && !reportError

  return {
    report,
    reportError,
    reportIsValidating,
    reportIsLoading,
    reportRevalidate
  }
}

const useCommittee = () => {
  const {
    data: committees,
    error: committeesError,
    isValidating: committeesIsValidating,
    mutate: committeesRevalidate
  } = useSWR(COMMITTEE_ROUTE)

  const committeesIsLoading = !committees && !committeesError

  return {
    committees,
    committeesError,
    committeesIsValidating,
    committeesIsLoading,
    committeesRevalidate
  }
}

const useProposal = (id, filter, page) => {
  const {
    data: proposals,
    error: proposalsError,
    isValidating: proposalsIsValidating,
    mutate: proposalsRevalidate
  } = useSWR(
    `${PROPOSAL_ROUTE}/filter/${id}?page=${page}&status=true&filter=${
      filter || 'COMMUNICATION_RECEIVED'
    }`
  )

  const proposalsIsLoading = !proposals && !proposalsError

  return {
    proposals,
    proposalsError,
    proposalsIsValidating,
    proposalsIsLoading,
    proposalsRevalidate
  }
}

const useProposalSingle = (id) => {
  const {
    data: proposal,
    error: proposalError,
    isValidating: proposalIsValidating,
    mutate: proposalRevalidate
  } = useSWR(`${PROPOSAL_ROUTE}/${id}`)

  const proposalIsLoading = !proposal && !proposalError

  return {
    proposal,
    proposalError,
    proposalIsValidating,
    proposalIsLoading,
    proposalRevalidate
  }
}

const createUser = async (payload) => {
  try {
    const user = await postRequest(USERS_ROUTE, payload)
    return user
  } catch (error) {
    return errorHandler(error)
  }
}

const createCommitteeReports = async (payload) => {
  try {
    const committeeReporets = await postRequest(
      COMMITTEE_REPORTS_ROUTE,
      payload
    )
    return committeeReporets
  } catch (error) {
    return errorHandler(error)
  }
}

const createProposal = async (id) => {
  try {
    const user = await putRequest(
      `${PROPOSAL_ROUTE}/manage/${id}`,
      {
        status: true
      }
    )
    return user
  } catch (error) {
    return errorHandler(error)
  }
}

const approvalDraft = async (id, payload) => {
  try {
    const user = await putRequest(
      `${PROPOSAL_ROUTE}/approval/${id}`,
      payload
    )
    return user
  } catch (error) {
    return errorHandler(error)
  }
}

export {
  useUsers,
  createUser,
  useProposal,
  createProposal,
  useProposalSingle,
  useCommittee,
  createCommitteeReports,
  approvalDraft,
  useProposalReport,
  useSignatory
}

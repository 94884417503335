import React from 'react'
import {
  AlertDialog as CAlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay
} from '@chakra-ui/react'

import Button from 'components/Button'

const AlertDialog = ({
  isOpen,
  onClose,
  isLoading,
  title,
  onConfirm,
  confirm
}) => {
  const cancelRef = React.useRef()

  return (
    <CAlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="2xl" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure? You can&apos;t undo this action
            afterwards.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              variant="outline"
              onClick={onClose}
            >
              Cancel
            </Button>
            {confirm ? (
              <Button
                colorScheme="blue"
                isLoading={isLoading}
                onClick={onConfirm}
                ml={3}
              >
                Confirm
              </Button>
            ) : (
              <Button
                colorScheme="red"
                isLoading={isLoading}
                onClick={onConfirm}
                ml={3}
              >
                Delete
              </Button>
            )}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </CAlertDialog>
  )
}

export default AlertDialog

const USERS_ROUTE = '/users/list'
const DESIGNATION_ROUTE = '/designations/list'
const OFFICE_ROUTE = '/office/list'
const CREATE_PERSONNEL_ROUTE = '/personnel'
const PERSONNEL_ROUTE = '/personnel'

export {
  USERS_ROUTE,
  DESIGNATION_ROUTE,
  OFFICE_ROUTE,
  CREATE_PERSONNEL_ROUTE,
  PERSONNEL_ROUTE
}

import React, { useContext } from 'react'

const DocumentTypeContext = React.createContext(null)

export const DocumentTypeProvider = ({ children, value }) => (
  <DocumentTypeContext.Provider value={value}>
    {children}
  </DocumentTypeContext.Provider>
)

export const useDocumentTypeContext = () => {
  return useContext(DocumentTypeContext)
}

export default DocumentTypeContext

import React from 'react'

import Admin from 'containers/Admin'
import useAuth from 'containers/Auth/useAuth'
import LoginPage from 'containers/LoginPage'

const HomePage = () => {
  const auth = useAuth()
  const { user } = auth

  if (user === undefined) return null
  if (user === null) return <LoginPage />

  return <Admin />
}

export default HomePage

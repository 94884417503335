import React, { useContext } from 'react'

const PublicHearingMinutesContext = React.createContext(null)

export const PublicHearingMinutesProvider = ({
  children,
  value
}) => (
  <PublicHearingMinutesContext.Provider value={value}>
    {children}
  </PublicHearingMinutesContext.Provider>
)

export const usePublicHearingMinutesContext = () => {
  return useContext(PublicHearingMinutesContext)
}

export default PublicHearingMinutesContext

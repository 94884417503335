import { Tooltip } from '@chakra-ui/react'
import styled from '@emotion/styled'
import Avatar from 'components/Avatar'
import React from 'react'

const AvatarWrapperSt = styled.div`
  .chakra-avatar__excess {
    justify-content: flex-end;
    font-size: 12px;
    padding: 4px;
  }
`

const Committee = ({ value }) => {
  if (!value) return null

  const avatars = value.map(({ committee }) => ({
    name: committee?.description
  }))

  const label = value
    .map(({ committee }) => committee?.description)
    .join(', ')

  return (
    <Tooltip label={label} fontSize="xs">
      <AvatarWrapperSt>
        <Avatar avatars={avatars} />
      </AvatarWrapperSt>
    </Tooltip>
  )
}

export default React.memo(Committee)

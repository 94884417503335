import styled from '@emotion/styled'
import { Box } from 'components/Layout'
import customScrollbar from 'theme/styles/customScrollbar'

const NavLinkWrapperSt = styled.div`
  .NavMenu {
    &.chakra-accordion__button {
      background: transparent;
      box-shadow: none;

      &:focus-visible {
        outline: auto;
      }
    }

    &.active {
      font-weight: 900;

      svg {
        font-size: 20px;
        stroke-width: 3px;
      }
    }

    &.SubMenu {
      display: block;
    }
  }
`

const NavWrapperSt = styled(Box)`
  overflow-y: overlay;
  max-height: calc(100vh - 90px);
  position: relative;
  padding-bottom: 20px;

  ${customScrollbar}
`

export { NavLinkWrapperSt, NavWrapperSt }

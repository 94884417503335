import React from 'react'
import { useDisclosure } from '@chakra-ui/react'
import { generateFormData } from 'utils/helpers'
import Modal from 'components/Modal'
import { ActionsRenderer } from 'components/CellRenderers'
import AlertDialog from 'components/AlertDialog'
import useRoleAccessContext from 'containers/RoleAccess/useRoleAccessContext'
import Edit from './Forms/Edit'
import View from './View/view'
import { useMutateMinutes, useMinutesSingle } from './api'
import { useMinutesContext } from './Context'

const TableActions = ({ row, value: id }) => {
  const { values, original } = row
  const { updateMinutes, deleteMinutes, isLoading } =
    useMutateMinutes()
  const { revalidate } = useMinutesContext()
  const { access = [] } = useRoleAccessContext()
  const { minutesSingle } = useMinutesSingle(id)

  const [contentState, setContentState] = React.useState(null)

  const {
    isOpen: editIsOpen,
    onOpen: editOnOpen,
    onClose: editOnClose
  } = useDisclosure()
  const {
    isOpen: deleteIsOpen,
    onOpen: deleteOnOpen,
    onClose: deleteOnClose
  } = useDisclosure()
  const {
    isOpen: viewIsOpen,
    onOpen: viewOnOpen,
    onClose: viewOnClose
  } = useDisclosure()

  const handleEdit = async (formValues) => {
    const { file = [], ...rest } = formValues

    const payload = {
      file: file && file.length > 0 ? file[0] : null,
      ...rest
    }

    await updateMinutes(id, generateFormData(payload))

    revalidate()
    editOnClose()
  }

  const handleDelete = async () => {
    await deleteMinutes(id)

    revalidate()
    deleteOnClose()
  }

  React.useEffect(() => {
    if (
      minutesSingle &&
      minutesSingle.data &&
      minutesSingle.data.content
    ) {
      setContentState(JSON.parse(minutesSingle.data.content))
    }
  }, [minutesSingle])

  return (
    <>
      <ActionsRenderer
        onEditClick={editOnOpen}
        onDeleteClick={deleteOnOpen}
        values={values}
        onViewClick={viewOnOpen}
        view={access.minutes && access.minutes.includes('view')}
        edit={access.minutes && access.minutes.includes('edit')}
        delete={
          access.minutes && access.minutes.includes('delete')
        }
      />
      <Modal
        title="Update Minutes"
        isOpen={editIsOpen}
        onClose={editOnClose}
        withActionButtons={false}
        closeOnOverlayClick={false}
        size="5xl"
      >
        <Edit
          onClose={editOnClose}
          onSubmit={handleEdit}
          values={original}
          contentState={contentState}
          setContentState={setContentState}
        />
      </Modal>
      <Modal
        title="View Minutes"
        isOpen={viewIsOpen}
        onClose={viewOnClose}
        withActionButtons={false}
        closeOnOverlayClick={false}
        size="5xl"
      >
        <View
          values={original}
          contentState={contentState}
          setContentState={setContentState}
        />
      </Modal>

      <AlertDialog
        isLoading={isLoading}
        isOpen={deleteIsOpen}
        onClose={deleteOnClose}
        onConfirm={handleDelete}
        title="Delete Minutes"
      />
    </>
  )
}

export default TableActions

import React, { useContext } from 'react'

const TermContext = React.createContext(null)

export const TermProvider = ({ children, value }) => (
  <TermContext.Provider value={value}>
    {children}
  </TermContext.Provider>
)

export const useTermContext = () => {
  return useContext(TermContext)
}

export default TermContext

import React from 'react'
import { FormFieldSetSt } from './styles'

const Form = React.forwardRef(
  (
    { onSubmit, children, disabled, isLoading, ...rest },
    forwardedRef
  ) => {
    return (
      <form onSubmit={onSubmit} {...rest} ref={forwardedRef}>
        <FormFieldSetSt aria-disabled={disabled || isLoading}>
          {children}
        </FormFieldSetSt>
      </form>
    )
  }
)

export default Form

/* eslint-disable react/button-has-type */
import { MembersRenderer } from 'components/CellRenderers'
import React from 'react'
import { formatDate } from 'utils/dateTime'
import TableActions from './TableActions'

const columns = [
  {
    Header: 'Title',
    accessor: 'title'
  },
  {
    Header: 'Description',
    accessor: 'description'
  },
  {
    Header: 'Term',
    accessor: (val) => {
      return val.term && val.term.description
    }
  },
  {
    Header: 'Committee',
    accessor: 'committees',
    Cell: MembersRenderer
  },
  {
    Header: 'Date',
    accessor: (val) => {
      return formatDate(val.date)
    }
  },
  {
    Header: 'Type',
    accessor: 'type.description'
  },
  {
    Header: 'File',
    accessor: (val) => {
      const func = () => {
        val.file.forEach((url) => {
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', '')
          link.style.display = 'none'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
      }
      return (
        <button onClick={() => func()} style={{ color: 'blue' }}>
          Download
        </button>
      )
    }
  },
  {
    Header: 'Action',
    accessor: 'id',
    fixed: 'right',
    Cell: TableActions
  }
]

export { columns }

import React, { useContext } from 'react'

const ArchivedContext = React.createContext(null)

export const ArchivedProvider = ({ children, value }) => (
  <ArchivedContext.Provider value={value}>
    {children}
  </ArchivedContext.Provider>
)

export const useArchivedContext = () => {
  return useContext(ArchivedContext)
}

export default ArchivedContext

import useSWR from 'swr'
import {
  deleteRequest,
  postRequest,
  putRequest
} from 'utils/request'
import errorHandler from 'utils/errorHandler'
import { useCallback, useState } from 'react'
import { callSWRFetchOnce } from 'utils/request/constant'
import { buildUrl } from 'utils/transformers/apiUrl'
import {
  DOCUMENT_TYPE_ROUTE,
  DOCUMENT_TYPE_OPTIONS_ROUTE
} from './constants'

const useFinalDocumentType = ({ page, id, search } = {}) => {
  const url = buildUrl({
    baseUrl: DOCUMENT_TYPE_ROUTE,
    page,
    id,
    search
  })

  const {
    data: finalDocumentType,
    error: finalDocumentTypeError,
    isValidating: finalDocumentTypeIsValidating,
    mutate: finalDocumentTypeRevalidate
  } = useSWR(url)

  const finalDocumentTypeIsLoading =
    !finalDocumentType &&
    !finalDocumentTypeError &&
    finalDocumentTypeIsValidating

  return {
    finalDocumentType,
    finalDocumentTypeError,
    finalDocumentTypeIsValidating,
    finalDocumentTypeIsLoading,
    finalDocumentTypeRevalidate
  }
}

const useFinalDocumentTypeOptions = () => {
  const {
    data,
    error: finalDocumentTypeOptionsError,
    isValidating: finalDocumentTypeOptionsIsValidating,
    mutate: finalDocumentTypeOptionsRevalidate
  } = useSWR(DOCUMENT_TYPE_OPTIONS_ROUTE, callSWRFetchOnce)

  const finalDocumentTypeOptions = data?.data

  const finalDocumentTypeOptionsIsLoading =
    !finalDocumentTypeOptions &&
    !finalDocumentTypeOptionsError &&
    finalDocumentTypeOptionsIsValidating

  return {
    finalDocumentTypeOptions,
    finalDocumentTypeOptionsError,
    finalDocumentTypeOptionsIsValidating,
    finalDocumentTypeOptionsIsLoading,
    finalDocumentTypeOptionsRevalidate
  }
}

const useMutateFinalDocumentType = () => {
  const [response, setResponse] = useState()
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const resetState = useCallback(() => {
    if (response) setResponse(undefined)
    if (isLoading) setError(undefined)
  }, [isLoading, response])

  const createFinalDocumentType = useCallback(
    async (payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await postRequest(
          DOCUMENT_TYPE_ROUTE,
          payload
        )
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  const updateFinalDocumentType = useCallback(
    async (id, payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await putRequest(
          `${DOCUMENT_TYPE_ROUTE}/${id}`,
          payload
        )
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  const deleteFinalDocumentType = useCallback(
    async (id) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await deleteRequest(
          `${DOCUMENT_TYPE_ROUTE}/${id}`
        )
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  return {
    data: response,
    error,
    isLoading,
    createFinalDocumentType,
    updateFinalDocumentType,
    deleteFinalDocumentType
  }
}

export {
  useFinalDocumentType,
  useFinalDocumentTypeOptions,
  useMutateFinalDocumentType
}

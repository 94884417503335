import React from 'react'
import { useDisclosure } from '@chakra-ui/react'

import PageWrapper from 'layout/PageWrapper'
import { Box } from 'components/Layout'
import Modal from 'components/Modal'
import Table from 'components/Table'
import TableHeader from 'components/Table/TableHeader'
import { FiPlus } from 'react-icons/fi'
import useRoleAccessContext from 'containers/RoleAccess/useRoleAccessContext'
import Button from 'components/Button'
import useRoutePagination from 'utils/hooks/useRoutePagination'
import useSearch from 'utils/hooks/useSearch'

import CreateUserForm from './Forms/Create'
import { columns } from './columns'
import {
  useFinalDocumentType,
  useMutateFinalDocumentType
} from './api'
import { FinalDocumentTypeProvider } from './Context'

const FinalDocumentType = () => {
  const { page } = useRoutePagination()
  const [search, setSearch] = useSearch()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { access = [] } = useRoleAccessContext()
  const {
    finalDocumentType = {},
    finalDocumentTypeIsLoading,
    finalDocumentTypeRevalidate
  } = useFinalDocumentType({ page, search })

  const { data, totalPages } = finalDocumentType

  const { createFinalDocumentType } =
    useMutateFinalDocumentType()

  const handleCreateFinalDocumentType = async (value) => {
    await createFinalDocumentType(value)

    finalDocumentTypeRevalidate()
    onClose()
  }

  return (
    <FinalDocumentTypeProvider
      value={{ revalidate: finalDocumentTypeRevalidate }}
    >
      <Box>
        <PageWrapper
          headerRightComponent={
            access['master-data'] &&
            access['master-data'].includes('create') ? (
              <Button
                leftIcon={<FiPlus />}
                onClick={() => onOpen()}
              >
                New Document Type
              </Button>
            ) : (
              false
            )
          }
        >
          <TableHeader
            totalPages={totalPages}
            onSearch={setSearch}
          />
          <Table
            isLoading={finalDocumentTypeIsLoading}
            data={data}
            columns={columns}
          />
        </PageWrapper>

        <Modal
          title="Create Document Type"
          isOpen={isOpen}
          onClose={onClose}
          withActionButtons={false}
          closeOnOverlayClick={false}
        >
          <CreateUserForm
            onClose={onClose}
            onSubmit={handleCreateFinalDocumentType}
          />
        </Modal>
      </Box>
    </FinalDocumentTypeProvider>
  )
}

export default FinalDocumentType

import { InputGroup } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { Flex } from 'components/Layout'

const ActionButtonsWrapperSt = styled(Flex)`
  z-index: 10;
  pointer-events: none;

  &.Show__Button--delete {
    pointer-events: auto;
  }

  .Button__Delete {
    z-index: 10;
    opacity: 0;
    transform: translateX(-10px);
    transition: 0.5s ease opacity, 0.5s ease transform;

    svg {
      stroke: var(--chakra-colors-red-500);
    }
  }
`

const InputGroupSt = styled(InputGroup)`
  && {
    cursor: pointer;
    width: unset;
  }

  &:hover {
    ${ActionButtonsWrapperSt} {
      &.Show__Button--delete {
        .Button__Delete {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }
  }
`

const IconWrapperSt = styled.span`
  font-size: 24px;
  border-radius: 100%;
  z-index: 20;

  svg {
    stroke: var(--chakra-colors-teal-500);
    fill: #fff;
    stroke-width: 3px;
  }
`

export { InputGroupSt, IconWrapperSt, ActionButtonsWrapperSt }

import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { Flex, Stack } from 'components/Layout'
import Button from 'components/Button'
import Form from 'components/Form'
import FormField from 'components/FormField'
import TextArea from 'components/TextArea'

import { model } from './schema'

const Edit = ({ onClose, values, onSubmit }) => {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty }
  } = useForm({
    resolver: yupResolver(model),
    defaultValues: values
  })

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      disabled={isSubmitting}
    >
      <Stack spacing={6} pb={2}>
        <FormField
          id="recommendation"
          isInvalid={errors.recommendation}
          error={errors.recommendation}
          label="Recommendation"
        >
          <TextArea
            id="recommendation"
            placeholder="Recommendation"
            {...register('recommendation')}
            readOnly
          />
        </FormField>
        <FormField
          id="findings"
          isInvalid={errors.findings}
          error={errors.findings}
          label="Findings"
        >
          <TextArea
            id="findings"
            placeholder="Findings"
            {...register('findings')}
          />
        </FormField>
        <FormField
          id="ammendments"
          isInvalid={errors.ammendments}
          error={errors.ammendments}
          label="Remarks"
        >
          <TextArea
            id="ammendments"
            placeholder="Remarks"
            {...register('ammendments')}
          />
        </FormField>
        <Flex mt={5} justify="flex-end">
          <Button mr={2} variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            isLoading={isSubmitting}
            disabled={isSubmitting || !isDirty}
            type="submit"
          >
            Submit
          </Button>
        </Flex>
      </Stack>
    </Form>
  )
}

export default Edit

import * as yup from 'yup'

const messages = {
  requiredGeneric: 'This field is required',
  codeMin: 'Code should be 4 characters and above'
}

const model = yup.object({
  code: yup
    .string()
    .min(4, messages.codeMin)
    .required(messages.requiredGeneric),
  userId: yup.string().required(messages.requiredGeneric),
  designationId: yup.string().required(messages.requiredGeneric),
  officeId: yup.string().required(messages.requiredGeneric),
  status: yup.boolean().required(messages.requiredGeneric),
  viceMayor: yup.boolean().required(messages.requiredGeneric),
  secretary: yup.boolean().required(messages.requiredGeneric),
  councilors: yup.boolean().required(messages.requiredGeneric)
})

const initialValues = {
  code: '',
  userId: '',
  designationId: '',
  officeId: '',
  status: false,
  viceMayor: false,
  secretary: false,
  councilors: false
}

export { model, initialValues }

import React from 'react'
import { useForm } from 'react-hook-form'
import FileViewer from 'react-file-viewer-plugins-v3'
import ReactSelect from 'components/ReactSelect'
import Form from 'components/Form'
import FormField from 'components/FormField'
import { Stack } from 'components/Layout'

import { yupResolver } from '@hookform/resolvers/yup'
import { useSession } from 'containers/Admin/Minutes/api'
import { model } from '../Forms/schema'

import { SESSION_TYPE_ROUTES } from '../constants'

const View = ({ values }) => {
  const {
    control,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(model),
    defaultValues: {
      ...values
    }
  })
  const { session } = useSession()

  return (
    <Form>
      <Stack spacing={6} pb={2}>
        <FormField
          id="sessionId"
          isInvalid={errors.sessionId}
          error={errors.sessionId}
          label="Session"
          mr={4}
        >
          <ReactSelect
            id="sessionId"
            name="sessionId"
            placeholder="Select Session..."
            control={control}
            options={session?.data || []}
            readOnly
          />
        </FormField>
        <FormField
          id="sessionType"
          isInvalid={errors.sessionType}
          error={errors.sessionType}
          label="Session Type"
          mr={4}
        >
          <ReactSelect
            id="sessionType"
            name="sessionType"
            placeholder="Select Session Type..."
            control={control}
            options={SESSION_TYPE_ROUTES || []}
            readOnly
          />
        </FormField>
        {values && values.file && (
          <FileViewer
            style={{ width: '20%' }}
            fileType="pdf"
            filePath={values?.file}
          />
        )}
      </Stack>
    </Form>
  )
}

export default View

import React from 'react'
import { useDisclosure } from '@chakra-ui/react'

import useRoutePagination from 'utils/hooks/useRoutePagination'
import useSearch from 'utils/hooks/useSearch'
import { FiPlus } from 'react-icons/fi'
import useRoleAccessContext from 'containers/RoleAccess/useRoleAccessContext'
import Button from 'components/Button'
import PageWrapper from 'layout/PageWrapper'
import { Box } from 'components/Layout'
import Modal from 'components/Modal'
import Table from 'components/Table'
import TableHeader from 'components/Table/TableHeader'

import Create from './Forms/Create'

import { DesignationProvider } from './Context'
import { columns } from './columns'
import { useDesignation, useMutateDesignation } from './api'

const Designation = () => {
  const { page } = useRoutePagination()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { createDesignation } = useMutateDesignation()
  const [search, setSearch] = useSearch()
  const { access = [] } = useRoleAccessContext()

  const {
    designations = {},
    designationsIsLoading,
    designationsRevalidate
  } = useDesignation({ page, search })

  const { data, totalPages } = designations

  const handleCreateDesignation = async (value) => {
    await createDesignation(value)

    designationsRevalidate()
    onClose()
  }

  return (
    <DesignationProvider
      value={{ revalidate: designationsRevalidate }}
    >
      <Box>
        <PageWrapper
          headerRightComponent={
            access['master-data'] &&
            access['master-data'].includes('create') ? (
              <Button
                leftIcon={<FiPlus />}
                onClick={() => onOpen()}
              >
                New Designation
              </Button>
            ) : (
              false
            )
          }
        >
          <TableHeader
            totalPages={totalPages}
            onSearch={setSearch}
          />
          <Table
            isLoading={designationsIsLoading}
            data={data}
            columns={columns}
          />
        </PageWrapper>

        <Modal
          title="Create Designation"
          isOpen={isOpen}
          onClose={onClose}
          withActionButtons={false}
          closeOnOverlayClick={false}
        >
          <Create
            onClose={onClose}
            onSubmit={handleCreateDesignation}
          />
        </Modal>
      </Box>
    </DesignationProvider>
  )
}

export default React.memo(Designation)

import React from 'react'
import {
  FiEdit2,
  FiSettings,
  FiEye,
  FiTrash
} from 'react-icons/fi'
import { Tooltip } from '@chakra-ui/react'
import Button from 'components/Button'
import { HStack } from 'components/Layout'

const ActionsWithJoin = ({
  values,
  deleteTooltipLabel,
  onEditClick,
  onDeleteClick,
  onJoinClick,
  orOrderOfBusinessClick,
  create,
  update,
  delete: remove,
  view
}) => {
  const couldJoin = true

  return (
    <HStack direction="row">
      {view && (
        <Tooltip label="Order Of Business" fontSize="xs">
          <Button
            onClick={onJoinClick}
            size="sm"
            variant="outline"
            colorScheme="teal"
            disabled={!couldJoin}
          >
            <FiEye />
          </Button>
        </Tooltip>
      )}
      {(create || update) && (
        <Tooltip label="Manage Proposals" fontSize="xs">
          <Button
            colorScheme="green"
            onClick={orOrderOfBusinessClick}
            size="sm"
            disabled={!couldJoin}
          >
            <FiSettings />
          </Button>
        </Tooltip>
      )}
      {update && (
        <Button
          colorScheme="blue"
          onClick={onEditClick}
          variant="outline"
          size="sm"
        >
          <FiEdit2 />
        </Button>
      )}
      {remove && (
        <Tooltip
          label={deleteTooltipLabel ?? `Delete ${values?.code}`}
          fontSize="xs"
        >
          <Button
            colorScheme="red"
            variant="solid"
            size="sm"
            onClick={onDeleteClick}
            _focus={{
              boxShadow: '0 0 0 3px rgba(229, 62, 62, 0.5)'
            }}
          >
            <FiTrash />
          </Button>
        </Tooltip>
      )}
    </HStack>
  )
}

export default React.memo(ActionsWithJoin)

/* eslint-disable import/no-cycle */
import TableActions from './TableActions'
import TableDetailsActions from './TableDetailsActions'

const committeeReferralColumn = [
  {
    Header: 'Committee',
    accessor: (val) => {
      return val['committee.description']
    }
  },
  {
    Header: 'Total',
    accessor: 'count'
  },
  {
    Header: 'Action',
    accessor: 'id',
    Cell: TableActions
  }
]

const measuresColumn = [
  {
    Header: 'Code',
    accessor: (val) => {
      let data = ''
      if (val && val.draftdocument) {
        data = val.draftdocument.draftCode
      }
      return data
    }
  },
  {
    Header: 'Title',
    accessor: (val) => {
      let data = ''
      if (val && val.draftdocument) {
        data =
          val.draftdocument.shortTitle.length > 50
            ? val.draftdocument.shortTitle.substring(0, 50)
            : val.draftdocument.shortTitle
      }
      return data
    }
  },
  {
    Header: 'Role',
    accessor: (val) => {
      function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      }
      let data = ''
      if (val && val.role) {
        data = capitalizeFirstLetter(val.role)
      }
      return data
    }
  },
  {
    Header: 'Action',
    accessor: 'id',
    Cell: TableDetailsActions
  }
]

const viewColumn = [
  {
    Header: 'Draft Code',
    accessor: (val) => {
      let data = ''
      if (
        val &&
        val.committeedocument &&
        val.committeedocument.code
      ) {
        data = val.committeedocument.code
      } else {
        data = `${val.draftdocument.draftCode}`
      }
      return data
    }
  },
  {
    Header: 'Title',
    accessor: (val) => {
      let data = ''
      if (
        val &&
        val.committeedocument &&
        val.committeedocument.shortTitle
      ) {
        data = val.committeedocument.shortTitle
      } else {
        data = `${val.draftdocument.shortTitle}`
      }
      return data
    }
  },
  {
    Header: 'Action',
    accessor: 'id',
    Cell: TableDetailsActions
  }
]
export { committeeReferralColumn, measuresColumn, viewColumn }

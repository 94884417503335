import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react'
import { SWRConfig } from 'swr'

import theme from 'theme'

import { fetcher } from 'utils/request'
import EntryContainer from 'containers/EntryContainer'

const App = () => (
  <BrowserRouter>
    <SWRConfig value={{ fetcher, errorRetryCount: 1 }}>
      <ChakraProvider theme={theme}>
        <EntryContainer />
      </ChakraProvider>
    </SWRConfig>
  </BrowserRouter>
)

export default App

import React from 'react'
import { useDisclosure } from '@chakra-ui/react'

import { Box } from 'components/Layout'
import Modal from 'components/Modal'
import Table from 'components/Table'
import { FiPlus } from 'react-icons/fi'
import useRoleAccessContext from 'containers/RoleAccess/useRoleAccessContext'
import Button from 'components/Button'
import PageWrapper from 'layout/PageWrapper'
import useRoutePagination from 'utils/hooks/useRoutePagination'
import useSearch from 'utils/hooks/useSearch'
import TableHeader from 'components/Table/TableHeader'
import Create from './Forms/Create'

import { columns } from './columns'
import { useLegislation, useMutateLegislation } from './api'
import { LegislationProvider } from './Context'

const Legislation = () => {
  const { page } = useRoutePagination()
  const [search, setSearch] = useSearch()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { access = [] } = useRoleAccessContext()
  const {
    legislation = {},
    legislationIsLoading,
    legislationRevalidate
  } = useLegislation({ page, search })

  const { data, totalPages } = legislation

  const { createLegislation } = useMutateLegislation()

  const handleCreateLegislation = async (value) => {
    await createLegislation(value)

    legislationRevalidate()
    onClose()
  }

  return (
    <LegislationProvider
      value={{ revalidate: legislationRevalidate }}
    >
      <Box>
        <PageWrapper
          headerRightComponent={
            access['master-data'] &&
            access['master-data'].includes('create') ? (
              <Button
                leftIcon={<FiPlus />}
                onClick={() => onOpen()}
              >
                New Legislation
              </Button>
            ) : (
              false
            )
          }
        >
          <TableHeader
            totalPages={totalPages}
            onSearch={setSearch}
          />
          <Table
            isLoading={legislationIsLoading}
            data={data}
            columns={columns}
          />
        </PageWrapper>

        <Modal
          title="Create Legislation"
          isOpen={isOpen}
          onClose={onClose}
          withActionButtons={false}
          closeOnOverlayClick={false}
        >
          <Create
            onClose={onClose}
            onSubmit={handleCreateLegislation}
          />
        </Modal>
      </Box>
    </LegislationProvider>
  )
}

export default React.memo(Legislation)

import React from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon
} from '@chakra-ui/react'
import { NavLink, useLocation } from 'react-router-dom'
import { Box, Flex } from 'components/Layout'
import Icon from 'components/Icon'
import { NavLinkWrapperSt } from './styles'

const NavTitle = ({
  label,
  icon,
  withSubmenu,
  offsetIcon,
  ...rest
}) => {
  return (
    <Flex
      align="center"
      pr={4}
      py={3}
      pl={4}
      w="100%"
      justifyContent="space-between"
      borderRadius="lg"
      role="group"
      cursor="pointer"
      _hover={{
        bg: 'gray.200'
      }}
      {...rest}
    >
      <Flex align="center">
        {icon && <Icon mr="4" as={icon} />}
        {label}
      </Flex>

      {withSubmenu && <AccordionIcon />}
    </Flex>
  )
}

const NavItem = ({ icon, children, subMenu, to }) => {
  const { pathname } = useLocation()

  const getClassName = ({ isActive }, className) => {
    const classes = ['NavMenu']
    if (isActive) classes.push('active')
    if (className) classes.push(className)

    return classes.join(' ')
  }

  const getAccordionClass = () => {
    const [, ...basePath] = pathname.split('/')
    const subMenuLinks = subMenu?.map((menu) => {
      if (!menu.to.includes('/')) return menu.to

      const [, basicSubMenuPath] = menu.to.split('/')
      return basicSubMenuPath
    })
    const classes = ['NavMenu']

    if (subMenuLinks.includes(basePath[basePath.length - 1])) {
      classes.push('active')
    }

    return classes.join(' ')
  }

  return subMenu ? (
    <Box px={10}>
      <Accordion allowToggle>
        <AccordionItem style={{ border: 0 }}>
          <NavLinkWrapperSt>
            <AccordionButton
              className={getAccordionClass()}
              padding={0}
            >
              <NavTitle
                icon={icon}
                label={children}
                withSubmenu={!!subMenu}
              />
            </AccordionButton>
            <AccordionPanel pt={0} pb={1} pl="33px" pr={0}>
              {subMenu.map((menu) => (
                <NavLink
                  key={menu.name}
                  to={menu.to}
                  className={(props) =>
                    getClassName(props, 'SubMenu')
                  }
                >
                  <NavTitle label={menu.name} offsetIcon />
                </NavLink>
              ))}
            </AccordionPanel>
          </NavLinkWrapperSt>
        </AccordionItem>
      </Accordion>
    </Box>
  ) : (
    <Box px={10} py={1}>
      <NavLinkWrapperSt>
        <NavLink to={to} className={getClassName}>
          <NavTitle icon={icon} label={children} />
        </NavLink>
      </NavLinkWrapperSt>
    </Box>
  )
}

export default NavItem

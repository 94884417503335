import * as yup from 'yup'

const messages = {
  requiredGeneric: 'This field is required',
  codeMin: 'Code should be 4 characters and above',
  membersMin: 'You should select at least (1) member'
}

const model = yup.object({
  code: yup.string().required(messages.requiredGeneric),
  term: yup.string().required(messages.requiredGeneric),
  description: yup.string().required(messages.requiredGeneric),
  chairman: yup.string().required(messages.requiredGeneric),
  viceChairman: yup.string().required(messages.requiredGeneric),
  member: yup
    .array()
    .min(1, messages.memberMin)
    .required(messages.requiredGeneric),
  status: yup.boolean().required(messages.requiredGeneric)
})

const initialValues = {
  code: '',
  term: '',
  description: '',
  chairman: '',
  viceChairman: '',
  member: '',
  status: false
}

export { model, initialValues }

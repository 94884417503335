import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import Button from 'components/Button'
import Form from 'components/Form'
import FormField from 'components/FormField'
import TextArea from 'components/TextArea'
import DatePicker from 'components/DatePicker'
import { Flex, Stack } from 'components/Layout'

import ReactSelect from 'components/ReactSelect'
import FileUploader from 'components/FileUploader'
import { model } from './schema'
import { useTerm, useCommittee } from '../api'

const Create = ({ onClose, onSubmit, documentTypeOptions }) => {
  const {
    handleSubmit,
    register,
    control,
    resetField,
    formState: { errors, isSubmitting, isDirty }
  } = useForm({
    resolver: yupResolver(model)
  })

  const { term } = useTerm()
  const { committee } = useCommittee()

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      disabled={isSubmitting}
    >
      <Stack spacing={6} pb={2}>
        <FormField
          id="title"
          isInvalid={errors.title}
          error={errors.title}
          label="Title"
        >
          <TextArea
            id="title"
            placeholder="Title"
            {...register('title')}
          />
        </FormField>

        <FormField
          id="description"
          isInvalid={errors.description}
          error={errors.description}
          label="Description"
        >
          <TextArea
            id="description"
            placeholder="Description"
            {...register('description')}
          />
        </FormField>
        <FormField
          id="termId"
          isInvalid={errors.termId}
          error={errors.termId}
          label="Term"
        >
          <ReactSelect
            id="termId"
            name="termId"
            placeholder="Select Term..."
            control={control}
            options={term?.data || []}
          />
        </FormField>
        <FormField
          id="committeeId"
          isInvalid={errors.committeeId}
          error={errors.committeeId}
          label="Committee"
        >
          <ReactSelect
            id="committeeId"
            name="committeeId"
            placeholder="Select Committee..."
            control={control}
            isMulti
            options={committee || []}
          />
        </FormField>
        <FormField
          id="date"
          isInvalid={errors.date}
          error={errors.date}
          label="Date Adopted"
        >
          <DatePicker
            id="date"
            name="date"
            showYearDropdown
            control={control}
            dateFormatCalendar="MMMM"
            maxDate={new Date()}
            placeholderText="Date"
            yearDropdownItemNumber={100}
            scrollableYearDropdown
            showMonthDropdown
          />
        </FormField>
        <FormField
          id="documentType"
          isInvalid={errors.documentType}
          error={errors.documentType}
          label="Document Type"
        >
          <ReactSelect
            id="documentType"
            name="documentType"
            placeholder="Select Document Type..."
            control={control}
            options={documentTypeOptions}
          />
        </FormField>

        <FormField
          id="file"
          isInvalid={errors.file}
          error={errors.file}
          label="File (PDF only)"
        >
          <FileUploader
            placeholder="Select File"
            id="file"
            multiple
            onReset={() => resetField('file')}
            {...register('file')}
          />
        </FormField>

        <Flex mt={5} justify="flex-end">
          <Button mr={2} variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            isLoading={isSubmitting}
            disabled={isSubmitting || !isDirty}
            type="submit"
          >
            Submit
          </Button>
        </Flex>
      </Stack>
    </Form>
  )
}

export default Create

import useSWR from 'swr'
import {
  deleteRequest,
  postRequest,
  putRequest
} from 'utils/request'
import errorHandler from 'utils/errorHandler'
import { useCallback, useState } from 'react'
import { buildUrl } from 'utils/transformers/apiUrl'
import {
  PUBLIC_HEARING_MINUTES_ROUTE,
  COMMITTEE_ROUTE,
  SESSION_ROUTE
} from './constants'

const usePublicHearingMinutes = ({
  page,
  id,
  search,
  year,
  committeeData
} = {}) => {
  const url = buildUrl({
    baseUrl: PUBLIC_HEARING_MINUTES_ROUTE,
    page,
    id,
    search,
    year,
    committeeData
  })

  const {
    data: publicHearingMinutes,
    error: publicHearingMinutesError,
    isValidating: publicHearingMinutesIsValidating,
    mutate: publicHearingMinutesRevalidate
  } = useSWR(url)

  const publicHearingMinutesIsLoading =
    !publicHearingMinutes &&
    !publicHearingMinutesError &&
    publicHearingMinutesIsValidating

  return {
    publicHearingMinutes,
    publicHearingMinutesError,
    publicHearingMinutesIsValidating,
    publicHearingMinutesIsLoading,
    publicHearingMinutesRevalidate
  }
}

const useCommittee = () => {
  const url = buildUrl({
    baseUrl: COMMITTEE_ROUTE
  })

  const {
    data: committee,
    error: committeeError,
    isValidating: committeeIsValidating,
    mutate: committeeRevalidate
  } = useSWR(url)

  const committeeIsLoading =
    !committee && !committeeError && committeeIsValidating

  return {
    committee,
    committeeError,
    committeeIsValidating,
    committeeIsLoading,
    committeeRevalidate
  }
}

const useSession = () => {
  const url = buildUrl({
    baseUrl: SESSION_ROUTE
  })

  const {
    data: session,
    error: sessionError,
    isValidating: sessionIsValidating,
    mutate: sessionRevalidate
  } = useSWR(url)

  const sessionIsLoading =
    !session && !sessionError && sessionIsValidating

  return {
    session,
    sessionError,
    sessionIsValidating,
    sessionIsLoading,
    sessionRevalidate
  }
}

const usePublicHearingMinutesSingle = (id) => {
  const url = buildUrl({
    baseUrl: `${PUBLIC_HEARING_MINUTES_ROUTE}/${id}`
  })

  const {
    data: publicHearingMinutesSingle,
    error: publicHearingMinutesSingleError,
    isValidating: publicHearingMinutesSingleIsValidating,
    mutate: publicHearingMinutesSingleRevalidate
  } = useSWR(url)

  const publicHearingMinutesSingleIsLoading =
    !publicHearingMinutesSingle &&
    !publicHearingMinutesSingleError &&
    publicHearingMinutesSingleIsValidating

  return {
    publicHearingMinutesSingle,
    publicHearingMinutesSingleError,
    publicHearingMinutesSingleIsValidating,
    publicHearingMinutesSingleIsLoading,
    publicHearingMinutesSingleRevalidate
  }
}

const useMutatePublicHearingMinutes = () => {
  const [response, setResponse] = useState()
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const resetState = useCallback(() => {
    if (response) setResponse(undefined)
    if (isLoading) setError(undefined)
  }, [isLoading, response])

  const createPublicHearingMinutes = useCallback(
    async (payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await postRequest(
          `${PUBLIC_HEARING_MINUTES_ROUTE}?type=publichearing`,
          payload
        )
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  const updatePublicHearingMinutes = useCallback(
    async (id, payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await putRequest(
          `${PUBLIC_HEARING_MINUTES_ROUTE}/${id}`,
          payload
        )
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  const deletePublicHearingMinutes = useCallback(
    async (id) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await deleteRequest(
          `${PUBLIC_HEARING_MINUTES_ROUTE}/${id}`
        )
        setResponse(data)
      } catch (err) {
        setError(errorHandler(err))
      }
      setIsLoading(false)
    },
    [resetState]
  )

  return {
    data: response,
    error,
    isLoading,
    createPublicHearingMinutes,
    updatePublicHearingMinutes,
    deletePublicHearingMinutes
  }
}

export {
  usePublicHearingMinutes,
  useMutatePublicHearingMinutes,
  useCommittee,
  useSession,
  usePublicHearingMinutesSingle
}

import {
  ActiveRenderer,
  MembersRenderer
} from 'components/CellRenderers'
import TableActions from './TableActions'

const columns = [
  {
    Header: 'Code',
    accessor: 'code'
  },
  {
    Header: 'Term',
    accessor: (val) => {
      return val.termdata && val.termdata.description
    }
  },
  {
    Header: 'Description',
    accessor: 'description'
  },
  {
    Header: 'Chairperson',
    accessor: (val) => {
      return `${
        val.chairman &&
        val.chairman.user &&
        val.chairman.user.firstName
      } ${
        val.chairman &&
        val.chairman.user &&
        val.chairman.user.lastName
      }`
    }
  },
  {
    Header: 'Vice Chairperson',
    accessor: (val) => {
      return `${
        val.viceChairman &&
        val.viceChairman.user &&
        val.viceChairman.user.firstName
      } ${
        val.viceChairman &&
        val.viceChairman.user &&
        val.viceChairman.user.lastName
      }`
    }
  },
  {
    Header: 'Members',
    accessor: 'member',
    Cell: MembersRenderer
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ActiveRenderer
  },
  {
    Header: 'Action',
    accessor: 'id',
    fixed: 'right',
    Cell: TableActions
  }
]

export { columns }

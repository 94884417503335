import styled from '@emotion/styled'
import { Box, Flex } from 'components/Layout'
import customScrollbar from 'theme/styles/customScrollbar'

const HEADER_HEIGHT = '55px'

const HeaderSt = styled(Flex)``

const NotificationWrapperSt = styled(Box)`
  max-height: 50vh;
  overflow: overlay;

  ${customScrollbar}
`

const RedDotSt = styled.span`
  position: absolute;
  width: 15px;
  aspect-ratio: 1/1;
  background-color: var(--chakra-colors-red-500);
  border-radius: 100%;
  top: 5px;
  right: 0;
  border: 3px solid #fff;
`

export {
  HeaderSt,
  HEADER_HEIGHT,
  NotificationWrapperSt,
  RedDotSt
}

import { useState } from 'react'
import { debounce } from 'utils/helpers'

const useSearch = () => {
  const [search, setSearch] = useState()
  const delayedSetSearch = debounce((value) => {
    setSearch(value)
  }, 250)

  const handleSearch = (value) => {
    delayedSetSearch(value)
  }

  return [search, handleSearch]
}

export default useSearch

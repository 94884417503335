import React from 'react'
import { useDisclosure } from '@chakra-ui/react'

import Modal from 'components/Modal'
import { Box } from 'components/Layout'
import Table from 'components/Table'
import { FiPlus } from 'react-icons/fi'
import useRoleAccessContext from 'containers/RoleAccess/useRoleAccessContext'
import Button from 'components/Button'
import PageWrapper from 'layout/PageWrapper'
import useRoutePagination from 'utils/hooks/useRoutePagination'
import useSearch from 'utils/hooks/useSearch'
import TableHeader from 'components/Table/TableHeader'
import CreateUserForm from './Forms/Create'

import { columns } from './columns'
import {
  useUsers,
  useOffice,
  useDesignations,
  useMutatePersonnel,
  usePersonnel
} from './api'
import { PersonnelCodeProvider } from './Context'

const PersonnelCode = () => {
  const { page } = useRoutePagination()
  const { users } = useUsers()
  const { office } = useOffice()
  const { designations } = useDesignations()

  const [search, setSearch] = useSearch()
  const { access = [] } = useRoleAccessContext()
  const {
    personnel = {},
    personnelIsLoading,
    personnelRevalidate
  } = usePersonnel({ page, search })

  const { totalPages, data } = personnel

  const { createPersonnel } = useMutatePersonnel()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleCreatePersonnel = async (value) => {
    await createPersonnel(value)
    personnelRevalidate()
    onClose()
  }

  return (
    <PersonnelCodeProvider
      value={{ revalidate: personnelRevalidate }}
    >
      <Box>
        <PageWrapper
          headerRightComponent={
            access['master-data'] &&
            access['master-data'].includes('create') ? (
              <Button
                leftIcon={<FiPlus />}
                onClick={() => onOpen()}
              >
                New Personnel Code
              </Button>
            ) : (
              false
            )
          }
        >
          <TableHeader
            totalPages={totalPages}
            onSearch={setSearch}
          />
          <Table
            isLoading={personnelIsLoading}
            data={data}
            columns={columns}
          />
        </PageWrapper>

        <Modal
          title="Create Personnel Code"
          isOpen={isOpen}
          onClose={onClose}
          withActionButtons={false}
          closeOnOverlayClick={false}
          size="xl"
        >
          <CreateUserForm
            users={users || []}
            office={office && office.data ? office.data : []}
            designations={
              designations && designations.data
                ? designations.data
                : []
            }
            onSubmit={handleCreatePersonnel}
            onClose={onClose}
          />
        </Modal>
      </Box>
    </PersonnelCodeProvider>
  )
}

export default PersonnelCode

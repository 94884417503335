import React from 'react'
import FileViewer from 'react-file-viewer-plugins-v3'
import { useForm } from 'react-hook-form'
import Input from 'components/Input'
import Form from 'components/Form'
import FormField from 'components/FormField'
import { Stack } from 'components/Layout'
import { yupResolver } from '@hookform/resolvers/yup'
import { model } from '../Forms/schema'

const View = ({ values }) => {
  const {
    formState: { errors },
    register
  } = useForm({
    resolver: yupResolver(model),
    defaultValues: {
      ...values
    }
  })

  return (
    <Form>
      <Stack spacing={6} pb={2}>
        <FormField
          id="description"
          isInvalid={errors.description}
          error={errors.description}
          label="Description"
          mr={4}
        >
          <Input
            id="description"
            name="description"
            placeholder="Description..."
            readOnly
            {...register('description', model.description)}
          />
        </FormField>
        {values.file && (
          <a href={values?.file} style={{ color: 'blue' }}>
            Download
          </a>
        )}
        {values && values.file && (
          <FileViewer fileType="pdf" filePath={values?.file} />
        )}
      </Stack>
    </Form>
  )
}

export default View

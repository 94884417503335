import React from 'react'
import { useNavigate } from 'react-router-dom'

import { noop } from 'utils/helpers'

import { ButtonSt } from './styles'

const Button = React.forwardRef(
  (
    {
      isLoading,
      children,
      type,
      link,
      to,
      isExternal,
      onClick,
      colorScheme,
      variant,
      fontWeight,
      _focus,
      ...rest
    },
    forwardedRef
  ) => {
    const navigate = useNavigate()

    const handleClick = (event) => {
      if (link) navigate(to)
      else onClick(event)
    }

    return (
      <ButtonSt
        ref={forwardedRef}
        type={type}
        isLoading={isLoading}
        colorScheme={colorScheme}
        variant={variant}
        onClick={handleClick}
        fontWeight={fontWeight}
        _focus={_focus}
        {...rest}
      >
        {children}
      </ButtonSt>
    )
  }
)

Button.defaultProps = {
  onClick: noop,
  colorScheme: 'brand',
  variant: 'solid',
  fontWeight: 500,
  _focus: {
    boxShadow: '0 0 0 3px var(--chakra-colors-brand-300)'
  }
}

export default Button

import React from 'react'
import { TextAreaSt } from './styles'

const Textarea = React.forwardRef(
  ({ children, ...rest }, forwardedRef) => {
    return (
      <TextAreaSt
        fontWeight={500}
        ref={forwardedRef}
        focusBorderColor="teal.500"
        {...rest}
      >
        {children}
      </TextAreaSt>
    )
  }
)
export default Textarea

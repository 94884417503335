import { formatDate } from 'utils/dateTime'
import { MembersRenderer } from 'components/CellRenderers'
import { DATE_TIME_FORMAT } from 'utils/dateTime/constants'
import TableActions from './TableActions'

const columns = [
  {
    Header: 'Document No.',
    accessor: (val) => {
      let data = ''
      if (val && val.document) {
        data = val.document.documentCode
      }
      return data
    }
  },
  {
    Header: 'Title',
    accessor: (val) => {
      let data = ''
      if (val && val.document) {
        data = val.document.shortTitle
      }
      return data
    }
  },
  {
    Header: 'Office',
    accessor: 'offices',
    Cell: MembersRenderer
  },
  {
    Header: 'Date Sent',
    accessor: (val) => {
      return formatDate(val.dateReleased, DATE_TIME_FORMAT)
    }
  },
  {
    Header: 'Date Received',
    accessor: (val) => {
      return formatDate(val.dateReceived, DATE_TIME_FORMAT)
    }
  },
  {
    Header: 'Status/Remarks',
    accessor: 'remarks'
  },
  {
    Header: 'Action',
    accessor: 'id',
    fixed: 'right',
    Cell: TableActions
  }
]

export { columns }

import { css } from '@emotion/react'

const customScrollbar = css`
  &:hover {
    &::-webkit-scrollbar {
      display: block;
    }
  }

  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 100px;
    display: none;
  }

  &::-webkit-scrollbar:hover {
    background-color: rgba(0, 0, 0, 0.09);
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 100px;
  }
  &::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, 0.61);
  }
`

export default customScrollbar

import * as yup from 'yup'

const model = yup.object({
  file: yup.mixed().nullable(),
  description: yup.string()
})

const initialValues = {
  file: '',
  descrption: ''
}

export { model, initialValues }

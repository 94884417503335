const USERS_ROUTE = '/api/users'
const DELETE_FILE_ROUTE = '/draft-document/file'
const DELETE_DOCFILE_ROUTE = '/draft-document/document-file'
const DOCUMENT_TYPE_OPTIONS = [
  {
    label: 'Resolution',
    value: 'RESOLUTION'
  },
  {
    label: 'Ordinance',
    value: 'ORDINANCE'
  }
]

export {
  USERS_ROUTE,
  DOCUMENT_TYPE_OPTIONS,
  DELETE_FILE_ROUTE,
  DELETE_DOCFILE_ROUTE
}

import React, { useRef, useState } from 'react'

import { Box, Flex } from 'components/Layout'

import PageWrapper from 'layout/PageWrapper'
import Button from 'components/Button'
import { formatToISOString } from 'utils/dateTime'
import useToast from 'utils/hooks/useToast'
import { generateFormData } from 'utils/helpers'
import Form from './Form'
import { useDocumentTypeOptions } from '../DocumentType/api'
import {
  useMutateDraftDocument,
  useCommittee
} from '../DraftDocument/api'
import { useTerm } from './api'
import { useUsersOptions } from '../UserManagement/api'

const DraftDocumentCreate = () => {
  const { documentTypeOptions } = useDocumentTypeOptions()
  const { createDraftDocument } = useMutateDraftDocument()
  const { term } = useTerm()
  const { usersOptions } = useUsersOptions()
  const { committee } = useCommittee()
  const toast = useToast()

  const formRef = useRef(null)
  const [formState, setFormState] = useState({})
  const [author, setAuthor] = useState([])
  const [coAuthor, setCoAuthor] = useState([])
  const { isSubmitting, isDirty } = formState

  const handleSubmitClick = () => {
    formRef.current.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true })
    )
  }

  const onSubmit = async (formValues, callback) => {
    const { file = [], documentFile = [], ...rest } = formValues
    const finalAuthor = author.map(({ value }) => value)
    const finalCoAuthor = coAuthor.map(({ value }) => value)
    const payload = {
      dateFiled: formatToISOString(new Date()),
      file,
      documentFile,
      author: finalAuthor,
      coAuthor: finalCoAuthor,
      ...rest
    }
    const data = await createDraftDocument(
      generateFormData(payload)
    )
    if (!data) return

    toast({
      title: 'Draft document created.',
      status: 'success',
      description: "We've created your draft document."
    })

    if (callback) callback()
  }

  return (
    <Box>
      <PageWrapper
        label="Create Draft Document"
        headerRightComponent={
          <Button
            onClick={handleSubmitClick}
            isLoading={isSubmitting}
            disabled={!isDirty || isSubmitting}
          >
            Save Draft
          </Button>
        }
      >
        <Form
          formRef={formRef}
          onSubmit={onSubmit}
          term={term}
          documentTypeOptions={documentTypeOptions}
          formState={formState}
          setFormState={setFormState}
          usersOptions={usersOptions}
          committee={committee}
          author={author}
          setAuthor={setAuthor}
          coAuthor={coAuthor}
          setCoAuthor={setCoAuthor}
        />
        <Flex mt={5} justify="flex-end">
          <Button
            onClick={handleSubmitClick}
            isLoading={isSubmitting}
            disabled={!isDirty || isSubmitting}
          >
            Save Draft
          </Button>
        </Flex>
      </PageWrapper>
    </Box>
  )
}

export default DraftDocumentCreate

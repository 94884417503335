import React from 'react'

import Tag from 'components/Tag'

const getTagColor = (value) => (value ? 'green' : 'red')

const Active = ({ value, cell }) => {
  return (
    <Tag
      size="md"
      key="md"
      w={86}
      display="flex"
      justifyContent="center"
      colorScheme={getTagColor(value)}
      borderRadius="full"
      {...cell.getCellProps()}
    >
      {value ? 'Active' : 'Inactive'}
    </Tag>
  )
}

export default React.memo(Active)

import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from 'components/Button'
import Form from 'components/Form'
import FormField from 'components/FormField'
import Switch from 'components/Switch'
import { Flex, Stack } from 'components/Layout'
import { editModel } from './schema'

const AssignRole = ({ onClose, onSubmit, values }) => {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty }
  } = useForm({
    resolver: yupResolver(editModel),
    defaultValues: {
      ...values
    }
  })

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      disabled={isSubmitting}
    >
      <Stack spacing={6} pb={2}>
        <Flex>
          <FormField
            id="masterData"
            mr={4}
            isInvalid={errors.masterData}
            error={errors.masterData}
            label="MASTER DATA"
          >
            <Switch
              id="masterData"
              name="masterData"
              {...register('masterData')}
            />
          </FormField>
          <FormField
            id="masterDataView"
            mr={4}
            isInvalid={errors.masterDataView}
            error={errors.masterDataView}
            label="View"
          >
            <Switch
              id="masterDataView"
              name="masterDataView"
              {...register('masterDataView')}
            />
          </FormField>
          <FormField
            id="masterDataUpdate"
            mr={4}
            isInvalid={errors.masterDataUpdate}
            error={errors.masterDataUpdate}
            label="Update"
          >
            <Switch
              id="masterDataUpdate"
              name="masterDataUpdate"
              {...register('masterDataUpdate')}
            />
          </FormField>
          <FormField
            id="masterDataCreate"
            mr={4}
            isInvalid={errors.masterDataCreate}
            error={errors.masterDataCreate}
            label="Create"
          >
            <Switch
              id="masterDataCreate"
              name="masterDataCreate"
              {...register('masterDataCreate')}
            />
          </FormField>
          <FormField
            id="masterDataDelete"
            mr={4}
            isInvalid={errors.masterDataDelete}
            error={errors.masterDataDelete}
            label="Delete"
          >
            <Switch
              id="masterDataDelete"
              name="masterDataDelete"
              {...register('masterDataDelete')}
            />
          </FormField>
        </Flex>
        <hr />
        <Flex>
          <FormField
            id="draftDocument"
            mr={4}
            isInvalid={errors.draftDocument}
            error={errors.draftDocument}
            label="DRAFT DOCUMENT"
          >
            <Switch
              id="draftDocument"
              name="draftDocument"
              {...register('draftDocument')}
            />
          </FormField>
          <FormField
            id="draftDocumentView"
            mr={4}
            isInvalid={errors.draftDocumentView}
            error={errors.draftDocumentView}
            label="View"
          >
            <Switch
              id="draftDocumentView"
              name="draftDocumentView"
              {...register('draftDocumentView')}
            />
          </FormField>
          <FormField
            id="draftDocumentUpdate"
            mr={4}
            isInvalid={errors.draftDocumentUpdate}
            error={errors.draftDocumentUpdate}
            label="Update"
          >
            <Switch
              id="draftDocumentUpdate"
              name="draftDocumentUpdate"
              {...register('draftDocumentUpdate')}
            />
          </FormField>
          <FormField
            id="draftDocumentCreate"
            mr={4}
            isInvalid={errors.draftDocumentCreate}
            error={errors.draftDocumentCreate}
            label="Create"
          >
            <Switch
              id="draftDocumentCreate"
              name="draftDocumentCreate"
              {...register('draftDocumentCreate')}
            />
          </FormField>
          <FormField
            id="draftDocumentDelete"
            mr={4}
            isInvalid={errors.draftDocumentDelete}
            error={errors.draftDocumentDelete}
            label="Delete"
          >
            <Switch
              id="draftDocumentDelete"
              name="draftDocumentDelete"
              {...register('draftDocumentDelete')}
            />
          </FormField>
        </Flex>
        <hr />
        <Flex>
          <FormField
            id="session"
            mr={4}
            isInvalid={errors.session}
            error={errors.session}
            label="SESSION"
          >
            <Switch
              id="session"
              name="session"
              {...register('session')}
            />
          </FormField>
          <FormField
            id="sessionView"
            mr={4}
            isInvalid={errors.sessionView}
            error={errors.sessionView}
            label="View"
          >
            <Switch
              id="sessionView"
              name="sessionView"
              {...register('sessionView')}
            />
          </FormField>
          <FormField
            id="sessionUpdate"
            mr={4}
            isInvalid={errors.sessionUpdate}
            error={errors.sessionUpdate}
            label="Update"
          >
            <Switch
              id="sessionUpdate"
              name="sessionUpdate"
              {...register('sessionUpdate')}
            />
          </FormField>
          <FormField
            id="sessionCreate"
            mr={4}
            isInvalid={errors.sessionCreate}
            error={errors.sessionCreate}
            label="Create"
          >
            <Switch
              id="sessionCreate"
              name="sessionCreate"
              {...register('sessionCreate')}
            />
          </FormField>
          <FormField
            id="sessionDelete"
            mr={4}
            isInvalid={errors.sessionDelete}
            error={errors.sessionDelete}
            label="Delete"
          >
            <Switch
              id="sessionDelete"
              name="sessionDelete"
              {...register('sessionDelete')}
            />
          </FormField>
        </Flex>
        <hr />
        <Flex>
          <FormField
            id="minutes"
            mr={4}
            isInvalid={errors.minutes}
            error={errors.minutes}
            label="MINUTES"
          >
            <Switch
              id="minutes"
              name="minutes"
              {...register('minutes')}
            />
          </FormField>
          <FormField
            id="minutesView"
            mr={4}
            isInvalid={errors.minutesView}
            error={errors.minutesView}
            label="View"
          >
            <Switch
              id="minutesView"
              name="minutesView"
              {...register('minutesView')}
            />
          </FormField>
          <FormField
            id="minutesUpdate"
            mr={4}
            isInvalid={errors.minutesUpdate}
            error={errors.minutesUpdate}
            label="Update"
          >
            <Switch
              id="minutesUpdate"
              name="minutesUpdate"
              {...register('minutesUpdate')}
            />
          </FormField>
          <FormField
            id="minutesCreate"
            mr={4}
            isInvalid={errors.minutesCreate}
            error={errors.minutesCreate}
            label="Create"
          >
            <Switch
              id="minutesCreate"
              name="minutesCreate"
              {...register('minutesCreate')}
            />
          </FormField>
          <FormField
            id="minutesDelete"
            mr={4}
            isInvalid={errors.minutesDelete}
            error={errors.minutesDelete}
            label="Delete"
          >
            <Switch
              id="minutesDelete"
              name="minutesDelete"
              {...register('minutesDelete')}
            />
          </FormField>
        </Flex>
        <hr />
        <Flex>
          <FormField
            id="documentTracking"
            mr={4}
            isInvalid={errors.documentTracking}
            error={errors.documentTracking}
            label="DOCUMENT TRACKING"
          >
            <Switch
              id="documentTracking"
              name="documentTracking"
              {...register('documentTracking')}
            />
          </FormField>
          <FormField
            id="documentTrackingView"
            mr={4}
            isInvalid={errors.documentTrackingView}
            error={errors.documentTrackingView}
            label="View"
          >
            <Switch
              id="documentTrackingView"
              name="documentTrackingView"
              {...register('documentTrackingView')}
            />
          </FormField>
          <FormField
            id="documentTrackingUpdate"
            mr={4}
            isInvalid={errors.documentTrackingUpdate}
            error={errors.documentTrackingUpdate}
            label="Update"
          >
            <Switch
              id="documentTrackingUpdate"
              name="documentTrackingUpdate"
              {...register('documentTrackingUpdate')}
            />
          </FormField>
          <FormField
            id="documentTrackingCreate"
            mr={4}
            isInvalid={errors.documentTrackingCreate}
            error={errors.documentTrackingCreate}
            label="Create"
          >
            <Switch
              id="documentTrackingCreate"
              name="documentTrackingCreate"
              {...register('documentTrackingCreate')}
            />
          </FormField>
          <FormField
            id="documentTrackingDelete"
            mr={4}
            isInvalid={errors.documentTrackingDelete}
            error={errors.documentTrackingDelete}
            label="Delete"
          >
            <Switch
              id="documentTrackingDelete"
              name="documentTrackingDelete"
              {...register('documentTrackingDelete')}
            />
          </FormField>
        </Flex>
        <hr />
        <Flex>
          <FormField
            id="committeeReports"
            mr={4}
            isInvalid={errors.committeeReports}
            error={errors.committeeReports}
            label="COMMITTEE REPORTS"
          >
            <Switch
              id="committeeReports"
              name="committeeReports"
              {...register('committeeReports')}
            />
          </FormField>
          <FormField
            id="committeeReportsView"
            mr={4}
            isInvalid={errors.committeeReportsView}
            error={errors.committeeReportsView}
            label="View"
          >
            <Switch
              id="committeeReportsView"
              name="committeeReportsView"
              {...register('committeeReportsView')}
            />
          </FormField>
          <FormField
            id="committeeReportsUpdate"
            mr={4}
            isInvalid={errors.committeeReportsUpdate}
            error={errors.committeeReportsUpdate}
            label="Update"
          >
            <Switch
              id="committeeReportsUpdate"
              name="committeeReportsUpdate"
              {...register('committeeReportsUpdate')}
            />
          </FormField>
          <FormField
            id="committeeReportsCreate"
            mr={4}
            isInvalid={errors.committeeReportsCreate}
            error={errors.committeeReportsCreate}
            label="Create"
          >
            <Switch
              id="committeeReportsCreate"
              name="committeeReportsCreate"
              {...register('committeeReportsCreate')}
            />
          </FormField>
          <FormField
            id="committeeReportsDelete"
            mr={4}
            isInvalid={errors.committeeReportsDelete}
            error={errors.committeeReportsDelete}
            label="Delete"
          >
            <Switch
              id="committeeReportsDelete"
              name="committeeReportsDelete"
              {...register('committeeReportsDelete')}
            />
          </FormField>
        </Flex>
        <hr />
        <Flex>
          <FormField
            id="chat"
            mr={4}
            isInvalid={errors.chat}
            error={errors.chat}
            label="CHAT"
          >
            <Switch
              id="chat"
              name="chat"
              {...register('chat')}
            />
          </FormField>
          <FormField
            id="chatView"
            mr={4}
            isInvalid={errors.chatView}
            error={errors.chatView}
            label="View"
          >
            <Switch
              id="chatView"
              name="chatView"
              {...register('chatView')}
            />
          </FormField>
          <FormField
            id="chatUpdate"
            mr={4}
            isInvalid={errors.chatUpdate}
            error={errors.chatUpdate}
            label="Update"
          >
            <Switch
              id="chatUpdate"
              name="chatUpdate"
              {...register('chatUpdate')}
            />
          </FormField>
          <FormField
            id="chatCreate"
            mr={4}
            isInvalid={errors.chatCreate}
            error={errors.chatCreate}
            label="Create"
          >
            <Switch
              id="chatCreate"
              name="chatCreate"
              {...register('chatCreate')}
            />
          </FormField>
          <FormField
            id="chatDelete"
            mr={4}
            isInvalid={errors.chatDelete}
            error={errors.chatDelete}
            label="Delete"
          >
            <Switch
              id="chatDelete"
              name="chatDelete"
              {...register('chatDelete')}
            />
          </FormField>
        </Flex>
        <hr />
        <Flex>
          <FormField
            id="archive"
            mr={4}
            isInvalid={errors.archive}
            error={errors.archive}
            label="ARCHIVE"
          >
            <Switch
              id="archive"
              name="archive"
              {...register('archive')}
            />
          </FormField>
          <FormField
            id="archiveView"
            mr={4}
            isInvalid={errors.archiveView}
            error={errors.archiveView}
            label="View"
          >
            <Switch
              id="archiveView"
              name="archiveView"
              {...register('archiveView')}
            />
          </FormField>
          <FormField
            id="archiveUpdate"
            mr={4}
            isInvalid={errors.archiveUpdate}
            error={errors.archiveUpdate}
            label="Update"
          >
            <Switch
              id="archiveUpdate"
              name="archiveUpdate"
              {...register('archiveUpdate')}
            />
          </FormField>
          <FormField
            id="archiveCreate"
            mr={4}
            isInvalid={errors.archiveCreate}
            error={errors.archiveCreate}
            label="Create"
          >
            <Switch
              id="archiveCreate"
              name="archiveCreate"
              {...register('archiveCreate')}
            />
          </FormField>
          <FormField
            id="archiveDelete"
            mr={4}
            isInvalid={errors.archiveDelete}
            error={errors.archiveDelete}
            label="Delete"
          >
            <Switch
              id="archiveDelete"
              name="archiveDelete"
              {...register('archiveDelete')}
            />
          </FormField>
        </Flex>
        <hr />
        <Flex>
          <FormField
            id="notes"
            mr={4}
            isInvalid={errors.notes}
            error={errors.notes}
            label="NOTES"
          >
            <Switch
              id="notes"
              name="notes"
              {...register('notes')}
            />
          </FormField>
          <FormField
            id="notesView"
            mr={4}
            isInvalid={errors.notesView}
            error={errors.notesView}
            label="View"
          >
            <Switch
              id="notesView"
              name="notesView"
              {...register('notesView')}
            />
          </FormField>
          <FormField
            id="notesUpdate"
            mr={4}
            isInvalid={errors.notesUpdate}
            error={errors.notesUpdate}
            label="Update"
          >
            <Switch
              id="notesUpdate"
              name="notesUpdate"
              {...register('notesUpdate')}
            />
          </FormField>
          <FormField
            id="notesCreate"
            mr={4}
            isInvalid={errors.notesCreate}
            error={errors.notesCreate}
            label="Create"
          >
            <Switch
              id="notesCreate"
              name="notesCreate"
              {...register('notesCreate')}
            />
          </FormField>
          <FormField
            id="notesDelete"
            mr={4}
            isInvalid={errors.notesDelete}
            error={errors.notesDelete}
            label="Delete"
          >
            <Switch
              id="notesDelete"
              name="notesDelete"
              {...register('notesDelete')}
            />
          </FormField>
        </Flex>
        <Flex>
          <FormField
            id="committeeHearingMinutes"
            mr={4}
            isInvalid={errors.committeeHearingMinutes}
            error={errors.committeeHearingMinutes}
            label="Committee Hearing Minutes"
          >
            <Switch
              id="committeeHearingMinutes"
              name="committeeHearingMinutes"
              {...register('committeeHearingMinutes')}
            />
          </FormField>
          <FormField
            id="committeeHearingMinutesView"
            mr={4}
            isInvalid={errors.committeeHearingMinutesView}
            error={errors.committeeHearingMinutesView}
            label="View"
          >
            <Switch
              id="committeeHearingMinutesView"
              name="committeeHearingMinutesView"
              {...register('committeeHearingMinutesView')}
            />
          </FormField>
          <FormField
            id="committeeHearingMinutesUpdate"
            mr={4}
            isInvalid={errors.committeeHearingMinutesUpdate}
            error={errors.committeeHearingMinutesUpdate}
            label="Update"
          >
            <Switch
              id="committeeHearingMinutesUpdate"
              name="committeeHearingMinutesUpdate"
              {...register('committeeHearingMinutesUpdate')}
            />
          </FormField>
          <FormField
            id="committeeHearingMinutesCreate"
            mr={4}
            isInvalid={errors.committeeHearingMinutesCreate}
            error={errors.committeeHearingMinutesCreate}
            label="Create"
          >
            <Switch
              id="committeeHearingMinutesCreate"
              name="committeeHearingMinutesCreate"
              {...register('committeeHearingMinutesCreate')}
            />
          </FormField>
          <FormField
            id="committeeHearingMinutesDelete"
            mr={4}
            isInvalid={errors.committeeHearingMinutesDelete}
            error={errors.committeeHearingMinutesDelete}
            label="Delete"
          >
            <Switch
              id="committeeHearingMinutesDelete"
              name="committeeHearingMinutesDelete"
              {...register('committeeHearingMinutesDelete')}
            />
          </FormField>
        </Flex>
        <Flex>
          <FormField
            id="committeeMeetingMinutes"
            mr={4}
            isInvalid={errors.committeeMeetingMinutes}
            error={errors.committeeMeetingMinutes}
            label="Committee Meeting Minutes"
          >
            <Switch
              id="committeeMeetingMinutes"
              name="committeeMeetingMinutes"
              {...register('committeeMeetingMinutes')}
            />
          </FormField>
          <FormField
            id="committeeMeetingMinutesView"
            mr={4}
            isInvalid={errors.committeeMeetingMinutesView}
            error={errors.committeeMeetingMinutesView}
            label="View"
          >
            <Switch
              id="committeeMeetingMinutesView"
              name="committeeMeetingMinutesView"
              {...register('committeeMeetingMinutesView')}
            />
          </FormField>
          <FormField
            id="committeeMeetingMinutesUpdate"
            mr={4}
            isInvalid={errors.committeeMeetingMinutesUpdate}
            error={errors.committeeMeetingMinutesUpdate}
            label="Update"
          >
            <Switch
              id="committeeMeetingMinutesUpdate"
              name="committeeMeetingMinutesUpdate"
              {...register('committeeMeetingMinutesUpdate')}
            />
          </FormField>
          <FormField
            id="committeeMeetingMinutesCreate"
            mr={4}
            isInvalid={errors.committeeMeetingMinutesCreate}
            error={errors.committeeMeetingMinutesCreate}
            label="Create"
          >
            <Switch
              id="committeeMeetingMinutesCreate"
              name="committeeMeetingMinutesCreate"
              {...register('committeeMeetingMinutesCreate')}
            />
          </FormField>
          <FormField
            id="committeeMeetingMinutesDelete"
            mr={4}
            isInvalid={errors.committeeMeetingMinutesDelete}
            error={errors.committeeMeetingMinutesDelete}
            label="Delete"
          >
            <Switch
              id="committeeMeetingMinutesDelete"
              name="committeeMeetingMinutesDelete"
              {...register('committeeMeetingMinutesDelete')}
            />
          </FormField>
        </Flex>
        <Flex>
          <FormField
            id="publicHearingMinutes"
            mr={4}
            isInvalid={errors.publicHearingMinutes}
            error={errors.publicHearingMinutes}
            label="Public Hearing Minutes"
          >
            <Switch
              id="publicHearingMinutes"
              name="publicHearingMinutes"
              {...register('publicHearingMinutes')}
            />
          </FormField>
          <FormField
            id="publicHearingMinutesView"
            mr={4}
            isInvalid={errors.publicHearingMinutesView}
            error={errors.publicHearingMinutesView}
            label="View"
          >
            <Switch
              id="publicHearingMinutesView"
              name="publicHearingMinutesView"
              {...register('publicHearingMinutesView')}
            />
          </FormField>
          <FormField
            id="publicHearingMinutesUpdate"
            mr={4}
            isInvalid={errors.publicHearingMinutesUpdate}
            error={errors.publicHearingMinutesUpdate}
            label="Update"
          >
            <Switch
              id="publicHearingMinutesUpdate"
              name="publicHearingMinutesUpdate"
              {...register('publicHearingMinutesUpdate')}
            />
          </FormField>
          <FormField
            id="publicHearingMinutesCreate"
            mr={4}
            isInvalid={errors.publicHearingMinutesCreate}
            error={errors.publicHearingMinutesCreate}
            label="Create"
          >
            <Switch
              id="publicHearingMinutesCreate"
              name="publicHearingMinutesCreate"
              {...register('publicHearingMinutesCreate')}
            />
          </FormField>
          <FormField
            id="publicHearingMinutesDelete"
            mr={4}
            isInvalid={errors.publicHearingMinutesDelete}
            error={errors.publicHearingMinutesDelete}
            label="Delete"
          >
            <Switch
              id="publicHearingMinutesDelete"
              name="publicHearingMinutesDelete"
              {...register('publicHearingMinutesDelete')}
            />
          </FormField>
        </Flex>
        <Flex>
          <FormField
            id="transcript"
            mr={4}
            isInvalid={errors.transcript}
            error={errors.transcript}
            label="Transcript"
          >
            <Switch
              id="transcript"
              name="transcript"
              {...register('transcript')}
            />
          </FormField>
          <FormField
            id="transcriptView"
            mr={4}
            isInvalid={errors.transcriptView}
            error={errors.transcriptView}
            label="View"
          >
            <Switch
              id="transcriptView"
              name="transcriptView"
              {...register('transcriptView')}
            />
          </FormField>
          <FormField
            id="transcriptUpdate"
            mr={4}
            isInvalid={errors.transcriptUpdate}
            error={errors.transcriptUpdate}
            label="Update"
          >
            <Switch
              id="transcriptUpdate"
              name="transcriptUpdate"
              {...register('transcriptUpdate')}
            />
          </FormField>
          <FormField
            id="transcriptCreate"
            mr={4}
            isInvalid={errors.transcriptCreate}
            error={errors.transcriptCreate}
            label="Create"
          >
            <Switch
              id="transcriptCreate"
              name="transcriptCreate"
              {...register('transcriptCreate')}
            />
          </FormField>
          <FormField
            id="transcriptDelete"
            mr={4}
            isInvalid={errors.transcriptDelete}
            error={errors.transcriptDelete}
            label="Delete"
          >
            <Switch
              id="transcriptDelete"
              name="transcriptDelete"
              {...register('transcriptDelete')}
            />
          </FormField>
        </Flex>
        <Flex>
          <FormField
            id="committeeReferrals"
            mr={4}
            isInvalid={errors.committeeReferrals}
            error={errors.committeeReferrals}
            label="Committee Referrals"
          >
            <Switch
              id="committeeReferrals"
              name="committeeReferrals"
              {...register('committeeReferrals')}
            />
          </FormField>
          <FormField
            id="committeeReferralsView"
            mr={4}
            isInvalid={errors.committeeReferralsView}
            error={errors.committeeReferralsView}
            label="View"
          >
            <Switch
              id="committeeReferralsView"
              name="committeeReferralsView"
              {...register('committeeReferralsView')}
            />
          </FormField>
          <FormField
            id="committeeReferralsUpdate"
            mr={4}
            isInvalid={errors.committeeReferralsUpdate}
            error={errors.committeeReferralsUpdate}
            label="Update"
          >
            <Switch
              id="committeeReferralsUpdate"
              name="committeeReferralsUpdate"
              {...register('committeeReferralsUpdate')}
            />
          </FormField>
          <FormField
            id="committeeReferralsCreate"
            mr={4}
            isInvalid={errors.committeeReferralsCreate}
            error={errors.committeeReferralsCreate}
            label="Create"
          >
            <Switch
              id="committeeReferralsCreate"
              name="committeeReferralsCreate"
              {...register('committeeReferralsCreate')}
            />
          </FormField>
          <FormField
            id="committeeReferralsDelete"
            mr={4}
            isInvalid={errors.committeeReferralsDelete}
            error={errors.committeeReferralsDelete}
            label="Delete"
          >
            <Switch
              id="committeeReferralsDelete"
              name="committeeReferralsDelete"
              {...register('committeeReferralsDelete')}
            />
          </FormField>
        </Flex>
        <Flex>
          <FormField
            id="finalDocument"
            mr={4}
            isInvalid={errors.finalDocument}
            error={errors.finalDocument}
            label="Final Document"
          >
            <Switch
              id="finalDocument"
              name="finalDocument"
              {...register('finalDocument')}
            />
          </FormField>
          <FormField
            id="finalDocumentView"
            mr={4}
            isInvalid={errors.finalDocumentView}
            error={errors.finalDocumentView}
            label="View"
          >
            <Switch
              id="finalDocumentView"
              name="finalDocumentView"
              {...register('finalDocumentView')}
            />
          </FormField>
          <FormField
            id="finalDocumentUpdate"
            mr={4}
            isInvalid={errors.finalDocumentUpdate}
            error={errors.finalDocumentUpdate}
            label="Update"
          >
            <Switch
              id="finalDocumentUpdate"
              name="finalDocumentUpdate"
              {...register('finalDocumentUpdate')}
            />
          </FormField>
          <FormField
            id="finalDocumentCreate"
            mr={4}
            isInvalid={errors.finalDocumentCreate}
            error={errors.finalDocumentCreate}
            label="Create"
          >
            <Switch
              id="finalDocumentCreate"
              name="finalDocumentCreate"
              {...register('finalDocumentCreate')}
            />
          </FormField>
          <FormField
            id="finalDocumentDelete"
            mr={4}
            isInvalid={errors.finalDocumentDelete}
            error={errors.finalDocumentDelete}
            label="Delete"
          >
            <Switch
              id="finalDocumentDelete"
              name="finalDocumentDelete"
              {...register('finalDocumentDelete')}
            />
          </FormField>
        </Flex>
        <Flex>
          <FormField
            id="reports"
            mr={4}
            isInvalid={errors.reports}
            error={errors.reports}
            label="Reports"
          >
            <Switch
              id="reports"
              name="reports"
              {...register('reports')}
            />
          </FormField>
        </Flex>
        <Flex mt={5} justify="flex-end">
          <Button mr={2} variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            isLoading={isSubmitting}
            disabled={isSubmitting || !isDirty}
            type="submit"
          >
            Submit
          </Button>
        </Flex>
      </Stack>
    </Form>
  )
}

export default AssignRole

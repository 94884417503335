import React from 'react'
import { useDisclosure } from '@chakra-ui/react'

import PageWrapper from 'layout/PageWrapper'
import { Box } from 'components/Layout'
import Modal from 'components/Modal'
import Table from 'components/Table'
import TableHeader from 'components/Table/TableHeader'
import { FiPlus } from 'react-icons/fi'
import useRoleAccessContext from 'containers/RoleAccess/useRoleAccessContext'
import Button from 'components/Button'
import useRoutePagination from 'utils/hooks/useRoutePagination'
import useSearch from 'utils/hooks/useSearch'

import CreateUserForm from './Forms/Create'
import { columns } from './columns'
import { useTerm, useMutateTerm } from './api'
import { TermProvider } from './Context'

const Term = () => {
  const { page } = useRoutePagination()
  const [search, setSearch] = useSearch()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { access = [] } = useRoleAccessContext()
  const {
    term = {},
    termIsLoading,
    termRevalidate
  } = useTerm({ page, search })

  const { data, totalPages } = term

  const { createTerm } = useMutateTerm()

  const handleCreateTerm = async (value) => {
    await createTerm(value)

    termRevalidate()
    onClose()
  }

  return (
    <TermProvider value={{ revalidate: termRevalidate }}>
      <Box>
        <PageWrapper
          headerRightComponent={
            access['master-data'] &&
            access['master-data'].includes('create') ? (
              <Button
                leftIcon={<FiPlus />}
                onClick={() => onOpen()}
              >
                New Term
              </Button>
            ) : (
              false
            )
          }
        >
          <TableHeader
            totalPages={totalPages}
            onSearch={setSearch}
          />
          <Table
            isLoading={termIsLoading}
            data={data}
            columns={columns}
          />
        </PageWrapper>

        <Modal
          title="Create Term"
          isOpen={isOpen}
          onClose={onClose}
          withActionButtons={false}
          closeOnOverlayClick={false}
        >
          <CreateUserForm
            onClose={onClose}
            onSubmit={handleCreateTerm}
          />
        </Modal>
      </Box>
    </TermProvider>
  )
}

export default Term

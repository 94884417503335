import React, { useContext } from 'react'

const CommitteeMeetingMinutesContext = React.createContext(null)

export const CommitteeMeetingMinutesProvider = ({
  children,
  value
}) => (
  <CommitteeMeetingMinutesContext.Provider value={value}>
    {children}
  </CommitteeMeetingMinutesContext.Provider>
)

export const useCommitteeMeetingMinutesContext = () => {
  return useContext(CommitteeMeetingMinutesContext)
}

export default CommitteeMeetingMinutesContext

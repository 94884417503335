import React from 'react'
import { useDisclosure } from '@chakra-ui/react'
import { generateFormData } from 'utils/helpers'
import Modal from 'components/Modal'
import { ActionsRenderer } from 'components/CellRenderers'
import AlertDialog from 'components/AlertDialog'
import useRoleAccessContext from 'containers/RoleAccess/useRoleAccessContext'
import Edit from './Forms/Edit'
import View from './View/view'
import {
  useMutateFinalDocument,
  useDraftDocument,
  useDocumentType
} from './api'
import { useFinalDocumentContext } from './Context'

const TableActions = ({ row, value: id }) => {
  const { original } = row
  const { updateFinalDocument, deleteFinalDocument, isLoading } =
    useMutateFinalDocument()
  const { revalidate } = useFinalDocumentContext()
  const { access = [] } = useRoleAccessContext()
  const { draftDocument } = useDraftDocument()
  const { documentType } = useDocumentType()

  const {
    isOpen: editIsOpen,
    onOpen: editOnOpen,
    onClose: editOnClose
  } = useDisclosure()
  const {
    isOpen: deleteIsOpen,
    onOpen: deleteOnOpen,
    onClose: deleteOnClose
  } = useDisclosure()
  const {
    isOpen: viewIsOpen,
    onOpen: viewOnOpen,
    onClose: viewOnClose
  } = useDisclosure()

  const handleEdit = async (formValues) => {
    const { file = [], attachments = [], ...rest } = formValues

    const payload = {
      file: file && file.length > 0 ? file[0] : null,
      attachments:
        attachments && attachments.length > 0
          ? attachments[0]
          : null,
      ...rest
    }

    await updateFinalDocument(id, generateFormData(payload))

    revalidate()
    editOnClose()
  }

  const handleDelete = async () => {
    await deleteFinalDocument(id)

    revalidate()
    deleteOnClose()
  }

  return (
    <>
      <ActionsRenderer
        onEditClick={editOnOpen}
        onDeleteClick={deleteOnOpen}
        values={original}
        onViewClick={viewOnOpen}
        edit={access.minutes && access.minutes.includes('edit')}
        delete={
          access.minutes && access.minutes.includes('delete')
        }
      />
      <Modal
        title="Update Document"
        isOpen={editIsOpen}
        onClose={editOnClose}
        withActionButtons={false}
        closeOnOverlayClick={false}
        size="5xl"
      >
        <Edit
          onClose={editOnClose}
          onSubmit={handleEdit}
          values={original}
          draftDocument={draftDocument}
          documentType={documentType}
        />
      </Modal>
      <Modal
        title="View Document"
        isOpen={viewIsOpen}
        onClose={viewOnClose}
        withActionButtons={false}
        closeOnOverlayClick={false}
        size="5xl"
      >
        <View values={original} />
      </Modal>

      <AlertDialog
        isLoading={isLoading}
        isOpen={deleteIsOpen}
        onClose={deleteOnClose}
        onConfirm={handleDelete}
        title="Delete Document"
      />
    </>
  )
}

export default TableActions

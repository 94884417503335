import {
  // CommitteeRenderer,
  DraftDocumentRenderer,
  CommitteeActions
} from 'components/CellRenderers'
import TableActions from './TableActions'

const columns = [
  {
    Header: 'Code',
    accessor: 'code'
  },
  {
    Header: 'Short Title',
    accessor: 'shortTitle'
  },
  {
    Header: 'Committees',
    accessor: 'committee',
    Cell: CommitteeActions
  },
  {
    Header: 'Drafts',
    accessor: 'drafts',
    Cell: DraftDocumentRenderer
  },
  {
    Header: 'Created By',
    accessor: (val) => {
      return `${val?.user?.firstName} ${val?.user?.lastName}`
    }
  },
  {
    Header: 'Action',
    accessor: 'id',
    fixed: 'right',
    Cell: TableActions
  }
]

export { columns }

const conversations = [
  {
    id: 1,
    name: 'Cloyd Alcantara',
    lastMessage: 'Hehe!',
    date: '2022-01-12',
    read: false
  }
]

const messages = [
  {
    id: 1,
    me: false,
    message: 'Sige check ko pre. Ready mo nalamg',
    date: '2022-01-01'
  },
  {
    id: 2,
    me: true,
    message: 'Lorem ipsum ',
    date: '2022-01-01'
  }
]

export { conversations, messages }

import React, { useContext } from 'react'

const CommitteeHearingMinutesContext = React.createContext(null)

export const CommitteeHearingMinutesProvider = ({
  children,
  value
}) => (
  <CommitteeHearingMinutesContext.Provider value={value}>
    {children}
  </CommitteeHearingMinutesContext.Provider>
)

export const useCommitteeHearingMinutesContext = () => {
  return useContext(CommitteeHearingMinutesContext)
}

export default CommitteeHearingMinutesContext

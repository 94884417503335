import localForage from 'localforage'

const getItem = (key) => localForage.getItem(key)

const setItem = (...args) => localForage.setItem(...args)

const removeItem = (key) => localForage.removeItem(key)

const clear = () => localForage.clear()

export { setItem, getItem, removeItem, clear }

import React, { useContext } from 'react'

const MinutesContext = React.createContext(null)

export const MinutesProvider = ({ children, value }) => (
  <MinutesContext.Provider value={value}>
    {children}
  </MinutesContext.Provider>
)

export const useMinutesContext = () => {
  return useContext(MinutesContext)
}

export default MinutesContext

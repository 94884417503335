import { Spinner } from '@chakra-ui/react'
import React from 'react'
import { LoadingOverlaySt } from './styles'

const LoadingOverlay = ({ isLoading, solid }) => {
  if (!isLoading) return null

  return (
    <LoadingOverlaySt solid={solid}>
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="base.500"
        size="lg"
      />
    </LoadingOverlaySt>
  )
}

export default LoadingOverlay

import { useCallback, useState } from 'react'
import useSWR from 'swr'

import {
  deleteRequest,
  postRequest,
  putRequest
} from 'utils/request'
import errorHandler from 'utils/errorHandler'
import { buildUrl } from 'utils/transformers/apiUrl'

import { COMMITTEE_ROUTE, TERM_ROUTE } from './constants'

const useCommittee = ({ page, id, search } = {}) => {
  const url = buildUrl({
    baseUrl: COMMITTEE_ROUTE,
    search,
    page,
    id
  })

  const {
    data: committee,
    error: committeeError,
    isValidating: committeeIsValidating,
    mutate: committeeRevalidate
  } = useSWR(url)

  const committeeIsLoading =
    !committee && !committeeError && committeeIsValidating

  return {
    committee,
    committeeError,
    committeeIsValidating,
    committeeIsLoading,
    committeeRevalidate
  }
}

const useTerm = ({ page, id, search } = {}) => {
  const url = buildUrl({
    baseUrl: TERM_ROUTE,
    search,
    page,
    id
  })

  const {
    data: term,
    error: termError,
    isValidating: termIsValidating,
    mutate: termRevalidate
  } = useSWR(url)

  const termIsLoading = !term && !termError && termIsValidating

  return {
    term,
    termError,
    termIsValidating,
    termIsLoading,
    termRevalidate
  }
}

const useMutateCommittee = () => {
  const [response, setResponse] = useState()
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const resetState = useCallback(() => {
    if (response) setResponse(undefined)
    if (isLoading) setError(undefined)
  }, [isLoading, response])

  const createCommittee = useCallback(
    async (payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await postRequest(COMMITTEE_ROUTE, payload)
        setResponse(data)
        setIsLoading(false)
        return data
      } catch (err) {
        setError(errorHandler(err))
        setIsLoading(false)
      }
      return null
    },
    [resetState]
  )

  const updateCommittee = useCallback(
    async (id, payload = {}) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await putRequest(
          `${COMMITTEE_ROUTE}/${id}`,
          payload
        )
        setResponse(data)
        setIsLoading(false)
        return data
      } catch (err) {
        setError(errorHandler(err))
        setIsLoading(false)
      }
      return null
    },
    [resetState]
  )

  const deleteCommittee = useCallback(
    async (id) => {
      resetState()
      setIsLoading(true)
      try {
        const data = await deleteRequest(
          `${COMMITTEE_ROUTE}/${id}`
        )
        setResponse(data)
        setIsLoading(false)
        return data
      } catch (err) {
        setError(errorHandler(err))
        setIsLoading(false)
      }
      return null
    },
    [resetState]
  )

  return {
    data: response,
    error,
    isLoading,
    createCommittee,
    updateCommittee,
    deleteCommittee
  }
}

export { useCommittee, useMutateCommittee, useTerm }

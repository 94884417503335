import React from 'react'
import { FiEdit2, FiTrash, FiSave } from 'react-icons/fi'
import { Tooltip } from '@chakra-ui/react'

import Button from 'components/Button'
import { HStack } from 'components/Layout'

const CommitteeDocument = ({
  values,
  deleteTooltipLabel,
  onEditClick,
  onSubmitClick,
  onDeleteClick,
  edit,
  delete: remove
}) => {
  return (
    <HStack direction="row">
      {edit && (
        <Tooltip label="Submit" fontSize="xs">
          <Button
            colorScheme="green"
            onClick={onSubmitClick}
            variant="outline"
            size="sm"
          >
            <FiSave />
          </Button>
        </Tooltip>
      )}
      {edit && (
        <Tooltip label="Edit" fontSize="xs">
          <Button
            colorScheme="blue"
            onClick={onEditClick}
            variant="outline"
            size="sm"
          >
            <FiEdit2 />
          </Button>
        </Tooltip>
      )}
      {remove && (
        <Tooltip
          label={
            deleteTooltipLabel ??
            `Delete ${values?.code ?? values?.draftCode}`
          }
          fontSize="xs"
        >
          <Button
            colorScheme="red"
            variant="solid"
            size="sm"
            onClick={onDeleteClick}
            _focus={{
              boxShadow: '0 0 0 3px rgba(229, 62, 62, 0.5)'
            }}
          >
            <FiTrash />
          </Button>
        </Tooltip>
      )}
    </HStack>
  )
}

export default React.memo(CommitteeDocument)

import * as yup from 'yup'

const messages = {
  requiredGeneric: 'This field is required'
}

const model = yup.object({
  termId: yup.string().required(messages.requiredGeneric),
  shortTitle: yup.string().required(messages.requiredGeneric),
  draftTitle: yup.string().required(messages.requiredGeneric),
  effectiveDate: yup.string().nullable(),
  draftNumber: yup.string(),
  approvedNumber: yup.string(),
  typeId: yup.string().required(messages.requiredGeneric),
  committee: yup.array().nullable(),
  file: yup.mixed().nullable(),
  documentFile: yup.mixed().nullable()
})

const initialValues = {
  draftTitle: '',
  termId: '',
  shortTitle: '',
  effectiveDate: '',
  draftNumber: '',
  typeId: '',
  committee: [],
  file: '',
  documentFile: '',
  approvedNumber: ''
}

export { model, initialValues }

/* eslint-disable no-console */
import React from 'react'
import { useDisclosure } from '@chakra-ui/react'
import useRoleAccessContext from 'containers/RoleAccess/useRoleAccessContext'
import { FiPlus } from 'react-icons/fi'

import { Box } from 'components/Layout'
import Modal from 'components/Modal'
import Table from 'components/Table'
import Button from 'components/Button'
import PageWrapper from 'layout/PageWrapper'
import useRoutePagination from 'utils/hooks/useRoutePagination'
import Pagination from 'components/Pagination'
import { generateFormData } from 'utils/helpers'
import TableHeader from 'components/Table/TableHeader'
import useSearch from 'utils/hooks/useSearch'
import Create from './Forms/Create'

import { columns } from './columns'
import {
  useArchived,
  useCommittee,
  useMutateArchived,
  useTerm
} from './api'
import { ArchivedProvider } from './Context'
import { useDocumentTypeOptions } from '../DocumentType/api'

const Archived = () => {
  const [filter, setFilter] = React.useState('')
  const [year, setYear] = React.useState('')
  const [committeeData, setCommitteeData] = React.useState('')
  const [termData, setTerm] = React.useState('')
  const { page, ...pageProps } = useRoutePagination()
  const [search, setSearch] = useSearch()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { access = [] } = useRoleAccessContext()
  const {
    archived = {},
    archivedIsLoading,
    archivedRevalidate
  } = useArchived({
    page,
    search,
    filter,
    year,
    termData,
    committeeData
  })
  const { committee } = useCommittee()
  const { documentTypeOptions } = useDocumentTypeOptions()

  const { data, totalPages } = archived
  const { term } = useTerm()
  const { createArchive } = useMutateArchived()

  const handleCreateArchived = async (values) => {
    const { file = [], ...rest } = values
    console.log('file', file)
    const payload = {
      file,
      ...rest
    }

    await createArchive(generateFormData(payload))

    archivedRevalidate()
    onClose()
  }

  return (
    <ArchivedProvider value={{ revalidate: archivedRevalidate }}>
      <Box>
        <PageWrapper
          headerRightComponent={
            access.archive &&
            access.archive.includes('create') ? (
              <Button
                leftIcon={<FiPlus />}
                onClick={() => onOpen()}
              >
                New Archived
              </Button>
            ) : (
              false
            )
          }
        >
          {console.log(committeeData)}
          <TableHeader
            committeeData={committee}
            setCommitteeData={setCommitteeData}
            totalPages={totalPages}
            onSearch={setSearch}
            filterData={documentTypeOptions}
            setFilter={setFilter}
            setTerm={setTerm}
            documentType={documentTypeOptions}
            defaultValue
            term={term}
            setYear={setYear}
            year={[
              {
                label: 1999,
                value: 1999
              },
              {
                label: 2000,
                value: 2000
              },
              {
                label: 2001,
                value: 2001
              },
              {
                label: 2002,
                value: 2002
              },
              {
                label: 2003,
                value: 2003
              },
              {
                label: 2004,
                value: 2004
              },
              {
                label: 2005,
                value: 2005
              },
              {
                label: 2006,
                value: 2006
              },
              {
                label: 2007,
                value: 2007
              },
              {
                label: 2008,
                value: 2008
              },
              {
                label: 2009,
                value: 2009
              },
              {
                label: 2010,
                value: 2010
              },
              {
                label: 2011,
                value: 2011
              },
              {
                label: 2012,
                value: 2012
              },
              {
                label: 2013,
                value: 2013
              },
              {
                label: 2014,
                value: 2014
              },
              {
                label: 2015,
                value: 2015
              },
              {
                label: 2016,
                value: 2016
              },
              {
                label: 2017,
                value: 2017
              },
              {
                label: 2018,
                value: 2018
              },
              {
                label: 2019,
                value: 2019
              },
              {
                label: 2020,
                value: 2020
              },
              {
                label: 2021,
                value: 2021
              },
              {
                label: 2022,
                value: 2022
              },
              {
                label: 2023,
                value: 2023
              },
              {
                label: 2024,
                value: 2024
              },
              {
                label: 2025,
                value: 2025
              },
              {
                label: 2026,
                value: 2026
              },
              {
                label: 2027,
                value: 2027
              },
              {
                label: 2028,
                value: 2028
              },
              {
                label: 2029,
                value: 2029
              },
              {
                label: 2030,
                value: 2030
              }
            ]}
          />
          <Table
            isLoading={archivedIsLoading}
            data={data}
            columns={columns}
          />
          {data && (
            <Pagination
              {...pageProps}
              totalPages={totalPages}
              page={page}
              nextDisabled={totalPages === page}
            />
          )}
        </PageWrapper>

        <Modal
          title="Create Archive"
          isOpen={isOpen}
          onClose={onClose}
          withActionButtons={false}
          closeOnOverlayClick={false}
        >
          <Create
            onClose={onClose}
            onSubmit={handleCreateArchived}
            documentTypeOptions={documentTypeOptions}
          />
        </Modal>
      </Box>
    </ArchivedProvider>
  )
}

export default React.memo(Archived)

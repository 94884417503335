import React, { useContext } from 'react'
import { useOutletContext } from 'react-router-dom'

const ChatContext = React.createContext(null)

export const ChatProvider = ({ children, value }) => (
  <ChatContext.Provider value={value}>
    {children}
  </ChatContext.Provider>
)

export const useChatContext = () => {
  return useContext(ChatContext)
}

export const useOutletChatContext = () => {
  return useOutletContext(ChatContext)
}

export default ChatContext

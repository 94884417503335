/* eslint-disable no-unsafe-optional-chaining */
import React, { useState } from 'react'

import { Flex } from 'components/Layout'

import useRoutePagination from 'utils/hooks/useRoutePagination'
import Pagination from 'components/Pagination'
import ReactSelect from 'components/ReactSelect'
import Input from 'components/Input'

const TableHeader = ({
  totalPages,
  onSearch,
  filterData,
  setFilter,
  defaultValue,
  year,
  term,
  setYear,
  setTerm,
  setCommitteeData,
  committeeData,
  sessionType,
  setSessionTypeData,
  sessionTypeData
}) => {
  const [search, setSearch] = useState()
  const { page, ...pageProps } = useRoutePagination()

  return (
    <>
      <Flex minWidth="max-content" alignItems="center" gap="2">
        <Flex>
          {onSearch && (
            <Input
              placeholder="Search..."
              w={300}
              mr={4}
              value={search}
              onChange={(event) => {
                const { value } = event.target
                onSearch(value)
                setSearch(value)
              }}
            />
          )}
        </Flex>
        <Flex>
          {committeeData && committeeData.length > 0 && (
            <div style={{ width: '200px', marginRight: '15px' }}>
              <ReactSelect
                id="committeeId"
                name="committeeId"
                onChange={(val) => setCommitteeData(val.value)}
                placeholder="Committee..."
                options={
                  [
                    { value: '', label: '--- Default ---' },
                    ...committeeData
                  ] || []
                }
              />
            </div>
          )}
        </Flex>
        <Flex>
          {filterData && filterData.length > 0 && (
            <div style={{ width: '200px', marginRight: '15px' }}>
              <ReactSelect
                id="documentType"
                name="documentType"
                onChange={(val) => setFilter(val.value)}
                placeholder="Type..."
                defaultValue={
                  defaultValue || {
                    value: 'COMMUNICATION_RECEIVED',
                    label: 'COMMUNICATION RECEIVED'
                  }
                }
                options={
                  [
                    { value: '', label: '--- Default ---' },
                    ...filterData
                  ] || []
                }
              />
            </div>
          )}
        </Flex>
        <Flex>
          {sessionType && sessionType.length > 0 && (
            <div style={{ width: '200px', marginRight: '15px' }}>
              <ReactSelect
                id="sessionType"
                name="sessionType"
                onChange={(val) => setSessionTypeData(val.value)}
                // value={sessionTypeData}
                placeholder="Type..."
                options={
                  [
                    { value: '', label: '--- Default ---' },
                    ...sessionType
                  ] || []
                }
              />
            </div>
          )}
        </Flex>
        <Flex>
          {term && term.data && term.data.length > 0 && (
            <div style={{ width: '200px', marginRight: '15px' }}>
              <ReactSelect
                id="termId"
                name="termId"
                onChange={(val) => setTerm(val.value)}
                placeholder="Term..."
                options={
                  [
                    { value: '', label: '--- Default ---' },
                    ...term?.data
                  ] || []
                }
              />
            </div>
          )}
        </Flex>
        <Flex>
          {year && year.length > 0 && (
            <div style={{ width: '200px' }}>
              <ReactSelect
                id="year"
                name="year"
                onChange={(val) => setYear(val.value)}
                placeholder="Select Year..."
                options={
                  [
                    { value: '', label: '--- Default ---' },
                    ...year
                  ] || []
                }
              />
            </div>
          )}
        </Flex>
      </Flex>
      <Flex justify="end">
        {totalPages !== 0 && (
          <Pagination
            {...pageProps}
            totalPages={totalPages}
            page={page}
            nextDisabled={totalPages === page}
          />
        )}
      </Flex>
    </>
  )
}

export default TableHeader

import React from 'react'
import { useDisclosure } from '@chakra-ui/react'
import { FiPlus } from 'react-icons/fi'
import useRoleAccessContext from 'containers/RoleAccess/useRoleAccessContext'
import Button from 'components/Button'
import { Box } from 'components/Layout'
import Modal from 'components/Modal'
import Table from 'components/Table'
import useRoutePagination from 'utils/hooks/useRoutePagination'
import PageWrapper from 'layout/PageWrapper'
import { formatToISOString } from 'utils/dateTime'
import { generateFormData } from 'utils/helpers'
import TableHeader from 'components/Table/TableHeader'
import useSearch from 'utils/hooks/useSearch'
import CreateUserForm from './Forms/Create'

import { columns } from './columns'
import { useMutateUsers, useUsers } from './api'
import { UsersManagementProvider } from './Context'

const UserManagement = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [search, setSearch] = useSearch()
  const { page } = useRoutePagination()
  const { access = [] } = useRoleAccessContext()
  const {
    users = {},
    usersIsLoading,
    usersRevalidate
  } = useUsers({
    search,
    page
  })

  const { data, totalPages } = users

  const { createUser } = useMutateUsers()

  const handleCreateUsers = async (values) => {
    const { birthdate, file = [], ...rest } = values

    const payload = {
      birthdate: formatToISOString(birthdate),
      file: file[0],
      ...rest
    }

    await createUser(generateFormData(payload))

    usersRevalidate()
    onClose()
  }

  return (
    <UsersManagementProvider
      value={{ revalidate: usersRevalidate }}
    >
      <Box>
        <PageWrapper
          headerRightComponent={
            access['master-data'] &&
            access['master-data'].includes('create') ? (
              <Button
                leftIcon={<FiPlus />}
                onClick={() => onOpen()}
              >
                New User
              </Button>
            ) : (
              false
            )
          }
        >
          <TableHeader
            totalPages={totalPages}
            onSearch={setSearch}
          />
          <Table
            isLoading={usersIsLoading}
            data={data}
            columns={columns}
          />
        </PageWrapper>
        <Modal
          title="Create User"
          isOpen={isOpen}
          onClose={onClose}
          withActionButtons={false}
          closeOnOverlayClick={false}
          size="3xl"
        >
          <CreateUserForm
            onClose={onClose}
            onSubmit={handleCreateUsers}
          />
        </Modal>
      </Box>
    </UsersManagementProvider>
  )
}

export default UserManagement
